import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientFileService, clientService, employeeBlacklistService, settingGeneralService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import { formatter, log, uploader, validator } from '../../../util'

import moment from 'moment'

// UI
import { Button, SideModal, Loading, List, Panel, FileUpload } from '../../../components'
import AddProviderModal from '../AddProviderModal'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Popover from 'antd/lib/popover'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import { apiHostname } from '../../../config'

const { Item: FormItem } = Form
const Option = Select.Option
const { TextArea } = Input
const { confirm, error } = Modal
const clientModule = 'client'

export class ClientBlacklist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loadingFile: false,
      loadingForm: false,
      loading: false,
      folderFileId: '',
      folderFileSelected: '', // To control selected folder
      selectedProviderId: '',
      selectedProviderName: '',
      isBlacklist: false,
      isEdit: false,
      isFolderFileSelected: false, // To control selected folder
      isShowFileRule: false,
      modalAddProviderShow: false,
      modalShow: false,
      providers: [],
      clientProviders: [],
      settings: [],
      providerServices: [],
      fileList: [],
      folderList: [],
      modalItem: {},
      modalFileItem: {}
    }
  }

  componentDidMount () {
    this.reload()
  }

  reload () {
    this.fetchSettings()
    this.fetchClientListedProviders()
    this.fetchClientProviders()
    this.fetchFiles()
  }

  render () {
    const { form, clientId } = this.props
    const { isBlacklist, isEdit, loading, modalAddProviderShow, modalShow, modalItem, providers, providerServices, clientProviders,
      loadingFile, loadingForm, isShowFileRule, modalFileItem, folderList, selectedProviderId, selectedProviderName,
      isFolderFileSelected, folderFileSelected } = this.state
    const { getFieldDecorator } = form
    const providersInList = Array.isArray(clientProviders) ? clientProviders.slice().filter(e => e.is_blacklist === false) : []
    const providersBlacklist = Array.isArray(clientProviders) ? clientProviders.slice().filter(e => e.is_blacklist === true) : []

    const columns = [
      {
        title: 'Name',
        width: 4,
        render: ({ provider_id: providerId, provider_fullname: fullname }) => <a href={`/providers/${providerId}`} rel='noopener noreferrer' target='_blank'>{fullname}</a>
      },
      {
        title: 'Start Date',
        width: 4,
        render: ({ start_date }) => formatter.toShortDate(start_date)
      },
      {
        title: 'End Date',
        width: 4,
        render: ({ end_date }) => formatter.toShortDate(end_date)
      },
      {
        title: 'Services',
        width: 5,
        render: ({selected_services: svs}) => {
          const { providerServices = [] } = this.state
          const serviceName = svs ? providerServices.filter(e => !!svs.find(f => f === e.id)).map(e => e.name).join(', ') : 'No Services Added'
          return (<div>{serviceName}</div>)
        }
      },
      {
        title: 'Contact',
        width: 3,
        render: (item) => <div>{item.cmsc_first_name || ''} {item.cmsc_last_name || ''} &nbsp;
          {item.cmsc_first_name ? <Popover content={<div>{item.cmsc_contact}<br />{item.cmsc_email}</div>} title={`${item.cmsc_first_name || ''} ${item.cmsc_last_name || ''}`}>
            <Icon type='info-circle' />
          </Popover> : null}
        </div>
      },
      {
        title: 'Active',
        width: 1,
        render: ({active}) => active
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (provider) => {
          return (
            <div className='file-action-buttons'>
              <Tooltip mouseLeaveDelay={0} title='Edit'>
                <div onClick={() => this.showAddModal(false, true, provider)} style={{ marginRight: 15 }}>
                  <Icon type='form' />
                </div>
              </Tooltip>
              {/* <FileTypeUpload
                loading={loadingFile}
                readOnly={false}
                upload={{
                  action: `${apiHostname}/private/api/clients/files/upload/files`,
                  beforeUpload: this.checkFile,
                  data: { id: provider.provider_id, moduleId: clientId },
                  disabled: loading,
                  headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                  name: 'file',
                  onChange: this.handleUploadFile(clientId, null, provider.provider_id),
                  showUploadList: false
                }}
              /> */}
              {/* <Tooltip mouseLeaveDelay={0} title='Folder'>
                <div style={{ cursor: 'auto' }}>
                  <div onClick={() => this.showModal(provider.provider_id, provider.provider_fullname)}>
                    <Icon type='folder' />
                  </div>
                </div>
              </Tooltip> */}
              <Tooltip mouseLeaveDelay={0} title='Blacklist'>
                <div onClick={() => this.showAddModal(true, true, provider)} style={{ color: '#222', marginRight: 15 }}>
                  <Icon type='close-circle' theme='filled' />
                </div>
              </Tooltip>
              <Tooltip mouseLeaveDelay={0} title='Remove Provider'>
                <Popconfirm
                  title='Confirm to remove this provider from participant provider list?'
                  onConfirm={() => this.removeProvider(provider)}
                  okText='Yes'
                  cancelText='No'
                >
                  <Icon type='delete' style={{ marginTop: '2px', marginRight: 15 }} />
                </Popconfirm>
              </Tooltip>
            </div>
          )
        }
      }
    ]

    const columnsBlacklist = [
      {
        title: 'Name',
        width: 4,
        render: ({ provider_id: providerId, provider_fullname: fullname }) => <a href={`/providers/${providerId}`} rel='noopener noreferrer' target='_blank'>{fullname}</a>
      },
      {
        title: 'Blacklist Date',
        width: 4,
        render: ({ blacklist_action_date }) => formatter.toShortDate(blacklist_action_date)
      },
      // {
      //   title: 'End Date',
      //   width: 4,
      //   render: ({ end_date }) => formatter.toShortDate(end_date)
      // },
      {
        title: 'Services',
        width: 5,
        render: ({selected_services: svs}) => {
          const { providerServices = [] } = this.state
          const serviceName = svs ? providerServices.filter(e => !!svs.find(f => f === e.id)).map(e => e.name).join(', ') : 'No Services Added'
          return (<div>{serviceName}</div>)
        }
      },
      {
        title: 'Reason',
        width: 10,
        key: 'reason'
      },
      {
        title: 'Action',
        width: 1,
        render: (provider) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Undo Blacklist'>
            <Popconfirm
              title='Confirm to undo blacklist for this provider?'
              onConfirm={() => this.undoProviderBlacklist(provider)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='close-circle' theme='filled' />
            </Popconfirm>
          </Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Remove Blacklist'>
            <Popconfirm
              title='Confirm to remove this provider from blacklist?'
              onConfirm={() => this.removeProvider(provider)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return (<div>
      <Loading loading={loading} blur>
        <Panel title='Providers' subtitle={<div className='btn' onClick={() => this.showAddModal(false, false)}>Add</div>}>
          <div className='task-list'>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={providersInList} />
            </Skeleton>
          </div>
        </Panel>

        <Panel title='Blacklisted Providers' subtitle={<div className='btn' onClick={() => this.showAddModal(true, false)}>Add</div>}>
          <div className='task-list'>
            <Skeleton loading={loading} active>
              <List cols={columnsBlacklist} rows={providersBlacklist} />
            </Skeleton>
          </div>
        </Panel>

        <AddProviderModal
          clientId={clientId}
          isBlacklist={isBlacklist}
          isEdit={isEdit}
          item={modalItem}
          providers={providers}
          visible={modalAddProviderShow}
          onClose={this.hideAddModal}
          onUpdate={this.updateProvider}
        />
      </Loading>

      <SideModal
        title='Folder'
        showModal={modalShow}
        onClose={() => this.hideModal()}
        buttons={[
          <Button key='0' onClick={() => this.handleSaveUploadFile()} feedback={loadingForm}>Save</Button>
        ]}
      >
        <Form layout='vertical'>
          <div>
            <div className='folder-name'>{`${selectedProviderName}'s Folder`}</div>
            <div className='folder-list'>
              {validator.isNotEmptyArray(folderList)
                ? (
                  <div className='folder-item'>
                    <ul>
                      {folderList.map((item, index) => {
                        return (
                          <Row key={`folder-item-${index}`} className={isFolderFileSelected && folderFileSelected === index ? 'folder-item-selected' : 'folder-item-style'}>
                            <li key={`folder-item-${index}`} style={item.active === false ? { color: '#ccc' } : null}>
                              <Col lg={18}>{item.label}</Col>
                              <Col lg={6}>
                                <div className='folder-item-buttons'>
                                  <Tooltip mouseLeaveDelay={0} title={`Edit`}>
                                    <div onClick={() => this.handleEditFolder(item, index)}>
                                      <Icon type='form' />
                                    </div>
                                  </Tooltip>
                                  <Tooltip mouseLeaveDelay={0} title={`Download`}>
                                    <div onClick={this.handleDownloadFolder(item.file_url)} style={{ cursor: 'pointer' }}>
                                      <Icon type='file-text' />
                                    </div>
                                  </Tooltip>
                                  <Tooltip mouseLeaveDelay={0} title={`Delete`}>
                                    <div onClick={() => this.handleDeleteFolder(item.id, item)} style={{ cursor: 'pointer' }}>
                                      <Icon type='delete' />
                                    </div>
                                  </Tooltip>
                                </div>
                              </Col>
                            </li>
                          </Row>
                        )
                      })}
                    </ul>
                  </div>
                )
                : <Row className='folder-empty-item'>
                  <ul style={{ listStyleType: 'none' }}><li>...Empty</li></ul>
                </Row>
              }
            </div>
          </div>

          {/* <FormItem label='Type'>
            {getFieldDecorator('type', {
              initialValue: modalItem.type || '',
              rules: [
                { required: true, message: 'Please select type' },
              ]
            })(
              <Select placeholder='Please select a type'
                onChange={(type) => this.handleFileType(type)}
                disabled={edit}>
                {
                  FileTypeList.map((types) => (
                    <Option key={types.value} value={types.value}>{types.name}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem> */}

          <FormItem label='Label'>
            {getFieldDecorator('label', {
              initialValue: modalItem.label,
              rules: [
                { min: 2, message: 'Label must be between 2 and 128 characters' },
                { max: 128, message: 'Label must be between 2 and 128 characters' },
                { required: true, message: 'Please enter label' },
                { whitespace: true, message: 'Please enter label' }
              ]
            })(
              <Input />
            )}
          </FormItem>

          <FormItem label=''>
            {getFieldDecorator('active', {
              initialValue: typeof modalItem.active === 'boolean' ? modalItem.active : true,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren='Enable'
                unCheckedChildren='Disable'
              />
            )}
          </FormItem>

          { !isFolderFileSelected
            ? (
              <FileUpload
                file={modalFileItem.file_url}
                loading={loadingFile}
                readOnly={false}
                showError={isShowFileRule}
                upload={{
                  action: `${apiHostname}/private/api/clients/files/upload/files`,
                  beforeUpload: this.checkFile,
                  data: { id: modalItem.id, moduleId: clientId },
                  disabled: loading,
                  headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                  name: 'file',
                  onChange: this.handleUploadFile(selectedProviderId),
                  showUploadList: false
                }}
              />
            )
            : null}

          {isShowFileRule
            ? <div style={{ marginTop: '5px', color: 'red' }}>Please upload file</div>
            : null }

        </Form>
      </SideModal>
    </div>
    )
  }

  // fetch all providers enlisted under the client id
  fetchClientProviders = async () => {
    try {
      const { clientId } = this.props
      this.setState({ loading: true })
      const clientProviders = await clientService.getClientProviderInList(clientId)
      this.setState({ loading: false, clientId, clientProviders })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load participant\'s providers successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  // fetch all providers available to select and yet to enlisted by client
  fetchClientListedProviders = async () => {
    try {
      const { clientId } = this.props
      this.setState({ loading: true })
      const providers = await clientService.getClientProviderListExclude(clientId)
      this.setState({ loading: false, providers })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load providers successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchFiles = async () => {
    this.setState({ loading: true })
    const { clientId } = this.props
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'providers' }
      ]
    }
    filter.module_id = { condition: '=', value: clientId }
    filter.module = { condition: '=', value: clientModule }
    // filter.active = { condition: '=', value: true }

    const { list, total } = await clientFileService.listByPage(1, 0, filter)
    this.setState({ fileList: list, loading: false })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'service-provider' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      providerServices: settings.list.filter(item => item.type === 'service-provider')
    })
  }

  handleDeleteFolder = async (fileId, info) => {
    const { clientId } = this.props
    const { selectedProviderId, selectedProviderName } = this.state

    // console.log('delete folder', fileId, info)
    this.setState({ loadingForm: true })

    const response = await clientFileService.remove(fileId)
    this.setState({ loadingForm: false })

    if (response.id) {
      log.deleteClientFile(clientId, `Delete file ${info.label}`)
      this.fetchFiles()
      this.hideModal()

      setTimeout(() => {
        this.setState({ loadingForm: false })
        notify.success('Deleted successfully', 'File deleted successfully')
        this.showModal(selectedProviderId, selectedProviderName)
      }, 1000)

      this.props.setRefreshActivityLog(true)
      // this.props.setRefreshFiles(true)
    }
  }

  handleDownloadFolder = (url) => (e) => {
    if (url) {
      window.location.href = url
    } else {
      error({
        title: 'Error',
        content: (
          <div>No file has been uploaded!</div>
        ),
        okText: 'OK',
        onOk () { }
      })
    }
  }

  handleUploadFile = (providerId) => async (info) => {
    const { clientId } = this.props
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loading: true })
    }

    if (status === 'done') {
      const { fileUrl } = response
      const lastIndex = `${fileUrl}`.lastIndexOf('/')
      const fileName = decodeURI(fileUrl.substring(lastIndex + 1))
      // const fileType = FileTypeList.filter(item => item.value === type)
      const fileType = 'Participant Providers File'
      const modalFileItem = {}

      modalFileItem.module = clientModule
      modalFileItem.module_id = clientId
      modalFileItem.type = 'providers'
      modalFileItem.file_name = fileName
      modalFileItem.file_url = fileUrl
      // modalFileItem.label = `Providers_${formatter.toShortDate(moment())}`
      modalFileItem.active = true
      modalFileItem.main_category_id = providerId

      this.setState({ loadingFile: false, loading: false, isShowFileRule: false, modalFileItem })
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { UploadType } = uploader
        // const response = await uploader.upload(fileId, info, token, UploadType.FILE)
        const response = await uploader.upload(1, info, token, UploadType.FILE)

        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = `${fileUrl}`.lastIndexOf('/')
          const fileName = decodeURI(fileUrl.substring(lastIndex + 1))
          // const fileType = FileTypeList.filter(item => item.value === type) // Need change to get from database 'Main Category' & 'Sub-Category'
          const fileType = 'Participant Providers File'
          const modalFileItem = {}

          modalFileItem.module = clientModule
          modalFileItem.module_id = clientId
          modalFileItem.type = 'providers'
          modalFileItem.file_name = fileName
          modalFileItem.file_url = fileUrl
          // modalFileItem.label = `Providers_${formatter.toShortDate(moment())}`
          modalFileItem.active = true
          modalFileItem.main_category_id = providerId

          this.setState({ loadingFile: false, loading: false, isShowFileRule: false, modalFileItem })
        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loadingForm: false })
    }
  }

  handleEditFolder = (item, index) => {
    this.setState({ loadingForm: true })
    const { form } = this.props
    const { setFieldsValue } = form
    // console.log('Editing folder', item)
    setFieldsValue({
      active: item.active,
      label: item.label
    })

    this.setState({ folderFileId: item.id, isFolderFileSelected: true, folderFileSelected: index, loadingForm: false })
  }

  handleSaveUploadFile = () => {
    const { clientId, form } = this.props
    const { validateFields } = form
    const { modalFileItem, selectedProviderId, selectedProviderName, folderFileId } = this.state

    validateFields(async (errors, values) => {
      if (!errors && modalFileItem) {
        this.setState({ loadingForm: true, isShowFileRule: false })
        const fileType = 'Participant Providers File'
        const providerId = selectedProviderId
        const providerName = selectedProviderName
        let response

        modalFileItem.label = values.label
        modalFileItem.active = values.active

        try {
          if (folderFileId) {
            response = await clientFileService.save(folderFileId, modalFileItem)
          } else {
            response = await clientFileService.add(modalFileItem)
          }

          this.setState({ shouldRefreshFiles: true })
          this.fetchFiles()
          this.hideModal()
          // this.props.setRefreshActivityLog(true)

          setTimeout(() => {
            this.setState({ loadingForm: false })
            notify.success('Upload successfully', 'File upload successfully')
            log.addClientFile(clientId, `New  ${fileType} ${values.label}`)
            this.showModal(providerId, providerName)
          }, 1000)

        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save file successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      } else {
        this.setState({ isShowFileRule: true })
      }
    })
  }

  hideModal = () => {
    const { form } = this.props
    const { resetFields } = form
    resetFields()
    this.setState({
      folderFileId: '',
      folderFileSelected: '',
      modalShow: false,
      isFolderFileSelected: false,
      isShowFileRule: false,
      selectedProviderId: '',
      selectedProviderName: ''
    })
  }

  showModal = (providerId, providerName) => {
    if (providerId) {
      const { fileList } = this.state
      const folderList = fileList.filter(file => file.main_category_id === providerId)
      this.setState({ selectedProviderId: providerId, selectedProviderName: providerName, folderList })
    }

    this.setState({ modalShow: true })
  }

  showAddModal = (isBlacklist = false, isEdit = false, modalItem = {}) => {
    this.setState({ isBlacklist, isEdit, modalItem, modalAddProviderShow: true })
  }

  hideAddModal = () => {
    this.setState({ modalAddProviderShow: false })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  updateProvider = async (result, providerName, isBlacklist, isEdit = false) => {
    const { clientId } = this.props
    this.hideAddModal()

    if (result && result.id) {
      this.reload()
    }

    setTimeout(() => {
      if (result && result.id) {
        const action = isEdit ? 'updated' : 'added'
        const content = isBlacklist ? 'Added blacklisted provider successfully' : `${formatter.capitalize(action)} provider successfully`
        notify.success(`${formatter.capitalize(action)} Successfully`, content)

        if (isBlacklist) {
          log.addClientBlacklistedProvider(clientId, `Provider ${providerName} blacklisted.`)
        } else {
          if (isEdit) {
            log.updateClientProvider(clientId, `Provider ${providerName} ${action}`)
          } else {
            log.addClientProvider(clientId, `Provider ${providerName} ${action}.`)
          }
        }

        this.props.setRefreshActivityLog(true)
      } else {
        notify.error('Unable to update provider successfully', 'Unable to update provider successfully. Please try again later.')
      }
    }, 500)
  }

  undoProviderBlacklist = async (provider) => {
    const { providerServices } = this.state
    const body = {
      is_blacklist: false,
      reason: '',
      end_date: null,
      active: false,
      blacklist_action_date: null
    }
    this.setState({loading: true})
    const r = await clientService.updateClientProvider(provider.client_id, provider.id, body)
    const s = providerServices.filter(e => !!provider.selected_services.find(f => f === e.id)).map(e => e.name).join(', ')

    if (r && r.id) {
      this.reload()

      setTimeout(() => {
        notify.success(`Revert Blacklisted Provider Successfully`, 'Revert blacklisted provider successfully.')
        log.revertClientBlacklistedProvider(provider.client_id, `Provider ${provider.provider_fullname}, Services ${s} reverted from blacklist.`)
        this.props.setRefreshActivityLog(true)
      }, 500)
    } else {
      notify.error('Unable to revert blacklisted provider successfully', 'Unable to revert blacklisted provider successfully. Please try again later.')
    }

    this.setState({loading: false})
  }

  removeProvider = async (provider) => {
    const { clientId } = this.props
    const { isBlacklist, clientProviders, providerServices } = this.state

    const body = {
      provider_rid: provider.id
    }

    try {
      this.setState({loading: true})
      const r = await clientService.removeClientProvider(clientId, body)
      const s = providerServices.filter(e => !!provider.selected_services.find(f => f === e.id)).map(e => e.name).join(', ')
      this.setState({loading: false})

      if (r && r.id) {
        const content = isBlacklist ? 'Removed blacklisted provider successfully' : 'Removed provider successfully'
        notify.success('Removed Successfully', content)
        if (isBlacklist) {
          log.deleteClientBlacklistedProvider(clientId, `Provider ${provider.provider_fullname}, Services ${s} removed from provider blacklist.`)
        } else {
          log.deleteClientProvider(clientId, `Provider ${provider.provider_fullname}, Services ${s} removed from provider list.`)
        }
        this.props.setRefreshActivityLog(true)
        this.reload()
      } else {
        notify.error('Unable to remove provider successfully', 'Unable to remove provider successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to remove provider successfully', 'Unable to remove provider successfully. Please try again later.')
    }

  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientBlacklist))
