export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          {
            title: 'Individual Jobs',
            icon: 'thunderbolt',
            menu: [
              {
                name: 'All Jobs',
                link: '/jobs/all',
                permission: 'listJobs'
              },
              {
                name: 'Cancelled Jobs',
                link: '/jobs/cancelled',
                permission: 'listJobs'
              }
            ],
            permission: 'listJobs'
          },
          {
            title: 'Funders',
            icon: 'team',
            menu: [
              {
                name: 'List Funders',
                link: '/funders',
                permission: 'listFunders'
              }, {
                name: 'Add Funder',
                link: '/funders/add',
                permission: 'createFunder'
              }
            ],
            permission: 'listFunders'
          },
          {
            title: 'Billings',
            icon: 'dollar',
            menu: [
              {
                name: 'Billings',
                link: '/billings',
                permission: 'listPayrolls'
              }
            ],
            permission: 'listPayrolls'
          }
        ]
      },
      {
        subgroup: [
          {
            title: 'Participants',
            icon: 'usergroup-add',
            menu: [
              {
                name: 'List Participants',
                link: '/clients',
                permission: 'listClients'
              }, {
                name: 'Add Participant',
                link: '/clients/add',
                permission: 'createClient'
              }
            ],
            permission: 'listClients'
          },
          {
            title: 'Providers',
            icon: 'project',
            menu: [
              {
                name: 'List Providers',
                link: '/providers',
                permission: 'listProviders'
              }, {
                name: 'Add Provider',
                link: '/providers/add',
                permission: 'createProvider'
              }
            ],
            permission: 'listProviders'
          }
        ]
      },
      {
        subgroup: [
          {
            title: 'Employees',
            icon: 'user',
            menu: [
              {
                name: 'List Employees',
                link: '/employees',
                permission: 'listEmployees'
              }, {
                name: 'Add Employee',
                link: '/employees/add',
                permission: 'createEmployee'
              }
            ],
            permission: 'listEmployees'
          },
          {
            title: 'Providers By Map',
            icon: 'global',
            menu: [
              {
                name: 'Full Map',
                link: '/map',
                permission: 'listMap'
              }
            ],
            permission: 'listMap'
          }
        ],
        permission: 'listEmployees'
      }
    ]
  },
  {
    title: 'Section 2',
    groups: [
      {
        subgroup: [
          {
            title: 'Reports',
            icon: 'snippets',
            menu: [
              {
                name: 'Day Counts Since Last Job',
                link: '/reports/client-last-job',
                permission: 'readClientLastJobCount'
              },
              {
                name: 'Participants\' Additional Reporting',
                link: '/reports/client-additional-reporting',
                permission: 'readClientAdditionalReporting'
              },
              {
                name: 'Participants\' Plan Details',
                link: '/reports/client-plan-details',
                permission: 'readClientJobBalanceHourReport'
              },
              {
                name: 'Participants\' Plan Expiry',
                link: '/reports/client-plan-expiry',
                permission: 'readClientFundReport'
              },
              {
                name: 'Participants\' Reports Due',
                link: '/reports/client-due',
                permission: 'readClientDueReport'
              },
              // {
              //   name: 'Billings',
              //   link: '/billings',
              //   permission: 'listPayrolls'
              // },
              {
                name: 'User Action',
                link: '/reports/action',
                permission: 'readActionReport'
              },
              {
                name: 'Recipient Settings',
                link: '/reports/setting',
                permission: 'listRecipients'
              }
            ],
            permission: 'listReports'
          }
        ],
        // permission: 'listEmployees'
      },
      {
        subgroup: [
          {
            title: 'System Users',
            icon: 'solution',
            menu: [
              {
                name: 'System Users',
                link: '/settings/admins',
                permission: 'listSystemUsers'
              },
              {
                name: 'User Roles',
                link: '/settings/admin-roles',
                permission: 'listUserRoles'
              },
              {
                name: 'System Modules',
                link: '/settings/modules',
                permission: 'listSystemModules'
              }
            ],
            permission: 'listSystemUsers'
          },
          {
            title: 'Logs',
            icon: 'profile',
            menu: [
              {
                name: 'Access Log',
                link: '/settings/access-log',
                permission: 'listSystemModules'
              },
              {
                name: 'Reminder Log',
                link: '/settings/reminder-log',
                permission: 'listSystemModules'
              }
            ],

            permission: 'listSystemModules'
          }
        ],
        permission: 'listSystemModules'
      },
      {
        title: 'Settings',
        icon: 'setting',
        menu: [
          {
            name: 'File',
            link: '/settings/files',
            permission: 'listFiles'
          },
          {
            name: 'Funders',
            link: '/settings/funder',
            permission: 'listFunderSettings'
          },
          {
            name: 'Public Holidays',
            link: '/settings/holidays',
            permission: 'listPublicHolidays'
          },
          {
            name: 'Profile',
            link: '/settings/profile',
            permission: 'listProfileSettings'
          },
          {
            name: 'Reasons',
            link: '/settings/reasons',
            permission: 'listCancellations'
          },
          {
            name: 'Cancellation',
            link: '/settings/cancellations',
            permission: 'listCancellations'
          },
          {
            name: 'Services',
            link: '/settings/services',
            permission: 'listServices'
          },
          {
            name: 'Suburbs',
            link: '/settings/suburbs',
            permission: 'listSuburbs'
          },
          {
            name: 'Others',
            link: '/settings/others',
            permission: 'listOthers'
          }
        ],
        permission: 'listSettings'
      }
    ]
  }
]
