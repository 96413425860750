/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, funderService, providerService, settingGeneralService, logService,
   providerFileService, settingCustomIdentifier } from '../../../services'
import { fetchingFunders, fetchingProviders, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates } from '../../../constants'
import moment from 'moment'
import { accRef, formatter, log, trigger, uploader } from '../../../util'
import Moment from 'moment-timezone'

// UI
import { Loading, List, Page, Panel, FileTypeUpload, CustomIdentifierList } from '../../../components'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import ActivityLog from '../ActivityLog'
import File from '../File'
// import ClientList from '../Client'
// import FundManager from '../FundManager'

import './styles.css'
import { apiHostname } from '../../../config'

const { confirm, error } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const funderModule = 'funder'

export class Provider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      loading: false,
      languageList: [],
      serviceList: [],
      suburbList: [],
      latitude: '',
      longitude: '',
      showSave: false,
      showEdit: true,
      shouldRefreshFiles: false,
      identifierSetting: []
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchProvider()
      this.fetchLogs()
      this.fetchCustomIdentifierSetting()
      // this.fetchFiles()
      // this.fetchFundManagers()
      // this.fetchFundManagerCount()
    }
    this.fetchSettings()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  async generateAccRef () {
    const number = await accRef.funder()
    this.setState({ newAccRef: number })
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { match, history } = this.props
    const { item, loading, showSave, showEdit, shouldRefreshFiles } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Provider (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.fullname} (View Only)` : showSave ? `${item.fullname} (Edit Mode)` : 'Provider'}>
            {/* {
              this.isEdit() && this.hasAccess('deleteProvider') ? showSave ? (
                <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
                </div>
              ) : null : null} */}
            {
              showEdit && this.isEdit() && this.hasAccess('updateProvider')
                ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)
                : null
            }
            {
              showSave || !this.isEdit()
                ? (
                  <div className='btn' onClick={this.handleSave}>
                  Save
                  </div>)
                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='funder-panel'>
            { this.isEdit()
              ? <div className='funder-panel-header'>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='funder-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='funder-panel-header-label'>Name</div>
                      <div className='funder-panel-header-value'>{item.fullname}<div className='funder-panel-header-subvalue'>{ item.acc_ref }</div></div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Phone Number</div>
                      <div className='funder-panel-header-value' style={{ textTransform: 'capitalize' }}>{item.phone_number || '-'}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Suburb</div>
                      <div className='funder-panel-header-value'>{item.suburb}</div>
                    </Col>
                    <Col lg={4} />
                  </Row> }
              </div> : null
            }
            <div className='funder-panel-body'>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Provider Info' key='1'>
                  { this.infoTab() }
                </TabPane>
                { this.isEdit() ? <TabPane tab='Custom Identifier' key='2'>
                  { this.customIdenfierTab() }
                </TabPane> : null}
                { this.isEdit() && this.hasAccess('readProviderFile') ? <TabPane tab='Files' key='3'>
                  <File providerId={match.params.id} history={this.props.history} onUpdateInfo={() => this.fetchFiles()} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Activity Log' key='4'>
                  <ActivityLog providerId={match.params.id} history={this.props.history} />
                </TabPane> : null }
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  customIdenfierTab = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const { identifierSetting } = this.state

    return <CustomIdentifierList
      moduleType={'provider'}
      moduleId={id}
      identifierSetting={identifierSetting}
    />
  }

  infoTab = () => {
    const { form } = this.props
    const { newAccRef, funding, item, loading, languageList, serviceList, suburbList } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }
    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const specialFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 11 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    return <div><Form>
      <Panel title='Provider Information'>
        <Loading loading={loading} blur>
          <Row>
            {/* <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                {getFieldDecorator('acc_ref', {
                  initialValue: item.acc_ref
                })(
                  <Input readOnly />
                )}
              </FormItem>
            </Col> */}
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='ABN' hasFeedback>
                {getFieldDecorator('abn', {
                  initialValue: item.abn || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Name' hasFeedback>
            {getFieldDecorator('fullname', {
              initialValue: item.fullname || '',
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter first name' },
                { whitespace: true, message: 'Please enter first name' }
              ]
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || '',
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states) => (
                        <Option key={`state${states.value}`} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number || '',
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Fax' hasFeedback>
                {getFieldDecorator('fax', {
                  initialValue: item.fax || '',
                  rules: [
                    { min: 2, message: 'Fax Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Fax Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter fax number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email || '',
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    },
                    { min: 2, message: 'Email must be between 2 and 128 characters' },
                    { max: 128, message: 'Email must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Contact Person'>
            {getFieldDecorator('contact_person', {
              initialValue: item.contact_person || ''
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Language Other Than English' hasFeedback>
            {getFieldDecorator('languages', {
              initialValue: Array.isArray(item.languages) && item.languages[0] !== null ? item.languages : []
            })(
              <Select mode='multiple'
                showSearch
                filterOption={(input, option) => {
                  return `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }

                }>
                {
                  languageList.map((language, index) => {
                    return <Option key={index} value={language.id}>{language.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Provided Services' hasFeedback>
            {getFieldDecorator('services', {
              initialValue: Array.isArray(item.services) && item.services[0] !== null ? item.services : [],
              rules: [
                { required: true, message: 'Please select provided services' }
              ]
            })(
              <Select mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  typeof `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {
                  serviceList.map((service, index) => {
                    return <Option key={index} value={service.id}>{service.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Service Suburbs' hasFeedback>
            {getFieldDecorator('service_area', {
              initialValue: Array.isArray(item.service_area) && item.service_area[0] !== null ? item.service_area : []
            })(
              <Select mode='multiple'
                showSearch
                filterOption={(input, option) => {
                  return `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }

                }>
                {
                  suburbList.map((suburb, index) => {
                    return <Option key={index} value={suburb.id}>{suburb.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
        </Loading>
      </Panel>

      {/* <Panel title='Additional Contact Person'>
        <Loading loading={loading} blur>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='First Name'>
                {getFieldDecorator('additional_contact_first_name', {
                  initialValue: item.additional_contact_first_name || '',
                  rules: [
                    { min: 1, message: 'First Name must be between 1 and 128 characters' },
                    { max: 128, message: 'First Name must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter First Name' }
                  ]
                })(
                  <Input placeholder='First Name' />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Last Name'>
                {getFieldDecorator('additional_contact_last_name', {
                  initialValue: item.additional_contact_last_name || '',
                  rules: [
                    { min: 1, message: 'Last Name must be between 1 and 128 characters' },
                    { max: 128, message: 'Last Name must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter Last Name' }
                  ]
                })(
                  <Input placeholder='Last Name' style={{ marginTop: '8px' }} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Contact Number'>
                {getFieldDecorator('additional_contact_phone', {
                  initialValue: item.additional_contact_phone || '',
                  rules: [
                    { min: 1, message: 'Contact Number must be between 1 and 128 characters' },
                    { max: 128, message: 'Contact Number must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Contact Number' }
                  ]
                })(
                  <Input placeholder='Contact' style={{ marginTop: '8px' }} />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Contact Email'>
                {getFieldDecorator('additional_contact_email', {
                  initialValue: item.additional_contact_email || '',
                  rules: [
                    { min: 1, message: 'Contact Email must be between 1 and 128 characters' },
                    { max: 128, message: 'Contact Email must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter Contact Email' }
                  ]
                })(
                  <Input placeholder='Email' style={{ marginTop: '8px' }} />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Organization'>
            {getFieldDecorator('additional_contact_org', {
              initialValue: item.additional_contact_org || '',
              rules: [
                { min: 1, message: 'CM/SC must be between 1 and 128 characters' },
                { max: 128, message: 'CM/SC must be between 1 and 128 characters' },
                { whitespace: true, message: 'Please enter CM/SC' }
              ]
            })(
              <Input placeholder='Organization' style={{ marginTop: '8px' }} />
            )}
          </FormItem>
        </Loading>
      </Panel>  */}

      <Panel title='Additional Information'>
        <Loading loading={loading} blur>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Name' hasFeedback>
                {getFieldDecorator('after_contact_name', {
                  initialValue: item.after_contact_name || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>

              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Number' hasFeedback>
                {getFieldDecorator('after_contact_number', {
                  initialValue: item.after_contact_number || ''
                })(
                  <Input />
                )}
              </FormItem>

            </Col>

          </Row>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Email' hasFeedback>
                {getFieldDecorator('after_contact_email', {
                  initialValue: item.after_contact_email || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || ''
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Loading>
      </Panel>
    </Form>
    </div>
  }

  fetchCustomIdentifierSetting = async () => {
    const filter = {}
    filter.module = { condition: '=', value: 'provider' }
    filter.active = { condition: '=', value: true }
    const customFields = await settingCustomIdentifier.listByPage(1, 0, filter)
    this.setState({ identifierSetting: customFields.list })
  }

  fetchFiles = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.module_id = { condition: '=', value: id }
    filter.module = { condition: '=', value: 'provider' }
    filter.has_mandatory = { condition: '=', value: true }
    filter.active = { condition: '=', value: true }
    // filter.classification = { condition: '=', value: 'employee' }

    const fileTypes = await providerFileService.listByPage(1, 0, filter)
    this.setState({ uploadedMandatory: fileTypes.list })
  }

  fetchProvider = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const { item } = await providerService.get(id)
      this.setState({ item, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load provider successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'service-provider' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'suburb' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      languageList: settings.list.filter(item => item.type === 'language'),
      serviceList: settings.list.filter(item => item.type === 'service-provider'),
      suburbList: settings.list.filter(item => item.type === 'suburb')
    })
  }

  fetchLogs = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.type = { condition: '=', value: 'provider' }
    filter.type_id = { condition: '=', value: id }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  handleDelete = () => {
    const { fetchingCompanies, history, match } = this.props
    const { params } = match
    const { id } = params

    confirm({
      title: 'Are you sure you want to delete this funder?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await funderService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Funder deleted successfully.')
            history.replace('/funders')
            fetchingCompanies(true)
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete funder successfully. Please try again later.')
        }
      }
    })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingProviders, history } = this.props
        const { item, latitude, longitude } = this.state
        this.setState({ loading: true })

        if (longitude && latitude) {
          values.longitude = longitude
          values.latitude = latitude
        }

        try {
          if (this.isEdit()) {
            const response = await providerService.save(item.id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response.id) {
              log.updateProvider(response.id, item, values)
              trigger.updateFunder(
                response.id,
                values.fullname,
                item,
                values,
                [
                  { key: 'abn' },
                  { key: 'fullname', label: 'Name' },
                  { key: 'address' },
                  { key: 'unit_building', label: 'Unit/Building' },
                  { key: 'phone_number' },
                  { key: 'fax' },
                  { key: 'email' },
                  { key: 'contact_person' },
                  { key: 'after_contact_name', label: 'After Hours Contact Name' },
                  { key: 'after_contact_number', label: 'After Hours Contact Number' },
                  { key: 'after_contact_email', label: 'After Hours Contact Email' },
                  { key: 'notes' }
                ]
              )

              notify.success('Saved successfully', 'Provider saved successfully.')
              fetchingProviders(true)
            }
          } else {
            const response = await providerService.add(values)
            this.setState({ loading: false })

            if (response.id) {
              const { id, acc_ref } = response
              log.addProvider(response.id, `New provider ${values.fullname}`)

              trigger.addNotificationByType(
                'Provider',
                id,
                `${values.fullname}`,
                values,
                [
                  { key: 'abn' },
                  { key: 'fullname', label: 'Name' },
                  { key: 'address' },
                  { key: 'unit_building', label: 'Unit/Building' },
                  { key: 'phone_number' },
                  { key: 'fax' },
                  { key: 'email' },
                  { key: 'contact_person' },
                  { key: 'after_contact_name', label: 'After Hours Contact Name' },
                  { key: 'after_contact_number', label: 'After Hours Contact Number' },
                  { key: 'after_contact_email', label: 'After Hours Contact Email' },
                  { key: 'notes' }
                ]
              )

              this.setState({ item: { ...item, ...values, id, acc_ref } })
              notify.success('Saved successfully', 'Provider saved successfully.')
              window.location.replace(`/providers/${id}`)
              fetchingProviders(true)
            }
          }

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save funder successfully. Please try again later.')
          this.setState({ loading: false })
        }

        this.fetchLogs()
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingFunders,
  fetchingProviders,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Provider))
