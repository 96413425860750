import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'
import moment from 'moment'

// UI
import { List, Page } from '../../../components'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

export class ReportEmployee extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      employeeLeave: { list: [], total: 0 },
      employeeBirthday: { list: [], total: 0 }
    }
  }

  componentDidMount () {
    this.fetchEmployee()
  }

  render () {
    const { employeeLeave, employeeBirthday, loading} = this.state

    const columns = [
      {
        title: 'Employee',
        width: 10,
        render: ({ id, fullname }) => <a href={`/employees/${id}`}>{fullname}</a>
      },
      {
        title: 'Leave',
        width: 10,
        key: 'type'
      },
      {
        title: 'Jobs To Action',
        width: 4,
        render: ({ id, leave, total_jobs, job_to_action }) => (leave > 0 && total_jobs !== '0') ?  <a href={`/employees/${id}/leave/${leave}?notify`}>{job_to_action} jobs to action</a> : null
      }
    ]

    const birthdayColumns = [
      {
        title: 'Employee',
        width: 10,
        render: ({ id, employee }) => <a href={`/employees/${id}`}>{employee}</a>
      },
      {
        title: 'Birthday',
        width: 10,
        render: ({ next_birthday }) => moment(next_birthday).format('DD/MM/YYYY')
      },
      {
        title: '',
        width: 4,
        render: ({ days_until_next }) => `${days_until_next} days to go`
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Employee's Leave & Birthday`} />

          <div className='admin-list'>
            <Skeleton loading={loading} active>

            <div className='report-title'>{`Employee's Leave `} <span className='report-tag'>{employeeLeave.total}</span></div>
            <List cols={columns} rows={employeeLeave.list} />

            <div className='report-title'>{`Employee's Birthday `} <span className='report-tag'>{employeeBirthday.total}</span></div>
            <List cols={birthdayColumns} rows={employeeBirthday.list} />

            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchEmployee () {
    this.setState({ loading: true })

    const [ leave, birthday ] = await Promise.all([
      reportService.get('employee-leave'),
      reportService.get('employee-birthday')
    ])

    this.setState({ employeeLeave: leave, employeeBirthday: birthday, loading: false })
  }
}

export default ReportEmployee
