import React, { Component } from 'react'
import moment from 'moment'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'
import { apiHostname } from '../../../config'

// UI
import { Button, List, Page } from '../../../components'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

export class ReportClientLastJobCount extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      loadingExport: false,
      clients: { list: [], total: 0 },
      sort: {}
    }
  }

  componentDidMount () {
    const { sort } = this.state
    this.fetchClientLastJobCount()
  }

  handleSort = (key, order) => {
    const sort = order === 0 ? {} : { [key]: order }
    this.fetchClientFunding({ sort })
    this.setState({ sort, loadingSpin: true })
  }

  render () {
    const { clients, loading, loadingExport } = this.state

    const columns = [
      {
        title: 'Participant',
        width: 5,
        render: ({ id, name }) => <a href={`/clients/${id}`}>{name}</a>,
        // onSort: (key, order) => this.handleSort(key, order)
      },
      {
        title: 'Plan End Date',
        width: 5,
        render: ({ plan_end_date }) => <div style={moment(plan_end_date).isBefore(moment()) ? { color: 'red', fontWeight: 900 } : {}}>
          {formatter.toShortDate(plan_end_date)}
        </div>
        // onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Remaining SC Hours',
        width: 4,
        render: ({ remaining_hours }) => formatter.toDecimalOptional(remaining_hours) || 0,
        // onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Last Job Date',
        width: 4,
        render: ({ job_start_date }) => formatter.toShortDate(job_start_date),
        // onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Days Count',
        width: 4,
        key: 'past_day',
        // onSort: (key, order) => this.handleSort(key, order)
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Participants' Day Counts Since Last Job`}>
            { !loading
              ? <Button key={'export'} type='primary' feedback={loadingExport} onClick={() => this.exportList()}>
                Export
              </Button>
              : null}
          </Page.Header>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Participants' Job Day Counts `} <span className='report-tag'>{clients.total}</span></div>
              <List cols={columns} rows={clients.list} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchClientLastJobCount () {
    this.setState({ loading: true })
    const [ lastjob ] = await Promise.all([
      reportService.get('client-last-job-count')
    ])
    this.setState({ clients: lastjob, loading: false })
  }

  async exportList () {
    this.setState({ loadingExport: true })

    window.location.href = `${apiHostname}/api/export/report/client-last-job-count`
    setTimeout(() => {
      this.setState({ loadingExport: false })
    }, 2000)
  }
}

export default ReportClientLastJobCount
