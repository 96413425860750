export default [
  {
    name: 'Participants',
    desc: 'Manage all participants',
    icon: 'team',
    link: '/clients',
    enable: true,
    permission: 'listClients'
  },
  {
    name: 'Add Participant',
    desc: 'Add new participant',
    icon: 'usergroup-add',
    link: '/clients/add',
    permission: 'createClient'
  }
]
