export default [
  {
    name: 'All Jobs',
    desc: 'List all shifts',
    icon: 'right-circle',
    permission: 'listJobs',
    link: '/jobs/all'
  },
  {
    name: 'Cancelled Jobs',
    desc: 'List all cancelled shifts',
    icon: 'exclamation-circle',
    permission: 'listJobs',
    link: '/jobs/cancelled'
  }
]
