import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import { Button, Input, Spin, Switch } from '../../components'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Upload from 'antd/lib/upload'

import './styles.css'

const { confirm } = Modal

class ImageWall extends Component {
  constructor (props) {
    super(props)
    this.state = {
      image: {},
      show: false,
      showEdit: false
    }
  }

  componentDidMount () {
  }

  render () {
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 15 }
    }
    const { canDelete = true, canSave = true, className, form, images, loading, readOnly = false, upload } = this.props
    const { image, show, showEdit } = this.state
    upload.className = formatter.toClassName(['wd-imagewall-dragger', upload.className])

    return (
      <div className={formatter.toClassName(['wd-imagewall', className])}>
        <div className='wd-imagewall-list'>
          {readOnly ? null : (
            <Upload
              className='wd-imagewall-uploader'
              disabled
              showUploadList={false}
            >
              <Icon className='wd-imagewall-uploader-trigger' type='upload' onClick={() => this.showModal()} />
            </Upload>
          )}

          {images.map((image, idx) => (
            <div
              key={idx}
              className='wd-imagewall-viewer'
              onClick={() => this.handleClick(image.url)}
            >
              {image.cover ? (
                <div className='wd-imagewall-cover'>
                  <div className='wd-imagewall-cover-badge'>
                    <Icon className='wd-imagewall-cover-icon' type='star' />
                  </div>
                </div>
              ) : null}

              <img className='wd-imagewall-img' alt='' src={image.thumbUrl || '/img/file.png'} />

              <div className='wd-imagewall-info'>
                <p>{image.remark}</p>

                <p>{formatter.toStandardDate(image.updatedAt)}</p>

                <div className='wd-imagewall-actions-container'>
                  {canDelete || canSave ? (
                    <Row>
                      {canSave ? (
                        <Col xs={canDelete ? 12 : 24}>
                          <Icon className='wd-imagewall-edit-trigger' type='edit' onClick={(e) => {
                            e.stopPropagation()
                            this.showEditModal(image)
                          }} />
                        </Col>
                      ) : null}

                      {canDelete ? (
                        <Col xs={canSave ? 12 : 24}>
                          <Icon className='wd-imagewall-delete-trigger' type='delete' onClick={(e) => {
                            e.stopPropagation()
                            this.handleDelete(image._id)
                          }} />
                        </Col>
                      ) : null}
                    </Row>
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>

        <Modal
          title='Upload Image(s)'
          visible={show}
          onCancel={() => this.hideModal()}
          footer={[
            <Button key='close' ghost disabled={loading} onClick={() => this.hideModal()}>Close</Button>
          ]}
        >
          <Spin loading={loading} blur>
            <Upload.Dragger {...Object.assign({ multiple: true }, upload)}>
              <p className='ant-upload-drag-icon'>
                <Icon className='wd-imagewall-dragger-trigger' type='inbox' />
              </p>

              <p className='ant-upload-text'>Click or drag image to this area to upload</p>

              <p className='ant-upload-hint'>Support for a single or bulk upload</p>
            </Upload.Dragger>
          </Spin>
        </Modal>

        <Modal
          title='Edit Image'
          visible={showEdit}
          onCancel={() => this.hideEditModal()}
          footer={[
            canSave ? <Button key='save' disabled={loading} onClick={() => this.handleSave()}>Save</Button> : null,
            <Button key='close' disabled={loading} ghost onClick={() => this.hideEditModal()}>Close</Button>
          ]}
        >
          <Spin loading={loading} blur>
            <Form>
              <Row>
                <Col md={24}>
                  <Input
                    fieldDecorator={{
                      id: 'remark',
                      options: {
                        initialValue: image.remark,
                        rules: [
                          { min: 2, message: 'Remark must be between 2 and 256 characters' },
                          { max: 256, message: 'Remark must be between 2 and 256 characters' },
                          { whitespace: true, message: 'Remark cannot contain only spaces' }
                        ]
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'Remark'
                    }}
                    readOnly={readOnly}
                    input={{
                      tabIndex: 1
                    }}
                  />

                  <Switch
                    fieldDecorator={{
                      id: 'cover',
                      options: {
                        initialValue: typeof image.cover === 'boolean' ? image.cover : false,
                        valuePropName: 'checked'
                      }
                    }}
                    form={form}
                    formItem={{
                      ...formItemLayout,
                      label: 'Cover Image'
                    }}
                    input={{
                      checkedChildren: 'Yes',
                      unCheckedChildren: 'No',
                      tabIndex: 2
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
      </div>
    )
  }

  handleClick (url) {
    const newWindow = window.open()
    newWindow.opener = null
    newWindow.location = url
  }

  handleDelete (id) {
    const { handleDelete } = this.props

    confirm({
      title: 'Are you sure you want to delete this image?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        typeof handleDelete === 'function' && handleDelete(id)
      }
    })
  }

  handleSave () {
    const { form, handleSave } = this.props
    const { image } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        typeof handleSave === 'function' && handleSave(image._id, values)
      }
    })
  }

  hideModal () {
    if (!this.props.loading) {
      this.setState({ show: false })
    }
  }

  showModal () {
    if (!this.props.disabled) {
      this.setState({ show: true })
    }
  }

  hideEditModal () {
    if (!this.props.loading) {
      this.props.form.resetFields()
      this.setState({ image: {}, showEdit: false })
    }
  }

  showEditModal (image) {
    if (!this.props.disabled) {
      this.setState({ image, showEdit: true })
    }
  }
}

export default Form.create()(ImageWall)

ImageWall.propTypes = {
  canDelete: PropTypes.bool,
  canSave: PropTypes.bool,
  className: PropTypes.string,
  handleDete: PropTypes.func,
  handleSave: PropTypes.func,
  images: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  upload: PropTypes.object.isRequired
}
