import { FETCHING_DASHBOARD_DATA, DASHBOARD_DATA_FETCHED } from '../actions/dashboard'

const INITIAL_STATE = {
  chart: {
    jobsByMonths: []
  },
  summary: {
    todayJobs: 0,
    pastDaysJobs: 0,
    completedJobs: 0,
    cancelJobs: 0
  },
  list: {
    pastJobs: []
  }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DASHBOARD_DATA_FETCHED:
      return handleDashboardDataFetched(state, action)
    case FETCHING_DASHBOARD_DATA:
      return handleFetchingDashboardData(state, action)
    default:
      return state
  }
}

function handleDashboardDataFetched (state, action) {
  const { data: { chart, list, summary }, loading } = action
  /* if (chart.ordersByStores.length < 8) {
    let total = chart.ordersByStores.length

    while (total++ < 8) {
      chart.ordersByStores.push({ name: '' })
    }
  } */
  return { ...state, chart, list, loading, summary }
}

function handleFetchingDashboardData (state, action) {
  const { loading } = action
  return { ...state, loading }
}
