import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'

// UI
import { List, Page } from '../../../components'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

export class ReportClientDue extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      reportList: { list: [], total: 0 },
      reviewList: { list: [], total: 0 }
    }
  }

  componentDidMount () {
    this.fetchClientActive()
  }

  render () {
    const { reportList, reviewList, loading } = this.state

    const columns1 = [
      {
        title: 'Participant',
        width: 6,
        render: ({ client_id: id, client_fullname }) => <a href={`/clients/${id}`} target='_blank'>{client_fullname}</a>
      },
      {
        title: 'Plan Start Date',
        width: 6,
        render: ({ id, plan_start_date }) => formatter.toShortDate(plan_start_date)
      },
      {
        title: 'Report Due',
        width: 6,
        render: ({ service_agreement_due_date: sadd }) => formatter.toShortDate(sadd)
      },
      // {
      //   title: 'As of today',
      //   width: 6,
      //   render: ({ signed_date_count: sdc }) => sdc === 0 ? 'Today' : sdc > 0 ? `${sdc} day${sdc === 1 ? '' : 's'} OVERDUE` : `${Math.abs(sdc)} day${Math.abs(sdc) === 1 ? '' : 's'} to go`
      // },
      {
        title: 'As of today',
        width: 6,
        render: ({ signed_date_count: sdc }) => {
          const weeks = sdc / 7
          const days = sdc % 7
          return (sdc === 0
            ? 'Today'
            : sdc > 0
              ? `${Math.floor(weeks)} week${Math.floor(weeks) === 0 || Math.floor(weeks) === 1 ? '' : 's'} ${days} day${days === 0 || days === 1 ? '' : 's'} (for ${sdc} day${sdc === 1 ? '' : 's'}) OVERDUE`
              : `${Math.floor(Math.abs(weeks))} week${Math.floor(Math.abs(weeks)) === 0 || Math.floor(Math.abs(weeks)) === 1 ? '' : 's'} ${Math.abs(days)} day${Math.abs(days) === 0 || Math.abs(days) === 1 ? '' : 's'} (for ${Math.abs(sdc)} day${Math.abs(sdc) === 1 ? '' : 's'}) to go`
          )
        }
      }
    ]

    const columns2 = [
      {
        title: 'Participant',
        width: 6,
        render: ({ client_id: id, client_fullname }) => <a href={`/clients/${id}`} target='_blank'>{client_fullname}</a>
      },
      {
        title: 'Plan End Date',
        width: 6,
        render: ({ id, plan_end_date }) => formatter.toShortDate(plan_end_date)
      },
      {
        title: 'Report Due',
        width: 6,
        render: ({ plan_due_date: pdd }) => formatter.toShortDate(pdd)
      },
      // {
      //   title: 'As of today',
      //   width: 6,
      //   render: ({ plan_date_count: pdc }) => pdc === 0 ? 'Today' : pdc > 0 ? `${pdc} day${pdc === 1 ? '' : 's'} OVERDUE` : `${Math.abs(pdc)} day${Math.abs(pdc) === 1 ? '' : 's'} to go`
      // },
      {
        title: 'As of today',
        width: 6,
        render: ({ plan_date_count: pdc }) => {
          const weeks = pdc / 7
          const days = pdc % 7
          return (pdc === 0
            ? 'Today'
            : pdc > 0
              ? `${Math.floor(weeks)} week${Math.floor(weeks) === 0 || Math.floor(weeks) === 1 ? '' : 's'} ${days} day${days === 0 || days === 1 ? '' : 's'} (for ${pdc} day${pdc === 1 ? '' : 's'}) OVERDUE`
              : `${Math.floor(Math.abs(weeks))} week${Math.floor(Math.abs(weeks)) === 0 || Math.floor(Math.abs(weeks)) === 1 ? '' : 's'} ${Math.abs(days)} day${Math.abs(days) === 0 || Math.abs(days) === 1 ? '' : 's'} (for ${Math.abs(pdc)} day${Math.abs(pdc) === 1 ? '' : 's'}) to go`
          )
        }
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Participants' Reports Due`} />

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Participant's Implementation Due Report`} <span className='report-tag'>{reportList.total}</span></div>
              <List cols={columns1} rows={reportList.list} />
            </Skeleton>
          </div>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Participant's Progress Due Report`} <span className='report-tag'>{reviewList.total}</span></div>
              <List cols={columns2} rows={reviewList.list} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchClientActive () {
    this.setState({ loading: true })
    const [r1, r2] = await Promise.all([
      reportService.get('client-implementation-list'),
      reportService.get('client-progress-list')
    ])

    this.setState({ reportList: r1, reviewList: r2, loading: false })
  }
}

export default ReportClientDue
