import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'
import moment from 'moment'
// UI
import { ControlLabel, Page } from '../../../components'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'
const { RangePicker } = DatePicker

export class ReportAction extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      action: { individual_actions: [] },
      actionStat: [],
      totalActions: 0,
    }
  }

  componentDidMount () {
    const from = moment().add(-6, 'day')
    const to = moment()
    this.fetchActionReport(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'))
    this.setState({ from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD') })
  }

  render () {
    const { action, actionStat, loading} = this.state
    let totalSystem = { add: 0, update: 0, cancel: 0, uncancel: 0}
    let subtotalSystem = { add: 0, update: 0, cancel: 0, uncancel: 0}
    let systemTotal = 0
    let lastUserRole = ''

    const isLastItem = (index, list) => {
      return index < list.length - 1
    }

    const isLastOfGroup  = (index, list, role) => {
      return (index + 1 < list.length && role !== list[index+1].role) || index + 1 === list.length
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='System User Actions' />

          <div className='admin-list'>
            <ControlLabel>Date Range</ControlLabel>
            <RangePicker format="DD/MM/YYYY" onChange={(d1, d2) => this.showReportPage(d1, d2)} defaultValue={[moment().add(-6, 'day'), moment()]}/>
            <Skeleton loading={loading} active>
              <table width='100%' style={{ marginTop: '20px' }} cellPadding='2' cellSpacing='2'>
                <tbody>
                  <tr>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_creation}</div> New Job
                    </td>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_update}</div> Amend Job
                    </td>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_cancellation}</div> Cancel Job
                    </td>
                    <td width='25%' style={{ textAlign: 'center' }}>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_uncancellation}</div> Uncancel Job
                    </td>
                  </tr>
                </tbody>
              </table>
              <table width='100%' style={{ marginTop: '40px' }} className='wd-list-container'>
                <thead className='wd-list-header'>
                  <tr>
                    <td width='25%'>
                      Action (Count)
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      New Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Amend Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Cancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Uncancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Total
                    </td>
                  </tr>
                </thead>
                <tbody>
                  { actionStat.map((item, index) => {
                      const prevUserRole = lastUserRole
                      lastUserRole = isLastItem(index, actionStat) ? item.role : ''

                      totalSystem = {
                        add: totalSystem.add + (item.add || 0),
                        update: totalSystem.update + (item.update || 0),
                        cancel: totalSystem.cancel + (item.cancel || 0),
                        uncancel: totalSystem.uncancel + (item.uncancel || 0)
                      }

                      const newGroup = prevUserRole !== item.role

                      subtotalSystem = {
                        add: (newGroup ? 0 : subtotalSystem.add) + (item.add || 0),
                        update: (newGroup ? 0 : subtotalSystem.update) + (item.update || 0),
                        cancel: (newGroup ? 0 : subtotalSystem.cancel) + (item.cancel || 0),
                        uncancel: (newGroup ? 0 : subtotalSystem.uncancel) + (item.uncancel || 0)
                      }

                      return [newGroup ? <tr key={index} className='wd-list-row'>
                      <td className='wd-list-col-full'>{item.role}</td>
                      </tr> : null, <tr key={'c' + index} className='wd-list-row'>
                      <td className='wd-list-col'><Link to={this.jobLink(item.staff)}>{item.staff}</Link></td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{item.add || 0}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{item.update || 0}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{item.cancel || 0}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{item.uncancel || 0}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{ (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0) }</td>
                    </tr>, isLastOfGroup(index, actionStat, lastUserRole) ? <tr>
                      <td className='wd-list-subtotal'>&nbsp; Sub Total</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{subtotalSystem.add || 0}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{subtotalSystem.update || 0}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{subtotalSystem.cancel || 0}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{subtotalSystem.uncancel || 0}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{ (subtotalSystem.add || 0) + (subtotalSystem.update || 0) + (subtotalSystem.cancel || 0) + (subtotalSystem.uncancel || 0) }</td>
                    </tr> : null]
                  })
                  }
                  <tr>
                      <td className='wd-list-total'>System Total</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{totalSystem.add || 0}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{totalSystem.update || 0}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{totalSystem.cancel || 0}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{totalSystem.uncancel || 0}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{ (totalSystem.add || 0) + (totalSystem.update || 0) + (totalSystem.cancel || 0) + (totalSystem.uncancel || 0) }</td>
                    </tr>
                </tbody>
              </table>

              <table width='100%' style={{ marginTop: '40px' }} className='wd-list-container'>
                <thead className='wd-list-header'>
                  <tr>
                    <td width='25%'>
                      Action (% of Total)
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      New Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Amend Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Cancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Uncancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Total
                    </td>
                  </tr>
                </thead>
                <tbody>
                  { actionStat.map((item, index) => {
                      const prevUserRole = lastUserRole
                      lastUserRole = isLastItem(index, actionStat) ? item.role : ''

                      const total = (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0)
                      systemTotal = (totalSystem.add || 0) + (totalSystem.update || 0) + (totalSystem.cancel || 0) + (totalSystem.uncancel || 0)

                      const newGroup = prevUserRole !== item.role

                      subtotalSystem = {
                        add: (newGroup ? 0 : subtotalSystem.add) + (item.add || 0),
                        update: (newGroup ? 0 : subtotalSystem.update) + (item.update || 0),
                        cancel: (newGroup ? 0 : subtotalSystem.cancel) + (item.cancel || 0),
                        uncancel: (newGroup ? 0 : subtotalSystem.uncancel) + (item.uncancel || 0)
                      }

                      return [newGroup ? <tr key={index} className='wd-list-row'>
                        <td className='wd-list-col-full'>{item.role}</td>
                      </tr> : null,<tr key={'p'+index} className='wd-list-row'>
                      <td className='wd-list-col'><Link to={this.jobLink(item.staff)}>{item.staff}</Link></td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.add || 0) / totalSystem.add * 100)}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.update || 0) / totalSystem.update * 100)}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.cancel || 0) / totalSystem.cancel * 100)}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.uncancel || 0) / totalSystem.uncancel * 100)}</td>
                      <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage(total / systemTotal * 100)}</td>
                    </tr>, isLastOfGroup(index, actionStat, lastUserRole) ? <tr>
                      <td className='wd-list-subtotal'>&nbsp; Sub Total</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.add || 0) / totalSystem.add * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.update || 0) / totalSystem.update * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.cancel || 0) / totalSystem.cancel * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.uncancel || 0) / totalSystem.uncancel * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage(((subtotalSystem.add || 0) + (subtotalSystem.update || 0) + (subtotalSystem.cancel || 0) + (subtotalSystem.uncancel || 0)) / systemTotal * 100)}</td>
                    </tr> : null]
                  })
                  }
                  <tr className='wd-list-total'>
                      <td>Total</td>
                      <td style={{ textAlign: 'center' }}>100%</td>
                      <td style={{ textAlign: 'center' }}>100%</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>100%</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>100%</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>100%</td>
                    </tr>
                </tbody>
              </table>

              <table width='100%' style={{ marginTop: '40px' }} className='wd-list-container'>
                <thead className='wd-list-header'>
                  <tr>
                    <td width='25%'>
                      Action (% of Grand Total)
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      New Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Amend Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Cancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Uncancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Total
                    </td>
                  </tr>
                </thead>
                <tbody>
                  { actionStat.map((item, index) => {
                      const total = (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0)
                      const prevUserRole = lastUserRole
                      lastUserRole = isLastItem(index, actionStat) ? item.role : ''

                      const newGroup = prevUserRole !== item.role

                      subtotalSystem = {
                        add: (newGroup ? 0 : subtotalSystem.add) + (item.add || 0),
                        update: (newGroup ? 0 : subtotalSystem.update) + (item.update || 0),
                        cancel: (newGroup ? 0 : subtotalSystem.cancel) + (item.cancel || 0),
                        uncancel: (newGroup ? 0 : subtotalSystem.uncancel) + (item.uncancel || 0)
                      }

                      return [newGroup ? <tr key={index} className='wd-list-row'>
                        <td className='wd-list-col-full'>{item.role}</td>
                      </tr> : null,<tr key={'g' + index} className='wd-list-row'>
                        <td className='wd-list-col'><Link to={this.jobLink(item.staff)}>{item.staff}</Link></td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.add || 0) / systemTotal * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.update || 0) / systemTotal * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.cancel || 0) / systemTotal * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.uncancel || 0) / systemTotal * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage(total / systemTotal * 100)}</td>
                    </tr>, isLastOfGroup(index, actionStat, lastUserRole) ? <tr>
                      <td className='wd-list-subtotal'>&nbsp; Sub Total</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.add || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.update || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.cancel || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.uncancel || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage(((subtotalSystem.add || 0) + (subtotalSystem.update || 0) + (subtotalSystem.cancel || 0) + (subtotalSystem.uncancel || 0)) / systemTotal * 100)}</td>
                    </tr> : null]
                  })
                  }
                  <tr>
                      <td className='wd-list-total'>Total</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.add / systemTotal * 100)}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.update / systemTotal * 100)}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.cancel / systemTotal * 100)}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.uncancel / systemTotal * 100)}</td>
                      <td className='wd-list-total' style={{ textAlign: 'center' }}>100%</td>
                    </tr>
                </tbody>
              </table>

              <table width='100%' style={{ marginTop: '40px' }} className='wd-list-container'>
                <thead className='wd-list-header'>
                  <tr>
                    <td width='25%'>
                      Action (% by action)
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      New Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Amend Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Cancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Uncancel Job
                    </td>
                    <td width='15%' style={{ textAlign: 'center' }}>
                      Total
                    </td>
                  </tr>
                </thead>
                <tbody>
                  { actionStat.map((item, index) => {
                      const total = (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0)
                      const prevUserRole = lastUserRole
                      lastUserRole = isLastItem(index, actionStat) ? item.role : ''

                      const newGroup = prevUserRole !== item.role

                      subtotalSystem = {
                        add: (newGroup ? 0 : subtotalSystem.add) + (item.add || 0),
                        update: (newGroup ? 0 : subtotalSystem.update) + (item.update || 0),
                        cancel: (newGroup ? 0 : subtotalSystem.cancel) + (item.cancel || 0),
                        uncancel: (newGroup ? 0 : subtotalSystem.uncancel) + (item.uncancel || 0)
                      }

                      return [prevUserRole !== item.role ? <tr key={index} className='wd-list-row'>
                        <td className='wd-list-col-full'>{item.role}</td>
                      </tr> : null,
                      <tr key={'a' + index} className='wd-list-row'>
                        <td className='wd-list-col'><Link to={this.jobLink(item.staff)}>{item.staff}</Link></td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.add || 0)/ total * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.update || 0) / total * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.cancel || 0) / total * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage((item.uncancel || 0) / total * 100)}</td>
                        <td className='wd-list-col' style={{ textAlign: 'center' }}>{formatter.toPercentage(total / total * 100)}</td>
                    </tr>, isLastOfGroup(index, actionStat, lastUserRole) ? <tr>
                      <td className='wd-list-subtotal'>&nbsp; Sub Total</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.add || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.update || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.cancel || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage((subtotalSystem.uncancel || 0) / systemTotal * 100)}</td>
                      <td className='wd-list-subtotal' style={{ textAlign: 'center' }}>{formatter.toPercentage(((subtotalSystem.add || 0) + (subtotalSystem.update || 0) + (subtotalSystem.cancel || 0) + (subtotalSystem.uncancel || 0)) / systemTotal * 100)}</td>
                    </tr> : null]
                  })
                  }
                  <tr>
                    <td className='wd-list-total'>Total</td>
                    <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.add / systemTotal * 100)}</td>
                    <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.update / systemTotal * 100)}</td>
                    <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.cancel / systemTotal * 100)}</td>
                    <td className='wd-list-total' style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.uncancel / systemTotal * 100)}</td>
                    <td className='wd-list-total' style={{ textAlign: 'center' }}>100%</td>
                  </tr>
                </tbody>
              </table>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  showReportPage = (d1, d2) => {
    this.fetchActionReport(d1[0].format('YYYY-MM-DD'), d1[1].format('YYYY-MM-DD'))
    this.setState({ from: d1[0].format('YYYY-MM-DD'), to: d1[1].format('YYYY-MM-DD') })
  }

  async fetchActionReport (from, to) {
    this.setState({ loading: true })

    const list = await reportService.getByDate('action', from, to)
    const totalActions = list.total_actions.filter((action) => action.action === 'add' || action.action === 'update' || action.action === 'cancel' || action.action === 'uncancel')

    const actions = totalActions.reduce((total, item) => total + parseFloat(item.count), 0)

    const indActions = list.individual_actions.filter((action) => action.action === 'add' || action.action === 'update' || action.action === 'cancel' || action.action === 'uncancel')

    const actionStat = []
    let lastStaff = ''
    let currStaff = {}

    for (let i=0; i < indActions.length; i++) {
      const action = indActions[i]
      const isNewStaff = lastStaff !== action.user
      lastStaff = action.user

      if (isNewStaff) {
        currStaff['staff'] = action.user
        currStaff['role'] = action.role
      }
      currStaff[action.action] = parseFloat(action.count)

      if ((indActions[i+1] && indActions[i+1].user !== action.user) || (i + 1 === indActions.length)) {
        actionStat.push(currStaff)
        currStaff = {}
      }
    }

    this.setState({ action: list, actionStat, loading: false })
  }

  jobLink = (client) => {
    const { from, to } = this.state
    return `/reports/action/${client}/${from}/${to}`
  }

}

export default Form.create()(ReportAction)
