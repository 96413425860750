import { diff } from 'json-diff'
import { formatter } from './'
import { authService, logService } from '../services'
import moment from 'moment'

export default {
  addFunder (id, changes) {
    saveLog('funder', id, 'add', changes)
  },
  updateFunder (id, before, after, exclude) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '') {
      saveLog('funder', id, 'update', changes)
    }
  },
  addProvider (id, changes) {
    saveLog('provider', id, 'add', changes)
  },
  updateProvider (id, before, after, exclude) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '') {
      saveLog('provider', id, 'update', changes)
    }
  },
  addClientProvider (id, changes) {
    saveLog('participant', id, 'add provider', changes)
  },
  updateClientProvider (id, changes) {
    saveLog('participant', id, 'update provider', changes)
  },
  deleteClientProvider (id, changes) {
    saveLog('participant', id, 'delete provider', changes)
  },
  addClientBlacklistedProvider (id, changes) {
    saveLog('participant', id, 'add blacklisted provider', changes)
  },
  deleteClientBlacklistedProvider (id, changes) {
    saveLog('participant', id, 'delete blacklisted provider', changes)
  },
  revertClientBlacklistedProvider (id, changes) {
    saveLog('participant', id, 'revert blacklisted provider', changes)
  },
  addClient (id, changes) {
    saveLog('participant', id, 'add', changes)
  },
  updateClient (id, before, after, exclude, additional, fields) {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'update', changes + (additional ? ', ' + additional : ''))
    }
  },
  updateClientText (id, action, changes) {
    saveLog('participant', id, action, changes)
  },
  addEmployee (id, changes) {
    saveLog('employee', id, 'add', changes)
  },
  updateEmployee (id, before, after, exclude, additional) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '' || additional !== '') {
      saveLog('employee', id, 'update', changes + (additional ? ', ' + additional : ''))
    }
  },
  updateEmployeeExtra (id, changes) {
    if (changes !== '') {
      saveLog('employee', id, 'update', changes)
    }
  },
  addJob (id, after, exclude, additional) {
    const changes = generateNewSet(after, exclude)
    if (changes !== '' || additional !== '') {
      saveLog('job', id, 'add', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateJob (id, before, after, exclude, additional) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '' || additional !== '') {
      saveLog('job', id, 'update', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateJobExtra (id, action, changes) {
    saveLog('job', id, action, changes)
  },
  cancelJob (id, changes) {
    saveLog('job', id, 'cancel', changes)
  },
  uncancelJob (id, changes) {
    saveLog('job', id, 'uncancel', changes)
  },
  addJobFile (id, after, exclude, additional) {
    const changes = generateNewSet(after, exclude)
    if (changes !== '' || additional !== '') {
      saveLog('job', id, 'add file', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateJobFile (id, before, after, exclude, additional) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '') {
      saveLog('job', id, 'update file', (additional ? additional + ', ' : '') + changes)
    }
  },
  deleteJobFile (id, changes) {
    saveLog('job', id, 'delete file', changes)
  },
  addBaseJob (id, after, exclude, additional) {
    const changes = generateNewSet(after, exclude)
    if (changes !== '' || additional !== '') {
      saveLog('basejob', id, 'add', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateBaseJob (id, before, after, exclude, additional) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '' || additional !== '') {
      saveLog('basejob', id, 'update', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateBaseJobExtra (id, action, changes) {
    saveLog('basejob', id, action, changes)
  },
  cancelBaseJob (id, changes) {
    saveLog('basejob', id, 'cancel', changes)
  },
  uncancelBaseJob (id, changes) {
    saveLog('basejob', id, 'uncancel', changes)
  },
  addClientTask (id, changes) {
    saveLog('participant', id, 'add task', changes)
  },
  updateClientTask (id, before, after, exclude) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '') {
      saveLog('participant', id, 'update task', changes)
    }
  },
  deleteClientTask (id, changes) {
    saveLog('participant', id, 'delete task', changes)
  },
  addClientFunder (id, changes) {
    saveLog('participant', id, 'add funder', changes)
  },
  updateClientFunder (id, before, after, exclude, additional, fields) {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'update funder', (additional ? additional + ', ' : '') + changes)
    }
  },
  deleteClientFunder (id, changes) {
    saveLog('participant', id, 'delete funder', changes)
  },
  addClientFundingPeriod (id, changes) {
    saveLog('participant', id, 'add f.period', changes)
  },
  updateClientFundingPeriod (id, before, after, exclude, additional, fields) {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'update f.period', (additional ? additional + ', ' : '') + changes)
    }
  },
  deleteClientFundingPeriod (id, changes) {
    saveLog('participant', id, 'delete f.period', changes)
  },
  addClientLeave (id, changes) {
    saveLog('participant', id, 'add leave', changes)
  },
  updateClientLeave (id, before, after, exclude) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '') {
      saveLog('participant', id, 'update leave', changes)
    }
  },
  deleteClientLeave (id, changes) {
    saveLog('participant', id, 'delete leave', changes)
  },
  addEmployeeLeave (id, changes) {
    saveLog('employee', id, 'add leave', changes)
  },
  updateEmployeeLeave (id, before, after, exclude) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '') {
      saveLog('employee', id, 'update leave', changes)
    }
  },
  deleteEmployeeLeave (id, changes) {
    saveLog('employee', id, 'delete leave', changes)
  },
  sendEmailSingleJob (id, changes) {
    saveLog('job', id, 'send email', changes)
  },
  sendEmailBaseJob (id, changes) {
    saveLog('basejob', id, 'send email', changes)
  },
  addBlacklist (id, changes) {
    saveLog('participant', id, 'blacklist carer', changes)
  },
  deleteBlacklist (id, changes) {
    saveLog('participant', id, 'blacklist carer', changes)
  },
  addFunderFundManager (id, changes) {
    saveLog('funder', id, 'add f.manager', changes)
  },
  updateFunderFundManager (id, changes) {
    saveLog('funder', id, 'update f.manager', changes)
  },
  deleteFunderFundManager (id, changes) {
    saveLog('funder', id, 'delete f.manager', changes)
  },
  addClientFile (id, after, exclude, additional, fields) {
    const changes = generateNewSet(after, exclude, fields)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'add file', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateClientFile (id, before, after, exclude, additional, fields) {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '') {
      saveLog('participant', id, 'update file', (additional ? additional + ', ' : '') + changes)
    }
  },
  deleteClientFile (id, changes) {
    saveLog('participant', id, 'delete file', changes)
  },
  addEmployeeFile (id, after, exclude, additional) {
    const changes = generateNewSet(after, exclude)
    if (changes !== '' || additional !== '') {
      saveLog('employee', id, 'add file', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateEmployeeFile (id, before, after, exclude, additional) {
    const changes = generateChanges(before, after, exclude)

    if (changes !== '') {
      saveLog('employee', id, 'update file', (additional ? additional + ', ' : '') + changes)
    }
  },
  deleteEmployeeFile (id, changes) {
    saveLog('employee', id, 'delete file', changes)
  },
  addProviderFile (id, after, exclude, additional) {
    const changes = generateNewSet(after, exclude)
    if (changes !== '' || additional !== '') {
      saveLog('provider', id, 'add file', (additional ? additional + ', ' : '') + changes)
    }
  },
  updateProviderFile (id, before, after, exclude, additional, fields) {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '') {
      saveLog('provider', id, 'update file', (additional ? additional + ', ' : '') + changes)
    }
  },
  deleteProviderFile (id, changes) {
    saveLog('provider', id, 'delete file', changes)
  },
  updateCustomIdentifier (id, module, changes) {
    if (changes !== '') {
      saveLog(module, id, 'update c.identifier', changes)
    }
  },
  generateNewItemChanges (values, excludes = []) {
    return generateNewSet(values)
  },
  generateItemChanges (item, values, excludes = [], fields = []) {
    return generateChanges(item, values, excludes, fields)
  }
}

function saveLog (type, id, action, changes) {
  const currentUser = authService.getCurrentUser()
  const { id: userId, name } = currentUser

  logService.add({ type, type_id: id, action, updated_by: name, updated_by_id: userId, changes })
}

function generateChanges (item, values, excludes = [], fields = []) {
  const result = diff(item, values)
  let changes = ''

  // console.log('generate changes', item, values, result)

  if (result) {
    for (const key of Object.keys(result)) {
      const value = result[key]
      const isExclude = excludes.findIndex(item => item === key)
      const isFound = fields.findIndex(item => item.key === key)
      // console.log('isExclude', key, isExclude)
      if (isExclude < 0) {
        if (value !== null && value !== undefined && value.hasOwnProperty('__new')) {
          // const label = formatter.capitalize(key.replace(/_/g, ' '))
          const label = isFound >= 0 ? fields[isFound].label : formatter.capitalize(key.replace(/_/g, ' '))
          // console.log('label: ', label)

          // check if the value is date
          if (value.__old !== null && isDate(value.__old)) {
            // skip if no changes on date
            if (showAsDateTime(key)) {
              if (!moment(value.__old).isSame(moment(value.__new))) {
                changes += `${label} from "${formatDateTime(value.__old)}" to "${formatDateTime(value.__new)}", `
              }
            } else if (showAsTime(key)) {
              if (!moment(value.__old).isSame(moment(value.__new))) {
                changes += `${label} from "${formatTime(value.__old)}" to "${formatTime(value.__new)}", `
              }
            } else {
              if (!moment(value.__old).isSame(moment(value.__new), 'day')) {
                changes += `${label} from "${formatDate(value.__old)}" to "${formatDate(value.__new)}", `
              }
            }
          } else {
            if (value.__old !== null && value.__new !== null && (value.__old.toString() !== value.__new.toString())) {
              changes += `${label} from "${value.__old}" to "${value.__new}", `
            } else if (value.__old === null && value.__new !== null) {
              // changes += `${label} to "${isDate(value.__new) ? formatDate(value.__new) : value.__new}", `
              changes += `${label} to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
            }
          }
        }
      }
    }
  }

  return changes.length > 0 ? changes.substr(0, changes.length - 2) : ''
}

function generateNewSet (values, excludes = [], fields = []) {
  let changes = ''
  for (const key of Object.keys(values)) {
    const value = values[key]
    const isExclude = excludes.findIndex(item => item === key)
    const isFound = fields.findIndex(item => item.key === key)
    if (isExclude < 0) {
      if (value !== null && value !== undefined) {
        const label = isFound >= 0 ? fields[isFound].label : formatter.capitalize(key.replace(/_/g, ' '))
        // check if the value is date
        if (isGMTDate(value)) {
          changes += `${label} to "${formatDate(value)}", `
        } else if (value !== false) {
          changes += `${label} to "${value}", `
        }
      }
    }
  }

  return changes.length > 0 ? changes.substr(0, changes.length - 2) : ''
}
/*
function isDate (date) {
  return date ? (date.toString().indexOf('Z') === date.toString().length - 1 || date.toString().indexOf('GMT') > -1) && moment(date).isValid() : false
}
 */
function isDate (date) {
  if (date && isNaN(date) && date.length >= 10) {
    const dateCheck = new RegExp(/^\d{4}-\d{2}-\d{2}$/)
    return dateCheck.exec(date.substr(0, 10)) !== null ? true : false
  }
  return false
}

function isGMTDate (date) {
  return date ? (date.toString().indexOf('GMT') > -1) && moment(date).isValid() : false
}

function formatDate (value) {
  const formatted = moment(value).format('DD/MM/YYYY')

  return formatted !== 'Invalid date' ? formatted : ''
}

function formatDateTime (value) {
  const formatted = moment(value).format('DD/MM/YYYY hh:mm A')

  return formatted !== 'Invalid date' ? formatted : ''
}

function showAsDateTime (key) {
  return key === 'job_start_date' || key === 'job_end_date'
}

function formatTime (value) {
  const formatted = moment(value).format('hh:mm A')

  return formatted !== 'Invalid date' ? formatted : ''
}

function showAsTime (key) {
  return key === 'job_start_time' || key === 'job_end_time'
}
