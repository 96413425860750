import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { fetchPayrolls } from '../../../states/actions'
import { formatter, queryString } from '../../../util'
import { payrollService } from '../../../services'
import moment from 'moment-timezone'
import { PayrollMenu, ReportMenu } from '../../../constants'

// UI
import { List, Page, Pager } from '../../../components'
import notify from '../../../components/Notification'

import { apiHostname } from '../../../config'
import './styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 20
const { confirm } = Modal

export class Payroll extends Component {
  constructor (props) {
    super(props)
    const { payrolls: { list, total }, loading } = props
    this.state = {
      employeeIds: [],
      currentPage: 1,
      filter: {},
      isGenerating: false,
      list,
      loading,
      searchText: '',
      showRangePicker: false,
      sort: {},
      suburbs: [],
      total,
      value: []
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { payrolls: { list, total }, loading } = nextProps
    return { ...prevState, list, loading, total }
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchPayrolls({ currentPage, filter, loading, searchText, sort })
  }

  onSearchPeriod (value) {
    const { currentPage, filter, loading, sort } = this.state
    this.fetchPayrolls({ currentPage, filter, loading, searchText: value, sort })
    this.setState({ searchText: value })
  }

  async generatePayroll (e) {
    const endDate = e.endOf('day').format('YYYY-MM-DD hh:mm')
    const startDate = e.add(-13, 'day').startOf('day').format('YYYY-MM-DD hh:mm')
    this.setState({ loading: true, showRangePicker: false })
    const result = await payrollService.generate(startDate, endDate)
    if (result.id) {
      notify.success('Payroll Generated', `Payroll (${startDate} - ${endDate}) have been generated.`)
      const { currentPage, filter, loading, searchText, sort } = this.state
      this.fetchPayrolls({ currentPage, filter, loading, searchText, sort })
    }
    this.setState({ loading: false })
  }

  async downloadBalance(id, jobs, done) {
    const filter = { payroll_id: id }
    const that = this

    if (jobs !== done) {
      confirm({
        title: `There are ${jobs} shifts. Proceed to download?`,
        content: 'Press Ok to download balance sheet',
        onOk () {
          that.generatingFile('balance-sum', filter)
        }
      })
    } else {
      this.generatingFile('balance-sum', filter)
    }
  }

  async downloadInvoice (id, jobs, done) {
    const filter = { payroll_id: id } // , status: 'approved' }
    const that = this

    if (jobs !== done) {
      confirm({
        title: `There are ${jobs} shifts. Proceed to download?`,
        content: 'Press Ok to download invoice',
        onOk () {
          that.generatingFile('invoice', filter)
        }
      })
    } else {
      this.generatingFile('invoice', filter)
    }
  }

  async downloadPayroll (id, jobs, done) {
    // const filter = { payroll_id: id, status: 'approved' }
    const filter = { payroll_id: id }
    const that = this

    if (jobs !== done) {
      confirm({
        title: `There are ${jobs - done} pending out of ${jobs} shifts. Proceed to download?`,
        content: 'Press Ok to downlod payroll',
        onOk () {
          that.generatingFile('payroll', filter)
        }
      })
    } else {
      this.generatingFile('payroll', filter)
    }
  }

  async downloadPayment (id, jobs, done) {
    const filter = { payroll_id: id }
    const that = this

    if (jobs !== done) {
      confirm({
        title: `There are ${jobs - done} pending out of ${jobs} shifts. Proceed to download?`,
        content: 'Press Ok to downlod payroll',
        onOk () {
          that.generatingFile('payment', filter)
        }
      })
    } else {
      this.generatingFile('payment', filter)
    }
  }

  generatingFile (type, filter) {
    this.setState({ isGenerating: true })
    window.location.href = `${apiHostname}/api/export/payroll/${type}${queryString.stringify({ filter: JSON.stringify(filter) })}`
    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 4000)
  }

  disabledDate (current) {
    // Can not select days other than monday
    return current.weekday() !== 0
  }

  render () {
    const { currentPage, isGenerating, list, loading, showRangePicker, total, value } = this.state

    const columns = [
      {
        title: 'Billings Period',
        width: 5,
        render: ({ date_from: dateFrom, date_to: dateTo }) => <div><Icon type='calendar' /> {formatter.toShortDate(dateFrom)} - {formatter.toShortDate(dateTo)}</div>
      },
      {
        title: 'Participants',
        width: 2,
        render: ({ clients }) => clients || 0
      },
      {
        title: 'Employees',
        width: 2,
        render: ({ employees }) => employees || 0
      },
      {
        title: 'Jobs',
        width: 1,
        render: ({ jobs }) => jobs || 0
      },
      {
        title: 'Hours',
        width: 1,
        render: ({ hours }) => formatter.round(hours) || 0
      },
      {
        title: 'Approved',
        width: 1,
        render: ({ approved }) => approved || 0
      },
      {
        title: 'To Review',
        width: 2,
        render: ({ review }) => review || 0
      },
      {
        title: '',
        width: 9,
        render: ({ id, jobs, approved }) => (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <div onClick={() => this.downloadBalance(id, jobs, approved)} className='list-button'><Icon type='crown' /> Hr Bal</div>
          <div onClick={() => this.downloadInvoice(id, jobs, approved)} className='list-button'><Icon type='audit' /> Invoice</div>
          <div onClick={() => this.downloadPayment(id, jobs, approved)} className='list-button'><Icon type='schedule' /> NDIS Payment</div>
          <div onClick={() => this.downloadPayroll(id, jobs, approved)} className='list-button'><Icon type='download' /> Payroll</div>
          <Link to={`/billings/${id}`} className='list-button'><div><Icon type='eye' /> TS</div></Link>
        </div>)
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={PayrollMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Billings'>
            {/* <div style={{ display: showRangePicker ? '' : 'none' }}><DatePicker onChange={(e) => this.generatePayroll(e)} open={showRangePicker} disabledDate={this.disabledDate} /></div>
            <div className='btn' onClick={() => this.setState({ showRangePicker: true })}>Generate Payroll</div> */}
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                {/* <ControlLabel>Search</ControlLabel>
                <SearchInput placeholder='Billing Date' onChange={(v) => this.onSearchName(v)} /> */}
                <DatePicker.RangePicker
                  value={value}
                  disabled={loading}
                  mode={['month', 'month']}
                  placeholder={['Start month', 'End month']}
                  format='YYYY-MM'
                  onChange={this.changeDate}
                  onPanelChange={this.handlePanelChange}
                  style={{ width: '100%' }}
                />
              </Col>
              <Col offset={15} lg={1}>
                { isGenerating ? <Icon type='loading' style={{ marginTop: '6px', fontSize: '15pt' }} /> : null }
              </Col>
            </Row>
          </Page.Filter>

          <div className='payroll'>
            <Skeleton loading={loading} active avatar>
              <List cols={columns} rows={list} />
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} payrolls`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changeDate = value => {
    const { filter } = this.state

    if (filter.date_from && filter.date_to) {
      delete filter.date_from
      delete filter.date_to

      this.setState({ value: [] })
    }

    this.fetchPayrolls({ loading: true, filter })
  }

  handlePanelChange = (value, mode) => {
    const { filter } = this.state
    const startAt = value[0].startOf('month')
    const endAt = value[1].endOf('month')
    this.setState({
      value
      // mode: [mode[0] === 'date' ? 'month' : mode[0], mode[1] === 'date' ? 'month' : mode[1]]
    })

    if (value[0] !== null && value[1] !== null) {
      filter.date_from = { condition: '>=', value: startAt }
      filter.date_to = { condition: '<=', value: endAt }
    }

    this.fetchPayrolls({ loading: true, filter })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchPayrolls({ currentPage, filter, searchText, sort })
  }

  fetchPayrolls = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchPayrolls } = this.props
      this.setState({ currentPage })
      fetchPayrolls({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchPayrolls
}

const mapStateToProps = (state) => {
  return { ...state.Payroll }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payroll)
