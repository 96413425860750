import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'

// UI
import { List, Page, Pager } from '../../../components'
import Skeleton from 'antd/lib/skeleton'
import Icon from 'antd/lib/icon'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 50

export class ReportClashJob extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clashCurrentPage: 1,
      backToBackCurrentPage: 1,
      loading: false,
      clashJob: { list: [], total: 0 },
      backToBackJob: { list: [], total: 0 }
    }
  }

  componentDidMount () {
    this.fetchClashJob()
  }

  render () {
    const { clashJob, backToBackJob, loading, clashCurrentPage, backToBackCurrentPage} = this.state

    const columns = [
      {
        title: 'Job Start',
        width: 2,
        render: ({ job_start_date }) => formatter.toStandardDate(job_start_date)
      },

      {
        title: 'Job End',
        width: 2,
        render: ({ job_end_date }) => formatter.toStandardDate(job_end_date)
      },

      {
        title: 'Employee',
        width: 3,
        key: 'employee'
      },

      {
        title: 'Participant',
        width: 3,
        key: 'client'
      },

      {
        title: 'Tasks',
        width: 6,
        render: ({ tasks }) => <div style={{ fontSize: 10 }}>{tasks}</div>
      },

      {
        title: 'Funder',
        width: 2,
        key: 'funder'
      },

      {
        title: '',
        width: 1,
        render: ({ id }) => <div>
          <Tooltip mouseLeaveDelay={0} title='View'> <Link to={`/jobs/single/${id}`}><Icon type='eye' /></Link></Tooltip>
          </div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Clash & Back-To-Back Shift`} />

          <div className='admin-list'>
            <Skeleton loading={loading} active>
            <div className='report-title'>{`Clash Shifts `} <span className='report-tag'>{clashJob.total}</span></div>
            <List cols={columns} rows={clashJob.list} />
            <Pager
              size={pageSize}
              total={clashJob.total}
              totalText={`Total ${clashJob.total} shifts`}
              current={clashCurrentPage}
              onChange={(e) => this.onChangeClashPage(e)}
              style={{ marginTop: '0px' }}
            />

            <div className='report-title'>{`Back-To-Back Shifts `} <span className='report-tag'>{backToBackJob.total}</span></div>
            <List cols={columns} rows={backToBackJob.list} />
            <Pager
              size={pageSize}
              total={backToBackJob.total}
              totalText={`Total ${backToBackJob.total} shifts`}
              current={backToBackCurrentPage}
              onChange={(e) => this.onChangebackToBackPage(e)}
              style={{ marginTop: '0px' }}
            />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchClashJob () {
    this.setState({ loading: true })
    const [ clash, backToBack ] = await Promise.all([
      reportService.getClashJobs(1, pageSize),
      reportService.getBackToBackJobs(1, pageSize)
    ])
    this.setState({ clashJob: clash, backToBackJob: backToBack, loading: false })
  }

  onChangeClashPage = async (clashCurrentPage) => {
    const response = await reportService.getClashJobs(clashCurrentPage, pageSize)
    this.setState({ clashJob: response, clashCurrentPage })
  }

  onChangebackToBackPage = async (backToBackCurrentPage) => {
    const response = await reportService.getBackToBackJobs(backToBackCurrentPage, pageSize)
    this.setState({ backToBackJob: response, backToBackCurrentPage })
  }
}

export default ReportClashJob
