import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, settingBillingRateService, settingBillingCategoryService } from '../../../../services'
import moment from 'moment'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'

// UI
import { Button, FullModal, Page, SectionTitle } from '../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import TimePicker from 'antd/lib/time-picker'
import Popconfirm from 'antd/lib/popconfirm'
import Popover from 'antd/lib/popover'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'
import BillingRateRow from './row'

const pageSize = 20
const { Item: FormItem } = Form
const Option = Select.Option

const settingTitle = 'Billing Rate Set'
const settingType = 'gender'

const SleepOverLabel = 'Sleepover'

export class SettingBillingRates extends Component {
  constructor (props) {
    super(props)
    this.state = {
      billingRates: [],
      categories: [],
      categoryRates: [],
      currentPage: 1,
      defaultBillingRates: [],
      defaultCategoryRates: [],
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      rateSet: {},
      searchText: '',
      selectedItem: { _id: null },
      showAddCategory: false,
      sort: {}
    }
  }

  componentDidMount () {
    const { currentPage } = this.state

    if (this.isEdit()) {
      this.fetchRateSet()
      this.fetchCatgories()
    } else {
      this.fetchBillingCategory()
    }
    this.fetchBillingRates({ currentPage })
  }

  fetchCatgories = async () => {
    const categories = await settingBillingCategoryService.listByPage(1, 0, { active: true })
    const nonSleepoverCategory = categories.list.filter(item => item.name !== SleepOverLabel)
    this.setState({ categories: nonSleepoverCategory })
  }

  fetchBillingRates = async ({ currentPage = 1 }) => {
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingBillingRateService.listByPage(currentPage, pageSize)
      this.setState({ list: settings.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load Billing Rate Set successfully. Please try again later.')
    }
  }

  fetchBillingCategory = async () => {
    try {
      const categories = await settingBillingCategoryService.listByPage(1, 0, { active: true })
      const nonSleepoverCategory = categories.list.filter(item => item.name !== SleepOverLabel)
      const billingRates = []
      const categoryRates = []

      // Sleepover category
      const SOCategories = categories.list.filter((item) => item.name === SleepOverLabel)
      const category = SOCategories[0]
      const rates = []

      for (let j = 0; j < 8; j++) {
        // populate normal hours
        rates.push({category_id: category.id, day: `n${j + 1}`, value: 0})
        // populate after hours
        rates.push({category_id: category.id, day: `a${j + 1}`, value: 0})

        billingRates.push({category_id: category.id, day: `n${j + 1}`, value: 0})
        billingRates.push({category_id: category.id, day: `a${j + 1}`, value: 0})
      }

      categoryRates.push({ id: category.id, name: category.name, rates, canDelete: false })

      this.setState({ categories: nonSleepoverCategory, billingRates: billingRates, categoryRates: categoryRates })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load category successfully. Please try again later.')
      console.log(e)
    }
  }

  fetchRateSet = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    try {
      const categoryRates = await settingBillingRateService.get(id)
      const billingRates = []

      for (let i = 0; i < categoryRates.categories.length; i++) {
        const rateSet = categoryRates.categories[i]

        if (rateSet.name === SleepOverLabel) {
          rateSet.canDelete = false
        }

        for (let j = 0; j < rateSet.rates.length; j++) {
          const rate = rateSet.rates[j]
          billingRates.push({category_id: rateSet.id, day: rate.day, value: rate.value})
        }
      }

      this.setState({ rateSet: categoryRates, categoryRates: categoryRates.categories, billingRates })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load rate set successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
    this.props.onComplete()
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { billingRates, selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { match } = this.props
        const { params } = match
        const { id } = params
        const { modal } = this.state
        const { item } = modal
        values.rates = billingRates
        this.setState({ loadingForm: true })

        try {
          let response

          if (this.isEdit()) {
            response = await settingBillingRateService.save(id, values)
          } else {
            response = await settingBillingRateService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit ({id, name, active, normal_hours_end, normal_hours_start, after_hours_end, after_hours_start, km_billing_rate}) {
    this.setState({ selectedItem: { id, name, normal_hours_end, normal_hours_start, after_hours_end, after_hours_start, active, km_billing_rate } })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await settingBillingRateService.remove(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchBillingRates({ currentPage: 1 })
    }
  }

  setBillingRate (category, day, value) {
    const { billingRates } = this.state
    let isExist = false

    for (let i = 0; i < billingRates.length; i++) {
      const rate = billingRates[i]
      if (rate.category_id === category && rate.day === day) {
        rate.value = parseFloat(value)
        isExist = true
        break
      }
    }

    if (!isExist) {
      billingRates.push({ category_id: category, day, value })
    }

    this.setState({ billingRates })
  }

  removeBillingRate (category) {
    const { billingRates, categoryRates } = this.state
    const newBillingRates = billingRates.filter(r => r.category_id !== category)
    const newCategoryRates = categoryRates.filter(r => r.id !== category)

    this.setState({ billingRates: newBillingRates, categoryRates: newCategoryRates })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  addCategory = (option) => {
    const { billingRates, categoryRates } = this.state

    const rates = []
    for (let j = 0; j < 8; j++) {
      // populate normal hours
      rates.push({category_id: option.key, day: `n${j + 1}`, value: 0})
      // populate after hours
      rates.push({category_id: option.key, day: `a${j + 1}`, value: 0})

      billingRates.push({category_id: option.key, day: `n${j + 1}`, value: 0})
      billingRates.push({category_id: option.key, day: `a${j + 1}`, value: 0})
    }

    categoryRates.push({ id: option.key, name: option.label, rates })

    this.setState({ billingRates, categoryRates })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { categories, categoryRates, currentPage, defaultCategoryRates, list, loading, loadingForm, modal, rateSet, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 7 },
      wrapperCol: { sm: 14, md: 14, lg: 16 }
    }

    const formItemTimeLayout = {
      labelCol: { sm: 6, md: 6, lg: 12 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={settingTitle}>
            { this.hasAccess('updateBillingRate') ? <div className='btn' onClick={() => this.handleSave()}>
              Save
            </div> : null
            }
            <Link to={`/settings/billing-rates`}>
              <div className='btn'>
                Back
              </div>
            </Link>
          </Page.Header>
          <Form>
            <div className='billing-rate-info'>
              <Row>
                <Col lg={10}>
                  <FormItem label='Name' {...formItemLayout}>
                    {getFieldDecorator('name', {
                      initialValue: rateSet.name || '',
                      rules: [
                        { min: 2, message: 'Name must be between 2 and 128 characters' },
                        { max: 128, message: 'Name must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter name' },
                        { whitespace: true, message: 'Please enter name' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
                <Col lg={2}>
                  <FormItem label='' {...formItemLayout}>
                    {getFieldDecorator('active', {
                      initialValue: rateSet.active || false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        checkedChildren='Enable' unCheckedChildren='Disable'
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={6}>
                  <FormItem label='Emergency Rate' {...formItemTimeLayout}>
                    {getFieldDecorator('emergency_rate', {
                      initialValue: rateSet.emergency_rate
                    })(
                      <Input addonBefore='✕' placeholder='0.00' />
                    )}
                  </FormItem>
                </Col>

                <Col lg={6}>
                  <FormItem label='KM Billing Rate' {...formItemTimeLayout}>
                    {getFieldDecorator('km_billing_rate', {
                      initialValue: rateSet.km_billing_rate
                    })(
                      <Input addonBefore='$' placeholder='0.00' />
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col lg={6}>
                  <FormItem label='Normal Hours Start' {...formItemTimeLayout}>
                    {getFieldDecorator('normal_hours_start', {
                      initialValue: rateSet.normal_hours_start ? moment(rateSet.normal_hours_start).seconds(0).milliseconds(0) : null
                    })(
                      <TimePicker use12Hours format='h:mm A' />
                    )}
                  </FormItem>
                </Col>
                <Col lg={6}>
                  <FormItem label='Normal Hours End' {...formItemTimeLayout}>
                    {getFieldDecorator('normal_hours_end', {
                      initialValue: rateSet.normal_hours_start ? moment(rateSet.normal_hours_end).seconds(0).milliseconds(0) : null
                    })(
                      <TimePicker use12Hours format='h:mm A' />
                    )}
                  </FormItem>
                </Col>
                <Col lg={6}>
                  <FormItem label='After Hours Start' {...formItemTimeLayout}>
                    {getFieldDecorator('after_hours_start', {
                      initialValue: rateSet.normal_hours_start ? moment(rateSet.after_hours_start).seconds(0).milliseconds(0) : null
                    })(
                      <TimePicker use12Hours format='h:mm A' />
                    )}
                  </FormItem>
                </Col>
                <Col lg={6}>
                  <FormItem label='After Hours End' {...formItemTimeLayout}>
                    {getFieldDecorator('after_hours_end', {
                      initialValue: rateSet.normal_hours_start ? moment(rateSet.after_hours_end).seconds(0).milliseconds(0) : null
                    })(
                      <TimePicker use12Hours format='h:mm A' />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </div>
            <Row gutter={10}>
              <Col lg={10} offset={3}>
                <b>Normal Hours</b>
              </Col>
              <Col lg={10}>
                <b>After Hours</b>
              </Col>
            </Row>
            <Row style={{ fontSize: 13, color: '#777' }} gutter={10}>
              <Col lg={3}>
                Category

                <Popover
                  content={<Select labelInValue style={{ width: 250 }} onChange={(e) => this.addCategory(e)}>
                    {
                      categories.map((cat, idx) => {
                        return <Option key={idx} value={cat.id}>{ cat.name }</Option>
                      })
                    }
                  </Select>}
                  title='Add Category'
                  placement='right'
                >
                  <span className='billing-rate-add-category'> <Icon type='plus-circle' theme='filled' /></span>
                </Popover>
              </Col>
              <Col lg={10}>
                <Row gutter={4}>
                  <Col lg={3}>
                    Mon
                  </Col>
                  <Col lg={3}>
                    Tue
                  </Col>
                  <Col lg={3}>
                    Wed
                  </Col>
                  <Col lg={3}>
                    Thu
                  </Col>
                  <Col lg={3}>
                    Fri
                  </Col>
                  <Col lg={3}>
                    Sat
                  </Col>
                  <Col lg={3}>
                    Sun
                  </Col>
                  <Col lg={3}>
                    Holiday
                  </Col>
                </Row>
              </Col>
              <Col lg={10}>
                <Row gutter={4}>
                  <Col lg={3}>
                    Mon
                  </Col>
                  <Col lg={3}>
                    Tue
                  </Col>
                  <Col lg={3}>
                    Wed
                  </Col>
                  <Col lg={3}>
                    Thu
                  </Col>
                  <Col lg={3}>
                    Fri
                  </Col>
                  <Col lg={3}>
                    Sat
                  </Col>
                  <Col lg={3}>
                    Sun
                  </Col>
                  <Col lg={3}>
                    Holiday
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className='billing-rate-set-row-list'>
              {
                categoryRates.map((cat, index) => (
                  <BillingRateRow key={index} item={cat} onChange={(category, day, value) => this.setBillingRate(category, day, value)} onDelete={(category) => this.removeBillingRate(category)} />
                ))
              }
            </div>
          </Form>
        </Page.Content>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingBillingRates))
