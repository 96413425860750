export const FETCH_DELETED_JOBS = 'FETCH_DELETED_JOBS'
export const FETCHING_DELETED_JOBS = 'FETCHING_DELETED_JOBS'
export const DELETED_JOBS_FETCHED = 'DELETED_JOBS_FETCHED'

export const fetchDeletedJobs = (params) => {
  return Object.assign({
    type: FETCH_DELETED_JOBS
  }, params)
}

export const fetchingDeletedJobs = (loading) => {
  return {
    type: FETCHING_DELETED_JOBS,
    loading
  }
}

export const deletedJobsFetched = (jobs) => {
  return {
    type: DELETED_JOBS_FETCHED,
    jobs
  }
}
