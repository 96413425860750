import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { settingOtherService } from '../../../services/setting'
import moment from 'moment'
import { authService } from '../../../services'

// UI
import { DateTimePicker, Page, Panel } from '../../../components'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Popover from 'antd/lib/popover'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'
import notify from '../../../components/Notification'
import { validator } from '../../../util'

const { Item: FormItem } = Form

const settingTitle = 'Settings'
const settingType = 'Public Holiday'

export class SettingOther extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      settings: {}
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)
      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        /*  console.log(values.date1, values.date2)

        delete values.date1
        delete values.date2 */

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  handleDecimal = (rule, value, callback) => {
    if (value) {
      if (validator.isDecimal(value)) {
        callback()
      } else {
        callback(new Error('Please enter digits or complete decimal values'))
      }
    }
    const field = rule ? rule.field : ''
    const errMsg = field === 'client_implementation_report_due'
      ? 'Please enter implementation report due'
      : field === 'client_progress_report_due'
        ? 'Please enter progress report due'
        : 'Please enter digits or complete decimal values'
    callback(new Error(errMsg))
  }

  render () {
    const { loading, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }

    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Other Settings'>
            {
              this.hasAccess('updateOther') ? <div className='btn' onClick={() => this.handleSave()}>
              Save
              </div> : null
            }
          </Page.Header>

          <div className='setting-list'>
            <Skeleton loading={loading} active>
              <Form>
                <Panel title={`Participants\' Report Due`}>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Implementation Report Due' {...formItemLayout}>
                        {getFieldDecorator('client_implementation_report_due', {
                          initialValue: settings.client_implementation_report_due,
                          rules: [
                            { validator: this.handleDecimal }
                          ]
                        })(
                          <Input
                            style={{width: '115px', marginLeft: '10px'}}
                            suffix={<div>
                              Weeks&nbsp;&nbsp;&nbsp;
                              <Popover content={'After Plan Start Date'} style={{marginLeft: '10px'}}>
                                <Icon type='info-circle' />
                              </Popover>
                            </div>}
                          />

                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Progress Report Due' {...formItemLayout}>
                        {getFieldDecorator('client_progress_report_due', {
                          initialValue: settings.client_progress_report_due,
                          rules: [
                            { validator: this.handleDecimal }
                          ]
                        })(
                          <Input
                            style={{width: '115px', marginLeft: '10px'}}
                            suffix={<div>
                              Months&nbsp;&nbsp;&nbsp;
                              <Popover content={'Before Plan End Date'} style={{marginLeft: '10px'}}>
                                <Icon type='info-circle' />
                              </Popover>
                            </div>}
                          />

                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
              </Form>

            </Skeleton>
          </div>

        </Page.Content>

      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingOther))
