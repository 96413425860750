import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class ListIcon extends Component {
  render () {
    const { file, name } = this.props

    return (
      <img src={process.env.PUBLIC_URL + '/icon/' + file} alt={name} style={{width: '24px'}} />
    )
  }
}

ListIcon.propTypes = {
  file: PropTypes.string.isRequired,
  name: PropTypes.string
}
