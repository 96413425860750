/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { Link } from 'react-router-dom'
import { authService, communicationService, employeeService, employeeFileService, jobService, logService, settingGeneralService,
  settingOtherService, settingReasonService, settingCustomIdentifier } from '../../../services'
import formatter from '../../../util/formatter'
import { fetchingEmployees, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates, FileTypes } from '../../../constants'
import Moment from 'moment-timezone'
import moment from 'moment'
import { accRef, log, trigger, validator } from '../../../util'

// UI
import { Loading, List, MandatoryList, Page, Pager, Panel, SideModal, CustomIdentifierList } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import DatePicker from 'antd/lib/date-picker'
import Popconfirm from 'antd/lib/popconfirm'

import ActivityLog from '../ActivityLog'
import File from '../File'
import Job from '../Job'

import './styles.css'
import { apiHostname } from '../../../config'

const { Item: FormItem } = Form
const { confirm, error, warning } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option
const ButtonGroup = Button.Group
const { RangePicker } = DatePicker

const dateFormat = 'DD/MM/YYYY'
const employeeModule = 'employee'
const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const pageSize = 20
export class Employee extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      item: {},
      empId: '',
      logList: [],
      loading: false,
      currentAge: 0,
      empAge: 0,
      showEdit: true,
      showSave: false,
      genders: [],
      heights: [],
      languages: [],
      visa: [],
      reasons: [],
      shouldRefreshFiles: false,
      // showResignedToggle: false,
      showResignedReason: false,
      isResignedToggleChange: false,
      isDuplicateEmployee: false,
      duplicateEmployeeInfo: {},
      settingsMandatory: [],
      uploadedMandatory: [],
      hasMissingMandatoryFiles: false,
      identifierSetting: []
    }
    this.googleAddress = null
    this.handleActiveChange = this.handleActiveChange.bind(this)
    this.handleDuplicateCheck = debounce(this.handleDuplicateCheck, 500)
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    const { currentPage } = this.state
    if (this.isEdit()) {
      this.fetchEmployee()
      this.fetchLogs()
      this.fetchFiles()
      this.fetchReasons()
      this.fetchCustomIdentifierSetting()
    }
    this.fetchSettings()
    this.fetchSettingFilesMandatory()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  async generateAccRef () {
    const number = await accRef.employee()
    this.setState({ newAccRef: number })
  }

  handleActiveChange (value) {
    if (!value) {
      this.setState({ showResignedReason: true })
    } else {
      this.setState({ showResignedReason: false })
    }

    // this.setState({ showResignedToggle: !value })

    // if (!value) {
    //   const { form } = this.props
    //   const { setFieldsValue } = form

    //   setTimeout(() => { setFieldsValue({ is_resigned: false }) }, 200)
    // }
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ address: place.formatted_address, postcode, state, suburb })
  }

  render () {
    const { history, match } = this.props
    const { item, loading, empId, loginStatus, newAccRef, currentAge, showEdit, showSave, shouldRefreshFiles } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Employee (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.first_name} ${item.last_name} (View Only)` : showSave ? `${item.first_name} ${item.last_name} (Edit Mode)` : 'Employee'}>

            {this.isEdit() && this.hasAccess('deleteEmployee') ? showSave ? (
              <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
              </div>
            ) : null : null}
            {
              showEdit && this.isEdit() && this.hasAccess('updateEmployee') ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)
                : null
            }
            {
              showSave || !this.isEdit() ? (<div className='btn' onClick={this.checkBeforeSave}>Save</div>) : null
            }
            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='employee-panel'>
            { this.isEdit()
              ? <div className='employee-panel-header'>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='employee-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='employee-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='employee-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='employee-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/' + (item.gender && item.gender.toLowerCase() === 'male' ? 'man.png' : 'woman.png')} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='employee-panel-header-label'>Name</div>
                      <div className='employee-panel-header-value'>{item.first_name}&nbsp;{item.last_name}<div className='employee-panel-header-subvalue'>{ item.acc_ref }</div></div>
                    </Col>
                    <Col lg={4}>
                      <div className='employee-panel-header-label'>Phone Number</div>
                      <div className='employee-panel-header-value' style={{ textTransform: 'capitalize' }}>{item.mobile_number || '-'}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='employee-panel-header-label'>Suburb</div>
                      <div className='employee-panel-header-value'>{item.suburb}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='employee-panel-header-label'>Age</div>
                      <div className='employee-panel-header-value'>{currentAge} years old</div>
                    </Col>
                  </Row> }
              </div> : null }
            <div className='employee-panel-body'>
              <Tabs
                defaultActiveKey='1'
              >
                <TabPane tab='Information' key='1'>
                  { this.infoTab() }
                </TabPane>
                {this.isEdit() ? <TabPane tab='Custom Identifier' key='2'>
                  {this.customIdenfierTab()}
                </TabPane> : null}
                { this.isEdit() ? <TabPane tab='Jobs' key='3'>
                  <Job empId={match.params.id} data={item} history={this.props.history} />
                </TabPane> : null }
                {/* { this.isEdit() ? <TabPane tab='Leave' key='3'>
                  <Leave empId={match.params.id} history={this.props.history} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Communications' key='4'>
                  <Communication empId={match.params.id} history={this.props.history} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Previous Participants' key='5'>
                  { this.clientTab() }
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Files' key='7'>
                  <File empId={match.params.id} history={this.props.history} />
                </TabPane> : null } */}
                { this.isEdit() && this.hasAccess('readEmployeeFile') ? <TabPane tab='Files' key='4'>
                  <File empId={match.params.id} history={this.props.history} onUpdateInfo={() => this.fetchFiles()} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Activity Log' key='5'>
                  <ActivityLog empId={match.params.id} history={this.props.history} />
                </TabPane> : null }

              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  customIdenfierTab = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const { identifierSetting } = this.state

    return <CustomIdentifierList
      moduleType={'employee'}
      moduleId={id}
      identifierSetting={identifierSetting}
    />
  }

  infoTab = () => {
    const { form } = this.props
    const {
      genders, item, languages, loading, currentAge, heights, visa, showResignedReason, isResignedToggleChange, showResignedToggle, reasons,
      settingsMandatory, uploadedMandatory
    } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    const sideBySideFirstFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 12 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 11 }
    }

    const sideBySideExtraFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 10 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    return <Form>
      <Loading loading={loading} blur>

        <Panel title='Account'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                {getFieldDecorator('acc_ref', {
                  initialValue: item.acc_ref
                })(
                  <Input readOnly />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={this.handleActiveChange}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    // disabled={item.is_resigned}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              {this.isEdit() && showResignedToggle && this.hasAccess('updateEmployeeResigned') ? <FormItem {...sideBySideFormItemLayout} label='Resigned'>
                {getFieldDecorator('is_resigned', {
                  initialValue: item.is_resigned || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={() => !item.is_resigned ? this.setState({ showResignedReason: true, isResignedToggleChange: true }) : null}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem> : null}
            </Col>
          </Row> */}
        </Panel>
        <Panel title='Employee Information'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='First Name' hasFeedback>
                {getFieldDecorator('first_name', {
                  initialValue: item.first_name || '',
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Last Name' hasFeedback>
                {getFieldDecorator('last_name', {
                  initialValue: item.last_name || '',
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input onChange={this.isEdit() ? null : this.handleDuplicateCheck} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Gender' hasFeedback>
                {getFieldDecorator('gender', {
                  initialValue: item.gender,
                  rules: [
                    // { required: true, message: 'Please select gender' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      genders.map((gender, idx) => {
                        return <Option key={gender.value}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={19}>
                  <FormItem {...sideBySideExtraFormItemLayout} label='Date Of Birth' hasFeedback>
                    {getFieldDecorator('dob', this.isEdit() && item.dob ? {
                      initialValue: Moment(item.dob),
                      rules: [
                        // { required: true, message: 'Please enter dob' }
                      ]
                    } : {
                      rules: [
                        // { required: true, message: 'Please enter dob' }
                      ]
                    })(
                      <DatePicker format={dateFormat} onChange={this.handleDOBChange} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={5}>
                  <div className='employee_age'>
                    {currentAge} years old
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email || '',
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    },
                    { min: 2, message: 'Email must be between 2 and 128 characters' },
                    { max: 128, message: 'Email must be between 2 and 128 characters' },
                    // { required: true, message: 'Please enter email' },
                    { whitespace: true, message: 'Please enter email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Secondary Email' hasFeedback>
                {getFieldDecorator('second_email', {
                  initialValue: item.second_email || '',
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number' hasFeedback>
                {getFieldDecorator('mobile_number', {
                  initialValue: item.mobile_number || '',
                  rules: [
                    // { required: true, message: 'Please enter Mobile Phone Number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || '',
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                // { required: true, message: 'Please enter address' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
            {getFieldDecorator('unit_building', {
              initialValue: item.unit_building,
              rules: [
                { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter unit/building info' }
              ]
            })(
              <Input placeholder='Please Enter Unit No/Building Name' />
            )}
          </FormItem>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Height' hasFeedback>
                {getFieldDecorator('height', {
                  initialValue: item.height,
                  rules: [
                    // { required: true, message: 'Please select height' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      heights.map((height, idx) => {
                        return <Option key={height.value}>{height.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Visa Status' hasFeedback>
                {getFieldDecorator('visa_status', {
                  initialValue: item.visa_status,
                  rules: [
                    // { required: true, message: 'Please select visa status' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      visa.map((visa, idx) => {
                        return <Option key={visa.value}>{visa.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states, index) => (
                        <Option key={index} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

        </Panel>

        <Panel title='Employee Ability'>

          <FormItem {...formItemLayout} label='Language Other Than English' hasFeedback>
            {getFieldDecorator('languages', {
              initialValue: item.languages && item.languages[0] !== null ? item.languages : []
            })(
              <Select mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {
                  languages.map((language, index) => {
                    return <Option key={index} value={language.id}>{language.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          {/* end date */}

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || ''
            })(
              <TextArea rows={4} />
            )}
          </FormItem>

        </Panel>

        <Panel title='Mandatory Files'>
          <div className='mandatory-files-panel'>
            <MandatoryList
              form={form}
              moduleType={'employee'}
              moduleId={item.id}
              settingsMandatory={settingsMandatory}
              items={uploadedMandatory}
            />
          </div>
        </Panel>

      </Loading>

      <SideModal
        title='Inactive Reason'
        showModal={showResignedReason}
        onClose={() => this.handleCancelInactiveReason()}
        buttons={[
          <Button key='0' type='primary' onClick={() => this.handleResignedReason()}>Submit</Button>
        ]}
      >

        <FormItem label='Reason'>
          {getFieldDecorator('resigned_reason', {
            rules: [
              isResignedToggleChange ? { required: true, message: 'Please select reason' } : {}
            ]
          })(
            <Select
              showSearch
              filterOption={(input, option) =>
                `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                reasons.map((reason, idx) => {
                  return <Option key={reason.name}>{reason.name}</Option>
                })
              }
            </Select>
          )}
        </FormItem>
        <FormItem label='Note'>
          {getFieldDecorator('resigned_reason_note', {
            rules: [
              // isResignedToggleChange ? { required: true, message: 'Please enter note' } : {}
            ]
          })(
            <TextArea rows={4} />
          )}
        </FormItem>

      </SideModal>

    </Form>
  }

  fetchCustomIdentifierSetting = async () => {
    const filter = {}
    filter.module = { condition: '=', value: 'employee' }
    filter.active = { condition: '=', value: true }
    const customFields = await settingCustomIdentifier.listByPage(1, 0, filter)
    this.setState({ identifierSetting: customFields.list })
  }

  fetchEmployee = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })

      const { item } = await employeeService.get(id)
      // get current age
      const empDate = Moment(item.dob)
      const currYear = Moment()
      const ageNum = currYear.diff(empDate, 'years')
      const prevClient = await employeeService.getEmployeePrevClient(id)

      this.setState({ item, prevClient, loading: false, currentAge: ageNum })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load Employee successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchFiles = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.module_id = { condition: '=', value: id }
    filter.module = { condition: '=', value: employeeModule }
    filter.has_mandatory = { condition: '=', value: true }
    filter.active = { condition: '=', value: true }
    filter.classification = { condition: '=', value: 'employee' }

    const fileTypes = await employeeFileService.listByPage(1, 0, filter)
    this.setState({ uploadedMandatory: fileTypes.list })
  }

  fetchSettingFilesMandatory = async (currentPage = 1) => {
    const filter = {}
    this.setState({ loading: true })

    filter.active = { condition: '=', value: true }
    filter.has_mandatory = { condition: '=', value: true }
    filter.classification = { condition: '=', value: 'employee' }

    const { list: documents } = await employeeFileService.listDocumentByPage(currentPage, 0, filter)

    this.setState({ loading: false, settingsMandatory: documents })
  }

  checkBeforeSave = () => {
    const { form } = this.props
    const { getFieldValue } = form
    const { isDuplicateEmployee, duplicateEmployeeInfo } = this.state
    const { handleSave } = this

    const mandatory = getFieldValue('mandatory')
    this.setState({ hasMissingMandatoryFiles: false })

    // Check whether all mandatory categories have file uploaded; missing file uploaded disactive employee profile but still allow to save
    let hasFileList = []
    if (validator.isArray(mandatory)) {
      hasFileList = mandatory.filter(item => item.file_name && item.file_url)
    }

    if (isDuplicateEmployee && duplicateEmployeeInfo.total > 0) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div className='duplicate-warnings'>
            <div style={{ fontSize: '14px' }}>
              <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              <strong> Employee may already exist</strong>
            </div>
            {duplicateEmployeeInfo.list.map((item, index) => {
              const url = `/employees/${item.id}`
              return (
                <div key={index}>
                  <a href={url} rel='noopener noreferrer' target='_blank'>
                    {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                  </a>
                </div>
              )
            })}

            <div><br /><br /><mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark></div>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () { handleSave() },
        onCancel () { }
      })
    } else if (validator.isArray(mandatory) && mandatory.length !== 0 && mandatory.length !== hasFileList.length) {
      this.setState({ hasMissingMandatoryFiles: true })
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div className='duplicate-warnings'>
            <div style={{ fontSize: '14px' }}>
              <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              <strong> Missing files in "Mandatory Files". Employee profile will be deactivate when proceed to save</strong>
            </div>

            <div><br /><br /><mark><strong>Please click OK to proceed or Cancel to return.</strong></mark></div>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () { handleSave() },
        onCancel () {}
      })
    } else {
      handleSave()
    }
  }

  fetchReasons = async () => {
    const filter = {}
    filter.code = { condition: '=', value: 'inactive-employee' }

    const reasons = await settingReasonService.listItemsByPage(1, 0, filter)

    this.setState({ reasons: reasons.list })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'language' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'height' },
        { condition: '=', value: 'visa' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      languages: settings.list.filter(item => item.type === 'language'),
      genders: settings.list.filter(item => item.type === 'gender'),
      heights: settings.list.filter(item => item.type === 'height'),
      visa: settings.list.filter(item => item.type === 'visa')
    })
  }

  fetchLogs = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.type = { condition: '=', value: 'employee' }
    filter.type_id = { condition: '=', value: id }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  handleCancelInactiveReason () {
    const { form } = this.props
    const { setFieldsValue, getFieldValue } = form

    setFieldsValue({ active: !getFieldValue('active') })

    this.setState({ showResignedReason: false, isResignedToggleChange: false })
  }

  handleResignedReason (value) {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['resigned_reason'], async (errors, values) => {
      if (!errors) {
        this.setState({ showResignedReason: false })
      }
    })
  }

  handleDuplicateCheck = () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['last_name', 'dob'],
      async (errors, values) => {
        if (!errors) {
          values.dob = moment(values.dob).format('YYYY-MM-DD')

          const res = await employeeService.checkDuplicateEmployee(values)

          if (res.total > 0) {
            warning({
              title: 'Employee may already exist',
              content: (
                <div className='duplicate-warnings'>
                  {res.list.map((item, index) => {
                    const url = `/employees/${item.id}`
                    return (
                      <div key={index}><a href={url} rel='noopener noreferrer' target='_blank'>
                        {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                      </a>
                      </div>
                    )
                  })}
                </div>
              ),
              okText: 'OK',
              onOk () { }
            })

            this.setState({ isDuplicateEmployee: true, duplicateEmployeeInfo: res })
          } else {
            this.setState({ isDuplicateEmployee: false, duplicateEmployeeInfo: {} })
          }
        }
      })
  }

  handleDelete = () => {
    const { fetchingEmployees, history, match } = this.props
    const { params } = match
    const { id } = params

    confirm({
      title: 'Are you sure you want to delete this employee?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await employeeService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Employee deleted successfully.')
            history.replace('/employees')
            fetchingEmployees(true)
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete Employees successfully. Please try again later.')
        }
      }
    })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleDOBChange = (e) => {
    const currYear = Moment()
    const ageNum = currYear.diff(e, 'years')

    this.setState({ currentAge: ageNum })

    if (!this.isEdit()) {
      this.handleDuplicateCheck()
    }
  }

  handleEditJobs = (id) => {
    const { history } = this.props
    history.replace(`/jobs/single/${id}`)
  }

  handleEditEmail = (id) => {
    // console.log('Will Edit Email', id)
  }

  handleSendEmail = async (id) => {
    const { item } = this.state
    const emailData = await jobService.getEmailsById(id)
    const empEmail = item.email
    const emailSubject = emailData.item.subject
    const emailBody = emailData.item.body

    const emailParam = {
      'to': empEmail,
      'subject': emailSubject,
      'content': emailBody
    }

    const sendEmail = communicationService.sendEmail(emailParam)
  }

  changePage = async (currentPage) => {
    this.setState({ loading: true })

    const { type } = this.state

    this.fetchJob(type, currentPage)
  }

  onChangeCommunicationPage = async (currentPage) => {
    const { communication } = this.state
    communication.currentPage = currentPage
    this.setState({ communication })
    this.fetchCommunications(currentPage)
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingEmployees, history } = this.props
        const { item, longitude, latitude, hasMissingMandatoryFiles } = this.state
        let extraLog = ''
        this.setState({ loading: true })

        if (longitude && latitude) {
          values.longitude = longitude
          values.latitude = latitude
        }

        if (values.dob) {
          values.dob = values.dob.format('YYYY-MM-DD')
        }

        if (hasMissingMandatoryFiles) {
          values.active = false
        }

        if (values.resigned_reason && values.resigned_reason !== '') {
          extraLog += `Inactive Reason "${values.resigned_reason}" ${values.resigned_reason_note ? `, Inactive Note "${values.resigned_reason_note}"` : ''}`
        }

        delete values.resigned_reason
        delete values.resigned_reason_note
        // console.log('extraLog', extraLog)

        try {
          if (this.isEdit()) {
            const response = await employeeService.save(item.id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            delete values.mandatory

            if (response.id) {
              log.updateEmployee(response.id, item, values, [], extraLog)

              trigger.updateEmployee(
                response.id,
                `${values.first_name} ${values.last_name}`,
                item,
                values,
                [
                  { key: 'active' },
                  { key: 'first_name' },
                  { key: 'last_name' },
                  { key: 'gender' },
                  { key: 'dob', label: 'Date of Birth' },
                  { key: 'mobile_number' },
                  { key: 'phone_number' },
                  { key: 'email' },
                  { key: 'second_email', label: 'Secondary Email' },
                  { key: 'height' },
                  { key: 'visa_status' },
                  { key: 'address' },
                  { key: 'unit_building', label: 'Unit/Building' }
                ]
              )

              notify.success('Saved successfully', 'Employees saved successfully.')
              fetchingEmployees(true)
              window.location.replace(`/employees/${response.id}`)
            }
            this.setState({ loading: false })
          } else {
            const response = await employeeService.add(values)
            this.setState({ loading: false })

            delete values.mandatory

            if (response.id) {
              const { id, acc_ref } = response
              log.addEmployee(id, `New Employee ${values.first_name} ${values.last_name}`)

              trigger.addNotificationByType(
                'Employee',
                id,
                `${values.first_name} ${values.last_name}`,
                values,
                [
                  { key: 'active' },
                  { key: 'first_name' },
                  { key: 'last_name' },
                  { key: 'gender' },
                  { key: 'dob', label: 'Date of Birth' },
                  { key: 'mobile_number' },
                  { key: 'phone_number' },
                  { key: 'email' },
                  { key: 'second_email', label: 'Secondary Email' },
                  { key: 'height' },
                  { key: 'visa_status' },
                  { key: 'address' },
                  { key: 'unit_building', label: 'Unit/Building' }
                ]
              )

              this.setState({ item: { ...item, ...values, id, acc_ref } })
              notify.success('Saved successfully', 'Employees saved successfully.')
              // history.replace(`/employees/${id}`)
              window.location.replace(`/employees/${id}`)
              fetchingEmployees(true)
            }
          }

          const empDate = Moment(values.dob)
          const currYear = Moment()
          const ageNum = currYear.diff(empDate, 'years')
          this.setState({ currentAge: ageNum })

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save employees successfully. Please try again later.')
          this.setState({ loading: false })
        }
        this.fetchLogs()
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingEmployees,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Employee))
