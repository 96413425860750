import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DashboardMenu } from '../../constants'
import { fetchDashboardData } from '../../states/actions/dashboard'
import { formatter } from '../../util'
import { Link } from 'react-router-dom'
import moment from 'moment'
import authService from '../../services/auth'

// UI
import { Page, Panel } from '../../components'
import notify from '../../components/Notification'

import { Chart, Geom, Axis, Tooltip } from 'bizcharts'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import DatePicker from 'antd/lib/date-picker'
import './styles.css'

const { RangePicker } = DatePicker

export class Dashboard extends Component {
  constructor () {
    super()
    this.state = {
      loading: false,
      showReport: false
    }
  }

  componentDidMount () {
    const { loading } = this.state
    this.fetchDashboardData({ loading })
  }

  showReport () {
    this.setState({ showReport: true })
  }

  showReportPage (d1, d2) {
    window.open(`/report/${d2[0]}/${d2[1]}`, '_blank')
    this.setState({ showReport: false })
  }

  render () {
    const { chart: { jobsByMonths = [] }, list: { pastJobs = [] }, summary } = this.props
    const { showReport } = this.state
    const isSuperUser = authService.getCurrentUser().role === 1
    const pastJob1 = pastJobs.filter((e, idx) => idx % 2 === 0)
    const pastJob2 = pastJobs.filter((e, idx) => idx % 2 === 1)

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={DashboardMenu} top />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Dashboard' />

          <div className='dashboard-stat-grid'>
            <div className='dashboard-stat-card-total'>
              <div className='value'><div>{summary.todayJobs}</div></div>

              <div className='label'>Today's Jobs</div>
            </div>

            <Link to={`/jobs/all`}>
              <div className='dashboard-stat-card-total'>
                <div className='value'><div>{summary.pastDaysJobs}</div></div>

                <div className='label'>Past 7 days Jobs</div>
              </div>
            </Link>

            <Link to={`/jobs/all`}>
              <div className='dashboard-stat-card-total'>
                <div className='value'><div>{summary.completedJobs}</div></div>

                <div className='label'>Past Jobs</div>
              </div>
            </Link>

            <Link to={`/jobs/cancelled`}>
              <div className='dashboard-stat-card-total'>
                <div className='value'><div>{summary.cancelJobs}</div></div>

                <div className='label'>Cancelled Jobs</div>
              </div>
            </Link>
          </div>
          <Row>
            <Col lg={24}>
              <Row>
                <Col lg={24}>
                  <div md={24} className='dashboard-section-title'>
                    <div>Past Jobs</div>
                  </div>
                  <Col lg={12}>
                    <div md={12} className='upcoming-jobs'>
                      { pastJob1.map((item, idx) => {
                        return <Link to={`/jobs/single/${item.id}`} key={idx}><div className='item'>
                          <span className='icon'><Icon type='thunderbolt' /></span>
                          { `${formatter.toShortDate(item.job_start_date)} ${formatter.toHourText(item.job_duration)}` }
                          <span className='name'>{ ` ${item.first_name} ${item.last_name}` }</span>
                          <span className='detail'>{ ` ${item.service_name}` }</span>
                        </div>
                        </Link>
                      })}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div md={12} className='upcoming-jobs'>
                      { pastJob2.map((item, idx) => {
                        return <Link to={`/jobs/single/${item.id}`} key={idx}><div className='item'>
                          <span className='icon'><Icon type='thunderbolt' /></span>
                          { `${formatter.toShortDate(item.job_start_date)} ${formatter.toHourText(item.job_duration)}` }
                          <span className='name'>{ ` ${item.first_name} ${item.last_name}` }</span>
                          <span className='detail'>{ ` ${item.service_name}` }</span>
                        </div>
                        </Link>
                      })}
                    </div>
                  </Col>
                </Col>
                {/* <Col lg={12}>
                  <div md={12} className='dashboard-section-title'>
                    <div>Upcoming Jobs</div>
                  </div>
                  <div className='upcoming-jobs'>
                    { upcomingJobs.map((item, idx) => {
                      return <Link to={`/jobs/future/${item.id}`} key={idx}><div className='item'>
                        <span className='icon'><Icon type='thunderbolt' /></span>
                        { formatter.toStandardDate(item.job_start_date) }
                        <span className='name'>{ ` ${item.first_name} ${item.last_name}` }</span>
                      </div>
                      </Link>
                    })}
                  </div>
                </Col> */}

              </Row>

            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col lg={24}>
              <div md={24} className='dashboard-section-title'>
                <div>Total Job Hours</div>
              </div>
              <Chart height={260} data={jobsByMonths} forceFit>
                <Axis name='month_string' />
                <Axis name='hours' />
                <Tooltip
                  crosshairs={{
                    type: 'y'
                  }}
                />
                <Geom type='line' position='month_string*hours' size={2} color='orange' />
                <Geom
                  type='point'
                  position='month_string*hours'
                  size={6}
                  shape={'circle'}
                  style={{
                    stroke: '#fff',
                    lineWidth: 2
                  }}
                  color='rgb(29, 44, 71)'
                />
              </Chart>
            </Col>
          </Row>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchDashboardData = async ({ loading = true, filter = {} }) => {
    try {
      const { fetchDashboardData } = this.props
      fetchDashboardData({ loading, filter })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load dashboard successfully. Please try again later.')
    }
  }
}

const mapStateToProps = (state) => ({
  ...state.Dashboard
})

export default connect(mapStateToProps, {
  fetchDashboardData
})(Dashboard)
