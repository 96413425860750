import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'

// UI
import { List, Page } from '../../../components'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

export class ReportClientFunding extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      fundingEnded: { list: [], total: 0 },
      fundingEnding: { list: [], total: 0 }
    }
  }

  componentDidMount () {
    this.fetchClientFunding()
  }

  render () {
    const { fundingEnded, fundingEnding, loading } = this.state

    const columns = [
      {
        title: 'Participant',
        width: 9,
        render: ({ id, client_fullname }) => <a href={`/clients/${id}`}>{client_fullname}</a>
      },
      {
        title: 'Balance Hours',
        width: 5,
        render: ({ remaining_hours, is_tba }) => is_tba ? 'TBC' : formatter.toDecimalOptional(remaining_hours) || 0,
      },

      {
        title: 'Ended On',
        width: 4,
        render: ({ end_date }) => formatter.toShortDate(end_date)
      },

      {
        title: 'As of Today',
        width: 4,
        key: 'ip',
        render: ({ end_date }) => `${formatter.duration(end_date)} days`
      }
    ]

    const endingColumns = [
      {
        title: 'Participant',
        width: 9,
        render: ({ id, client_fullname }) => <a href={`/clients/${id}`} target='_blank'>{client_fullname}</a>
      },
      {
        title: 'Balance Hours',
        width: 5,
        render: ({ remaining_hours, is_tba }) => is_tba ? 'TBC' : formatter.toDecimalOptional(remaining_hours) || 0
      },

      {
        title: 'Ending On',
        width: 4,
        render: ({ plan_end_date }) => formatter.toShortDate(plan_end_date)
      },

      {
        title: 'Remaining',
        width: 4,
        key: 'ip',
        render: ({ plan_end_date }) => `${formatter.expiringDuration(plan_end_date)} days to go`
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Participant's Plan Expiry`} />

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Participants with Ended Plan `} <span className='report-tag'>{fundingEnded.total}</span></div>
              <List cols={columns} rows={fundingEnded.list} />

              <div className='report-title'>{`Participants with Ending Plan `} <span className='report-tag'>{fundingEnding.total}</span></div>
              <List cols={endingColumns} rows={fundingEnding.list} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchClientFunding () {
    this.setState({ loading: true })
    const [ ended, ending ] = await Promise.all([
      reportService.get('funding-ended'),
      reportService.get('funding-ending')
    ])
    this.setState({ fundingEnded: ended, fundingEnding: ending, loading: false })
  }
}

export default ReportClientFunding
