import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingCancellationService } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Page, Pager, Panel, SideModal, List } from '../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Cancellation'
const settingType = 'gender'

export class SettingCancellation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      searchText: '',
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchCancellations({ currentPage })
  }

  fetchCancellations = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingCancellationService.listByPage(currentPage, pageSize)
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  async handleDelete (id) {
    const res = await settingCancellationService.removeSet(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchCancellations({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, total } = this.state

    const columns = [
      {
        title: 'Type',
        width: 22,
        render: ({ id, name }) => <div>{name}  { this.hasAccess('readCancellation') && !this.hasAccess('updateCancellation') ? <Col md={1}>
          <Link to={`/settings/cancellations/${id}`}><div><Icon type='eye' /></div></Link>
        </Col> : null } </div>
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('updateCancellation') ? <Link to={`/settings/cancellations/${id}`}><div><Icon type='form' /></div></Link> : null
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('deleteCancellation') ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        ><Icon type='delete' />
        </Popconfirm> : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} cancellation reasons`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchCancellations({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingCancellation))
