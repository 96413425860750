import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { reportService } from '../../../services'
import { formatter } from '../../../util'

import { apiHostname } from '../../../config'

// UI
import Icon from 'antd/lib/icon'
import './styles.css'

export class ReportEmail extends Component {
  constructor (props) {
    super(props)
    this.state = {
      action: { individual_actions: [] },
      actionStat: [],
      totalActions: 0,
      certExpired: { list: [], total: 0 },
      certExpiring: { list: [], total: 0 },
      clientLeave: { list: [], total: 0 },
      fundingEnded: { list: [], total: 0 },
      fundingEnding: { list: [], total: 0 },
      summary: { client_leave: { leave: 0, job_to_action: 0 }, employee_leave: { leave: 0, job_to_action: 0 } }
    }
  }

  static getDerivedStateFromProps (props, state) {
    return { ...state, ...props }
  }

  async componentDidMount () {
    const { match } = this.props
    const { params } = match
    const { from, to } = params

    this.fetchSummary()
    this.fetchActionReport(from, to)
    this.fetchClientFundingEnded()
    this.fetchEmployeeCertExpired()
    this.fetchClientFundingEnding()
    this.fetchEmployeeCertExpiring()
    this.fetchClientLeaveOverMonth()
    // this.fetchClientFundingEndedJobs()
    // this.fetchEmployeeCertExpiredJobs()
  }

  downloadFile (type) {
    if (type === 'funder') {
      this.fetchClientFundingEndedJobs()
    } else if (type === 'cert') {
      this.fetchEmployeeCertExpiredJobs()
    }
  }

  render () {
    const { action, actionStat, certExpired, certExpiring, clientLeave, fundingEnded, fundingEnding, summary } = this.state
    let totalSystem = { add: 0, update: 0, cancel: 0, uncancel: 0}
    let systemTotal = 0

    const { match } = this.props
    const { params } = match
    const { from, to } = params

    return (
      <div style={{ margin: '0 auto', width: '800px', background: '#ffffff', padding: '50px 20px', border: '1px solid #f2f2f2'}}>
        <div style={{ fontSize: '14pt', fontWeight: '800' }}>Flexi Support Report <span style={{ padding: '2px 10px', fontSize: '11pt', borderRadius: '8px', backgroundColor: '#ffecdd'}}>{moment(from).format('DD/MM/YYYY')} - {moment(to).format('DD/MM/YYYY')}</span></div>
        <div style={{ fontSize: '12pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '30px' }}>Summary</div>

        <table width='100%' style={{ marginTop: '20px', fontSize: '9pt' }} cellSpacing='2' cellPadding='2'>
          <tbody>
          <tr>
            <td colSpan='2' width='50%'>
              <table width='100%' height='60' style={{ textAlign: 'center', backgroundColor: '#ffecdd' }}>
                <tr>
                  <td width='50%' style={{ textAlign: 'center'}}>
                    <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.client_leave.leave}</div> Participant Leaves
                  </td>
                  <td width='50%' style={{ textAlign: 'center'}}>
                    <a href='/notification'>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.client_leave.job_to_action || 0}</div> Jobs To Action
                    </a>
                  </td>
                </tr>
              </table>
            </td>
            <td colSpan='2' width='50%'>
              <table width='100%' height='60' style={{ textAlign: 'center', backgroundColor: '#ffecdd' }}>
                <tr>
                  <td width='50%' style={{ textAlign: 'center' }}>
                    <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.employee_leave.leave}</div> Employee Leaves
                  </td>
                  <td width='50%' style={{ textAlign: 'center' }}>
                    <a href='/notification'>
                      <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.employee_leave.job_to_action || 0}</div> Jobs To Action
                    </a>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan='4' width='100%'>
              <table width='100%' height='60' style={{ textAlign: 'center' }} cellSpacing='2' cellPadding='2'>
                <tr>
                  <td width='25%' style={{ textAlign: 'center', border: '2px solid #ffecdd' }}>
                    <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.client_fund_ending}</div> Plan Period Ending
                  </td>
                  <td width='25%' style={{ textAlign: 'center', border: '2px solid #ffecdd' }}>
                    <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.employee_cert_expiring}</div> Employee Cert Expiring
                  </td>
                  <td width='25%' style={{ textAlign: 'center', border: '2px solid #ffecdd' }}>
                    <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.client_birthday}</div> Participant Birthday
                  </td>
                  <td width='25%' style={{ textAlign: 'center', border: '2px solid #ffecdd' }}>
                    <div style={{ fontSize: '16pt', fontWeight: '800' }}>{summary.employee_birthday}</div> Employee Birthday
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          </tbody>
        </table>

        <div style={{ fontSize: '12pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '30px' }}>{`Participants with Ended Funding (${fundingEnded.total})`}</div>
        <table width='100%'>
          <tbody>
          { fundingEnded.list.map((client, index) => {
            return <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f4f4f4' : '#fff'}}>
              <td width='40%' style={{ paddingLeft: '5px' }}><a href={`https://app.flexi-support.com.au/clients/${client.id}`}>{client.fullname}</a></td>
              <td width='20%' style={{ paddingLeft: '5px' }}>{client.funder_name}</td>
              <td width='20%'>Ended on {formatter.toShortDate(client.end_date)}</td>
              <td width='20%' style={{textAlign: 'right'}}>{ this.duration(client.end_date) } days (as of today)</td>
            </tr>
          }) }
          </tbody>
        </table>

        <div style={{ fontSize: '12pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '30px' }}>{`Participants with Ending Funding (${fundingEnding.total})`}</div>
        <table width='100%'>
          <tbody>
          { fundingEnding.list.map((client, index) => {
            return <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f4f4f4' : '#fff'}}>
              <td width='40%' style={{ paddingLeft: '5px' }}><a href={`https://app.flexi-support.com.au/clients/${client.id}`}>{client.fullname}</a></td>
              <td width='20%' style={{ paddingLeft: '5px' }}>{client.funder_name}</td>
              <td width='20%'>Ending on {formatter.toShortDate(client.end_date)}</td>
              <td width='20%' style={{textAlign: 'right'}}>{ this.expiringDuration(client.end_date) } days to go</td>
            </tr>
          }) }
          </tbody>
        </table>


        <div style={{ fontSize: '12pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '30px' }}>{`Employees with Expired Certificates (${certExpired.total})`}</div>
        <table width='100%'>
          <tbody>
          { certExpired.list.map((emp, index) => {
            return <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f4f4f4' : '#fff'}}>
              <td width='40%' style={{ paddingLeft: '5px' }}><a href={`https://app.flexi-support.com.au/employees/${emp.id}`}>{emp.first_name} {emp.last_name}</a></td>
              <td width='60%'>
              <table width='100%'>
               {
                  emp.police_expiry_days !== null ? <tr><td width='65%'>● Police Check expired on {moment(emp.police_expire_date).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.duration(emp.police_expire_date)} days</td></tr> : null
               }
               {
                  emp.first_aid_expiry_days !== null ? <tr><td width='65%'>● First Aid Cert. expired on {moment(emp.first_aid_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.duration(emp.first_aid_expiry)} days</td></tr> : null
               }
               {
                  emp.dwes_expiry_days !== null ? <tr><td width='65%'>● DWES expired on {moment(emp.dwes_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.duration(emp.dwes_expiry)} days</td></tr> : null
               }
               {
                  emp.work_with_child_expiry_days !== null ? <tr><td width='65%'>● Working With Children Check expired on {moment(emp.work_with_child_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.duration(emp.work_with_child_expiry)} days</td></tr> : null
               }
               {
                  emp.driver_license_expiry_days !== null ? <tr><td width='65%'>● Driver License expired on {moment(emp.driver_license_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.duration(emp.driver_license_expiry)} days</td></tr> : null
               }
               {
                  emp.vehicle_registration_expiry_days !== null ? <tr><td width='65%'>● Vehicle Registration expired on {moment(emp.vehicle_registration_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.duration(emp.vehicle_registration_expiry)} days</td></tr> : null
               }
               {
                  emp.vehicle_insurance_expiry_days !== null ? <tr><td width='65%'>● Vehicle Insurance expired on {moment(emp.vehicle_insurance_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.duration(emp.vehicle_insurance_expiry)} days</td></tr> : null
               }
              </table>
              </td>
            </tr>
          }) }
          </tbody>
        </table>

        <div style={{ fontSize: '12pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '30px' }}>{`Employees with Expiring Certificates (${certExpiring.total})`}</div>
        <table width='100%'>
          <tbody>
          { certExpiring.list.map((emp, index) => {
            return <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f4f4f4' : '#fff'}}>
              <td width='40%' style={{ paddingLeft: '5px' }}><a href={`https://app.flexi-support.com.au/employees/${emp.id}`}>{emp.first_name} {emp.last_name}</a></td>
              <td width='60%'>
                <table width='100%'>
               {
                  emp.police_expiry_days !== null && parseInt(emp.police_expiry_days) <= 60 ? <tr><td width='60%'>● Police Check expiring on {moment(emp.police_expire_date).format('DD/MM/YYYY')}</td><td td width='40%' style={{ textAlign: 'right' }}>{this.expiringDuration(emp.police_expire_date)} days to go</td></tr> : null
               }
               {
                  emp.first_aid_expiry_days !== null && parseInt(emp.first_aid_expiry_days) <= 60 ? <tr><td width='60%'>● First Aid Cert. expiring on {moment(emp.first_aid_expiry).format('DD/MM/YYYY')}</td><td td width='40%' style={{ textAlign: 'right' }}>{this.expiringDuration(emp.first_aid_expiry)} days to go</td></tr> : null
               }
               {
                  emp.dwes_expiry_days !== null && parseInt(emp.dwes_expiry_days) <= 60 ? <tr><td width='60%'>● DWES expiring on {moment(emp.dwes_expiry).format('DD/MM/YYYY')}</td><td td width='40%' style={{ textAlign: 'right' }}>{this.expiringDuration(emp.dwes_expiry)} days to go</td></tr> : null
               }
                {
                  emp.work_with_child_expiry_days !== null && parseInt(emp.work_with_child_expiry_days) <= 60 ? <tr><td width='65%'>● Working With Children Check expiring on {moment(emp.work_with_child_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.expiringDuration(emp.work_with_child_expiry)} days to go</td></tr> : null
               }
               {
                  emp.driver_license_expiry_days !== null && parseInt(emp.driver_license_expiry_days) <= 60 ? <tr><td width='65%'>● Driver License expiring on {moment(emp.driver_license_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.expiringDuration(emp.driver_license_expiry)} days to go</td></tr> : null
               }
               {
                  emp.vehicle_registration_expiry_days !== null && parseInt(emp.vehicle_registration_expiry_days) <= 60 ? <tr><td width='65%'>● Vehicle Registration expiring on {moment(emp.vehicle_registration_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.expiringDuration(emp.vehicle_registration_expiry)} days to go</td></tr> : null
               }
               {
                  emp.vehicle_insurance_expiry_days !== null && parseInt(emp.vehicle_insurance_expiry_days) <= 60 ? <tr><td width='65%'>● Vehicle Insurance expiring on {moment(emp.vehicle_insurance_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{this.expiringDuration(emp.vehicle_insurance_expiry)} days to go</td></tr> : null
               }
                </table>
              </td>
            </tr>
          }) }
          </tbody>
        </table>

        <div style={{ fontSize: '12pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '30px' }}>Participants On Leave Over A Month ({clientLeave.total})</div>
        <table width='100%'>
          <tbody>
          { clientLeave.list.map((client, index) => {
            return <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f4f4f4' : '#fff'}}>
              <td width='40%' style={{ paddingLeft: '5px' }}><a href={`https://app.flexi-support.com.au/clients/${client.client_id}`}>{client.client}</a></td>
              <td width='40%'>{moment(client.leave_start).format('DD/MM/YYYY')} - {moment(client.leave_end).format('DD/MM/YYYY')}</td>
              <td width='20%' style={{textAlign: 'right'}}>{client.leave_duration} days (as of today)</td>
            </tr>
          }) }
          </tbody>
        </table>

        <div style={{ fontSize: '12pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '30px' }}>Jobs Download</div>
        <table width='100%'>
          <tbody>
            <tr>
              <td width='50%' style={{ textAlign: 'center', paddingTop: '25px', curosr: 'pointer' }}>
                <a href={`${apiHostname}/api/report/job-ended-funding/${from}/${to}`} target='_blank'>
                  <Icon type="download" style={{fontSize: '22pt'}} /><br />
                  Jobs With Ended/Ending Funding
                </a>
              </td>
              <td width='50%' style={{ textAlign: 'center', paddingTop: '25px', curosr: 'pointer' }}>
                <a href={`${apiHostname}/api/report/job-expired-cert/${from}/${to}`} target='_blank'>
                  <Icon type="download" style={{fontSize: '22pt'}} /><br />
                  Jobs With Expired/Expiring Certs
                </a>
              </td>
            </tr>
          </tbody>
        </table>

          {/* ACTION */}
        <div style={{ fontSize: '14pt', fontWeight: '800', width: '100%', borderBottom: '1px solid #ccc', marginTop: '60px' }}>Action</div>
        <table width='100%' style={{ marginTop: '20px' }} cellPadding='2' cellSpacing='2'>
          <tr>
            <td width='25%' style={{ textAlign: 'center', backgroundColor: '#fff3db' }}>
              <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_creation}</div> New Job
            </td>
            <td width='25%' style={{ textAlign: 'center', backgroundColor: '#fff3db' }}>
              <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_update}</div> Amend Job
            </td>
            <td width='25%' style={{ textAlign: 'center', backgroundColor: '#fff3db' }}>
              <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_cancellation}</div> Cancel Job
            </td>
            <td width='25%' style={{ textAlign: 'center', backgroundColor: '#fff3db' }}>
              <div style={{ fontSize: '16pt', fontWeight: '800' }}>{action.job_uncancellation}</div> Uncancel Job
            </td>
          </tr>
        </table>
        <table width='100%' style={{ marginTop: '30px' }}>
          <thead style={{ fontSize: '11pt', fontWeight: '800', backgroundColor: '#f4f4f4' }}>
            <td width='25%'>
              Action (Count)
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              New Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Amend Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Cancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Uncancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Total
            </td>
          </thead>
          <tbody>
            { actionStat.map((item, index) => {
                totalSystem = {
                  add: totalSystem.add + (item.add || 0),
                  update: totalSystem.update + (item.update || 0),
                  cancel: totalSystem.cancel + (item.cancel || 0),
                  uncancel: totalSystem.uncancel + (item.uncancel || 0)
                }
                return <tr key={index}>
                <td>{item.staff}</td>
                <td style={{ textAlign: 'center' }}>{item.add || 0}</td>
                <td style={{ textAlign: 'center' }}>{item.update || 0}</td>
                <td style={{ textAlign: 'center' }}>{item.cancel || 0}</td>
                <td style={{ textAlign: 'center' }}>{item.uncancel || 0}</td>
                <td style={{ textAlign: 'center' }}>{ (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0) }</td>
              </tr>
            })
            }
            <tr style={{fontWeight: '600'}}>
                <td>System Total</td>
                <td style={{ textAlign: 'center' }}>{totalSystem.add || 0}</td>
                <td style={{ textAlign: 'center' }}>{totalSystem.update || 0}</td>
                <td style={{ textAlign: 'center' }}>{totalSystem.cancel || 0}</td>
                <td style={{ textAlign: 'center' }}>{totalSystem.uncancel || 0}</td>
                <td style={{ textAlign: 'center' }}>{ (totalSystem.add || 0) + (totalSystem.update || 0) + (totalSystem.cancel || 0) + (totalSystem.uncancel || 0) }</td>
              </tr>
          </tbody>
        </table>

        <table width='100%' style={{ marginTop: '30px' }}>
          <thead style={{ backgroundColor: '#f4f4f4', fontSize: '11pt', fontWeight: '800'}}>
            <td width='25%'>
              Action (% of Total)
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              New Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Amend Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Cancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Uncancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Total
            </td>
          </thead>
          <tbody>
            { actionStat.map((item, index) => {
                const total = (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0)
                systemTotal = (totalSystem.add || 0) + (totalSystem.update || 0) + (totalSystem.cancel || 0) + (totalSystem.uncancel || 0)
                return <tr key={index}>
                <td>{item.staff}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.add || 0) / totalSystem.add * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.update || 0) / totalSystem.update * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.cancel || 0) / totalSystem.cancel * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.uncancel || 0) / totalSystem.uncancel * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(total / systemTotal * 100)}</td>
              </tr>
            })
            }
            <tr style={{fontWeight: '600'}}>
                <td>Total</td>
                <td style={{ textAlign: 'center' }}>100%</td>
                <td style={{ textAlign: 'center' }}>100%</td>
                <td style={{ textAlign: 'center' }}>100%</td>
                <td style={{ textAlign: 'center' }}>100%</td>
                <td style={{ textAlign: 'center' }}>100%</td>
              </tr>
          </tbody>
        </table>

        <table width='100%' style={{ marginTop: '30px' }}>
          <thead style={{ backgroundColor: '#f4f4f4', fontSize: '11pt', fontWeight: '800'}}>
            <td width='25%'>
              Action (% of Grand Total)
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              New Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Amend Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Cancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Uncancel Job
            </td>
            <td width='15%' style={{ textAlign: 'center' }}>
              Total
            </td>
          </thead>
          <tbody>
            { actionStat.map((item, index) => {
                const total = (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0)
                return <tr key={index}>
                <td>{item.staff}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.add || 0) / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.update || 0) / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.cancel || 0) / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.uncancel || 0) / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(total / systemTotal * 100)}</td>
              </tr>
            })
            }
            <tr style={{fontWeight: '600'}}>
                <td>Total</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.add / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.update / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.cancel / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.uncancel / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>100%</td>
              </tr>
          </tbody>
        </table>

        <table width='100%' style={{ marginTop: '30px' }}>
          <thead style={{ backgroundColor: '#f4f4f4', fontSize: '11pt', fontWeight: '800'}}>
            <tr>
              <td width='25%'>
                Action (% by action)
              </td>
              <td width='15%' style={{ textAlign: 'center' }}>
                New Job
              </td>
              <td width='15%' style={{ textAlign: 'center' }}>
                Amend Job
              </td>
              <td width='15%' style={{ textAlign: 'center' }}>
                Cancel Job
              </td>
              <td width='15%' style={{ textAlign: 'center' }}>
                Uncancel Job
              </td>
              <td width='15%' style={{ textAlign: 'center' }}>
                Total
              </td>
            </tr>
          </thead>
          <tbody>
            { actionStat.map((item, index) => {
                const total = (item.add || 0) + (item.update || 0) + (item.cancel || 0) + (item.uncancel || 0)
                return <tr key={index}>
                <td>{item.staff}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.add || 0)/ total * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.update || 0) / total * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.cancel || 0) / total * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage((item.uncancel || 0) / total * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(total / total * 100)}</td>
              </tr>
            })
            }
            <tr style={{fontWeight: '600'}}>
                <td>Total</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.add / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.update / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.cancel / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>{formatter.toPercentage(totalSystem.uncancel / systemTotal * 100)}</td>
                <td style={{ textAlign: 'center' }}>100%</td>
              </tr>
          </tbody>
        </table>
      </div>
    )
  }

  async fetchSummary () {
    const summary = await reportService.get('summary')
    this.setState({ summary })
  }

  async fetchActionReport (from, to) {
    const list = await reportService.getByDate('action', from, to)
    const totalActions = list.total_actions.filter((action) => action.action === 'add' || action.action === 'update' || action.action === 'cancel' || action.action === 'uncancel')

    const actions = totalActions.reduce((total, item) => total + parseFloat(item.count), 0)

    const indActions = list.individual_actions.filter((action) => action.action === 'add' || action.action === 'update' || action.action === 'cancel' || action.action === 'uncancel')

    const actionStat = []
    let lastStaff = ''
    let currStaff = {}

    for (let i=0; i < indActions.length; i++) {
      const action = indActions[i]
      const isNewStaff = lastStaff !== action.user
      lastStaff = action.user

      if (isNewStaff) {
        currStaff['staff'] = action.user
      }
      currStaff[action.action] = parseFloat(action.count)

      if ((indActions[i+1] && indActions[i+1].user !== action.user) || (i + 1 === indActions.length)) {
        actionStat.push(currStaff)
        currStaff = {}
      }
    }

    this.setState({ action: list, actionStat })
  }

  async fetchClientFundingEnded () {
    const client = await reportService.get('funding-ended')
    this.setState({ fundingEnded: client })
  }

  async fetchEmployeeCertExpired () {
    const employee = await reportService.get('cert-expired')
    this.setState({ certExpired: employee })
  }

  async fetchClientFundingEnding () {
    const client = await reportService.get('funding-ending')
    this.setState({ fundingEnding: client })
  }

  async fetchEmployeeCertExpiring () {
    const employee = await reportService.get('cert-expiring')
    this.setState({ certExpiring: employee })
  }

  async fetchClientLeaveOverMonth () {
    const client = await reportService.get('client-leave-over-month')
    this.setState({ clientLeave: client })
  }

  async fetchClientFundingEndedJobs () {
    const { match } = this.props
    const { params } = match
    const { from, to } = params

    const client = await reportService.getByDate('funding-ended-job', from, to)
    this.setState({ fundingEndedJobs: client })
  }

  async fetchEmployeeCertExpiredJobs () {
    const { match } = this.props
    const { params } = match
    const { from, to } = params

    const employee = await reportService.get('cert-expired-job', from, to)
    this.setState({ certExpiredJobs: employee })
  }

  duration(date) {
    const duration = moment.duration(moment().endOf('days').diff(moment(date).endOf('days')))
    return duration.asDays().toFixed(0)
  }

  expiringDuration(date) {
    const duration = moment.duration(moment(date).endOf('days').diff(moment().endOf('days')))
    return duration.asDays().toFixed(0)
  }
}

const mapStateToProps = (state) => ({
  ...state.ReportSales
})

export default connect(mapStateToProps, {

})(ReportEmail)
