// Please set this for deployment !!!!!
// Options [local, dev, staging, prod]
const build = 'prod'
// Options [local, dev, staging, prod]

const apiHostname = build === 'local' ? 'http://localhost:3000' : ''
const companyId = '5b780b639b3370063ed76788'
const googleMapApi = 'AIzaSyAjBbFpg3ZUajBzL7k6gEiurQ2XK-TyEx0'

export {
  build,
  apiHostname,
  companyId,
  googleMapApi
}
