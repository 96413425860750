import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import moment from 'moment'

import debounce from 'lodash.debounce'

import './styles.css'

const dtFormat = 'DD/MM/YYYY hh:mm A'
const minBlock = 3

export default class DurationPicker extends Component {
  constructor (props) {
    super(props)
    const { value, interval: invl } = this.props
    const duration = (value === null || value === undefined || value === '') ? 60 : value
    const interval = invl || minBlock

    this.state = {
      isSet: false,
      showPicker: false,
      interval,
      duration,
      durationParsed: {
        hour: parseInt(Math.floor(duration / 60)),
        minute: parseInt(duration % 60)
      }
    }
    // this.changeDateValue = debounce(this.changeDateValue, 80)
  }

  static getDerivedStateFromProps (props, state) {
    const { value, interval, onChange } = props

    let data = null

    if (value && state.isSet === false) {
      let val = value

      if (!data) data = {}
      data.duration = val
      data.durationParsed = {
        hour: parseInt(Math.floor(val / 60)),
        minute: parseInt(val % 60)
      }
      data.isSet = true
    }

    // if interval changed and duration is not times of interval (duration/new interval has remainder), compensate the value
    const rem = state.duration % interval
    if (props.interval && props.interval !== state.interval) {
      if (!data) data = {}

      data.interval = interval
      let dur = state.duration

      const rem = dur % interval
      if (rem !== 0) {
        // if new interval set and duration is not times of interval, compensate new duration with deduct remainder value
        dur -= rem
        if (dur < 0) dur = 0
        data.duration = dur
        data.durationParsed = {
          hour: parseInt(Math.floor(dur / 60)),
          minute: parseInt(dur % 60)
        }

        // onchange the value to parent
        onChange && onChange(dur)
      }
    }

    if (data) {
      return data
    }

    return null
  }

  componentDidMount () {
  }

  showPicker = () => {
    this.setState({ showPicker: true })
  }

  changeDuration = (type, increment) => {
    let { duration, interval } = this.state
    if (type === 'hour') {
      if (increment && duration < 6000) duration += 60
      else if (!increment && duration >= 60) duration -= 60
    } else if (type === 'minute') {
      if (increment) {
        duration += interval
      } else if (!increment && duration > 0) {
        duration -= interval
      }

      // if interval changed and duration is not times of interval (duration/new interval has remainder), compensate the value
      const rem = duration % interval
      if (rem !== 0) {
        duration = increment ? duration + rem : duration - rem
      }
    }

    this.setState({
      duration,
      durationParsed: {
        hour: parseInt(Math.floor(duration / 60)),
        minute: parseInt(duration % 60)
      }
    })
  }

  set = () => {
    const { duration } = this.state
    const { onChange } = this.props
    this.setState({ showPicker: false, isSet: true })
    if (onChange) {
      onChange(duration)
    }
  }

  closePicker = () => {
    // const { defaultDateValue } = this.state
    // this.setState({ dateValue: defaultDateValue.clone(), showPicker: false })
    this.setState({ showPicker: false })
  }

  handleFocus = (event) => event.target.select()

  getDurationText = (duration = 0) => {
    const hour = Math.floor(duration / 60)
    const hourText = hour > 1 ? 'Hrs' : 'Hr'
    const minutes = duration % 60
    const minText = minutes > 1 ? 'Mins' : 'Min'
    return `${hour} ${hourText} ${minutes} ${minText}`
  }

  getEndDateText = (dateVal, duration = 0) => {
    if (dateVal && moment.isMoment(dateVal)) {
      return dateVal.clone().add(duration, 'minutes').format(dtFormat)
    }
  }

  getDurationTitleText = (duration = 0) => {
    const hour = parseFloat((duration / 60).toFixed(2))
    const hourText = hour > 1 ? 'Hrs' : 'Hr'
    const minText = duration > 1 ? 'Mins' : 'Min'
    return `${hour} ${hourText} (${duration} ${minText})`
  }

  render () {
    const { disabled = false, placeholder = '', value } = this.props
    const { duration, durationParsed, isSet, showPicker } = this.state

    return (
      <div className='picker'>
        <Input className='inputField' readOnly disabled={disabled} placholder={placeholder} onClick={() => this.showPicker()} value={this.getDurationText(duration)} suffix={<Icon type='clock-circle' />} />
        <div className='container' style={{ display: showPicker ? '' : 'none' }}>
          <div className='header'>
            <div className='date-display'>{this.getDurationTitleText(duration)}</div>
            <div className='action-icon' onClick={() => this.closePicker()}><Icon type='close-circle' theme='filled' /></div>
          </div>
          <div style={{zIndex: 30000, padding: '0px 8px'}}>
            <Row>
              <Col span={5}/>
              <Col span={14}>
                <Col xs={9} lg={9}><div className='control' onClick={() => this.changeDuration('hour', true)}><Icon type='plus-circle' theme='filled' /></div></Col>
                <Col span={3} />
                <Col xs={9} lg={9}><div className='control' onClick={() => this.changeDuration('minute', true)}><Icon type='plus-circle' theme='filled' /></div></Col>
                <Col span={3} />
              </Col>
              <Col span={5}/>
            </Row>
            <Row className='value'>
              <Col span={5}/>
              <Col span={14}>
                <Col xs={9} lg={9} style={{ textAlign: 'center' }}>
                  <input
                    type='text'
                    className='label'
                    value={durationParsed.hour}
                    onFocus={this.handleFocus} onClick={this.handleFocus}
                    // onChange={(e) => this.changeDateValue('day', e.target.value)}
                    // onKeyDown={e => this.changeValue('day', e.key)}
                  />
                </Col>
                <Col span={3}><div>{durationParsed.hour > 1 ? 'Hrs' : 'Hr'}</div></Col>
                <Col xs={9} lg={9} style={{ textAlign: 'center' }}>
                  <input
                    type='text'
                    className='label'
                    value={durationParsed.minute}
                    onFocus={this.handleFocus}
                    // onChange={(e) => this.changeDateValue('month', e.target.value)}
                    // readOnly
                  />
                </Col>
                <Col span={3}><div>{durationParsed.minute > 1 ? 'Mins' : 'Min'}</div></Col>
              </Col>
              <Col span={5}/>
            </Row>
            <Row>
              <Col span={5}/>
              <Col span={14}>
                <Col xs={9} lg={9}><div className='control' onClick={() => this.changeDuration('hour', false)}><Icon type='minus-circle' theme='filled' /></div></Col>
                <Col span={3} />
                <Col xs={9} lg={9}><div className='control' onClick={() => this.changeDuration('minute', false)}><Icon type='minus-circle' theme='filled' /></div></Col>
                <Col span={3} />
              </Col>
              <Col span={5}/>
            </Row>
          </div>
          <div className='footer'>
            {/* <div className='button' onClick={() => this.reset()}>Reset</div> */}
            <div className='button' onClick={() => this.set()}>Set Duration</div>
          </div>
        </div>

      </div>
    )
  }
}

DurationPicker.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func
}
