import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { settingOtherService, authService } from '../../../services'

// UI
import { Page, Panel, Button } from '../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'
import notify from '../../../components/Notification'
import setting from '../../../constants/menu/setting'

const { Item: FormItem } = Form

const settingTitle = 'Settings'

export class ReportSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filter: {},
      loading: false,
      settings: {}
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)

      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error(`Unable to save successfully', 'Unable to save ${settingTitle} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error(`Unable to save successfully', 'Unable to save ${settingTitle} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { loading, loadingForm, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    const specialFormItemLayout = {
      labelCol: { sm: 5, md: 5, lg: 5 },
      wrapperCol: { sm: 17, md: 17, lg: 17 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Recipient Settings'>
            {
              this.hasAccess('updateRecipient')
                ? <Button key={'save'} type='primary' feedback={loadingForm} onClick={() => this.handleSave()}>
                  Save
                </Button>
                : null
            }
          </Page.Header>

          <div /* className='setting-list' */>
            <Skeleton loading={loading} active>
              <Form>
                <Panel title='Email Report Recipients'>
                  <FormItem label='Participant Additional Reporting' {...specialFormItemLayout}>
                    {getFieldDecorator('client_additional_reporting_email', {
                      initialValue: settings.client_additional_reporting_email,
                      rules: [
                        { required: true, message: 'Please enter additional reporting recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Participant Last Job Count' {...specialFormItemLayout}>
                    {getFieldDecorator('last_job_count_email', {
                      initialValue: settings.last_job_count_email,
                      rules: [
                        { required: true, message: 'Please enter last job count report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Participant Plan Details' {...specialFormItemLayout}>
                    {getFieldDecorator('client_plan_details_email', {
                      initialValue: settings.client_plan_details_email,
                      rules: [
                        { required: true, message: 'Please enter participant plan details report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Participant Plan Expiry' {...specialFormItemLayout}>
                    {getFieldDecorator('client_plan_expiry_email', {
                      initialValue: settings.client_plan_expiry_email,
                      rules: [
                        { required: true, message: 'Please enter participant plan expiry report recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Participant Reports Due' {...specialFormItemLayout}>
                    {getFieldDecorator('client_report_due_email', {
                      initialValue: settings.client_report_due_email,
                      rules: [
                        { required: true, message: 'Please enter participant reports due recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Panel>

                <Panel title='Changes Notification Recipients'>
                  <FormItem label='Employee Changes' {...specialFormItemLayout}>
                    {getFieldDecorator('employee_changes_email', {
                      initialValue: settings.employee_changes_email,
                      rules: [
                        { required: true, message: 'Please enter employee changes recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Participant Changes' {...specialFormItemLayout}>
                    {getFieldDecorator('client_changes_email', {
                      initialValue: settings.client_changes_email,
                      rules: [
                        { required: true, message: 'Please enter participant changes recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Participant SC Hours Notification' style={{ textAlign: 'left' }} {...specialFormItemLayout}>
                    {getFieldDecorator('client_sc_hour_changes_email', {
                      initialValue: settings.client_sc_hour_changes_email,
                      rules: [
                        { required: true, message: 'Please enter participant sc hours notification recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                  <FormItem label='Provider Changes' {...specialFormItemLayout}>
                    {getFieldDecorator('provider_changes_email', {
                      initialValue: settings.provider_changes_email,
                      rules: [
                        { required: true, message: 'Please enter provider changes recipients' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Panel>
              </Form>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ReportSetting))
