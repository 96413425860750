import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'
import moment from 'moment'

// UI
import { List, Page } from '../../../components'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

export class ReportClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      clientLeave: { list: [], total: 0 },
      clientLeaveOverMonth: { list: [], total: 0 },
      clientBirthday: { list: [], total: 0 }
    }
  }

  componentDidMount () {
    this.fetchClient()
  }

  render () {
    const { clientLeave, clientLeaveOverMonth, clientBirthday, loading} = this.state

    const columns = [
      {
        title: 'Participant',
        width: 10,
        render: ({ client_id, fullname }) => <a href={`/clients/${client_id}`} target='_blank'>{fullname}</a>
      },
      {
        title: 'Leave',
        width: 10,
        key: 'type'
      },
      {
        title: 'Jobs To Action',
        width: 4,
        render: ({ id, leave, total_jobs, job_to_action }) => (leave > 0 && total_jobs !== '0') ?  <a href={`/clients/${id}/leave/${leave}?notify`}>{job_to_action} jobs to action</a> : null
      }
    ]

    const overMonthColumns = [
      {
        title: 'Participant',
        width: 10,
        render: ({ client_id, client }) => <a href={`/clients/${client_id}`} target='_blank'>{client}</a>
      },
      {
        title: 'Leave',
        width: 10,
        render: ({ leave_start, leave_end }) => `${moment(leave_start).format('DD/MM/YYYY')} - ${moment(leave_end).format('DD/MM/YYYY')}`
      },
      {
        title: 'As Of Today',
        width: 4,
        render: ({ leave_duration }) => `${leave_duration} days`
      }
    ]

    const birthdayColumns = [
      {
        title: 'Participant',
        width: 10,
        render: ({ id, client }) => <a href={`/clients/${id}`} target='_blank'>{client}</a>
      },
      {
        title: 'Birthday',
        width: 10,
        render: ({ next_birthday }) => moment(next_birthday).format('DD/MM/YYYY')
      },
      {
        title: '',
        width: 4,
        render: ({ days_until_next }) => `${days_until_next} days to go`
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Participant's Leave & Birthday`} />

          <div className='admin-list'>
            <Skeleton loading={loading} active>

              <div className='report-title'>{`Participant's Leave `} <span className='report-tag'>{clientLeave.total}</span></div>
              <List cols={columns} rows={clientLeave.list} />

              <div className='report-title'>{`Participant On Leave Over A Month `} <span className='report-tag'>{clientLeaveOverMonth.total}</span></div>
              <List cols={overMonthColumns} rows={clientLeaveOverMonth.list} />

              <div className='report-title'>{`Participant's Birthday `} <span className='report-tag'>{clientBirthday.total}</span></div>
              <List cols={birthdayColumns} rows={clientBirthday.list} />

            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchClient () {
    this.setState({ loading: true })

    const [ leave, leaveOverMonth, birthday ] = await Promise.all([
      reportService.get('client-leave'),
      reportService.get('client-leave-over-month'),
      reportService.get('client-birthday')
    ])

    this.setState({ clientLeave: leave, clientLeaveOverMonth: leaveOverMonth, clientBirthday: birthday, loading: false })
  }
}

export default ReportClient
