import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGeneralService } from '../../../services/setting'
import { authService } from '../../../services'

// UI
import { Button, Page, Pager, Panel, SideModal, List } from '../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Tabs from 'antd/lib/tabs'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import ProviderTab from './Providers'
import ScTab from './SC'

import './styles.css'
import notify from '../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

export class SettingServices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: []
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { form } = this.props

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Services' />
          <Tabs defaultActiveKey='1'>
            <TabPane tab='Providers' key='1'>
              <ProviderTab />
            </TabPane>
            <TabPane tab='Support Coordinators' key='2'>
              <ScTab />
            </TabPane>
          </Tabs>
        </Page.Content>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingServices))
