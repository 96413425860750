import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

export default class WitSelect extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { label, options, placeholder, value, ...others } = this.props
    const { label: optionsLabel, list: optionsList = [], value: optionsValue } = options || {}
    const _value = value || ''

    return label ? (
      <div className='witty-select-container'>
        <div className='witty-label'>{label}</div>

        <div className='witty-select'>
          <select className='control' value={_value} {...others}>
            <option disabled value=''>Select a {`${placeholder}`.toLowerCase()}</option>

            {optionsList.map((item) => (
              <option key={item[optionsValue]} value={item[optionsValue]}>{item[optionsLabel]}</option>
            ))}
          </select>
        </div>
      </div>
    ) : (
      <div className='witty-select'>
        <div className={'label' + (value ? ' label-visible' : '')}>{placeholder}</div>

        <select className='control' value={_value} {...others}>
          <option disabled value=''>Select a {`${placeholder}`.toLowerCase()}</option>

          {optionsList.map((item) => (
            <option key={item[optionsValue]} value={item[optionsValue]}>{item[optionsLabel]}</option>
          ))}
        </select>
      </div>
    )
  }
}

WitSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  placeholder: PropTypes.string
}
