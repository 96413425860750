import React, { Component } from 'react'
import { connect } from 'react-redux'
import { settingFundManagerService } from '../../../../../services'
import Moment from 'moment-timezone'
import { formatter } from '../../../../../util'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Pager } from '../../../../../components'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class FundManagerClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      clientList: [],
      loading: false,
      clientId: ''
    }
    this.filterLogs = this.filterLogs.bind(this)
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchClients(currentPage)
  }

  filterLogs (e) {
    this.setState({ currentPage: 1 })
    this.fetchLogs(e.target.value, 1)
  }

  render () {
    const { currentPage, loading, clientList, total } = this.state

    const clientColumns = [
      {
        title: 'Name',
        width: 8,
        render: ({ id, first_name, last_name }) => <Link to={`/clients/${id}`}><div className='name-label'>{first_name} {last_name} <Icon type='eye' /></div></Link>
      }
    ]

    return (
      <Loading loading={loading} blur>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={clientColumns} rows={clientList} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} participants`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchClients = async (startPage = null) => {
    this.setState({ loading: true })

    const { fundManagerId } = this.props
    const { currentPage } = this.state
    const filter = { }
    const page = startPage || currentPage

    filter.fund_manager_id = fundManagerId

    const { list: clientList, total } = await settingFundManagerService.listClientsByPage(page, pageSize, filter)

    this.setState({ clientList, total, loading: false, currentPage: page, filter })
  }

  changePage = async (currentPage) => {
    this.fetchClients(currentPage)
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FundManagerClient))
