
import ClientList from './containers/Client/List'
import ClientPage from './containers/Client/Page'
import Communication from './containers/Communication'
import Dashboard from './containers/Dashboard'
import EmployeeList from './containers/Employee/List'
import EmployeePage from './containers/Employee/Page'
import FunderList from './containers/Funder/List'
import FunderPage from './containers/Funder/Page'
import JobPage from './containers/Job/IndividualPage'
import Map from './containers/Map'
import Notification from './containers/Notification'
import IndividualList from './containers/Job/Individual'

// Payroll
import PayrollMain from './containers/Payroll/Main'
import PayrollList from './containers/Payroll/List'
import PayrollIndividual from './containers/Payroll/Individual'

// Provider
import ProviderList from './containers/Provider/List'
import ProviderPage from './containers/Provider/Page'

// Report
import ReportMain from './containers/Report'
import ReportAction from './containers/Report/Action'
import ReportActionJob from './containers/Report/Action/job'
import ReportClient from './containers/Report/Client'
import ReportClientAdditionalReporting from './containers/Report/Client/additionalReporting'
import ReportClientBalanceHour from './containers/Report/Client/balanceHour'
import ReportClientDue from './containers/Report/Client/reportDue'
import ReportClientFunding from './containers/Report/Client/funding'
import ReportClientLastJob from './containers/Report/Client/lastJob'
import ReportEmployee from './containers/Report/Employee'
import ReportEmployeeCert from './containers/Report/Employee/certificate'
import ReportClashJob from './containers/Report/Clash'
import ReportSetting from './containers/Report/Setting'

// Settings
import Setting from './containers/Setting'
import SettingModule from './containers/Setting/Module'
import SettingAdmin from './containers/Setting/Admin'
import SettingAdminRole from './containers/Setting/AdminRole'
import SettingAccessLog from './containers/Setting/AccessLog'
import SettingBillingRates from './containers/Setting/BillingRates'
import SettingBillingRatesSet from './containers/Setting/BillingRates/Set/set'
import SettingCancellation from './containers/Setting/Cancellation'
import SettingCancellationPage from './containers/Setting/Reason/Cancellation/Page'
import SettingReason from './containers/Setting/Reason'
import SettingResignedExitedPage from './containers/Setting/Reason/ResignedExited/Page'
import SettingClientDifficulty from './containers/Setting/ClientDifficulty'
import SettingHoliday from './containers/Setting/Holiday'
import SettingHolidayPage from './containers/Setting/Holiday/Page'
import SettingHolidayImpactedJob from './containers/Setting/Holiday/ImpactedJob'
import SettingProfile from './containers/Setting/Profile'
import SettingReminderLog from './containers/Setting/ReminderLog'
import SettingService from './containers/Setting/Service'
import SettingSuburbs from './containers/Setting/Suburb'
import SettingOther from './containers/Setting/Other'
import SettingFunder from './containers/Setting/Funder'
import SettingFunderBillingRatesSet from './containers/Setting/Funder/BillingRates/Set/set'
import SettingFunderFundManagerPage from './containers/Setting/Funder/FundManager/Page'
import SettingFile from './containers/Setting/File'

export const routes = [
  {
    component: Dashboard,
    exact: true,
    icon: 'dashboard.svg',
    menu: true,
    name: 'Dashboard',
    path: '/'
  },
  {
    component: Setting,
    exact: true,
    icon: 'admin.svg',
    name: 'Admins',
    path: '/settings'
  },
  {
    component: ClientList,
    name: 'Participant',
    path: '/clients',
    exact: true
  },
  {
    component: ClientPage,
    name: 'Participant',
    path: '/clients/:id',
    exact: true
  },
  {
    component: EmployeeList,
    name: 'Employee',
    path: '/employees',
    exact: true
  },
  {
    component: EmployeePage,
    name: 'Employee',
    path: '/employees/:id',
    exact: true
  },
  {
    component: FunderList,
    name: 'Funder',
    path: '/funders',
    exact: true
  },
  {
    component: FunderPage,
    name: 'Client',
    path: '/funders/:id',
    exact: true
  },
  // {
  //   component: RecurringBaseList,
  //   name: 'Job',
  //   path: '/jobs/recurring',
  //   exact: true
  // },'
  {
    component: IndividualList,
    name: 'Job',
    path: '/jobs/all',
    exact: true
  },
  {
    component: IndividualList,
    name: 'Job',
    path: '/jobs/cancelled',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/jobs/single/:id',
    exact: true
  },
  // {
  //   component: RecurringJobPage,
  //   name: 'Job',
  //   path: '/jobs/recurring/:id',
  //   exact: true
  // },
  {
    component: JobPage,
    name: 'Job',
    path: '/jobs/conflict/:id',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/jobs/cancelled/:id',
    exact: true
  },
  {
    component: JobPage,
    name: 'Job',
    path: '/jobs/deleted/:id',
    exact: true
  },
  // {
  //   component: JobPage,
  //   name: 'Job',
  //   path: '/jobs/future/:id',
  //   exact: true
  // },
  // {
  //   component: JobPage,
  //   name: 'Job',
  //   path: '/jobs/past/:id',
  //   exact: true
  // },
  // {
  //   component: JobPage,
  //   name: 'Job',
  //   path: '/jobs/pending-individual/:id',
  //   exact: true
  // },
  // {
  //   component: RecurringJobPage,
  //   name: 'Job',
  //   path: '/jobs/pending-recurring/:id',
  //   exact: true
  // },
  {
    component: Map,
    name: 'Map',
    path: '/map',
    exact: true
  },
  {
    component: Notification,
    name: 'Notification',
    path: '/notification',
    exact: true
  },
  // {
  //   component: Communication,
  //   name: 'Communication',
  //   path: '/communication',
  //   exact: true
  // },
  {
    component: PayrollIndividual,
    name: 'Billings',
    path: '/billings/:pid/:view/:id',
    exact: true
  },
  {
    component: PayrollList,
    name: 'Billings',
    path: '/billings/:id',
    exact: true
  },
  {
    component: PayrollMain,
    name: 'Billings',
    path: '/billings',
    exact: true
  },
  {
    component: ProviderList,
    name: 'Providers',
    path: '/providers',
    exact: true
  },
  {
    component: ProviderPage,
    name: 'Providers',
    path: '/providers/:id',
    exact: true
  },
  {
    component: ReportAction,
    name: 'System User Action Report',
    path: '/reports/action',
    exact: true
  },
  {
    component: ReportActionJob,
    name: 'System User Action Report',
    path: '/reports/action/:client/:from/:to',
    exact: true
  },
  {
    component: ReportClientAdditionalReporting,
    name: 'Participants\' Additional Reporting',
    path: '/reports/client-additional-reporting',
    exact: true
  },
  {
    component: ReportClientBalanceHour,
    name: 'Participants\' Plan Details',
    path: '/reports/client-plan-details',
    exact: true
  },
  {
    component: ReportClientDue,
    name: 'Participants\' Reports Due',
    path: '/reports/client-due',
    exact: true
  },
  {
    component: ReportClientFunding,
    name: 'Participants\' Plan Expiry',
    path: '/reports/client-plan-expiry',
    exact: true
  },
  {
    component: ReportClientLastJob,
    name: 'Participants\' Day Counts Since Last Job',
    path: '/reports/client-last-job',
    exact: true
  },
  {
    component: ReportMain,
    name: 'Report',
    path: '/reports',
    exact: true
  },
  // {
  //   component: ReportClient,
  //   name: 'Client Leave and Birthday Report',
  //   path: '/reports/client',
  //   exact: true
  // },
  // {
  //   component: ReportEmployee,
  //   name: 'Employee Leave and Birthday Report',
  //   path: '/reports/employee',
  //   exact: true
  // },
  // {
  //   component: ReportEmployeeCert,
  //   name: 'Employee Certificate',
  //   path: '/reports/employee-cert',
  //   exact: true
  // },
  // {
  //   component: ReportClashJob,
  //   name: 'Clash & Back-to-Back Report',
  //   path: '/reports/clash-job',
  //   exact: true
  // },
  {
    component: ReportSetting,
    name: 'Report Settings',
    path: '/reports/setting',
    exact: true
  },
  {
    component: SettingModule,
    name: 'Setting System Module',
    path: '/settings/modules',
    exact: true
  },
  {
    component: SettingAdmin,
    name: 'Setting System User',
    path: '/settings/admins',
    exact: true
  },
  {
    component: SettingAdminRole,
    name: 'Setting User Role',
    path: '/settings/admin-roles',
    exact: true
  },
  {
    component: SettingAccessLog,
    name: 'User Access Log',
    path: '/settings/access-log',
    exact: true
  },
  {
    component: SettingProfile,
    name: 'Setting Profile',
    path: '/settings/profile',
    exact: true
  },
  {
    component: SettingReminderLog,
    name: 'Reminder Log',
    path: '/settings/reminder-log',
    exact: true
  },
  {
    component: SettingService,
    name: 'Setting Service',
    path: '/settings/services',
    exact: true
  },
  {
    component: SettingSuburbs,
    name: 'Suburbs',
    path: '/settings/suburbs',
    exact: true
  },
  {
    component: SettingHoliday,
    name: 'Setting Holidays',
    path: '/settings/holidays',
    exact: true
  },
  {
    component: SettingHolidayPage,
    name: 'Setting Holidays',
    path: '/settings/holidays/:id',
    exact: true
  },
  {
    component: SettingHolidayImpactedJob,
    name: 'Setting Holidays',
    path: '/holidays/:id/job',
    exact: true
  },
  {
    component: SettingBillingRates,
    name: 'Setting Billing Rates',
    path: '/settings/billing-rates',
    exact: true
  },
  {
    component: SettingBillingRatesSet,
    name: 'Setting Billing Rates',
    path: '/settings/billing-rates/:id',
    exact: true
  },
  {
    component: SettingClientDifficulty,
    name: 'Setting Client Difficulty',
    path: '/settings/client-difficulties',
    exact: true
  },
  {
    component: SettingOther,
    name: 'Setting Other',
    path: '/settings/others',
    exact: true
  },
  {
    component: SettingCancellation,
    name: 'Setting Cancellation',
    path: '/settings/cancellations',
    exact: true
  },
  {
    component: SettingCancellationPage,
    name: 'Setting Cancellation',
    path: '/settings/cancellations/:id',
    exact: true
  },
  {
    component: SettingReason,
    name: 'Setting Reason',
    path: '/settings/reasons',
    exact: true
  },
  {
    component: SettingResignedExitedPage,
    name: 'Setting Resigned/Exited',
    path: '/settings/resigned-or-exited/:id',
    exact: true
  },
  {
    component: SettingFunder,
    name: 'Setting Funder',
    path: '/settings/funder',
    exact: true
  },
  {
    component: SettingFunderBillingRatesSet,
    name: 'Setting Billing Rates',
    path: '/settings/funder/billing-rates/:id',
    exact: true
  },
  {
    component: SettingFunderFundManagerPage,
    name: 'Setting Plan Manager',
    path: '/settings/funder/fund-manager/:id',
    exact: true
  },
  {
    component: SettingFile,
    name: 'Setting File',
    path: '/settings/files',
    exact: true
  }
]
