export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          {
            title: 'Billings',
            icon: 'dollar',
            menu: [
            ],
            permission: 'listPayrolls'
          },
          {
            title: 'Reports',
            icon: 'snippets',
            menu: [
              {
                name: 'Day Counts Since Last Job',
                link: '/reports/client-last-job',
                permission: 'readClientLastJobCount'
              },
              {
                name: 'Participants\' Additional Reporting',
                link: '/reports/client-additional-reporting',
                permission: 'readClientAdditionalReporting'
              },
              {
                name: 'Participants\' Plan Details',
                link: '/reports/client-plan-details',
                permission: 'readClientJobBalanceHourReport'
              },
              {
                name: 'Participants\' Plan Expiry',
                link: '/reports/client-plan-expiry',
                permission: 'readClientFundReport'
              },
              {
                name: 'Participants\' Reports Due',
                link: '/reports/client-due',
                permission: 'readClientDueReport'
              },
              // {
              //   name: 'Billings',
              //   link: '/billings',
              //   permission: 'listPayrolls'
              // },
              {
                name: 'User Action',
                link: '/reports/action',
                permission: 'readActionReport'
              },
              {
                name: 'Recipient Settings',
                link: '/reports/setting',
                permission: 'listRecipients'
              }
            ],
            permission: 'listReports'
          }
        ]
      }
    ]
  }
]
