import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, settingBillingRateService, settingBillingCategoryService } from '../../../../../services'
import moment from 'moment'
import { Link } from 'react-router-dom'

// UI
import { Button, FullModal, Pager, SectionTitle, List, ListDiv } from '../../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import TimePicker from 'antd/lib/time-picker'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../../components/Notification'
import BillingRateRow from './row'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Billing Rate Set'
const settingType = 'billing rate set'

export class SettingBillingRates extends Component {
  constructor (props) {
    super(props)
    this.state = {
      billingRates: [],
      categories: [],
      categoryRates: [],
      currentPage: 1,
      defaultBillingRates: [],
      defaultCategoryRates: [],
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {},
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchBillingRates({ loading: true, currentPage })
  }

  componentDidUpdate (prevProps, prevState) {
    const { defaultBillingRates, defaultCategoryRates, onAdd } = this.state
    if (onAdd && onAdd !== prevState.onAdd) {
      const billingRates = (Array.isArray(defaultBillingRates) ? defaultBillingRates : []).slice(0)

      for (let i = 0; i < billingRates.length; i++) {
        billingRates[i].value = 0
      }
      this.setState({ billingRates, categoryRates: defaultCategoryRates })
    }
  }

  fetchBillingRates = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      this.setState({ currentPage, loading: loading })
      const settings = await settingBillingRateService.listByPage(currentPage, pageSize, filter, sort, searchText)

      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load Billing Rate Set successfully. Please try again later.')
    }
  }

  async handleDelete (id) {
    this.setState({ loading: true })
    const res = await settingBillingRateService.remove(id)

    if (res) {
      await this.fetchBillingRates({ currentPage: 1 })
      notify.success('Deleted successfully', `Billing rate set deleted successfully`)
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, total } = this.state

    const columns = [
      {
        title: 'Name',
        width: 16,
        key: 'name',
        render: ({ name }) => <div style={{ fontWeight: 'bold' }}>{ name }</div>
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ id, active }) => <div style={{ fontSize: '11pt' }}>{ active ? <Icon type='check-circle' theme='filled' /> : null } { this.hasAccess('readBillingRate') && !this.hasAccess('updateBillingRate') ? <Col md={1}>
          <Link to={`/settings/funder/billing-rates/${id}`}><div><Icon type='eye' /></div></Link>
        </Col> : null }</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('updateBillingRate') ? <Link to={`/settings/funder/billing-rates/${id}`}><div><Icon type='form' /></div></Link> : null
      },
      {
        title: '',
        width: 1,
        render: ({ id }) => this.hasAccess('deleteBillingRate') ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        ><Icon type='delete' />
        </Popconfirm> : null
      }
    ]

    const subcolumns = [
      {
        title: 'Name',
        width: 16,
        key: 'name'
      },
      {
        title: 'Enable',
        width: 8,
        render: ({ active }) => <div style={{ fontSize: '10pt', width: '30px' }}>{ active ? <Icon type='check-circle' /> : null }</div>
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <ListDiv cols={columns} rows={list} subcols={subcolumns} subrows='rate_set' />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} rate set`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
              />
          </Skeleton>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchBillingRates({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingBillingRates))
