export const FETCH_PROVIDERS = 'FETCH_PROVIDERS'
export const FETCHING_PROVIDERS = 'FETCHING_PROVIDERS'
export const PROVIDERS_FETCHED = 'PROVIDERS_FETCHED'

export const fetchProviders = (params) => {
  return Object.assign({
    type: FETCH_PROVIDERS
  }, params)
}

export const fetchingProviders = (loading) => {
  return {
    type: FETCHING_PROVIDERS,
    loading
  }
}

export const providersFetched = (providers) => {
  return {
    type: PROVIDERS_FETCHED,
    providers
  }
}
