/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, funderService, settingGeneralService, settingBillingRateService, logService, funderFileService, settingFundManagerService } from '../../../services'
import { fetchingFunders, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates } from '../../../constants'
import moment from 'moment'
import { accRef, formatter, log, trigger, uploader } from '../../../util'
import Moment from 'moment-timezone'

// UI
import { Loading, List, Page, Panel, FileTypeUpload } from '../../../components'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import ActivityLog from '../ActivityLog'
import ClientList from '../Client'
import FundManager from '../FundManager'

import './styles.css'
import { apiHostname } from '../../../config'

const { confirm, error } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const funderModule = 'funder'

export class Funder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      funding: [],
      billingRate: [],
      clientList: [],
      logList: [],
      fundManager: [],
      loading: false,
      loadingFile: false,
      loadingForm: false,
      showSave: false,
      showEdit: true,
      taxCodes: [],
      showFundingDate: true,
      showFundManager: false,
      showFundManagerTab: false,
      selectedManagers: []
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchFunder()
      this.fetchLogs()
      this.fetchFiles()
      this.fetchFundManagers()
      this.fetchFundManagerCount()
    }
    this.fetchBillingRate()
    this.fetchSettings()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  async generateAccRef () {
    const number = await accRef.funder()
    this.setState({ newAccRef: number })
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { match, history } = this.props
    const { item, loading, showSave, showEdit, showFundManagerTab } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Funder (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.fullname} (View Only)` : showSave ? `${item.fullname} (Edit Mode)` : 'Funder'}>
            {
              this.isEdit() && this.hasAccess('deleteFunder') ? showSave ? (
                <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
                </div>
              ) : null : null}
            {
              showEdit && this.isEdit() && this.hasAccess('updateFunder')
                ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)
                : null
            }
            {
              showSave || !this.isEdit()
                ? (
                  <div className='btn' onClick={this.handleSave}>
                  Save
                  </div>)
                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='funder-panel'>
            { this.isEdit()
              ? <div className='funder-panel-header'>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='funder-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='funder-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='funder-panel-header-label'>Name</div>
                      <div className='funder-panel-header-value'>{item.fullname}<div className='funder-panel-header-subvalue'>{ item.acc_ref }</div></div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Phone Number</div>
                      <div className='funder-panel-header-value' style={{ textTransform: 'capitalize' }}>{item.phone_number || '-'}</div>
                    </Col>
                    <Col lg={4}>
                      <div className='funder-panel-header-label'>Suburb</div>
                      <div className='funder-panel-header-value'>{item.suburb}</div>
                    </Col>
                    <Col lg={4} />
                  </Row> }
              </div> : null
            }
            <div className='funder-panel-body'>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Funder Info' key='1'>
                  { this.infoTab() }
                </TabPane>
                { this.isEdit() && showFundManagerTab ? <TabPane tab='Plan Manager' key='2'>
                  <FundManager funderId={match.params.id} history={this.props.history} onUpdateFM={() => this.fetchFundManagerCount()} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Participants' key='3'>
                  <ClientList funderId={match.params.id} history={this.props.history} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Activity Log' key='4'>
                  <ActivityLog funderId={match.params.id} history={this.props.history} />
                </TabPane> : null }
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { newAccRef, funding, item, loading, loadingFile, loadingForm, billingRate, taxCodes, showFundingDate,
      agreement, showFundManager, fundManager, showFundManagerTab, selectedManagers } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }
    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const specialFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 11 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    return <div><Form>
      <Panel title='Funding Settings'>
        <Loading loading={loading} blur>
          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Funding Type'>
                {getFieldDecorator('funding_type', {
                  initialValue: item.funding_type || '',
                  rules: [
                    { required: true, message: 'Please select a funding type.' }
                  ]
                })(
                  <Select onChange={this.changeFundingType} placeholder='Please select a Funding Type'
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      funding.map((states) => (
                        <Option key={`funding${states.value}`} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Rate Set'>
                {getFieldDecorator('rate_set', {
                  initialValue: item.rate_set || '',
                  rules: [
                    { required: true, message: 'Please select a rate set' }
                  ]
                })(
                  <Select placeholder='Please select a Rate Set'
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      billingRate.map((bill, idx) => {
                        return <Option key={`bill${idx}`} value={bill.id}>{bill.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Output Prefix'>
                {getFieldDecorator('output_prefix', {
                  initialValue: item.output_prefix || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          {
            showFundingDate

              ? <Row gutter={9}>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Agreement Start Date'>
                    {getFieldDecorator('funder_start_date',
                      item.funder_start_date ? { initialValue: Moment(item.funder_start_date) || null } : {}

                    )(
                      <DatePicker format={dateFormat} />
                    )}

                  </FormItem>
                </Col>
                <Col lg={9}>
                  <FormItem {...specialFormItemLayout} label='Agreement End Date'>
                    {getFieldDecorator('funder_end_date',
                      item.funder_end_date ? { initialValue: Moment(item.funder_end_date) || null } : {}
                    )(
                      <DatePicker format={dateFormat} />
                    )}

                  </FormItem>
                </Col>
                <Col lg={2}>
                  <div className='file-action-buttons'>
                    {this.isEdit() && this.hasAccess('createEmployeeFile')
                      ? <FileTypeUpload
                        loading={loadingFile}
                        readOnly={false}
                        upload={{
                          action: `${apiHostname}/private/api/funders/files/upload/files`,
                          beforeUpload: this.checkFile,
                          data: { id: undefined, moduleId: item.id },
                          disabled: loading,
                          headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                          name: 'file',
                          onChange: this.handleUploadFile('agreement', item.id, (agreement ? agreement.id : null)),
                          showUploadList: false
                        }}
                      />
                      : null}
                    {this.isEdit() && agreement
                      ? <Tooltip mouseLeaveDelay={0} title={`Download ${agreement.file_name}`}><div onClick={this.handleDownloadFile(agreement.file_url)} style={{ cursor: 'pointer', marginRight: 15 }}><Icon type='file-text' /></div></Tooltip>
                      : null}
                    {this.isEdit() && agreement
                      ? <div>
                      <Tooltip mouseLeaveDelay={0} title='Delete'>
                        <Popconfirm
                          title='Confirm to delete this?'
                          onConfirm={() => this.handleDeleteFile(agreement.id, agreement)}
                          okText='Yes'
                          cancelText='No'
                        ><Icon type='delete' />
                        </Popconfirm>
                      </Tooltip>
                    </div>
                      : null}
                  </div>
                </Col>
              </Row>

              : null
          }

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Tax Code'>
                {getFieldDecorator('tax_code', {
                  initialValue: item.tax_code || '',
                  rules: [
                    { required: true, message: 'Please select a tax code' }
                  ]
                })(
                  <Select placeholder='Please select a tax code'
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      taxCodes.map((tax, idx) => {
                        return <Option key={`tax${idx}`} value={tax.id}>{tax.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            {this.isEdit()
              ? <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Plan Manager'>
                  {getFieldDecorator('fund_managers', {
                    initialValue: showFundManagerTab || false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={this.handleFundManager()}
                    />
                  )}
                </FormItem>
              </Col>
              : null}
          </Row>
        </Loading>

      </Panel>
      <Panel title='Funder Information'>
        <Loading loading={loading} blur>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                {getFieldDecorator('acc_ref', {
                  initialValue: item.acc_ref
                })(
                  <Input readOnly />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='ABN' hasFeedback>
                {getFieldDecorator('abn', {
                  initialValue: item.abn || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Name' hasFeedback>
            {getFieldDecorator('fullname', {
              initialValue: item.fullname || '',
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter first name' },
                { whitespace: true, message: 'Please enter first name' }
              ]
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || '',
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states) => (
                        <Option key={`state${states.value}`} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number || '',
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Fax' hasFeedback>
                {getFieldDecorator('fax', {
                  initialValue: item.fax || '',
                  rules: [
                    { min: 2, message: 'Fax Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Fax Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter fax number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email || '',
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    },
                    { min: 2, message: 'Email must be between 2 and 128 characters' },
                    { max: 128, message: 'Email must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Contact Person'>
            {getFieldDecorator('contact_person', {
              initialValue: item.contact_person || ''
            })(
              <Input />
            )}
          </FormItem>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Name' hasFeedback>
                {getFieldDecorator('after_contact_name', {
                  initialValue: item.after_contact_name || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>

              <FormItem {...sideBySideFormItemLayout} label='After Hours Contact Number' hasFeedback>
                {getFieldDecorator('after_contact_number', {
                  initialValue: item.after_contact_number || ''
                })(
                  <Input />
                )}
              </FormItem>

            </Col>

          </Row>

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || ''
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Loading>
      </Panel>
    </Form>

      <Modal
        title="Add Plan Manager"
        style={{ top: 20 }}
        visible={showFundManager}
        maskClosable={false}
        confirmLoading={loadingForm}
        onOk={() => this.handleSaveFundManager()}
        onCancel={() => this.closeFundManagerModal()}
      >
        <Form layout='vertical'>
          <FormItem label='Plan Manager'>
            {getFieldDecorator('selected_fund_manager', {
              initialValue: Array.isArray(selectedManagers) ? selectedManagers : [],
              rules: [
                { required: true, message: 'Please select a plan manager' }
              ]
            })(
              <Select placeholder='Please select one/multiple plan manager(s)'
                mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {
                  fundManager.map((manager, idx) => {
                    return <Option key={`fm${idx}`} value={manager.id}>{manager.fullname}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
        </Form>
      </Modal>
    </div>
  }

  fetchFunder = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const { item } = await funderService.get(id)
      this.setState({ item, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load funder successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchBillingRate = async () => {
    const billRate = await settingBillingRateService.listByPage(1, 0)
    this.setState({ billingRate: billRate.list })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'funding' },
        { condition: '=', value: 'tax_code' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      funding: settings.list.filter(item => item.type === 'funding'),
      taxCodes: settings.list.filter(item => item.type === 'tax_code')
    })
  }

  fetchLogs = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.type = { condition: '=', value: 'funder' }
    filter.type_id = { condition: '=', value: id }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  fetchFiles = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const filter = {}

    filter.type = {
      $or: [
        { condition: '=', value: 'agreement' }
      ]
    }
    filter.module_id = { condition: '=', value: id }
    filter.module = { condition: '=', value: funderModule }
    filter.active = { condition: '=', value: true }

    const fileTypes = await funderFileService.listByPage(1, 0, filter)

    const agreement = fileTypes.list.filter(item => item.type === 'agreement')

    this.setState({
      agreement: agreement[0]
    })
  }

  fetchFundManagers = async () => {
    const fm = await settingFundManagerService.listByPage(1, 0)
    this.setState({ fundManager: fm.list })
  }

  fetchFundManagerCount = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const filter = { }

    // const fundManagerCount = await funderService.getFunderFundManagerCount(id)

    filter.funder_id = { condition: '=', value: id }
    const { list, total } = await funderService.listFundManagerByPage(1, 0, filter)
    const selectedManagers = list.reduce((a, value) => (a.push(parseInt(value.fund_manager_id)), a), [])

    if (total > 0) {
      this.setState({ showFundManagerTab: true, selectedManagers })
    }
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  changeFundingType = (value) => {
    if (value === 'A002') {
      this.setState({ showFundingDate: false })
    } else {
      this.setState({ showFundingDate: true })
    }
  }

  handleFundManager = () => async (value) => {
    const { form } = this.props
    const { showFundManagerTab } = this.state
    const { setFieldsValue } = form

    if (value === false && showFundManagerTab === true) {
      try {
        const { match } = this.props
        const { params } = match
        const { id } = params
        this.setState({ loading: true })
        const response = await funderService.removeFundManager(id)

        if (response > 0) {
          const { fundManager , selectedManagers } = this.state
          let fmLabels = ''

          for (var i = 0; i < selectedManagers.length; i++) {
            const funderInfo = fundManager.find((f) => f.id === selectedManagers[i])
            fmLabels += funderInfo.fullname + ', '
          }

          log.deleteFunderFundManager(id, `${(fmLabels || '').slice(0, -2)}`)
          notify.success('Deleted successfully', 'Plan manager deleted successfully.')
          this.setState({ loading: false, showFundManager: false, showFundManagerTab: false })
          setFieldsValue({ selected_fund_manager: undefined })
          this.props.setRefreshActivityLog(true)
        }
      } catch (e) {
        notify.error('Unable to delete successfully', 'Unable to delete plan manager successfully. Please try again later.')
        setFieldsValue({ fund_managers: true })
        this.setState({ loading: false })
      }

    } else {
      this.setState({ showFundManager: value })
    }
  }

  handleDelete = () => {
    const { history, match } = this.props
    const { params } = match
    const { id } = params

    confirm({
      title: 'Are you sure you want to delete this funder?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await funderService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Funder deleted successfully.')
            history.replace('/funders')
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete funder successfully. Please try again later.')
        }
      }
    })
  }

  async handleDeleteFile (id, info) {
    const { match } = this.props
    const { params } = match
    const { id: funderId } = params
    this.setState({ loading: true })

    const response = await funderFileService.remove(id)
    this.setState({ loading: false })

    if (response.id) {
      // log.deleteEmployeeFile(funderId, `Delete file ${info.label}`)
      this.fetchFiles()
      this.setState({ shouldRefreshFiles: true })
      notify.success('Deleted successfully', 'File deleted successfully')
      this.props.setRefreshActivityLog(true)
    }
  }

  handleDownloadFile = (url) => (e) => {
    if (url) {
      e.preventDefault()
      e.stopPropagation()
      const newWindow = window.open()
      newWindow.opener = null
      newWindow.location = url
    } else {
      error({
        title: `Error`,
        content: (
          <div>No file has been uploaded!</div>
        ),
        okText: 'OK',
        onOk () { },
      })
    }

  }

  handleUploadFile = (type, empId, fileId) => async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loading: true })
    }

    if (status === 'done') {
      const { fileUrl } = response
      const lastIndex = `${fileUrl}`.lastIndexOf('/')
      const fileName = fileUrl.substring(lastIndex + 1)
      const values = {}

      values.module = funderModule
      values.module_id = empId
      values.type = type
      values.file_name = fileName
      values.file_url = fileUrl
      values.label = `${type}_${formatter.toShortDate(moment())}`

      const addFileResult = await funderFileService.add(values)

      if (addFileResult.id) {
        if(fileId) {
          const file = {}
          file.active = false
          const updateStatusResult = await funderFileService.save(fileId, file)
          if (updateStatusResult.id) {
            // console.log('Previous file status update successfully')
          }
        }
        // log.addEmployeeFile(empId, `New file ${values.label}`)
        this.fetchFiles()
        // this.setState({ shouldRefreshFiles: true })
        notify.success('Upload successfully', 'File upload successfully')
        this.props.setRefreshActivityLog(true)
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loading: false})
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { UploadType } = uploader
        const response = await uploader.upload(fileId, info, token, UploadType.FILE)
        // console.log('token refreshed & upload file done', response)
        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = `${fileUrl}`.lastIndexOf('/')
          const fileName = fileUrl.substring(lastIndex + 1)
          const values = {}

          values.module = funderModule
          values.module_id = empId
          values.type = type
          values.file_name = fileName
          values.file_url = fileUrl
          values.label = `${type}_${formatter.toShortDate(moment())}`

          const addFileResult = await funderFileService.add(values)

          if (addFileResult.id) {
            if(fileId) {
              const file = {}
              file.active = false
              const updateStatusResult = await funderFileService.save(fileId, file)
              if (updateStatusResult.id) {
                // console.log('Previous file status update successfully')
              }
            }

            // log.addEmployeeFile(empId, `New file ${values.label}`)
            this.fetchFiles()
            this.setState({ shouldRefreshFiles: true })
            notify.success('Upload successfully', 'File upload successfully')
            this.props.setRefreshActivityLog(true)
          } else {
            notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
          }

        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false })
    }
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingFunders, history } = this.props
        const { item } = this.state
        this.setState({ loading: true })

        values.funder_start_date = values.funder_start_date ? values.funder_start_date.format('YYYY-MM-DD') : null
        values.funder_end_date = values.funder_end_date ? values.funder_end_date.format('YYYY-MM-DD') : null
        delete values.fund_managers
        delete values.selected_fund_manager

        try {
          if (this.isEdit()) {
            const response = await funderService.save(item.id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response.id) {
              log.updateFunder(response.id, item, values)
              trigger.updateFunder(
                response.id,
                values.fullname,
                item,
                values,
                [
                  { key: 'abn' },
                  { key: 'fullname', label: 'Name' },
                  { key: 'address' },
                  { key: 'phone_number' },
                  { key: 'email' },
                  { key: 'contact_person' },
                  { key: 'after_contact_name' },
                  { key: 'after_contact_number' }
                ]
              )

              notify.success('Saved successfully', 'Funder saved successfully.')
              fetchingFunders(true)
            }
          } else {
            const response = await funderService.add(values)
            this.setState({ loading: false })

            if (response.id) {
              const { id, acc_ref } = response
              log.addFunder(response.id, `New funder ${values.fullname}`)
              this.setState({ item: { ...item, ...values, id, acc_ref } })
              notify.success('Saved successfully', 'Funder saved successfully.')
              // history.replace(`/funders/${id}`)
              window.location.replace(`/funders/${id}`)
              fetchingFunders(true)
            }
          }

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save funder successfully. Please try again later.')
          this.setState({ loading: false })
        }

        this.fetchLogs()
      }
    })
  }

  handleSaveFundManager = async () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['selected_fund_manager'], async (errors, values) => {
      if (!errors) {
        try {
          const { form, match } = this.props
          const { params } = match
          const { id } = params
          const { getFieldValue } = form
          this.setState({ loadingForm: true })
          const selectedIds = getFieldValue('selected_fund_manager')

          const response = await funderService.addFunderFundManager(id, { selectedIds: selectedIds})

          if (response) {
            const { fundManager } = this.state
            let fmLabels = ''

            for (var i = 0; i < selectedIds.length; i++) {
              const funderInfo = fundManager.find((f) => f.id === selectedIds[i])
              fmLabels += funderInfo.fullname + ', '
            }

            log.addFunderFundManager(id, `${(fmLabels || '').slice(0, -2)}`)
            this.setState({ loadingForm: false, showFundManager: false, showFundManagerTab: true })
            notify.success('Saved successfully', 'Plan Manager saved successfully.')
            this.props.setRefreshActivityLog(true)
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save plan manager successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  closeFundManagerModal = () => {
    const { form } = this.props
    const { setFieldsValue } = form

    this.setState({ showFundManager: false })
    setFieldsValue({ fund_managers: false })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingFunders,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Funder))
