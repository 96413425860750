import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchClients } from '../../../states/actions/client'
import { ClientMenu, SystemParticipantLimit } from '../../../constants'
import { apiHostname } from '../../../config'
import { formatter, queryString } from '../../../util'

// UI
import { ControlLabel, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

const pageSize = 40
const Option = Select.Option

export class Client extends Component {
  constructor (props) {
    super(props)
    const { clients: { list, total }, loading } = props
    this.state = {
      clientIds: [],
      currentPage: 1,
      filter: { active: true },
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      suburbs: [],
      total
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    this.onSelectStatus = this.onSelectStatus.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clients: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, loading, searchText, sort })

    if (window) {
      window.scrollTo(0,0)
    }
  }

  onSearchName (value) {
    const { filter, loading, sort } = this.state

    this.setState({ searching: true })

    if (value && value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchClients({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  // onSelectStatus (value) {
  //   const { currentPage, filter, searchText, sort } = this.state

  //   if (value === 'active' || value === 'inactive') {
  //     filter.active = (value === 'active')
  //     filter.is_exited = false
  //   } else if (value === 'exited') {
  //     filter.active = false
  //     filter.is_exited = true
  //   }

  //   this.fetchClients({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
  //   this.setState({ filter })
  // }

  onSelectStatus (value) {
    const { currentPage, filter, searchText, sort } = this.state

    if (value === 'active') {
      filter.active = (value === 'active')
    } else if (value === 'inactive') {
      filter.active = false
    }

    this.fetchClients({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
    this.setState({ filter })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  async export () {
    const { filter } = this.state

    this.setState({ isGenerating: true })
    window.location.href = `${apiHostname}/api/export/list/client${queryString.stringify({ filter: JSON.stringify(filter) })}`
    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 4000)
  }

  render () {
    const { currentPage, list, total, searching } = this.state
    const { loading } = this.props
    const isShowAdd = !loading && (SystemParticipantLimit === 0 || (SystemParticipantLimit > 0 && SystemParticipantLimit > total))

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ClientMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Participants'>
            <Link to='/clients/add'>
              {
                this.hasAccess('createClient') && isShowAdd ? <div className='btn'>
                Add
                </div> : null
              }
            </Link>
            {
              this.hasAccess('listClients') ? <div className='btn' onClick={() => this.export()}>
              Export
              </div> : null
            }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Name, NDIS Number, Suburb</ControlLabel>
                <SearchInput placeholder='Search participants' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={8}>
                <ControlLabel>Status</ControlLabel>
                <Select defaultValue='active' style={{ width: 120 }} onChange={this.onSelectStatus}>
                  <Option value='active'>Active</Option>
                  <Option value='inactive'>Inactive</Option>
                  {/* <Option value='exited'>Exited</Option> */}
                </Select>
              </Col>
            </Row>
          </Page.Filter>

          <div className='clients'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, active, first_name: firstName, last_name: lastName, gender, suburb, ndis_number: ndisNumber, leave_id: leaveId, leave_start: leaveStart, leave_end: leaveEnd } = item

                return (
                  <Link to={`/clients/${id}`} key={id}>
                    <div className={`list-item ${active ? '' : 'list-item-dim'}`}>
                      <Row className='list-content'>
                        <Col md={6}><img alt='' src={process.env.PUBLIC_URL + '/img/' + (gender && gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' /></Col>

                        <Col md={18}>
                          <div className='name'>{firstName} {lastName} { leaveId ? <Tooltip title={`Leave ${formatter.toShortDate(leaveStart)} - ${formatter.toShortDate(leaveEnd)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null }</div>
                          <div style={{ fontSize: '8pt' }}>{ndisNumber}</div>
                          <div style={{ marginTop: '3px', fontSize: '8pt', color: '#888' }}>{suburb}</div>
                        </Col>

                      </Row>
                    </div>
                  </Link>
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} clients`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, searchText, sort })
  }

  fetchClients = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { fetchClients } = this.props
      this.setState({ currentPage })
      fetchClients({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Client)
