import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

export default class SideModal extends Component {
  render () {
    const { buttons, children, onClose, showModal, title, modalStyle = {} } = this.props

    return (
      <div className={'wd-side-modal' + (showModal ? ' wd-side-modal-show' : ' wd-side-modal-hide')} style={modalStyle}>
        <div className='header'><div>{title}</div><div className='close' onClick={onClose}>&#x2715;</div></div>
        <div className='content'>
          { children }
        </div>
        <div className='footer'>
          { buttons || null }
        </div>
      </div>
    )
  }
}

SideModal.propTypes = {
  title: PropTypes.string
}
