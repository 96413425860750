import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_PROVIDERS, FETCHING_PROVIDERS, PROVIDERS_FETCHED } from '../actions/provider'
import providerService from '../../services/provider'

function * fetchProviders ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_PROVIDERS, loading })
    const providers = yield providerService.listByPage(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: PROVIDERS_FETCHED, providers, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PROVIDERS, loading: false })
    console.error(e)
  }
}

function * watchProvider () {
  yield takeEvery(FETCH_PROVIDERS, fetchProviders)
}

export default watchProvider
