import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

export default class Flag extends Component {
  render () {
    const { color = '#1ac974', label } = this.props

    return (
      <div className='witty-flag' style={{backgroundColor: color}}>
        <div>{label || ''}</div>
      </div>
    )
  }
}

Flag.propTypes = {
  label: PropTypes.string.isRequired
}
