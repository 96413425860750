export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES'
export const FETCHING_EMPLOYEES = 'FETCHING_EMPLOYEES'
export const EMPLOYEES_FETCHED = 'EMPLOYEES_FETCHED'

export const fetchEmployees = (params) => {
  return Object.assign({
    type: FETCH_EMPLOYEES
  }, params)
}

export const fetchingEmployees = (loading) => {
  return {
    type: FETCHING_EMPLOYEES,
    loading
  }
}

export const employeesFetched = (employees) => {
  return {
    type: EMPLOYEES_FETCHED,
    employees
  }
}
