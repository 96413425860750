import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Moment from 'moment-timezone'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Popconfirm from 'antd/lib/popconfirm'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import TimePicker from 'antd/lib/time-picker'

import './styles.css'

const timezone = 'Australia/Melbourne'
const Option = Select.Option

Moment.tz.setDefault(timezone)

export class GetUpTask extends Component {
  constructor (props) {
    super(props)
    const startTime = this.props.item
    this.state = {
      startTime: startTime ? moment(startTime).seconds(0).milliseconds(0) : null
    }

    this.disabledMinutes = this.disabledMinutes.bind(this)
  }

  onChangeTime (type, value) {
    const { index, item } = this.props

    this.props.onChange(item.id, type, value, index - 1)

    if (type === 'start_time') {
      this.setState({ startTime: value })
    }
    return value
  }

  onChangeNote (value) {
    const { item } = this.props

    this.props.onChange(item.id, 'notes', value)
    return value
  }

  onChangeBillingCategory (value) {
    const { item } = this.props

    this.props.onChange(item.id, 'billing_category_id', value)
    return value
  }

  disabledMinutes (time) {
    const { startTime } = this.state

    if (startTime.format('mm') === '00' || startTime.format('mm') === '30') {
      return [15, 45]
    } else {
      return [0, 30]
    }
  }

  render () {
    const { item, index, form, onDelete, billingCategory } = this.props
    const { id, start_time: startTime, end_time: endTime, notes, billing_category_id } = item
    const { getFieldDecorator } = form

    return (<Row gutter={2} style={{ marginBottom: '5px' }}>
      <Col lg={3}><div className='getup-label'>Get Up {index}</div></Col>
      <Col lg={3}>
        {getFieldDecorator('getup_hours_start', {
          getValueFromEvent: (e) => this.onChangeTime('start_time', e),
          initialValue: startTime ? moment(startTime).seconds(0).milliseconds(0) : null
        })(
          <TimePicker use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
        )}
      </Col>
      <Col lg={1}><div className='getup-label'>to</div></Col>
      <Col lg={3}>
        {getFieldDecorator('getup_hours_end', {
          getValueFromEvent: (e) => this.onChangeTime('end_time', e),
          initialValue: endTime ? moment(endTime).seconds(0).milliseconds(0) : null
        })(
          <TimePicker use12Hours format='h:mm A' minuteStep={15} style={{ width: 100 }} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} disabledMinutes={this.disabledMinutes}/>
        )}
      </Col>
      <Col lg={6}>
        {getFieldDecorator('billing_category_id', {
          initialValue: billing_category_id,
        })(
          <Select onSelect={(v) => this.onChangeBillingCategory(v)} placeholder='Shift Type'>
          {
            billingCategory && billingCategory.map((bill, idx) => {
              return <Option key={idx} value={bill.category_id}>{bill.category_name}</Option>
            })
          }
          </Select>
        )}
      </Col>
      <Col lg={7}>
        {getFieldDecorator('notes', {
          initialValue: notes,
          getValueFromEvent: (e) => this.onChangeNote(e.target.value)
        })(
          <Input placeholder='Notes' />
        )}
      </Col>
      <Col lg={1}>
        <Popconfirm
          title='Confirm to remove?'
          onConfirm={(e) => onDelete(item.id)}
          okText='Yes'
          cancelText='No'
          placement='left'
        ><div className='getup-remove-row'><Icon type='delete' /></div>
        </Popconfirm>
      </Col>
    </Row>
    )
  }
}

GetUpTask.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onDelete: PropTypes.func
}

export default (Form.create()(GetUpTask))
