import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import './styles.css'

import AccRef from './AccRef'
import CustomIdentifier from './CustomIdentifier'
import Gender from './Gender'
import Height from './Height'
import Language from './Language'
import Relationship from './Relationship'
import VisaStatus from './VisaStatus'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'Profile'
const settingType = 'profile'

export class SettingProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addCustomIdentifier: false,
      addGender: false,
      addHeight: false,
      addLanguage: false,
      addRelationship: false,
      addSkill: false,
      addVisaStatus: false,
      currentPage: 1,
      activeTab: '1',
      filter: {},
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      moduleKey: 'participant',
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    const { currentPage } = this.state

    const defaultKey = this.hasAccess('listGenders')
      ? '1'
      : this.hasAccess('listCustomIdentifier')
        ? '2'
        : this.hasAccess('listHeights')
          ? '3'
          : this.hasAccess('listLanguages')
            ? '4'
            : this.hasAccess('listRelationships')
              ? '5'
              : this.hasAccess('listVisaStatus')
                ? '6'
                : '1'

    this.setState({ activeTab: defaultKey })

    // const tab = (new URLSearchParams(window.location.search)).get("t")
    // this.setState({ activeTab: tab === 'rateset' ? '2' : (tab === 'fundmanager' ? '5' : '1') })
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  addCustomIdentifier = () => {
    this.setState({ addCustomIdentifier: true })
  }

  addGender = () => {
    this.setState({ addGender: true })
  }

  addHeight = () => {
    this.setState({ addHeight: true })
  }

  addLanguage = () => {
    this.setState({ addLanguage: true })
  }

  addRelationship = () => {
    this.setState({ addRelationship: true })
  }

  addSkill = () => {
    this.setState({ addSkill: true })
  }

  addVisaStatus = () => {
    this.setState({ addVisaStatus: true })
  }

  onModuleKeyChange = (key) => {
    this.setState({ moduleKey: key })
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addCustomIdentifier, addGender, addHeight, addLanguage, addRelationship, addVisaStatus, activeTab, moduleKey } = this.state
    const defaultKey = this.hasAccess('listOthers')
      ? '1'
      : this.hasAccess('listCustomIdentifier')
        ? '2'
        : this.hasAccess('listGenders')
          ? '3'
          : this.hasAccess('listHeights')
            ? '4'
            : this.hasAccess('listLanguages')
              ? '5'
              : this.hasAccess('listRelationships')
                ? '6'
                : this.hasAccess('listVisaStatus')
                  ? '7'
                  : '1'

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onChange={this.onTabChange}>
            { this.hasAccess('listOthers')
             ? <TabPane tab='Acc Ref' key='1'>
                <AccRef />
              </TabPane>
              : null }

            { this.hasAccess('listCustomIdentifier')
              ? <TabPane tab='Custom Identifier' key='2'>
                <Tabs defaultActiveKey='client' activeKey={moduleKey} onChange={this.onModuleKeyChange}>
                  { /* participant tab */ }
                  <TabPane tab='Participant' key='participant'>
                    <div className='header-action'>
                      { this.hasAccess('createCustomIdentifier') ? <div className='btn' onClick={() => this.addCustomIdentifier()}>
                        Add Custom Identifier
                      </div> : null }
                    </div>
                    <CustomIdentifier module='participant' onAdd={addCustomIdentifier} onComplete={() => this.setState({ addCustomIdentifier: false })} />
                  </TabPane>
                  { /* employee tab */ }
                  <TabPane tab='Employee' key='employee'>
                    <div className='header-action'>
                      { this.hasAccess('createCustomIdentifier') ? <div className='btn' onClick={() => this.addCustomIdentifier()}>
                        Add Custom Identifier
                      </div> : null }
                    </div>
                    <CustomIdentifier module='employee' onAdd={addCustomIdentifier} onComplete={() => this.setState({ addCustomIdentifier: false })} />
                  </TabPane>
                  { /* provider tab */ }
                  <TabPane tab='Provider' key='provider'>
                    <div className='header-action'>
                      { this.hasAccess('createCustomIdentifier') ? <div className='btn' onClick={() => this.addCustomIdentifier()}>
                        Add Custom Identifier
                      </div> : null }
                    </div>
                    <CustomIdentifier module='provider' onAdd={addCustomIdentifier} onComplete={() => this.setState({ addCustomIdentifier: false })} />
                  </TabPane>
                </Tabs>
              </TabPane>
              : null }

            { this.hasAccess('listGenders')
             ? <TabPane tab='Genders' key='3'>
                <div className='header-action'>
                  { this.hasAccess('createGender') ? <div className='btn' onClick={() => this.addGender()}>
                    Add Gender
                  </div> : null }
                </div>
                <Gender onAdd={addGender} onComplete={() => this.setState({ addGender: false })} />
              </TabPane>
              : null }

            { this.hasAccess('listHeights')
              ? <TabPane tab='Heights' key='4'>
                <div className='header-action'>
                  { this.hasAccess('createHeight') ? <div className='btn' onClick={() => this.addHeight()}>
                    Add Height
                  </div> : null }
                </div>
                <Height onAdd={addHeight} onComplete={() => this.setState({ addHeight: false })} />
              </TabPane>
              : null }

            { this.hasAccess('listLanguages')
              ? <TabPane tab='Languages' key='5'>
                <div className='header-action'>
                  { this.hasAccess('createLanguage') ? <div className='btn' onClick={() => this.addLanguage()}>
                    Add Language
                  </div> : null }
                </div>
                <Language onAdd={addLanguage} onComplete={() => this.setState({ addLanguage: false })} />
              </TabPane>
              : null }

            { this.hasAccess('listRelationships')
              ? <TabPane tab='Relationships' key='6'>
                <div className='header-action'>
                  { this.hasAccess('createRelationship') ? <div className='btn' onClick={() => this.addRelationship()}>
                    Add Relationship
                  </div> : null }
                </div>
                <Relationship onAdd={addRelationship} onComplete={() => this.setState({ addRelationship: false })} />
              </TabPane>
              : null }

            { this.hasAccess('listVisaStatus')
              ? <TabPane tab='Visa Status' key='7'>
                <div className='header-action'>
                  { this.hasAccess('createVisaStatus') ? <div className='btn' onClick={() => this.addVisaStatus()}>
                    Add Visa Status
                  </div> : null }
                </div>
                <VisaStatus onAdd={addVisaStatus} onComplete={() => this.setState({ addVisaStatus: false })} />
              </TabPane>
              : null }

          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingProfile))
