import { combineReducers } from 'redux'

import AdminReducer from './admin'
import AdminRoleReducer from './adminRole'
import CancelJobReducer from './cancelledJob'
import CancelRecurringJobReducer from './cancelledRecurringJob'
import DeleteJobReducer from './deletedJob'
import ClientReducer from './client'
import CustomerReducer from './customer'
import DashboardReducer from './dashboard'
import EmployeeReducer from './employee'
import EndedBaseJobReducer from './endedBaseJob'
import FunderReducer from './funder'
import FutureJobReducer from './futureJob'
import GeneralReducer from './general'
import JobReducer from './job'
import PastJobReducer from './pastJob'
import PendingEmployeeJobReducer from './pendingEmployeeJob'
import PendingEmployeeBaseJobReducer from './pendingEmployeeBaseJob'
import ProviderReducer from './provider'
import RecurringJobReducer from './recurringJob'
import RecurringJobListReducer from './recurringJobList'
import ModuleReducer from './module'
import PayrollReducer from './payroll'
import SettingFileReducer from './setting/file'
import SettingStoreReducer from './setting/store'
import UserReducer from './user'

export default combineReducers({
  Admin: AdminReducer,
  AdminRole: AdminRoleReducer,
  CancelledJob: CancelJobReducer,
  CancelledRecurringJob: CancelRecurringJobReducer,
  DeletedJob: DeleteJobReducer,
  Client: ClientReducer,
  Customer: CustomerReducer,
  Dashboard: DashboardReducer,
  Employee: EmployeeReducer,
  EndedBaseJob: EndedBaseJobReducer,
  File: SettingFileReducer,
  Funder: FunderReducer,
  FutureJob: FutureJobReducer,
  General: GeneralReducer,
  Job: JobReducer,
  PastJob: PastJobReducer,
  PendingEmployeeJob: PendingEmployeeJobReducer,
  PendingEmployeeBaseJob: PendingEmployeeBaseJobReducer,
  Provider: ProviderReducer,
  Module: ModuleReducer,
  Payroll: PayrollReducer,
  RecurringJob: RecurringJobReducer,
  RecurringJobList: RecurringJobListReducer,
  Store: SettingStoreReducer,
  User: UserReducer
})
