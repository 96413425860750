/* global google */

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatter, validator } from '../../util'
import { compose, withProps, lifecycle, withState, withHandlers } from 'recompose'
import { googleMapApi } from '../../config'
import { settingGeneralService, clientService, employeeService, providerService } from '../../services'

// UI
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow, Circle } from 'react-google-maps'
import { Page } from '../../components'

import './styles.css'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Form from 'antd/lib/form'
import Select from 'antd/lib/select'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Spin from 'antd/lib/spin'

// const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer')
const FormItem = Form.Item
const TabPane = Tabs.TabPane

const Option = Select.Option
const clientMarker = {
  url: process.env.PUBLIC_URL + '/img/home.png',
  anchor: window.google.maps.Point(2, 78),
  scaledSize: new google.maps.Size(23, 82)
}
const carerMarker = {
  url: process.env.PUBLIC_URL + '/img/placeholder2.png',
  anchor: window.google.maps.Point(2, 78),
  scaledSize: new google.maps.Size(23, 82)
}

const FlexiMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapApi}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  lifecycle({
    componentWillReceiveProps () {
      const refs = {}
      this.setState({
        flexMap: map => {
          if (!map || this.props.origin === true) { return }
          const { zoomToMarkers: idx, zoomToCoord, selectedType, selectedClient, clients, carers } = this.props
          // console.log('map selected client', selectedClient)

          let coord = null

          if (selectedType === 'client') {
            if (idx && map.props.children[0].length !== 0) {
              const { clients } = this.props
              const child = clients.filter(item => item.id === idx)
              if (child.length > 0) {
                const latitude = child[0].latitude
                const longitude = child[0].longitude
                if (latitude !== null && longitude !== null) {
                  coord = { lat: formatter.toNumber(latitude), lng: formatter.toNumber(longitude) }
                }
              }
            }
          } else {
            if (selectedClient.length > 0) {
              const child = selectedClient.filter(item => item.id === idx)
              if (child.length > 0) {
                const latitude = child[0].latitude
                const longitude = child[0].longitude
                if (latitude !== null && longitude !== null) {
                  coord = { lat: formatter.toNumber(latitude), lng: formatter.toNumber(longitude) }
                }
              }
            } else if (idx && this.props.clients.length !== 0) {
              const { clients } = this.props
              const child = clients.filter(item => item.id === idx)

              if (child.length > 0) {
                const latitude = child[0].latitude
                const longitude = child[0].longitude
                if (latitude !== null && longitude !== null) {
                  coord = { lat: formatter.toNumber(latitude), lng: formatter.toNumber(longitude) }
                }
              }
            }
          }

          if (zoomToCoord || this.props.zoomToCoord) {
            const zoc = zoomToCoord || this.props.zoomToCoord
            if (zoc.latitude !== null && zoc.longitude !== null) {
              coord = { lat: parseFloat(zoc.latitude), lng: parseFloat(zoc.longitude) }
            }
          }

          if (coord) {
            map.panTo(coord)
          }
          this.props.offOrigin()
        }
      })
    }
  }),
  withState('isOpen', 'updateIsOpen', null),
  withHandlers({
    onToggleOpen: ({ updateIsOpen }) => (key, type) => {
      updateIsOpen(`${type}${key}`)
    },
    onToggleOff: ({ updateIsOpen }) => () => {
      updateIsOpen(null)
    },
    onRegionChangeComplete: (ki) => {
    },
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers()
    }
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  // console.log('MAP PROPS ', props)
  if (props.zoomToMarkers) {
    if (props.selectedType === 'client') {
      props.onToggleOpen(props.zoomToMarkers, 'Client')
    } else if (props.selectedType === 'carer') {
      props.onToggleOpen(props.zoomToMarkers, 'Client')
    }
  }
  return (
    <GoogleMap
      zoom={props.zoom}
      defaultCenter={{ lat: -37.8829427, lng: 145.1599169 }}
      ref={props.flexMap}
      origin={props.origin}
    >
      {/* <MarkerClusterer
        onClick={props.onMarkerClustererClick}
        averageCenter
        enableRetinaIcons
        gridSize={60}
        minimumClusterSize={1000}
        styles={[
          { marginTop: '20px' }
        ]}
      > */}
      { props.clients.map((client, idx) => {
        return (
          props.isMarkerShown && client.latitude && client.longitude &&
            <Marker
              key={`client${idx}`}
              position={{ lat: formatter.toNumber(client.latitude), lng: formatter.toNumber(client.longitude) }}
              onClick={() => props.onToggleOpen(client.id, 'Client')}
              icon={clientMarker}
              // label={`Participant - ${client.first_name} ${client.last_name}`}
              label={{
                text: `Participant - ${client.first_name} ${client.last_name}`,
                fontWeight: 'bold',
                fontSize: '11px',
                color: 'black'
              }}
            >
              { props.isOpen === `Client${client.id}` && <InfoWindow onCloseClick={props.onToggleOpen}>
                <div>
                  <div className='map-link'><a href={`/clients/${client.id}`} target='_blank'>{`Participant - ${client.first_name} ${client.last_name}`}</a></div>
                  <div className='map-address'>{client.address}</div>
                </div>
              </InfoWindow>}
            </Marker>
        )
      })
      }
      {/* </MarkerClusterer> */}

      {
        props.providers
          ? (
            props.providers.map((provider, idx) => {
              return (
                props.isMarkerShown && provider.latitude && provider.longitude &&
                <Marker
                  key={`provider${idx}`}
                  position={{ lat: formatter.toNumber(provider.latitude), lng: formatter.toNumber(provider.longitude) }}
                  onClick={() => props.onToggleOpen(provider.id, 'Provider')}
                  icon={carerMarker}
                  label={{
                    text: `${provider.fullname}`,
                    fontWeight: 'bold',
                    fontSize: '11px',
                    color: 'black'
                  }}
                >
                  { props.isOpen === `Provider${provider.id}` && <InfoWindow onCloseClick={props.onToggleOpen}>
                    <div>
                      <div className='map-link'><a href={`/providers/${provider.id}`} target='_blank'>{`Provider - ${provider.fullname}`}</a></div>
                      <div className='map-address'>{provider.address}</div>
                    </div>
                  </InfoWindow>}
                </Marker>
              )
            })
          ) : null
      }

      {
        props.zoomToCoord
          ? <Marker
            key={`addressMarker`}
            position={{ lat: parseFloat(props.zoomToCoord.latitude), lng: parseFloat(props.zoomToCoord.longitude) }}
          />
          : null
      }
    </GoogleMap>
  )
})

export class Map extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clients: [],
      clientsFilter: [],
      employees: [],
      // genders: [],
      languages: [],
      providers: [],
      skills: [],
      services: [],
      suburbs: [],
      zoomLevel: 11,
      origin: false,
      boundaryBox: {
        westLng: 0,
        southLat: 0,
        eastLng: 0,
        northLat: 0
      },
      inputLanguages: '',
      // inputSkills: '',
      inputServices: '',
      inputSuburbs: '',
      // inputGender: '',
      inputAddress: '',
      selectedType: 'carer',
      selectedClient: [],
      spinLoading: false
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    this.fetchSettings()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )
    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const { selectedType } = this.state
    const place = this.googleAddress.getPlace()

    // console.log(place.geometry.location.lat(), place.geometry.location.lng())
    // console.log('place changed', place)

    this.setState({
      inputAddress: place.geometry ? { latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() } : '',
      inputSuburbs: place.vicinity || '',
      zoomLevel: 15,
      zoomToMarkers: null,
      origin: false
    })
    this.props.form.setFieldsValue({
      address: place.formatted_address,
      client: undefined,
      employee: undefined
    })
  }

  onRegionChangeComplete = (screenCoordinate) => {
    // console.log(5550000, screenCoordinate)
    // let boundingBox = this.getBoundingBox(screenCoordinate)
    // this.setState({screenCoordinate, boundingBox})
  }

  handleOffOrigin () {
    // console.log('Off Origin')
    this.setState({ zoomToMarkers: null, origin: true })
  }

  handleOnOrigin () {
    // console.log('On Origin')
    this.setState({ origin: false })
  }

  // To clear all states and form field values
  handleClearAll () {
    const { setFieldsValue } = this.props.form
    this.setState({
      zoomLevel: 11,
      zoomToMarkers: null,
      // inputGender: '',
      inputLanguages: '',
      inputServices: '',
      inputSuburbs: '',
      // inputSkills: '',
      selectedClient: []
    })

    setFieldsValue({
      client: undefined,
      employee: undefined,
      // gender: undefined,
      languages: undefined,
      // skills: undefined
      services: undefined
    })
    this.fetchTypeList('', '', '')
  }

  // To reset map zoom level and address field
  handleClearAddress () {
    const { setFieldsValue } = this.props.form

    this.setState({
      zoomLevel: 11,
      inputAddress: '',
      inputSuburbs: ''
    })
    setFieldsValue({
      address: ''
    })
  }

  selectClientCarer = (value, option) => {
    const { setFieldsValue } = this.props.form
    const { selectedType } = this.state

    setFieldsValue({
      address: ''
    })

    if (!value) {
      this.setState({ zoomToMarkers: null,
        zoomLevel: 11,
        inputAddress: '',
        inputSuburbs: ''
      })
    } else {
      this.setState({ zoomToMarkers: value,
        zoomLevel: 15,
        inputAddress: '',
        inputSuburbs: ''
      })
      this.handleSelectedGetInfo(value, selectedType)
    }
  }

  handleSelectedGetInfo = async (id, type) => {
    const { setFieldsValue } = this.props.form
    const { suburbs } = this.state
    const filter = {}
    const selectedClient = []
    // console.log('type', type, ' ; ', id)

    const { item } = await clientService.get(id)

    if (item) {
      // console.log('Get client info: ', item)
      selectedClient.push(item)

      if (item.languages) {
        // console.log('item languages', item.languages)
        filter.languages = `{${item.languages.join(',')}}`
        filter.showLanguages = item.languages.map((language) => {
          if (language !== null) {
            return language.toString()
          }
        })
      }

      if (item.services) {
        // console.log('item languages', item.languages)
        filter.services = `{${item.services.join(',')}}`
        filter.showServices = item.services.map((service) => {
          if (service !== null) {
            return service.toString()
          }
        })
      }

      if (item.suburb) {
        const suburb = typeof item.suburb === 'string' ? item.suburb.toLowerCase() : item.suburb
        const suburbList = suburbs.filter(e => e.value === suburb).map(e => e.id)
        // console.log('suburb', suburb, suburbList, suburbs, suburbs.filter(e => e.value === suburb))
        if (suburbList.length > 0) {
          filter.suburbs = `{${suburbList.join(',')}}`
        }
      }

      this.setState({
        inputLanguages: filter.languages || '',
        inputServices: filter.services || '',
        inputSuburbs: filter.suburbs || '',
        selectedClient,
        selectedType: type,
        origin: false
      })

      setFieldsValue({
        languages: item.languages && item.languages.indexOf(null) >= 0 ? undefined : filter.showLanguages,
        services: item.services && item.services.indexOf(null) >= 0 ? undefined : filter.showServices
      })
    }

    this.fetchTypeList(filter.languages, filter.services, filter.suburbs)
  }

  // Tab changed need to clear all form fields and set opposite type
  handleTabChange = async (type) => {
    const { setFieldsValue } = this.props.form
    this.setState({ spinLoading: true })

    const filter = {}
    filter.active = { condition: '=', value: true }

    if (type === 'client') {
      const { list } = await employeeService.listByPageAddress(1, 0, { active: filter.active })

      this.setState({ employees: list })
    } else {
      const { list } = await clientService.listByPageAddress(1, 0, { active: filter.active })

      this.setState({ clients: list })
    }

    this.setState({
      // inputGender: '',
      inputLanguages: '',
      inputServices: '',
      // inputSkills: '',
      spinLoading: false,
      selectedType: (type === 'carer' ? 'client' : 'carer'),
      selectedClient: [],
      zoomLevel: 11
    })

    setFieldsValue({
      client: undefined,
      employee: undefined,
      // gender: undefined,
      languages: undefined,
      // skills: undefined,
      services: undefined
    })
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { languages, skills, clients, clientsFilter, employees, providers, selectedType, selectedClient, services, suburbs, spinLoading } = this.state
    // console.log('clients', clients)
    // console.log('employees', employees)
    // console.log('providers', providers)
    // console.log('suburbs', suburbs)
    // console.log('services', services, selectedType)
    // console.log('inputs', 'L:', this.state.inputLanguages, 'S:', this.state.inputSkills, 'G:', this.state.inputGender)

    const carerTab = () => {
      return (
        <Spin spinning={spinLoading}>
          <Form className='form'>
            <Row gutter={24}>
              <Col lg={2}>
                <div className='map-control-label'>Filter by </div>
                <div className='btn map' style={{ margin: '7px 0' }} onClick={e => this.handleClearAll()}>Clear</div>
              </Col>
              <Col lg={4}>
                <div className='map-control-label'>Participant</div>
                <FormItem>
                  {getFieldDecorator('client')(
                    <Select showSearch allowClear
                      style={{ width: '100%' }}
                      placeholder='Participant'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      onChange={(value, option) => this.selectClientCarer(value, option)}
                      filterOption={(input, option) => this.findClients(input, option)}>
                      {/* {
                        clientsFilter
                          ? clientsFilter.map((client, idx) => {
                            return <Option key={client.id} value={client.id}>{ client.leave_id ? <div>{client.first_name} {client.last_name} <Tooltip title={`Leave ${formatter.toShortDate(client.leave_start)} - ${formatter.toShortDate(client.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div> : `${client.first_name} ${client.last_name}`}</Option>
                          }) : null
                      } */}

                      {
                        clientsFilter
                          ? clientsFilter.map((client, idx) => {
                            return <Option key={client.id} value={client.id}>{`${client.first_name} ${client.last_name}`} { client.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client.leave_start)} - ${formatter.toShortDate(client.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null} </Option>
                          }) : null
                      }

                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={9}>
                <div className='map-control-label'>Required Services</div>
                <FormItem>
                  {getFieldDecorator('services', {
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterServices(value, option, selectedType)}
                      showSearch
                      filterOption={(input, option) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        services.map((service, idx) => {
                          return <Option key={service.id}>{service.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={9}>
                <div className='map-control-label'>Preferred Languages</div>
                <FormItem>
                  {getFieldDecorator('languages', {
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterLanguages(value, option)}
                      showSearch
                      filterOption={(input, option) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        languages.map((lang, idx) => {
                          return <Option key={lang.id}>{lang.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      )
    }

    const clientTab = () => {
      return (
        <Spin spinning={spinLoading}>
          <Form className='form'>
            <Row gutter={24}>
              <Col lg={2}>
                <div className='btn map' style={{ margin: '25px 0' }} onClick={e => this.handleClearAll()}>Clear</div>
              </Col>
              <Col lg={4}>
                <div className='map-control-label'>Participant</div>
                <FormItem>
                  {getFieldDecorator('client')(
                    <Select showSearch allowClear
                      style={{ width: '100%' }}
                      placeholder='Participant'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      onChange={(value, option) => this.selectClientCarer(value, option)}
                      filterOption={(input, option) => this.findClients(input, option)}>
                      {/* {
                        clientsFilter
                          ? clientsFilter.map((client, idx) => {
                            return <Option key={client.id} value={client.id}>{ client.leave_id ? <div>{client.first_name} {client.last_name} <Tooltip title={`Leave ${formatter.toShortDate(client.leave_start)} - ${formatter.toShortDate(client.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div> : `${client.first_name} ${client.last_name}`}</Option>
                          }) : null
                      } */}
                      {
                        clientsFilter
                          ? clientsFilter.map((client, idx) => {
                            return <Option key={client.id} value={client.id}>{`${client.first_name} ${client.last_name}`} { client.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client.leave_start)} - ${formatter.toShortDate(client.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null} </Option>
                          }) : null
                      }
                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={9}>
                <div className='map-control-label'>Required Services</div>
                <FormItem>
                  {getFieldDecorator('services', {
                    // initialValue: ['10', '12'],
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterServices(value, option, selectedType)}
                      showSearch
                      filterOption={(input, option) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        services.map((service, idx) => {
                          return <Option key={service.id}>{service.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>

              <Col lg={9}>
                <div className='map-control-label'>Preferred Languages</div>
                <FormItem>
                  {getFieldDecorator('languages', {
                    valuePropName: 'value'
                  })(
                    <Select mode='multiple' style={{ width: '100%' }}
                      onChange={(value, option) => this.filterLanguages(value, option)}
                      showSearch
                      filterOption={(input, option) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }>
                      {
                        languages.map((lang, idx) => {
                          return <Option key={lang.id}>{lang.name}</Option>
                        })
                      }
                    </Select>)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Spin>
      )
    }

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header icon={<Icon type='global' />} title='Full Map' />

          <div className='map-header'>

            <div className='map-panel-tabs'>
              <Tabs defaultActiveKey='1' size='default' onChange={() => this.handleTabChange(selectedType)}>
                <TabPane tab='Find Provider' key='1'>
                  { carerTab() }
                </TabPane>
                <TabPane tab='List Participant' key='2'>
                  { clientTab() }
                </TabPane>
              </Tabs>
            </div>

          </div>

          <div className='map-header'>
            <Form className='form'>
              <Row gutter={24}>
                <Col lg={2}>
                  <div className='map-control-label'>Filter by </div>
                  <div className='btn map' style={{ margin: '7px 0' }} onClick={e => this.handleClearAddress()}>Clear</div>
                </Col>
                <Col lg={22}>
                  <div className='map-control-label'>Address</div>
                  <FormItem>
                    {getFieldDecorator('address', {
                      initialValue: this.state.inputAddress
                    })(
                      <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
                    )}
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </div>

          <div className='map-container'>
            <FlexiMap
              isMarkerShown
              clients={selectedType === 'carer' ? selectedClient : clients}
              carers={selectedType === 'carer' ? employees : []}
              providers={selectedType === 'carer' ? providers : []}
              zoom={this.state.zoomLevel}
              zoomToMarkers={this.state.zoomToMarkers}
              zoomToCoord={this.state.inputAddress}
              origin={this.state.origin}
              offOrigin={() => this.handleOffOrigin()}
              onOrigin={() => this.handleOnOrigin()}
              selectedClient={selectedClient}
              selectedType={selectedType}
            />
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'language' },
        { condition: '=', value: 'skill' },
        { condition: '=', value: 'service-provider' },
        { condition: '=', value: 'suburb' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const { list } = await clientService.listByPageAddress(1, 0, { active: filter.active })
    const { list: employeeList } = await employeeService.listByPageAddress(1, 0, { active: filter.active })
    const { list: providerList } = await providerService.listByPageAddress(1, 0, { active: filter.active })

    this.setState({
      clients: list,
      clientsFilter: list,
      employees: employeeList,
      providers: providerList,
      languages: settings.list.filter(item => item.type === 'language'),
      skills: settings.list.filter(item => item.type === 'skill'),
      services: settings.list.filter(item => item.type === 'service-provider'),
      suburbs: settings.list.filter(item => item.type === 'suburb'),
      origin: false
    })
  }

  fetchTypeList = async (languages = '', services = '', suburbs = '') => {
    const { inputLanguages, inputServices, inputSuburbs, selectedType, selectedClient } = this.state
    const filter = {}

    if (services && services.length > 2) {
      filter.services = { condition: '@>', value: services }
    } else {
      if (services !== '{}' && services !== '') filter.services = { condition: '@>', value: inputServices }
    }

    if (languages && languages.length > 2) {
      filter.languages = { condition: '@>', value: languages }
    } else {
      if (languages !== '{}' && languages !== '') filter.languages = { condition: '@>', value: inputLanguages }
    }

    // if client suburb is not in the setting suburb list, it will not added into filter
    if (suburbs && suburbs.length > 2) {
      filter.service_area = { condition: '@>', value: suburbs }
    } else {
      if (suburbs !== '{}' && suburbs !== '') filter.service_area = { condition: '@>', value: inputSuburbs }
    }

    if (selectedType === 'client') {
      const { list } = await clientService.listByPageAddress(1, 0, filter)
      this.setState({ clients: list, inputLanguages: languages, inputServices: services })
    } else {
      const { list: clientList } = await clientService.listByPageAddress(1, 0, filter)
      const { list } = await providerService.listByPageAddress(1, 0, filter)
      this.setState({ providers: list, inputLanguages: languages, inputServices: services, clients: clientList })
    }
  }

  findClients = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]}`

    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findCarers = (input, option) => {
    const carer = `${option.props.children}`

    return carer.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterLanguages = (input) => {
    const { inputServices, inputSuburbs } = this.state
    const languages = `{${input.join(',')}}`
    this.setState({ inputLanguages: languages })
    this.fetchTypeList(
      languages,
      (inputServices !== '{}' ? inputServices : ''),
      (inputSuburbs !== '{}' ? inputSuburbs : '')
    )
  }

  filterServices = (input, type) => {
    const { inputLanguages, inputSuburbs } = this.state
    const services = `{${input.join(',')}}`
    this.setState({ inputServices: services })
    this.fetchTypeList(
      (inputLanguages !== '{}' ? inputLanguages : ''),
      services,
      (inputSuburbs !== '{}' ? inputSuburbs : '')
    )
  }
}

export default Form.create()(Map)
