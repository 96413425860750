import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/providers'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  // getClient (id) {
  //   return request(`${url}/${id}/clients`)
  // },
  // getRateset (id) {
  //   return request(`${url}/${id}/rateset`)
  // },
  // getBillingCategory (id) {
  //   return request(`${url}/${id}/billing`)
  // },
  // getManager (id) {
  //   return request(`${url}/${id}/manager`)
  // },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageAddress (page, size, filter, sorting, text) {
    return request(`${url}/map/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getServices (id, clientId) {
    return request(`${url}/services/${id}/cid/${clientId}`)
  },
  async getProviderClientServices (values) {
    return request(`${url}/client/services`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
  // listClientByPage (page, size, filter, sorting, text) {
  //   return request(`${url}/client/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  // },
  // getPhones () {
  //   return request(`${url}/phones`)
  // },
  // getFunderIds () {
  //   return request(`${url}/ids`)
  // },
  // getClientFunderSetting (id) {
  //   return request(`${url}/clientfunder/${id}`)
  // },
  // addFunderFundManager (id, values) {
  //   return request(`${url}/${id}/fund-manager`, {
  //     method: 'POST',
  //     headers,
  //     body: JSON.stringify(values)
  //   })
  // },
  // getFunderFundManagerCount (id) {
  //   return request(`${url}/${id}/fund-manager`)
  // },
  // listFundManagerByPage (page, size, filter, sorting, text) {
  //   console.log(filter)
  //   return request(`${url}/fund-manager/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  // },
  // removeFundManager (id) {
  //   return request(`${url}/${id}/fund-manager`, {
  //     method: 'DELETE',
  //     headers
  //   })
  // },
}
