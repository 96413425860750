export const FETCH_PAYROLLS = 'FETCH_PAYROLLS'
export const FETCHING_PAYROLLS = 'FETCHING_PAYROLLS'
export const PAYROLLS_FETCHED = 'PAYROLLS_FETCHED'

export const FETCH_PAYROLL_TIMESHEETS = 'FETCH_PAYROLL_TIMESHEETS'
export const FETCHING_PAYROLL_TIMESHEETS = 'FETCHING_PAYROLL_TIMESHEETS'
export const PAYROLL_TIMESHEETS_FETCHED = 'PAYROLL_TIMESHEETS_FETCHED'

export const FETCH_PAYROLL_TIMESHEET_SUM = 'FETCH_PAYROLL_TIMESHEET_SUM'
export const FETCHING_PAYROLL_TIMESHEET_SUM = 'FETCHING_PAYROLL_TIMESHEET_SUM'
export const PAYROLL_TIMESHEET_SUM_FETCHED = 'PAYROLL_TIMESHEET_SUM_FETCHED'

export const fetchPayrolls = (params) => {
  return Object.assign({
    type: FETCH_PAYROLLS
  }, params)
}

export const fetchingPayrolls = (loading) => {
  return {
    type: FETCHING_PAYROLLS,
    loading
  }
}

export const payrollsFetched = (payrolls) => {
  return {
    type: PAYROLLS_FETCHED,
    payrolls
  }
}

export const fetchTimesheets = (params) => {
  return Object.assign({
    type: FETCH_PAYROLL_TIMESHEETS
  }, params)
}

export const fetchTimesheetSummary = (params) => {
  return Object.assign({
    type: FETCH_PAYROLL_TIMESHEET_SUM
  }, params)
}
