import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { authService, clientService, clientFileService, clientFundingService, clientFundingPeriodService, funderService, settingGeneralService, settingFileCategoryService, settingFileTypeService } from '../../../services'
import { fetchingClients, setRefreshActivityLog, setRefreshFiles } from '../../../states/actions'
import moment from 'moment-timezone'
import { formatter, log, trigger, validator, uploader } from '../../../util'
import { FieldList, ClientSCFund } from '../../../constants'

// UI
import { Button, ControlLabel, FileTypeUpload, Loading, List, Panel, SideModal, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Modal from 'antd/lib/modal'
import Popover from 'antd/lib/popover'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Popconfirm from 'antd/lib/popconfirm'
import AddFileModal from './AddFileModal'

import './styles.css'
import { apiHostname } from '../../../config'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const Option = Select.Option
const { confirm, error } = Modal

const pageSize = 20
const defaultFunderId = 1 // for SC, funder id is fixed to 1 (NDIS)
const clientModule = 'client'

export class ClientFunder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientId: '',
      currentPage: 1,
      currentSubCats: [],
      defaultSelectedCategory: '',
      funders: [],
      funderBillingCategories: [],
      funding: { list: [], total: 0 },
      fundingItem: [],
      fundManagers: [],
      hasNDISPlan: false,
      hasNDISSA: false,
      isEdit: false,
      isGetRateSet: false,
      isNdiaManaged: false,
      isPlanTBA: false,
      isReportDone: false,
      isReviewDone: false,
      isShowFileModal: false,
      item: [],
      lastPeriod: {},
      listFile: [],
      listFileErrMsg: [],
      loading: false,
      loadingFile: false,
      loadingForm: false,
      mainCats: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalFileItem: {},
      noKMRestriction: false,
      rateSetErrorMsg: [],
      rateSet: {},
      selectedItem: { id: null },
      startDate: null,
      startValue: null,
      subCats: [],
      total: 0
    }
    this.handleHoulyRateChange = debounce(this.handleHoulyRateChange, 80)
  }

  componentWillReceiveProps (nextProps) {
    const { onRefreshFunding } = this.props
    if (nextProps.shouldRefreshFunding !== this.props.shouldRefreshFunding) {
      if (nextProps.shouldRefreshFunding) {
        this.fetchFunders()
        this.fetchFunding()
        this.handleFunderCategoryLoaded(defaultFunderId)
        this.fetchFiles()
        this.fetchFileCats()
        onRefreshFunding(false)
      }
    }
  }

  componentDidMount () {
    this.fetchFunders()
    this.fetchFunding()
    this.handleFunderCategoryLoaded(defaultFunderId)
    this.fetchFiles()
    this.fetchFileCats()

    if (window) {
      window.scrollTo(0,0)
    }
  }

  render () {
    const { form, clientId } = this.props
    const { getFieldDecorator } = form
    const {
      defaultSelectedCategory, fileList, funders, funding, funderBillingCategories, fundManagers,
      isDirectNDIS, isEdit, isGetRateSet, isNdiaManaged, isPlanTBA, isShowFileModal, loading, loadingFile, loadingForm,
      mainCats, modalFileItem, modalFunder, modalPeriod,
      selectedItem, startDate, startValue, subCats,
      rateSetErrorMsg, rateSet
    } = this.state

    // Current table columns width: 21
    const columns = [
      {
        width: 1,
        render: ({is_current: isCurrent}, index) => isCurrent ? <Icon type='clock-circle' style={{color: '#EC726E'}} /> : null
      },
      {
        title: 'Start',
        width: 2,
        render: ({ start_date }) => formatter.toShortDate(start_date)
      },
      {
        title: 'End',
        width: 2,
        render: ({ end_date }) => formatter.toShortDate(end_date)
      },
      {
        title: 'Allocated',
        width: 2,
        render: ({ plan_allocated_hrs }) => `${formatter.toDecimalOptional(plan_allocated_hrs)} Hr${plan_allocated_hrs === 1 ? '' : 's'}`
      },
      {
        title: 'Usable',
        width: 2,
        render: ({ allocated_hrs, is_tba }) => is_tba ? 'TBC' : `${formatter.toDecimalOptional(allocated_hrs)} Hr${allocated_hrs === 1 ? '' : 's'}`
      },
      // {
      //   title: 'TBA',
      //   width: 1,
      //   render: ({ is_tba: isTba }) => {
      //     return (
      //       isTba ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      //         : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      //     )
      //   }
      // },
      {
        title: 'Balance',
        width: 2,
        render: ({ remaining_hours, is_tba }) => is_tba ? 'TBC' : `${formatter.toDecimalOptional(remaining_hours)} Hr${remaining_hours === 1 ? '' : 's'}`
      },
      {
        title: 'SC Fund',
        width: 3,
        render: ({ sc_fund }) => sc_fund === 'ndia-managed' ? 'NDIA-managed' : formatter.capitalize(sc_fund)
      },
      // {
      //   title: 'Plan Manager',
      //   width: 3,
      //   key: 'fund_manager'
      // },
      {
        title: 'NDIS Planner',
        width: 4,
        render: (item) => <div>{item.cmsc_first_name || ''} {item.cmsc_last_name || ''} &nbsp;
          {item.cmsc_first_name ? <Popover content={<div>{item.cmsc_org}<br />{item.cmsc_contact}<br />{item.cmsc_email}</div>} title={`${item.cmsc_first_name || ''} ${item.cmsc_last_name || ''}`}>
            <Icon type='info-circle' />
          </Popover> : null}
        </div>
      },

      {
        title: 'Implmt. Report',
        width: 2,
        render: ({ is_report_done: isReportDone }) => {
          return (
            isReportDone ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
              : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          )
        }
      },
      {
        title: 'Prog. Report',
        width: 2,
        render: ({ is_review_done: isReviewDone }) => {
          return (
            isReviewDone ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
              : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          )
        }
      },
      {
        title: 'Actions',
        width: 1,
        render: (item) => {
          const { id } = item || {}
          const fileListMatch = Array.isArray(fileList) && fileList.find(({ main_category_id: mcId }) => mcId === id)

          return (
            <div className='file-action-buttons'>
              <div>
                {this.hasAccess('updateClient')
                  ? <Tooltip mouseLeaveDelay={0} title='Edit'>
                    <div onClick={() => this.handleEditPeriod(item)} style={{ cursor: 'pointer' }}>
                      <Icon type='form' />
                    </div>
                  </Tooltip>
                  : null}
              </div>
              {/* <div style={{ marginLeft: 15, marginRight: 15 }}>
                {this.hasAccess('updateClient')
                  ? (
                    <FileTypeUpload
                      loading={loadingFile}
                      readOnly={false}
                      upload={{
                        action: `${apiHostname}/private/api/clients/files/upload/files`,
                        beforeUpload: this.checkFile,
                        data: { id: item.id, moduleId: clientId },
                        disabled: loading,
                        headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                        name: 'file',
                        onChange: this.handleUploadFile(clientId, null, item.id),
                        showUploadList: false
                      }}
                    />
                  )
                  : null}
              </div> */}
              <div style={ fileListMatch ? { marginRight: 15 } : { marginRight: 27 }}>
                {this.hasAccess('updateClient') && fileListMatch
                  ? <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(fileListMatch.file_name)}`}>
                    <div onClick={this.handleDownloadFile(fileListMatch.file_url)} style={{ cursor: 'pointer' }}>
                      <Icon type='file-text' />
                    </div>
                  </Tooltip>
                  : null}
              </div>
              <div>
                {this.hasAccess('deleteClient')
                  ? <Popconfirm
                    title='Confirm to delete this?'
                    onConfirm={() => this.handleDeletePeriod(item)}
                    okText='Yes'
                    cancelText='No'>
                    <Icon type='delete' style={{ marginTop: '2px' }} />
                  </Popconfirm>
                  : null}
              </div>
            </div>
          )
        }
      }
    ]

    return (<div>
      {/* NDIS Plan & Settings>Funder - hide client funding UI but still handling in backend */}
      {/* <Loading loading={loading} blur>
        <Panel title='NDIS Plan' subtitle={this.hasAccess('createClient') ? <div className='btn' onClick={() => this.showFunderModal()}>Add</div> : null}>
          <div className='client-funder-list'>
            {
              funding.list.map((item, index) => {
                return (
                  <div key={index} className='client-funder-list-item'>

                    <Row style={{ fontWeight: 'bold', padding: '14px', backgroundColor: '#f4f4f4', borderBottom: '1px solid #eee' }}>
                      <Col lg={6}>
                        <ControlLabel>Funder</ControlLabel>
                        <div className='client-funder-info'>{item.funder_name}</div>
                      </Col>
                      <Col lg={11}>
                        <ControlLabel>NDIS Number</ControlLabel>
                        <div className='client-funder-info'>{item.invoice_note}</div>
                      </Col>
                      <Col lg={6}>
                        <ControlLabel>ACTIVE</ControlLabel>
                        <div className='client-funder-info' style={{ fontSize: '11pt' }}>{item.active ? 'Yes' : 'No'}</div>
                      </Col>
                      <Col lg={1}>
                        <div className='action-buttons' style={{ marginRight: '10px', marginTop: '15px' }}>
                          {
                            this.hasAccess('updateClient') ? <Tooltip mouseLeaveDelay={0} title='Edit'>
                              <div onClick={() => this.handleEdit(item)}><Icon type='form' /></div>
                            </Tooltip> : null
                          }
                          {
                            this.hasAccess('deleteClient') ? <Popconfirm
                              title='Confirm to delete this?'
                              onConfirm={() => this.handleDelete(item.id, item.funder_name, item.period)}
                              okText='Yes'
                              cancelText='No'>
                              <Icon type='delete' style={{ marginTop: '2px' }} />
                            </Popconfirm> : null
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ borderColor: '1px solid #f4f4f4', padding: '10px' }}>
                      <Col style={{ fontWeight: 'bold', fontSize: '10.5pt', padding: '5px' }}>Plan Period  { this.hasAccess('updateClient') ? <span onClick={() => this.showPeriodModal(item.funder_id, item.id)} style={{ cursor: 'pointer' }}><Icon type='plus-circle' theme='filled' style={{ fontSize: '10pt' }} /></span> : null }</Col>
                      {item.period.length > 0 ? <List cols={columns} rows={item.period} /> : null}
                    </Row>
                  </div>)
              })
            }
          </div>
        </Panel>
      </Loading>

      <Pager
        size={pageSize}
        total={funding.total}
        totalText={`Total ${funding.total} funders`}
        current={currentPage}
        onChange={(e) => this.changePage(e)}
        style={{ marginTop: '15px' }}
      /> */}

      <Loading loading={loading} blur>
        {funding.list.length > 0 ? (
          funding.list.map((item, index) => {
            return (
              <div key={`flist-item${index}`}>
                <Panel title='NDIS Plan' subtitle={this.hasAccess('createClient') ? <div className='btn' onClick={() => this.showPeriodModal(item.funder_id, item.id)}>Add</div> : null}>
                  <div className='client-funder-list'>
                    <div className='client-funder-list-item'>
                      <Row style={{ borderColor: '1px solid #f4f4f4', padding: '10px' }}>
                        <Col style={{ fontWeight: 'bold', fontSize: '10.5pt', padding: '5px' }}>Plan Period</Col>
                        {item.period.length > 0 ? <List cols={columns} rows={item.period} /> : null}
                      </Row>
                    </div>

                    <Pager
                      // size={pageSize}
                      // total={item.period.length}
                      totalText={`Total ${item.period.length} plan periods`}
                      // current={currentPage}
                      // onChange={(e) => this.changePage(e)}
                      style={{ marginTop: '15px' }}
                    />
                  </div>
                </Panel>
              </div>
            )
          })
        )
          : <Panel title='NDIS Plan' subtitle={this.hasAccess('createClient') ? <div className='btn' onClick={() => this.showPeriodModal(defaultFunderId)}>Add</div> : null} /> }
      </Loading>

      <SideModal
        title='NDIS Plan'
        showModal={modalFunder}
        onClose={() => this.hideFunderModal()}
        buttons={[
          <Button key='0' onClick={() => this.handleSaveFunder()} feedback={loadingForm}>Save</Button>
        ]}
      >
        <Form layout='vertical'>
          <FormItem label='Active'>
            {getFieldDecorator('active', {
              initialValue: isEdit ? selectedItem.active : true,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren='Yes'
                unCheckedChildren='No'
              />
            )}
          </FormItem>
          <FormItem label='Funder'>
            {getFieldDecorator('funder_id', {
              // update: default set funder id to 1 (NDIS) and disabled
              initialValue: isEdit ? selectedItem.funder_id : defaultFunderId,
              rules: [
                { required: true, message: 'Please select a funder' }
              ]
            })(
              <Select
                showSearch
                optionFilterProp='children'
                notFoundContent='Not found'
                placeholder='Please select a funder'
                filterOption={(input, option) => this.findFunders(input, option)}
                disabled={true}
              >
                {
                  funders.map((funder, idx) => (
                    <Option key={idx} value={funder.id}>{funder.fullname}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem>
          <FormItem label='NDIS Number'>
            {getFieldDecorator('invoice_note', {
              initialValue: isEdit ? selectedItem.invoice_note : null,
              rules: [
                { min: 2, message: 'NDIS Number must be between 2 and 128 characters' },
                { max: 128, message: 'NDIS Number must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter NDIS Number' }
              ]
            })(
              <Input />
            )}
          </FormItem>
        </Form>
      </SideModal>

      <SideModal
        title='Plan Period'
        showModal={modalPeriod}
        modalStyle={{maxWidth: '420px', right: modalPeriod ? '0px' : '-420px', transition: modalPeriod ? '0.3s' : '0.3s'}}
        onClose={() => this.hidePeriodModal()}
        buttons={[
          <Button key='0' onClick={() => this.checkBeforeSavePeriod()} feedback={loadingForm}>Save</Button>
        ]}
      >
        <Form layout='vertical'>
          <FormItem label='Plan Start Date'>
            {getFieldDecorator('start_date', {
              initialValue: isEdit ? (selectedItem.start_date ? moment(selectedItem.start_date) : null) : startDate,
              rules: [
                { required: true, message: 'Please select a start date' },
                { validator: this.handleDateChange('getEnd') }
              ]
            })(
              <DatePicker format='DD/MM/YYYY' onChange={(value) => this.setState({ startValue: value })} />
            )}
          </FormItem>

          <FormItem label='Plan End Date'>
            {getFieldDecorator('end_date', {
              initialValue: isEdit ? (selectedItem.end_date ? moment(selectedItem.end_date).endOf('day') : null) : null,
              rules: [
                { required: true, message: 'Please select an end date' },
                { validator: this.handleDateChange('getStart') }
              ]
            })(
              <DatePicker format='DD/MM/YYYY' disabledDate={this.disabledStartDate} />
            )}
          </FormItem>

          <FormItem label='Support Item'>
            {getFieldDecorator('category', {
              initialValue: isEdit ? (selectedItem && validator.isNotEmptyArray(selectedItem.category) ? selectedItem.category[0] : '') : defaultSelectedCategory,
              rules: [{ required: true, message: 'Please select support item' }]
            })(
              <Select
                showSearch
                placeholder='Select support item'
                disabled={funderBillingCategories.length === 0}
                onChange={(e) => this.handleChangeCategory(e)}
                filterOption={(input, option) =>
                  `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {
                  funderBillingCategories.map((cat) => {
                    return <Option key={cat.category_id} value={cat.category_id} disabled={cat.category_name === FieldList.SLEEPOVER}>{`${validator.isNotEmptyArray(cat.label) ? `[${cat.label[0]}] ` : ''}${cat.category_name}`}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>

          <FormItem label={<div>
            <span>Hourly Rate</span>
            <span><Button style={{marginLeft: '10px'}} onClick={() => this.getDefaultRateSet()} feedback={isGetRateSet}>{rateSet && rateSet.category_id ? 'Reset' : 'Get Rate'}</Button></span>
          </div>}
          >
            { validator.isNotEmptyArray(rateSetErrorMsg)
              ? <div style={{color: 'red', fontWeight: 'bold', fontSize: '9px', marginTop: '0px', marginBottom: '4px'}}>
                { rateSetErrorMsg.map(e => (
                  <div key={'rs-error-msg'}>{e}</div>
                ))}
              </div>
              : <div>
                { ' ' }
              </div> }
            <Row gutter={10} style={{marginTop: '0px', marginBottom: '0px'}}>
              <Col md={20} lg={20}>
                <Row gutter={4}>
                  <Col md={7} lg={7}>
                    <FormItem>
                    {getFieldDecorator('rate_set_value', {
                      initialValue: isEdit ? (rateSet.value || 0.0) : 0.0,
                      rules: [
                        { validator: this.handleRateValueChange }
                      ]
                    })(
                      <Input className='billing-rate-set-field' placeholder='0.00' />
                    )}
                    </FormItem>
                  </Col>

                  <Col md={1} lg={1}></Col>

                  <Col md={9} lg={9}>
                    <FormItem style={{display: 'none'}}>
                    {getFieldDecorator('rate_set_label', {
                      initialValue: isEdit ? (rateSet.label || '') : '',
                      // rules: [
                      //   { required: true, message: 'Please enter rate set label' }
                      // ]
                    })(
                      <Input className='billing-rate-set-field' placeholder='Label' />
                    )}
                    </FormItem>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormItem>

          <FormItem style={{ marginTop: '-30px' }} label='Plan Allocated Hours'>
            {getFieldDecorator('plan_allocated_hrs', {
              initialValue: isEdit ? selectedItem.plan_allocated_hrs : null,
              rules: [
                { required: true, message: 'Please enter plan allocated hours' },
                { validator: this.handleDigitDecimalChange }
              ]
            })(
              <Input placeholder='Total Hours for Plan Period' />
            )}
          </FormItem>

          <FormItem label={<span>
            <span>Usable Hours</span>
            <span style={{ marginLeft: 15 }}>{getFieldDecorator('is_tba', {
              initialValue: isEdit ? (selectedItem.is_tba || false) : false,
              valuePropName: 'checked'
            })(
              <Switch
                checkedChildren='TBC'
                unCheckedChildren='No'
                onChange={this.handleTBAChange}
              />
            )}</span>
          </span>}
          >
            {getFieldDecorator('allocated_hrs', {
              initialValue: isEdit ? selectedItem.allocated_hrs : null,
              rules: [
                { required: !isPlanTBA, message: 'Please enter usable hours' },
                { validator: this.handleDigitDecimalChange }
              ]
            })(
              <Input placeholder='Usable Hours for SC' disabled={isPlanTBA} onChange={this.handleHoulyRateChange} />
            )}
          </FormItem>

          <FormItem label='SC Fund' style={{/*marginTop: '-30px'*/}}>
            {getFieldDecorator('sc_fund', {
              initialValue: isEdit ? selectedItem.sc_fund : null,
              rules: [
                { required: true, message: 'Please select a sc fund' }
              ]
            })(
              <Select
                placeholder='Please select a sc fund'
                notFoundContent='Not found'
                onChange={this.handleSCFundChange}
              >
                {
                  ClientSCFund.map((sc) => (
                    <Option key={`sc-fund ${sc.value}`} value={sc.value}>{sc.label}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem>

          {isNdiaManaged ? <FormItem label='Service Booking Number' style={{}}>
            {getFieldDecorator('rate_booking_number', {
              initialValue: isEdit ? selectedItem.rate_booking_number : null,
              rules: [
                { required: isNdiaManaged && !isPlanTBA, message: 'Please enter service booking number' }
              ]
            })(
              <Input placeholder='Service Booking Number' disabled={isPlanTBA} />
            )}
          </FormItem>
            : null}

          {isNdiaManaged ? <FormItem label='Service Booking Amount' style={{}}>
            {getFieldDecorator('rate_booking_amount', {
              initialValue: isEdit ? (selectedItem.rate_booking_amount ? formatter.toDecimalWithoutComma(selectedItem.rate_booking_amount) : null) : null,
              rules: [
                { required: isNdiaManaged && !isPlanTBA, message: 'Please enter service booking amount' },
                { validator: this.handleDigitDecimalChange }
              ]
            })(
              <Input placeholder='Service Booking Amount' onChange={this.handleHoulyRateChange} disabled={isPlanTBA} />
            )}
          </FormItem>
            : null}

          {/* Plan Manager removed from card: NDIS Plan & Settings>Funder */}
          {/* <FormItem label='Plan Manager'>
            {getFieldDecorator('fund_manager_id', {
              initialValue: isEdit ? (fundManagers.length > 0 ? selectedItem.fund_manager_id : null) : null,
              rules: [
                fundManagers.length > 0 ? { required: true, message: 'Please select a plan manager' } : {}
              ]
            })(
              <Select
                placeholder='Please select a plan manager'
                showSearch
                optionFilterProp='children'
                notFoundContent='Not found'
                disabled={fundManagers.length === 0}
              >
                {
                  fundManagers.map((fm) => (
                    <Option key={fm.fund_manager_id} value={parseInt(fm.fund_manager_id)}>{fm.fullname}</Option>
                  ))
                }
              </Select>
            )}
          </FormItem> */}

          <FormItem label='Planner'>
            {getFieldDecorator('cmsc_first_name', {
              initialValue: isEdit ? selectedItem.cmsc_first_name : null,
              rules: [
                { min: 1, message: 'Planner name must be between 1 and 128 characters' },
                { max: 128, message: 'Planner name must be between 1 and 128 characters' },
                { whitespace: true, message: 'Please enter Planner name ' }
              ]
            })(
              <Input placeholder='Name' />
            )}
            {getFieldDecorator('cmsc_org', {
              initialValue: isEdit ? selectedItem.cmsc_org : null,
              rules: [
                { min: 1, message: 'Planner organization must be between 1 and 128 characters' },
                { max: 128, message: 'Planner organization must be between 1 and 128 characters' },
                { whitespace: true, message: 'Please enter Planner organization' }
              ]
            })(
              <Input placeholder='Organization' style={{ marginTop: '8px' }} />
            )}
            {getFieldDecorator('cmsc_contact', {
              initialValue: isEdit ? selectedItem.cmsc_contact : null,
              rules: [
                { min: 1, message: 'Planner contact must be between 1 and 128 characters' },
                { max: 128, message: 'Planner contact must be between 1 and 128 characters' },
                { whitespace: true, message: 'Please enter Planner contact ' }
              ]
            })(
              <Input placeholder='Contact' style={{ marginTop: '8px' }} />
            )}
            {getFieldDecorator('cmsc_email', {
              initialValue: isEdit ? selectedItem.cmsc_email : null,
              rules: [
                { min: 1, message: 'Planner email must be between 1 and 128 characters' },
                { max: 128, message: 'Planner email must be between 1 and 128 characters' },
                { whitespace: true, message: 'Please enter Planner email' }
              ]
            })(
              <Input placeholder='Email' style={{ marginTop: '8px' }} />
            )}
          </FormItem>

          {isEdit ? <FormItem label={<span>Documents</span>}>
            <Row>
              <Col lg={12}>
                <Col lg={10}>
                  <div style={{ marginTop: 6 }} className='ant-form-label'>NDIS Plan:</div>
                </Col>
                <Col lg={14}>
                  <span>{this.hasAccess('updateClient')
                    ? this.getUploader('ppt_ndis_plan', (selectedItem && selectedItem.list_files ? selectedItem.list_files : []), 'documents')
                    : null}
                  </span>
                </Col>
              </Col>
              <Col lg={12}>
                <Col lg={17}>
                  <div style={{ marginTop: 6 }} className='ant-form-label'>Service Agreement:</div>
                </Col>
                <Col lg={7}>
                  <span>{this.hasAccess('updateClient')
                    ? this.getUploader('ppt_service_agreement', (selectedItem && selectedItem.list_files ? selectedItem.list_files : []), 'documents')
                    : null}
                  </span>
                </Col>
              </Col>
            </Row>
          </FormItem>
            : null}

          {isEdit ? (
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={19}>
                    <FormItem label='Implementation Report'>
                      {getFieldDecorator('is_report_done', {
                        initialValue: isEdit ? (selectedItem.is_report_done || false) : false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          checkedChildren='Completed'
                          unCheckedChildren='Not Done'
                          onChange={this.handleReportChange}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={5}>
                    <div style={{ marginTop: 30, marginLeft: -30 }}>{this.hasAccess('updateClient')
                      ? this.getUploader('ppt_8_week_report', (selectedItem && selectedItem.list_files ? selectedItem.list_files : []), 'is_report_done')
                      : null}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <Row>
                  <Col lg={15}>
                    <FormItem label='Progress Report'>
                      {getFieldDecorator('is_review_done', {
                        initialValue: isEdit ? (selectedItem.is_review_done || false) : false,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          checkedChildren='Completed'
                          unCheckedChildren='Not Done'
                          onChange={(value) => this.setState({ isReviewDone: value })}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={9}>
                    <div style={{ marginTop: 30, marginLeft: -5 }}>{this.hasAccess('updateClient')
                      ? this.getUploader('ppt_9_month_report', (selectedItem && selectedItem.list_files ? selectedItem.list_files : []), 'is_review_done')
                      : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          )
            : null}
        </Form>
      </SideModal>

      <AddFileModal
        clientId={clientId}
        fPeriod={selectedItem}
        key={`addfilemodal${modalFileItem && modalFileItem.id ? modalFileItem.id : '_new'}`}
        item={modalFileItem}
        isDirectNDIS={isDirectNDIS}
        categoriesList={mainCats}
        subCategoriesList={subCats}
        onClose={() => this.showAddFileModal(false)}
        onSetFile={(values) => this.updateFile(values)}
        visible={isShowFileModal}
      />
            </div>)
  }

  fetchFunder = async () => {
    try {
      const { clientId } = this.props

      this.setState({ loading: true })
      const item = await clientService.getClientFunders(clientId)
      this.setState({ item, loading: false, clientId: clientId })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load funder successfully. Please try again later.' + e)
      this.setState({ loading: false })
    }
  }

  fetchFunders = async () => {
    const funders = await funderService.listByPage(1, 0, { active: true })
    this.setState({ funders: funders.list })
  }

  async fetchFundManager (funderId) {
    const fundManagers = await funderService.getManager(funderId)
    this.setState({ fundManagers })
  }

  fetchFiles = async () => {
    this.setState({ loading: true })
    const { clientId } = this.props
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'ndis_plan' }
      ]
    }
    filter.module_id = { condition: '=', value: clientId }
    filter.module = { condition: '=', value: clientModule }
    filter.active = { condition: '=', value: true }

    const { list, total } = await clientFileService.listByPage(1, 0, filter)
    // console.log('fetch files', list, total)
    this.setState({ fileList: list, fileTotal: total, loading: false })
  }

  showPeriodModal (funderId, fundingId) {
    const { form } = this.props
    const { funding } = this.state
    form.resetFields()

    this.fetchFundManager(funderId)

    // Get start date and end date
    const funder = funding.list.find(funder => funder.funder_id === funderId)

    if (funder) {
      const periods = funder.period
      // console.log('periods', periods)
      let startDate = moment()
      let endDate = moment().add(364, 'days')
      let lastPeriod = {}

      if (periods.length > 0) {
        startDate = moment(periods[0].end_date).add(1, 'day')
        endDate = moment(periods[0].end_date).add(1, 'year')

        // Get Last Period Date
        const hasCurrent = periods.find(period => period.is_current)

        if (hasCurrent) {
          lastPeriod = periods.length > 1 ? periods[1] : periods[0]
        } else {
          lastPeriod = periods[0]
        }
      }

      this.setState({ startDate, endDate, lastPeriod })
    }

    this.setState({ modalPeriod: true, isEdit: false, selectedItem: null, funderId, fundingId, noKMRestriction: false, isReportDone: false, isReviewDone: false })
  }

  showFunderModal () {
    this.setState({ modalFunder: true, isEdit: false, selectedItem: null })
  }

  hidePeriodModal () {
    const { form } = this.props
    form.resetFields()
    this.setState({ modalPeriod: false, isEdit: false, selectedItem: {}, startValue: null, isPlanTBA: false, isReportDone: false, isReviewDone: false, listFile: [] })
  }

  hideFunderModal = () => {
    this.setState({ modalFunder: false, isEdit: false })
  }

  findFunders = (input, option) => {
    const funder = `${option.props.children}`
    return funder.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleNoKMRestriction = (noRestriction) => {
    const { setFieldsValue } = this.props.form
    this.setState({ noKMRestriction: noRestriction })
    if (noRestriction) {
      setFieldsValue({ km_max: '' })
    }
  }

  handleFunderCategoryLoaded = async (funderId) => {
    const categories = await funderService.getBillingCategory(funderId)
    this.setState({ funderBillingCategories: categories })
  }

  handleDownloadFile = (url) => (e) => {
    if (url) {
      window.location.href = url
    } else {
      error({
        title: 'Error',
        content: (
          <div>No file has been uploaded!</div>
        ),
        okText: 'OK',
        onOk () { }
      })
    }
  }

  handleReportChange = (value) => {
    this.setState({ isReportDone: value })
  }

  handleSCFundChange = (value) => {
    const { setFieldsValue } = this.props.form

    if (value) {
      // setFieldsValue({ allocated_hrs: null })

      if (value !== 'ndia-managed') {
        setFieldsValue({ rate_booking_number: null, rate_booking_amount: null })
      }
    }

    this.setState({ isNdiaManaged: value === 'ndia-managed' ? value : false })
  }

  handleTBAChange = (value) => {
    const { setFieldsValue } = this.props.form

    if (value) {
      setFieldsValue({ allocated_hrs: null, rate_booking_number: null, rate_booking_amount: null, rate_set_value: null })
    }

    this.setState({ isPlanTBA: value })
  }

  handleUploadFile = (clientId, fileId, fundingId) => async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loading: true })
    }

    if (status === 'done') {
      const { fileUrl } = response
      const lastIndex = `${fileUrl}`.lastIndexOf('/')
      const fileName = decodeURI(fileUrl.substring(lastIndex + 1))
      // const fileType = FileTypeList.filter(item => item.value === type)
      const fileType = 'NDIS Plan'
      const values = {}

      values.module = clientModule
      values.module_id = clientId
      values.type = 'ndis_plan'
      values.file_name = fileName
      values.file_url = fileUrl
      values.label = `NDIS_Plan_${formatter.toShortDate(moment())}`
      values.active = true
      values.main_category_id = fundingId

      const addFileResult = await clientFileService.add(values)

      if (addFileResult.id) {
        this.setState({ shouldRefreshFiles: true })
        // log.addEmployeeFile(clientId, `New ${fileType[0].name} ${values.label}`)
        log.addEmployeeFile(clientId, `New ${fileType} ${values.label}`)
        this.fetchFiles()
        notify.success('Upload successfully', 'File upload successfully')
        this.props.setRefreshActivityLog(true)
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loading: false })
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { UploadType } = uploader
        const response = await uploader.upload(fileId, info, token, UploadType.FILE)
        // console.log('token refreshed & upload file done', response)

        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = `${fileUrl}`.lastIndexOf('/')
          const fileName = decodeURI(fileUrl.substring(lastIndex + 1))
          // const fileType = FileTypeList.filter(item => item.value === type) // Need change to get from database 'Main Category' & 'Sub-Category'
          const fileType = 'NDIS Plan'
          const values = {}

          values.module = clientModule
          values.module_id = clientId
          values.type = 'ndis_plan'
          values.file_name = fileName
          values.file_url = fileUrl
          values.label = `NDIS_Plan_${formatter.toShortDate(moment())}`
          values.active = true
          values.main_category_id = fundingId

          const addFileResult = await clientFileService.add(values)

          if (addFileResult.id) {
            this.setState({ shouldRefreshFiles: true })
            // log.addEmployeeFile(clientId, `New ${fileType[0].name} ${values.label}`)
            log.addEmployeeFile(clientId, `New ${fileType} ${values.label}`)
            this.fetchFiles()
            notify.success('Upload successfully', 'File upload successfully')
            this.props.setRefreshActivityLog(true)
          } else {
            notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
          }

        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loadingForm: false })
    }
  }

  getUploader = (type, fields = [], fieldValue) => {
    const { isReportDone, isReviewDone, listFile, mainCats, subCats } = this.state
    const isButtonEnable = validator.isNotEmptyArray(mainCats) && validator.isNotEmptyArray(subCats)
    // console.log('type', type, 'fieldValue', fieldValue)
    const isCompleted = fieldValue === 'is_report_done'
      ? isReportDone
      : fieldValue === 'is_review_done'
        ? isReviewDone
        : type === 'ppt_ndis_plan' && fieldValue === 'documents'
          ? true
          : type === 'ppt_service_agreement' && fieldValue === 'documents'
            ? true
            : false
    const f = listFile && listFile.find(e => e.type === type) ? listFile.find(e => e.type === type) : fields.find(e => e.type === type) // ListFile = uploaded files, fields = existing files

    // console.log('fields', fields)
    // console.log('f', f)

    return (
      <div className='row-care-plan-left' style={{ marginTop: '6px' }}>
        { !f && isCompleted
          ? <div
            className={`file-name-icon ${isButtonEnable ? '' : 'disabled'}`}
            onClick={isButtonEnable ? () => this.showAddFileModal(true, type) : undefined}
          >
            <Icon type='upload' />
          </div>
          : null}
        <div>
          { !f
            ? validator.isNotEmptyArray(fields)
              ? <div className='row-care-plan-left' style={{}}>
                <div className='file-name-text-alert'>
                  {fields.id === undefined
                    ? null
                    : <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(f.file_name)}`}>
                      <div onClick={this.handleDownloadFile(fields.file_url)} className='file-name-icon'>
                        <Icon type='file-text' />
                      </div>
                    </Tooltip>
                  }</div>
              </div>
              : null
            : f.id === null
              ? <div className='file-name-text-alert'>
                The file is removed.
              </div>
              : <div className='row-care-plan-left' style={{}}>
                <div className='file-name-text-alert'>
                  {f && f.id === undefined
                    ? <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(f.file_name)}`}>
                      <div onClick={this.handleDownloadFile(f.file_url)} className='file-name-icon'>
                        <Icon type='file-text' />
                      </div>
                    </Tooltip>
                    : <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(f.file_name)}`}>
                      <div onClick={this.handleDownloadFile(f.file_url)} className='file-name-icon'>
                        <Icon type='file-text' />
                      </div>
                    </Tooltip>
                  }</div>
                {/* <div className='file-name-label'>{f.label}</div> */}
                {/* <div className='file-name-text'>[{f.file_name}]</div> */}
              </div>
          }
        </div>
      </div>
    )
  }

  async fetchFunding () {
    this.setState({ loading: true })
    const { clientId } = this.props
    const filter = { }

    const funding = await clientFundingService.get(clientId)

    this.setState({ funding, loading: false, filter })

    setTimeout(() => {
      this.props.setRefreshFiles(false)
    }, 200)
  }

  fetchFileCats = async () => {
    try {
      let filter1 = {}
      filter1.active = { condition: '=', value: true }
      filter1.classification = { condition: '=', value: 'participant' }
      filter1.code = { condition: 'ilike', value: '%ndis%' }

      const mainCategories = await settingFileCategoryService.listByPage(1, 0, filter1)

      if (mainCategories && validator.isNotEmptyArray(mainCategories.list)) {
        const cat = mainCategories.list[0]
        let filter2 = {}

        if (cat && cat.id) {
          filter2.active = { condition: '=', value: true }
          filter2.file_category_id = { condition: '=', value: cat.id }

          const subCategories = await settingFileTypeService.listByPage(1, 0, filter2)

          if (subCategories && validator.isNotEmptyArray(subCategories.list)) {
            this.setState({ mainCats: mainCategories.list, subCats: subCategories.list })
          }
        }
      }
    } catch (e) {

    }
  }

  changePage = async (currentPage) => {
    this.fetchFundingSetting(currentPage)
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'tax_code' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      taxCodes: settings.list
    })
  }

  async handleEdit (item) {
    const { form } = this.props
    form.resetFields()

    const { funders } = this.state
    const currentFunder = funders.find((f) => f.id === item.funder_id)

    this.setState({ modalFunder: true, isEdit: true, currentFunder, selectedItem: item })

    const categories = await funderService.getBillingCategory(item.funder_id)
    this.setState({ funderBillingCategories: categories })
  }

  async handleDelete (id, funderName, period) {
    const { clientId } = this.props

    if (period.length > 0) {
      notify.error('Unable to delete', 'All plan period(s) have to removed first')
    } else {
      this.setState({ loading: true })
      const res = await clientFundingService.remove(id)

      if (res) {
        log.deleteClientFunder(clientId, `${funderName}`)
        notify.success('Deleted successfully', 'Funder deleted successfully')
        this.fetchFunding()

        this.props.setRefreshActivityLog(true)
      }
      this.setState({ loading: false })
    }
  }

  async handleEditPeriod (item) {
    const { form } = this.props
    form.resetFields()

    this.fetchFundManager(item.funder_id)
    // console.log('editing period item', item)
    this.handleNoKMRestriction(item.km_restrict)
    this.setState({
      modalPeriod: true,
      isEdit: true,
      selectedItem: item,
      rateSet: item.rate_set || {},
      funderId: item.funder_id,
      isPlanTBA: item.is_tba,
      isReportDone: item.is_report_done,
      isReviewDone: item.is_review_done,
      isNdiaManaged: item.sc_fund && item.sc_fund === 'ndia-managed' ? true : false
    })
  }

  async handleDeletePeriod (item) {
    const { clientId } = this.props
    const { funders } = this.state
    const f = funders.find((funder) => funder.id === item.funder_id)
    this.setState({ loading: true })

    const res = await clientFundingPeriodService.remove(item.id)

    if (res) {
      log.deleteClientFundingPeriod(clientId,
        `'${f ? f.fullname : 'Unknow Funder'}' from ${formatter.toShortDate(item.start_date)} to ${formatter.toShortDate(item.end_date)},
        ${item.cmsc_first_name ? `', Planner ${item.cmsc_first_name} ${item.cmsc_org ? `, Organisation ${item.cmsc_org}` : ''} ${item.cmsc_contact ? `, Contact ${item.cmsc_contact}` : ''} ${item.cmsc_email ? `, Email ${item.cmsc_email}` : ''}` : ''}
        ${item.plan_allocated_hrs ? `Plan allocated hours '${item.plan_allocated_hrs}'` : 'No plan allocated hours'} ${item.allocated_hrs ? `Usable hours '${item.allocated_hrs}'` : 'No usable hours'}`)
        // ${item.km_restrict ? ', No KM restriction' : `, Max KM restriction ${item.km_max}KM`}`)
      notify.success('Deleted successfully', 'Plan period deleted successfully')
      this.fetchFunding()

      this.props.setRefreshActivityLog(true)
    }
    this.setState({ loading: false })
  }

  handleDateChange = (detect) => (rule, value, callback) => {
    const overlapping = this.isDateOverlapping(value, detect)

    if (overlapping.result) {
      try {
        throw new Error(`Date is overlapping with ${overlapping.period}`)
      } catch (err) {
        callback(err)
      }
    } else {
      callback()
    }
  }

  handleDigitDecimalChange = (rule, value, callback) => {
    if (value) {
      if (validator.isDecimal(value)) {
        callback()
      } else {
        callback(new Error('Please enter digits or complete decimal values'))
      }
    }

    // const field = rule ? rule.field : ''
    // const errMsg = field === 'plan_allocated_hrs'
    //   ? 'Please enter plan allocated hours'
    //   : field === 'allocated_hrs'
    //     ? 'Please enter usable hours'
    //     : 'Please enter digits or complete decimal values'
    // callback(new Error(errMsg))
    callback()
  }

  handleHoulyRateChange = () => {
    const { form } = this.props
    const { validateFields, setFieldsValue } = form

    validateFields(['allocated_hrs', 'rate_booking_amount'],
      async (errors, values) => {
        // console.log('handle houly rate', values)
        if (!errors && values.allocated_hrs && values.rate_booking_amount) {
          const hRate = values.rate_booking_amount / values.allocated_hrs
          // console.log('hRate', hRate)
          setFieldsValue({ rate_set_value: formatter.toDecimalWithoutComma(hRate) })
        } else {
          console.log('Errors', errors)
        }
      })
  }

  handleRateValueChange = (rule, value, callback) => {
    if (value) {
      if (validator.isDecimal(value)) {
        callback()
      } else {
        callback(new Error('Please enter digits or complete decimal values'))
      }
    }

    callback(new Error('Please enter rate set value'))
  }

  isDateOverlapping = (value, detect) => {
    const { form } = this.props
    const { funderId, funding, selectedItem, startDate, lastPeriod, isSavingPeriod } = this.state
    const { getFieldValue, setFieldsValue } = form
    let overlapped = false

    for (let i = 0; i < funding.list.length; i++) {
      for (let j = 0; j < funding.list[i].period.length; j++) {
        const fundingItem = funding.list[i].period[j]

        if (!selectedItem || (selectedItem && fundingItem.id !== selectedItem.id)) {
          let isBetween = moment(value).isBetween(moment(fundingItem.start_date).startOf('day'), moment(fundingItem.end_date).endOf('day'), undefined, '[]')

          if (!isBetween && detect === 'getEnd') {
            const endDate = getFieldValue('end_date')
            if (endDate !== null) {
              isBetween = (moment(value).isBetween(moment(fundingItem.start_date).startOf('day'), moment(fundingItem.end_date).endOf('day'), undefined, '[]')) || (moment(value).startOf('day') < moment(fundingItem.start_date).startOf('day') && moment(endDate).endOf('day') > moment(fundingItem.end_date).endOf('day'))
            }
          } else if (!isBetween && detect === 'getStart') {
            const startDate = getFieldValue('start_date')
            if (startDate !== null) {
              isBetween = (moment(value).isBetween(moment(fundingItem.start_date).startOf('day'), moment(fundingItem.end_date).endOf('day'), undefined, '[]')) || (moment(startDate).endOf('day') < moment(fundingItem.start_date).startOf('day') && moment(value).startOf('day') > moment(fundingItem.end_date).endOf('day'))
            }
          }

          if (isBetween) {
            overlapped = true
            return { result: true, period: `${(funding.list[i].invoice_note && funding.list[i].invoice_note !== null) || (funding.list[i].invoice_note && funding.list[i].invoice_note !== '') ? `NDIS ${funding.list[i].invoice_note}` : 'Other NDIS'} ${moment(fundingItem.start_date).format('DD/MM/YYYY')} - ${moment(fundingItem.end_date).format('DD/MM/YYYY')}` }
          }
        } else {
          console.log('cannot compare')
        }
      }
    }

    if (!isSavingPeriod && !overlapped && detect === 'getEnd' && moment(value).isAfter(moment(lastPeriod.end_date).add(1, 'days'))) {
      confirm({
        title: 'There is a gap between this start date and last plan period\'s end date. Are you sure?',
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk () {},
        onCancel () {
          setFieldsValue({ start_date: moment(lastPeriod.end_date).add(1, 'days') })
        }
      })
    }

    // const sameFunder = funding.list.filter(funding => funding.funder_id === funderId)

    // if (sameFunder) {
    //   for (let i = 0; i < sameFunder[0].period.length; i++) {
    //     const fundingItem = sameFunder[0].period[i]

    //     if (!selectedItem || (selectedItem && fundingItem.id !== selectedItem.id)) {
    //       const isBetween = moment(value).isBetween(fundingItem.start_date, fundingItem.end_date)

    //       if (isBetween) {
    //         return { result: true, period: `${moment(fundingItem.start_date).format('DD/MM/YYYY')} - ${moment(fundingItem.end_date).format('DD/MM/YYYY')}` }
    //       }
    //     } else {
    //       console.log('cannot compare')
    //     }
    //   }
    // }

    return { result: false }
  }

  disabledStartDate = endValue => {
    const { startValue } = this.state
    if (!endValue || !startValue) {
      return false
    }
    return endValue.valueOf() <= startValue.valueOf()
  }

  handleSaveFunder = () => {
    const { clientId, clientName, form } = this.props
    const { validateFields } = form
    const { isEdit, selectedItem, funders, funderBillingCategories } = this.state
    let response

    validateFields(['active', 'funder_id', 'invoice_note'], async (errors, values) => {
      if (!errors) {
        values.client_id = clientId
        this.setState({ loadingForm: true })

        if (!isEdit) {
          response = await clientFundingService.add(values)
          this.setState({ funderId: values.funder_id })

          const { fullname: funderName } = funders.find((funder) => funder.id === values.funder_id)
          log.addClientFunder(clientId, `${funderName}`)

          notify.success('Saved successfully', 'Funder added successfully.')
        } else {
          const extraLogs = []
          const { fullname: funderName } = funders.find((funder) => funder.id === values.funder_id)

          response = await clientFundingService.save(selectedItem.id, values)
          log.updateClientFunder(clientId, selectedItem, values, ['id', 'period'], extraLogs.join(), [{ key: 'invoice_note', label: 'NDIS Number' }])

          trigger.updateClientFunder(
            clientId,
            `${clientName}: ${funderName}`,
            selectedItem,
            values,
            [
              { key: 'start_date' },
              { key: 'end_date' },
              // { key: 'km_max' },
              { key: 'cmsc', label: 'Planner' },
              { key: 'invoice_note', label: 'NDIS Number' },
              // { key: 'km_restrict', label: 'No Restriction' },
              { key: 'allocated_hrs', label: 'Allocated Hours' },
              { key: 'manager' }
            ],
            extraLogs.join()
          )
          notify.success('Saved successfully', 'Funder saved successfully.')
        }

        this.setState({ loadingForm: false })

        this.fetchFunding()
        this.hideFunderModal()
        this.props.setRefreshActivityLog(true)

        if (!isEdit) {
          this.showPeriodModal(values.funder_id, response.id)
        }
      }
    })
  }

  async checkBeforeSavePeriod () {
    this.setState({ isSavingPeriod: true })
    this.handleSavePeriod()
  }

  async handleSavePeriod () {
    const { form, clientId, clientName, onUpdateInfo } = this.props
    const { validateFields } = form
    const { hasNDISPlan, hasNDISSA, isEdit, isGetRateSet, selectedItem, funders, funderId, fundingId, fundManagers, funderBillingCategories, listFile, listFileErrMsg } = this.state
    const { fullname: funderName } = await funders.find((funder) => funder.id === funderId)
    let response
    const extraLogs = []
    const extraContent = []

    validateFields(isEdit ? [
      'start_date', 'end_date',
      'category',
      // 'fund_manager_id',
      'cmsc_first_name', 'cmsc_last_name', 'cmsc_org', 'cmsc_contact', 'cmsc_email',
      // 'km_max', 'km_restrict',
      'allocated_hrs',
      'is_report_done', 'is_review_done', 'is_tba',
      'rate_set_value', 'rate_booking_number', 'rate_booking_amount',
      'sc_fund', 'plan_allocated_hrs'
    ]
    : [
      'start_date', 'end_date',
      'category',
      'cmsc_first_name', 'cmsc_last_name', 'cmsc_org', 'cmsc_contact', 'cmsc_email',
      'allocated_hrs', 'plan_allocated_hrs', 'sc_fund',
      'is_tba',
      'rate_set_value', 'rate_booking_number', 'rate_booking_amount'
    ], async (errors, values) => {
      if (!errors) {
        if (isGetRateSet) return
        // If implementation report is checked, see whether listFile got this file type uploaded
        if (values.is_report_done) {
          const found = listFile.find(item => item.type === 'ppt_8_week_report') || selectedItem.list_files.find(item => item.type === 'ppt_8_week_report')
          // console.log('found?', found ? found.type : undefined)
          if (!found) {
            notify.error('Implementation Report is missing', 'Please upload implementation report once completed.')
            return
          }
        }
        // If progress report is checked, see whether listFile got this file type uploaded
        if (values.is_review_done) {
          const found = listFile.find(item => item.type === 'ppt_9_month_report') || selectedItem.list_files.find(item => item.type === 'ppt_9_month_report')
          // console.log('found?', found ? found.type : undefined)
          if (!found) {
            notify.error('Progress Report are not uploaded', 'Please upload progress report once completed.')
            return
          }
        }
        // If no NDIS Plan uploaded, prompt error msg
        // if (!hasNDISPlan) {
        //   notify.error('NDIS Plan are not uploaded', 'Please upload ndis plan.')
        //   return
        // }
        // If no NDIS Service Agreement uploaded, prompt error msg
        // if (!hasNDISSA) {
        //   notify.error('Service Agreement are not uploaded', 'Please upload service agreement.')
        //   return
        // }

        const logStartDate = formatter.toShortDate(values.start_date)
        const logEndDate = formatter.toShortDate(values.end_date)
        values.start_date = moment(values.start_date).startOf('day')
        values.end_date = moment(values.end_date).endOf('day')
        values.funding_id = fundingId
        values.list_files = listFile
        values.rate_booking_number = values.sc_fund === 'ndia-managed' ? values.rate_booking_number : null
        values.rate_booking_amount = values.sc_fund === 'ndia-managed' ? values.rate_booking_amount : null

        /** handling on plan period category and rate set issue */
        if (values.category) {
          values.rate_set = {
            day: 'c0',
            value: values.rate_set_value,
            label: values.rate_set_label,
            category_id: values.category,
            client_id: clientId,
            client_funding_period_id: isEdit ? selectedItem.id : null
          }

          values.category = [values.category]
        }

        /** handling plan period category text issue  */
        let currentCategoryLabels = ''
        let categoryLabels = ''

        if (isEdit) {
          if (JSON.stringify(values.category) !== JSON.stringify(selectedItem.category)) {
            if (selectedItem.category) {
              for (var i = 0; i < selectedItem.category.length; i++) {
                const categoryInfo = funderBillingCategories.find((item) => item.category_id === selectedItem.category[i])
                if (categoryInfo) {
                  currentCategoryLabels += categoryInfo.category_name
                } else {
                  currentCategoryLabels += 'None'
                }
              }
            }
            for (var i = 0; i < values.category.length; i++) {
              const categoryInfo = funderBillingCategories.find((item) => item.category_id === values.category[i])
              if (categoryInfo) {
                categoryLabels += categoryInfo.category_name
              } else {
                categoryLabels += 'Unknown'
              }
            }

            extraLogs.push(`Support Item of the Period from "${currentCategoryLabels}" to "${categoryLabels}"`)
          }
        } else {
          for (var i = 0; i < values.category.length; i++) {
            const categoryInfo = funderBillingCategories.find((item) => item.category_id === values.category[i])
            if (categoryInfo) {
              categoryLabels += categoryInfo.category_name
            } else {
              categoryLabels += 'Unknown'
            }

            extraLogs.push(`Support Item of the Period: "${categoryLabels}"`)
            extraContent.push(`Support Item of the Period: "${categoryLabels}"<br />`)
          }
        }

        /** copy the editted values for log actions used */
        const values2 = Object.assign({}, values)
        delete values.rate_set_value
        delete values.rate_set_label

        this.setState({ loadingForm: true })

        // let fundmanagerName = ''

        // if (values.fund_manager_id && fundManagers.length > 0) {
        //   const { fullname } = await fundManagers.find((fm) => parseInt(fm.fund_manager_id) === parseInt(values.fund_manager_id))
        //   fundmanagerName = fullname
        // }

        let scfundName = ''

        if (values.sc_fund && ClientSCFund.length > 0) {
          const { label } = await ClientSCFund.find((sc) => sc.value === values.sc_fund)
          scfundName = label
        }

        if (!isEdit) {
          // console.log('item 1')

          // if (values.fund_manager_id) {
          //   extraContent.push(`Plan Manager: ${fundmanagerName}<br />`)
          // }

          if (values.sc_fund) {
            extraContent.push(`SC Fund: ${scfundName}<br />`)
          }

          try {
            response = await clientFundingPeriodService.add(values)

            log.addClientFundingPeriod(clientId,
              `'${funderName}' from ${logStartDate} to ${logEndDate},
              SC Fund '${scfundName}', ${values.cmsc_first_name ? ` Planner Name '${values.cmsc_first_name} ${values.cmsc_org ? `', Planner Organisation '${values.cmsc_org}'` : ''} ${values.cmsc_contact ? `, Planner Contact '${values.cmsc_contact}'` : ''} ${values.cmsc_email ? `, Planner Email '${values.cmsc_email}'` : ''}` : ''}
              ${values.plan_allocated_hrs ? `Plan Allocated Hours: ${values.plan_allocated_hrs}` : 'No plan allocated hours'} ${values.allocated_hrs ? `, Usable hours: ${values.allocated_hrs}` : 'No usable hours'} ${values.sc_fund === 'ndia-managed' ? `, Service Booking Number: ${values.rate_booking_number}, Service Booking Amount: ${values.rate_booking_amount}` : ''},
               Implementation Report: ${values.is_report_done}, Progess Review: ${values.is_review_done}, Support Item of the Period: ${currentCategoryLabels}, Participant Booking Rate Value: ${values2.rate_set_value}
              , TBC '${values.is_tba}'
              `)
            trigger.addClientFunder(
              clientId,
              `${clientName}: ${funderName}`,
              funderName,
              values2,
              [
                { key: 'start_date' },
                { key: 'end_date' },
                { key: 'cmsc_first_name', label: 'Planner Name' },
                { key: 'cmsc_org', label: 'Planner Organization' },
                { key: 'cmsc_contact', label: 'Planner Contact' },
                { key: 'cmsc_email', label: 'Planner Email' },
                { key: 'plan_allocated_hrs', label: 'Plan Allocated Hours' },
                { key: 'allocated_hrs', label: 'Usable Hours' },
                { key: 'is_tba', label: 'TBC' },
                { key: 'is_report_done', label: 'Implementation Report' },
                { key: 'is_review_done', label: 'Progress Report' },
                { key: 'rate_set_value', label: 'Participant Booking Rate Value' },
                { key: 'rate_booking_number', label: 'Service Booking Number' },
                { key: 'rate_booking_amount', label: 'Service Booking Amount' }
              ],
              extraContent
            )
            notify.success('Saved successfully', 'Funding added successfully.')

            this.setState({ loadingForm: false, shouldRefreshFiles: true, isSavingPeriod: false })

            this.fetchFunding()
            this.hidePeriodModal()
            this.props.setRefreshActivityLog(true)
            this.props.setRefreshFiles(true)
            onUpdateInfo()
          } catch (e) {
            try {
              const { response } = e

              notify.error('Unable to save successfully', response.error_message)
              this.setState({ loadingForm: false })
            } catch (e) {
              console.error(e)
            }
          }
        } else {
          // console.log('item 2', selectedItem.id, values)

          // if (values.fund_manager_id !== selectedItem.fund_manager_id) {
          //   extraLogs.push(`Plan Manager from "${selectedItem.fund_manager}" to "${fundmanagerName}"`)
          // }

          if (values.sc_fund !== selectedItem.sc_fund) {
            const prevSCFund = await ClientSCFund.find((sc) => sc.value === selectedItem.sc_fund)
            extraLogs.push(` SC Fund from "${prevSCFund ? prevSCFund.label : ''}" to "${scfundName}"`)
          }

          response = await clientFundingPeriodService.save(selectedItem.id, values)
          notify.success('Saved successfully', 'Funding saved successfully.')

          log.updateClientFundingPeriod(
            clientId,
            selectedItem,
            values2,
            ['fund_manager', 'funding_id', 'id', 'category', 'rate_set_label', 'sc_fund'],
            extraLogs.join(),
            [
              { key: 'cmsc_first_name', label: 'Planner Name' },
              // { key: 'cmsc_last_name', label: 'Planner Last Name' },
              { key: 'cmsc_org', label: 'Planner Organization' },
              { key: 'cmsc_contact', label: 'Planner Contact' },
              { key: 'cmsc_email', label: 'Planner Email' },
              { key: 'plan_allocated_hrs', label: 'Plan Allocated Hours' },
              { key: 'allocated_hrs', label: 'Usable Hours' },
              { key: 'is_tba', label: 'TBC' },
              { key: 'is_report_done', label: 'Implementation Report' },
              { key: 'is_review_done', label: 'Progress Report' },
              { key: 'rate_set_value', label: 'Participant Booking Rate Value' },
              { key: 'rate_booking_number', label: 'Service Booking Number' },
              { key: 'rate_booking_amount', label: 'Service Booking Amount' }
            ]
          )
          trigger.updateClientFunder(
            clientId,
            `${clientName}: ${funderName}`,
            selectedItem,
            values2,
            [
              { key: 'start_date' },
              { key: 'end_date' },
              // { key: 'km_max' },
              { key: 'cmsc_first_name', label: 'Planner Name' },
              // { key: 'cmsc_last_name', label: 'Planner Last Name' },
              { key: 'cmsc_org', label: 'Planner Organization' },
              { key: 'cmsc_contact', label: 'Planner Contact' },
              { key: 'cmsc_email', label: 'Planner Email' },
              { key: 'plan_allocated_hrs', label: 'Plan Allocated Hours' },
              { key: 'allocated_hrs', label: 'Usable Hours' },
              { key: 'is_tba', label: 'TBC' },
              // { key: 'km_restrict', label: 'No Restriction' }
              { key: 'is_report_done', label: 'Implementation Report' },
              { key: 'is_review_done', label: 'Progress Report' },
              // { key: 'rate_set_label', label: 'Participant Booking Rate Label'},
              { key: 'rate_set_value', label: 'Participant Booking Rate Value' },
              { key: 'rate_booking_number', label: 'Service Booking Number' },
              { key: 'rate_booking_amount', label: 'Service Booking Amount' }
            ],
            extraLogs.join()
          )
        }

        this.setState({ loadingForm: false, shouldRefreshFiles: true, isSavingPeriod: false })

        this.fetchFunding()
        this.hidePeriodModal()
        this.props.setRefreshActivityLog(true)
        this.props.setRefreshFiles(true)
        onUpdateInfo()
      } else {
        console.log('Errors', errors)
      }
    })
  }

  handleChangeCategory = (e) => {
    const { form } = this.props
    const { setFieldsValue } = form

    setFieldsValue({ rate_set_value: 0.0, rate_set_label: '' })
    this.setState({rateSet: {}})
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  async getDefaultRateSet () {
    const { form } = this.props
    const { getFieldsValue, setFieldsValue } = form
    const { rateSet } = this.state

    let errMsg = []
    let rs = rateSet

    this.setState({ rateSetErrorMsg: [], isGetRateSet: true })
    const { category, start_date, end_date } = getFieldsValue()
    if (!category) {
      errMsg = ['Please select Support Item.']
    } else {
      const startDate = start_date && moment.isMoment(start_date) ? start_date.format('YYYY-MM-DD') : 'null'
      const endDate = end_date && moment.isMoment(end_date) ? end_date.format('YYYY-MM-DD') : 'null'
      const s = await clientFundingPeriodService.getDefaultRateSet(category, startDate, endDate)

      if (s) {
        if (s.rate_set && s.rate_set.category_id) {
          rs = s.rate_set
        }

        if (s.error_message && validator.isArray(s.error_message)) {
          errMsg = s.error_message
        }
      }
    }

    this.setState({ rateSetErrorMsg: errMsg, isGetRateSet: false, rateSet: rs }, () => {
      if (rs && rs.category_id) {
        setFieldsValue({ rate_set_value: rs.value, rate_set_label: rs.label })
      }
    })
  }

  showAddFileModal = (isShowFileModal, type) => {
    const { mainCats, subCats, listFile, selectedItem } = this.state
    // console.log('mainCats', mainCats)
    // console.log('subCats', subCats)
    // console.log('selectedItem', selectedItem)
    const currentSubCats = subCats.filter(e => e.file_sub_code === type)
    // console.log('currentSubCats', currentSubCats)
    const file = listFile.find(e => e.type === type) || {}

    if (isShowFileModal) {
      const mainCatId = validator.isNotEmptyArray(mainCats) ? mainCats[0].id : null
      const subCatId = validator.isNotEmptyArray(currentSubCats) ? currentSubCats[0].id : null
      this.setState({
        currentSubCats,
        isDirectNDIS: true,
        isShowFileModal,
        modalFileItem: { ...file, type, main_category_id: mainCatId, sub_category_id: subCatId, is_associate_plan_period: true, linked_id: selectedItem ? selectedItem.id : null }
      })
    } else {
      this.setState({
        currentSubCats: [],
        isDirectNDIS: false,
        isShowFileModal,
        modalFileItem: {}
      })
    }
  }

  updateFile = (values) => {
    const { form } = this.props
    // console.log('values', values)
    this.showAddFileModal(false)
    const { listFile, listFileErrMsg, selectedItem } = this.state
    // console.log('selectedItem', selectedItem)
    // console.log('listFile before', listFile)
    const idx = selectedItem ? (selectedItem.list_files.findIndex(e => e.type === values.type)) : null
    const currentFlag = form.getFieldValue(values.type)

    if (idx > -1) {
      listFile.splice(idx, 1, values)

      if (currentFlag === true) {
        listFileErrMsg.push(values.type)
      }
    } else {
      listFile.push(values)

      const errIdx = listFileErrMsg.findIndex(e => e === values.type)
      if (errIdx > -1) {
        listFileErrMsg.splice(errIdx, 1)
      }
    }

    // Check if NDIS Plan uploaded then only update state
    if (values.type === 'ppt_ndis_plan') {
      this.setState({ hasNDISPlan: true })
    }
    // Check if NDIS Service Agreement uploaded then only update state
    if (values.type === 'ppt_service_agreement') {
      this.setState({ hasNDISSA: true })
    }

    console.log('listFile after ', listFile)
    this.setState({ listFile, listFileErrMsg })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog,
  setRefreshFiles
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientFunder))
