import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'
import { apiHostname } from '../../../config'

// UI
import { List, Page, Button } from '../../../components'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import './styles.css'
import { color } from 'jimp'

export class ReportClientBalanceHour extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      loadingExport: false,
      loadingSpin: false,
      balanceList: { list: [], total: 0 },
      sort: {}
    }
  }

  componentDidMount () {
    const { sort } = this.state
    this.fetchClientFunding({ sort })
  }

  handleSort = (key, order) => {
    const sort = order === 0 ? {} : { [key]: order }
    this.fetchClientFunding({ sort })
    this.setState({ sort, loadingSpin: true })
  }

  render () {
    const { balanceList, loading, loadingExport, loadingSpin, sort } = this.state

    const columns = [
      {
        title: 'Participant',
        width: 6,
        key: 'client_first_name',
        render: ({ client_id: id, client_first_name, client_last_name }) => <a href={`/clients/${id}`} target='_blank'>{`${client_first_name} ${client_last_name}`}</a>,
        onSort: (key, order) => this.handleSort(key, order)
      },

      // {
      //   title: 'Support Item',
      //   width: 5,
      //   render: ({ category_name }) => category_name
      // },

      {
        title: 'Plan Allocated Hours',
        width: 2,
        key: 'plan_allocated_hrs',
        render: ({ plan_allocated_hrs }) => formatter.toDecimalOptional(plan_allocated_hrs) || 0,
        onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Usable Hours',
        width: 2,
        key: 'usable_hours',
        render: ({ usable_hours, is_tba }) => is_tba ? 'TBC' : formatter.toDecimalOptional(usable_hours) || 0,
        onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Used SC Hours',
        width: 2,
        key: 'total_hours_percentage',
        render: ({ total_hours, total_hours_percentage }) => <div>
          <div>{formatter.toDecimalOptional(total_hours) || 0}</div>
          <div> ({formatter.toPercentage(total_hours_percentage)})</div></div>,
        onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Remaining SC Hours',
        width: 2,
        key: 'remaining_hours_percentage',
        render: ({ remaining_hours, remaining_hours_percentage, is_tba }) => is_tba ? (<div>
          TBC
        </div>)
          : <div style={(remaining_hours_percentage) <= 25 && remaining_hours_percentage > 1
            ? { color: 'orange', fontWeight: 'bold' }
            : remaining_hours_percentage <= 0
              ? { color: 'red', fontWeight: 'bold' }
              : {}}
          >
            <div>{formatter.toDecimalOptional(remaining_hours) || 0}</div>
            <div>({formatter.toPercentage(remaining_hours_percentage)})</div></div>,
        onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Remaining Plan Days',
        width: 3,
        key: 'plan_days_remaining',
        render: ({ plan_days_remaining, plan_days_remaining_percentage, plan_end_date }) => <div style={plan_days_remaining <= 60 && plan_days_remaining > 0
          ? { color: 'orange', fontWeight: 'bold' }
          : plan_days_remaining <= 0
            ? { color: 'red', fontWeight: 'bold' }
            : {}}
        >
          {plan_days_remaining < 0
            ? <span>-{formatter.duration(plan_end_date)} days<br />(OVER)</span>
            : <span>{plan_days_remaining + ' days' || ''}<br />({formatter.toPercentage(plan_days_remaining_percentage)})</span>
          }
        </div>,
        onSort: (key, order) => this.handleSort(key, order)
      },

      // {
      //   title: 'TBC',
      //   width: 1,
      //   key: 'is_tba',
      //   render: ({ is_tba: isTba }) => {
      //     return (
      //       isTba ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      //         : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      //     )
      //   },
      //   onSort: (key, order) => this.handleSort(key, order)
      // },

      {
        title: 'Plan Starts On',
        width: 3,
        key: 'plan_start_date',
        render: ({ plan_start_date }) => formatter.toShortDate(plan_start_date),
        onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Plan Ends On',
        width: 3,
        key: 'plan_end_date',
        render: ({ plan_end_date }) => `${formatter.toShortDate(plan_end_date)}`,
        onSort: (key, order) => this.handleSort(key, order)
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Participants' Plan Details`}>
            { !loading
              ? <Button key={'export'} type='primary' feedback={loadingExport} onClick={() => this.exportList()}>
                Export
              </Button>
              : null}
          </Page.Header>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <Spin size='large' spinning={loadingSpin}>
                {/* <div className='report-title'>{`Participants SC Hour Balance`} <span className='report-tag'>{balanceList.total}</span></div> */}
                <List cols={columns} rows={balanceList.list} defaultSort={sort} />
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchClientFunding ({ sort = {} }) {
    this.setState({ loading: true })
    const filter = {}

    filter.active = { condition: '=', value: true }
    // const balance = await reportService.get('client-funding-hour-balance')
    const balance = await reportService.getPlanDetails(1, 5000, filter, sort, '')

    this.setState({ balanceList: balance, loading: false, loadingSpin: false })
  }

  async exportList () {
    this.setState({ loadingExport: true })

    window.location.href = `${apiHostname}/api/export/report/client-plan-details`
    setTimeout(() => {
      this.setState({ loadingExport: false })
    }, 4000)
  }
}

export default ReportClientBalanceHour
