import React, { Component } from 'react'

// UI
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Popconfirm from 'antd/lib/popconfirm'

import './styles.css'

export class SettingBillingRateRow extends Component {
  constructor (props) {
    super(props)
  }

  onChangeValue (day, value) {
    const { item } = this.props

    this.props.onChange(item.id, day, value)
    return value
  }

  onChangeLabel (day, value) {
    const { item } = this.props

    this.props.onChangeLabel(item.id, day, value)
    return value
  }

  render () {
    const { form, item, onDelete, setId } = this.props
    const { getFieldDecorator } = form
    const { canDelete = true } = item

    const rateProps = (field, value = '', index) => (getFieldDecorator(`${field}-${setId}-${item.id}-${index}`, {
      getValueFromEvent: (e) => this.onChangeValue(field, e.target.value),
      initialValue: value
    })(
      <Input key={field} className='billing-rate-set-field' placeholder='0.00' />
    ))

    const labelProps = (field, value = '', index) => (getFieldDecorator(`${field}-label-${setId}-${item.id}-${index}`, {
      getValueFromEvent: (e) => this.onChangeLabel(field, e.target.value),
      initialValue: value
    })(
      <Input className='billing-rate-set-field' placeholder='Label' />
    ))

    const rates = item.rates && Array.isArray(item.rates) ? item.rates.filter(r => r.day && r.day.indexOf('c') > -1).sort((x, y) => x.day - y.day) : []

    return (
      <div style={{ margin: '20px 0' }}>
        <Row gutter={10}>
          <Col lg={18}>
            <div className='billing-rate-category-name'>
              { canDelete ? <Popconfirm
                title='Confirm to remove this?'
                onConfirm={(e) => onDelete(item.id)}
                okText='Yes'
                cancelText='No'
                placement='left'
              >
                <div className='billing-rate-remove-row'><Icon type='delete' /></div>
              </Popconfirm>
                : null
              }
              { item.name }
            </div>
          </Col>

        </Row>
        <Row gutter={10} style={{marginTop: '12px'}}>
          <Col md={4} lg={4} style={{ fontSize: '8.5pt', fontWeight: 'bold', marginTop: '6px' }}>
            Service Booking Rate
          </Col>

          <Col md={20} lg={20}>
            <Row gutter={4}>
              { rates.map((rate, index) => {
                return index === 0
                  ? <Col md={4} lg={4} key={index}>
                    {rateProps(rate.day, rate.value, index)}
                  </Col>
                  : null
              }) }
              { rates.map((rate, index) => {
                return index === 0
                  ? <Col md={5} lg={5} key={index} style={{marginLeft: '20px'}}>
                    {labelProps(rate.day, rate.label, index)}
                  </Col>
                  : null
              }) }
            </Row>

          </Col>
        </Row>
      </div>
    )
  }
}

export default (Form.create()(SettingBillingRateRow))
