import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingReasonService } from '../../../../../services/setting'
import { authService } from '../../../../../services'

// UI
import { Button, Page, Panel, SideModal } from '../../../../../components'
import Empty from 'antd/lib/empty'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../../components/Notification'

import {formatter} from '../../../../../util'
import setting from '../../../../../constants/menu/setting'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Resigned/Exited Reasons'
const settingType = 'Resigned/Exited Reasons'

export class SettingResignedExitedPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchResignedExited()
    }
  }

  fetchResignedExited = async () => {
    try {
      const { match } = this.props
      const { params: { id } } = match
      this.setState({ loading: true })
      const settings = await settingReasonService.get(id)
      if (settings.id) {
        this.setState({ list: settings.list, loading: false, name: settings.name })
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSaveSet = async () => {
    const { match, history } = this.props
    const { params: { id } } = match

    const { form } = this.props
    const { validateFields } = form

    validateFields(['setName'], async (errors, values) => {
      values.name = values.setName
      delete values.setName

      if (this.isEdit()) {
        const result = await settingReasonService.saveSet(id, values)
        if (result) {
          notify.success('Saved successfully', `${settingTitle} saved successfully.`)
        } else {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
        }
      } else {
        const response = await settingReasonService.addSet(values)
        if (response) {
          const { id } = response
          notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          history.replace(`/settings/resigned-or-exited/${id}`)
        } else {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
        }
      }
    })
  }

  handleSave = () => {
    const { form, match } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    const { params: { id: setId } } = match

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        this.setState({ loadingForm: true })

        delete values.setName

        try {
          let response

          if (id) {
            response = await settingReasonService.save(id, values)
          } else {
            values.reason_id = setId
            response = await settingReasonService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
            this.fetchResignedExited()
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, name, date) {
    this.setState({ selectedItem: { id, name, date } })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await settingReasonService.remove(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchResignedExited({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, name, selectedItem } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={name || 'Resigned/Exited'}>
            { this.hasAccess('createCancellation') ? <div className='btn' onClick={() => this.handleSaveSet()}>
              Save
            </div> : null }
            <Link to={`/settings/reasons?t=resigned`}>
              <div className='btn'>
                Back
              </div>
            </Link>
          </Page.Header>

          <div className='setting-list'>

            <Skeleton loading={loading} active>
              <Row className='holiday-panel'>
                <Col lg={20}>
                  <FormItem label='Type' {...formItemLayout}>
                    {getFieldDecorator('setName', {
                      initialValue: name,
                      rules: [
                        { min: 2, message: 'Name must be between 2 and 128 characters' },
                        { max: 128, message: 'Name must be between 2 and 128 characters' },
                        { required: true, message: 'Please enter name' },
                        { whitespace: true, message: 'Please enter name' }
                      ]
                    })(
                      <Input />
                    )}
                  </FormItem>
                </Col>
              </Row>
              { this.isEdit()
                ? <div>
                  <Row>
                    <Col lg={16}>
                    </Col>
                    <Col lg={8} className='holiday-title-action'>
                      { this.hasAccess('createCancellation') ? <div className='btn' style={{ width: '120px', float: 'right' }} onClick={() => this.showModal()}>
                        Add Reason
                      </div> : null }
                    </Col>
                  </Row>
                  <Row className='list-header'>
                    <Col md={20}>Reason</Col>
                  </Row>
                  {list.map((item) => {
                    const { id, name, date } = item

                    return (name
                      ? <div className='list-item' key={id}>
                        <Row className='list-content'>
                          <Col md={22}>{ name }</Col>
                          <Col md={1}>
                            { this.hasAccess('updateCancellation') ? <div onClick={() => this.handleEdit(id, name, date)}><Icon type='form' /></div> : null }
                          </Col>
                          <Col md={1}>
                            { this.hasAccess('deleteCancellation') ? <Popconfirm
                              title='Confirm to delete this?'
                              onConfirm={(e) => this.handleDelete(id)}
                              okText='Yes'
                              cancelText='No'
                            ><Icon type='delete' />
                            </Popconfirm> : null }
                          </Col>
                        </Row>
                      </div> : null
                    )
                  })}
                  {
                    list && list[0] && !list[0].name ? <Empty description={
                      <span>
                        No reason yet, <a onClick={() => this.showModal()}>create one</a>?
                      </span>
                    } /> : null
                  }
                </div>
                : null }
            </Skeleton>
          </div>

        </Page.Content>

        <SideModal
          title={settingTitle}
          showModal={modal.show}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Reason'>
              {getFieldDecorator('name', {
                initialValue: selectedItem.name,
                rules: [
                  { min: 2, message: 'Reason must be between 2 and 128 characters' },
                  { max: 128, message: 'Reason must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter reason' },
                  { whitespace: true, message: 'Please enter reason' }
                ]
              })(
                <Input />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingResignedExitedPage))
