import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchTimesheets } from '../../states/actions'
import { formatter, localStorage } from '../../util'
import { payrollService } from '../../services'

// UI
import { List } from '../../components'
import notify from '../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'

const pageSize = 2000

export class PayrollPrint extends Component {
  constructor (props) {
    super(props)
    const { payrolls: { list, total }, loading } = props
    this.state = {
      employeeIds: [],
      currentPage: 1,
      filter: {},
      list,
      loading,
      sort: {},
      total
    }
  }

  componentDidMount () {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = { id }
    const { currentPage, loading, searchText, sort } = this.state
    this.fetchTimesheetSummary(id)
    this.fetchTimesheets({ currentPage, filter, loading, searchText, sort })
    this.setState({ filter, loading: true })
  }

  render () {
    const { payroll = {}, summary = {}, loading } = this.state
    const { date_from, date_to, employees = 0, clients = 0, jobs = 0, kms = 0, hours = 0, sleepovers = 0, approved = 0, review = 0 } = summary

    const columns = [
      this.isEmployeeView() ? {
        title: 'Employees',
        width: 9,
        render: ({ employee }) => <span style={{ fontWeight: 'bold' }}><Icon type='user' /> {employee}</span>
      } : {
        title: 'Participants',
        width: 9,
        render: ({ client }) => <span style={{ fontWeight: 'bold' }}><Icon type='user' /> {client}</span>
      },
      {
        title: 'Accounting Ref',
        width: 5,
        key: 'acc_ref'
      },
      {
        title: 'Jobs',
        width: 2,
        render: ({ jobs }) => <div style={{ color: '#1a63b0' }}>{jobs}</div>
      },
      {
        title: 'Approved',
        width: 2,
        render: ({ approved }) => <div style={{ color: approved > 0 ? '#4fbc85' : '#aaa' }}>{approved}</div>
      },
      {
        title: 'To Review',
        width: 3,
        render: ({ review }) => <div style={{ color: review > 0 ? '#f50' : '#aaa' }}>{review}</div>
      },
      {
        title: 'Hours',
        width: 3,
        render: ({ hours }) => formatter.toDecimal(hours) || 0
      },
      // {
      //   title: 'Sleepover',
      //   width: 2,
      //   render: ({ sleepovers }) => (sleepovers || 0)
      // },
      // {
      //   title: 'KMs',
      //   width: 1,
      //   key: 'kms'
      // }
    ]

    return (
      <div className='payroll-print'>
        <Skeleton loading={loading} active>
          <div className='title'>Timesheet ( {formatter.toShortDate(date_from)} - {formatter.toShortDate(date_to)} )</div>
          <div className='page-summary' style={{ width: '99%' }}>
            <Row>
              {this.isEmployeeView() ? <Col sm={6} lg={6} className='col'>
                  <div className='number'>{employees}</div>
                  Employees
                </Col> : <Col sm={6} lg={6} className='col'>
                  <div className='number'>{clients}</div>
                  Participants
                </Col>}
              <Col sm={4} lg={4} className='col'>
                <div className='number'>{jobs}</div>
                Jobs
              </Col>
              <Col sm={4} lg={4} className='col'>
                <div className='number'>{approved}</div>
                Approved
              </Col>
              <Col sm={4} lg={4} className='col'>
                <div className='number'>{review}</div>
                To Review
              </Col>
              <Col sm={4} lg={4} className='col'>
                <div className='number'>{formatter.toDecimal(hours)}</div>
                Hours
              </Col>
              {/* <Col sm={3} lg={3} className='col'>
                <div className='number'>{sleepovers}</div>
                Sleepovers
              </Col>
              <Col sm={2} lg={2} className='col'>
                <div className='number'>{kms}</div>
                KMs
              </Col> */}
            </Row>
          </div>
          <List cols={columns} rows={payroll.list || []} />
        </Skeleton>
      </div>
    )
  }

  fetchTimesheets = async ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const payroll = await payrollService.listTimesheetByPage(currentPage, pageSize, filter, sort, searchText, this.getTimesheetView())
      this.setState({ payroll, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  fetchTimesheetSummary = async (id) => {
    try {
      const summary = await payrollService.getTimesheetSummary(id, this.getTimesheetView())
      this.setState({ summary, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  getTimesheetView () {
    return localStorage.getItem('timesheetView') || 'employee'
  }

  isEmployeeView () {
    return this.getTimesheetView() === 'employee'
  }
}

const mapDispatchToProps = {
  fetchTimesheets
}

const mapStateToProps = (state) => {
  return { ...state.Payroll }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayrollPrint)
