import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/clients'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateFunder (id, values) {
    return request(`${url}/${id}/funder`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listDetailsByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageAddress (page, size, filter, sorting, text) {
    return request(`${url}/map/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageActive (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}/active${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageNotification (page, size, filter, sorting, text) {
    return request(`${url}/notification/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listByPageFunder (page, size, filter, sorting, text) {
    return request(`${url}/funder/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getSuburbs () {
    return request(`${url}/suburbs`)
  },
  getClientIds () {
    return request(`${url}/ids`)
  },
  getClientEmployees (id) {
    return request(`${url}/${id}/employees/active`)
  },
  getClientPrevEmployees (id) {
    return request(`${url}/${id}/employees/prev`)
  },
  getClientPrevEmployeesActive (id) {
    return request(`${url}/${id}/employees/prev/active`)
  },
  getClientBalanceHours (id, date) {
    return request(`${url}/funding-balance/${id}/${date}`)
  },
  getClientFunders (id) {
    return request(`${url}/${id}/funders`)
  },
  getClientFundersInfo (id, fid) {
    return request(`${url}/${id}/funder/${fid}`)
  },
  getClientFundersByDate (id, startD, endD) {
    return request(`${url}/${id}/funder/startD/${startD}`)
  },
  getClientFunderSetting (id) {
    return request(`${url}/${id}/funders/setting`)
  },
  addClientFunderSetting (clientId, values) {
    return request(`${url}/${clientId}/funders/setting`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveClientFunderSetting (clientId, id, values) {
    return request(`${url}/${clientId}/funders/setting/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeClientFunderSetting (clientId, id) {
    return request(`${url}/${clientId}/funders/setting/${id}`, {
      method: 'DELETE'
    })
  },
  getClientFundingPeriod (id) {
    return request(`${url}/funding-period/${id}`)
  },
  getClientFunding (id) {
    return request(`${url}/funding-client/${id}`)
  },
  getClientFundingAll (id) {
    return request(`${url}/funding-client-plans/${id}`)
  },
  getClientFundingByStartD (id, startD) {
    return request(`${url}/funding-client/${id}/startD/${startD}`)
  },
  sendJobList (id, from, to) {
    return request(`${url}/${id}/jobs/${from}/${to}`)
  },
  getClientAvailableProviderList (clientId) {
    return request(`${url}/${clientId}/providers/available`)
  },
  getClientProviderInList (clientId) {
    return request(`${url}/${clientId}/providers`)
  },
  getClientProviderListExclude (clientId) {
    return request(`${url}/${clientId}/providers/exclude`)
  },
  addClientProvider (clientId, values) {
    return request(`${url}/${clientId}/providers`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  updateClientProvider (clientId, listId, values) {
    return request(`${url}/${clientId}/${listId}/providers`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeClientProvider (clientId, values) {
    return request(`${url}/${clientId}/providers`, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(values)
    })
  },
  checkDuplicateClient (values) {
    return request(`${url}/check/duplicate${queryString.stringify(values)}`)
  },
  checkDuplicateNDISNum (values) {
    return request(`${url}/check/duplicateNDISNum${queryString.stringify(values)}`)
  }
}
