import { FETCHING_EMPLOYEES, EMPLOYEES_FETCHED } from '../actions/employee'

const INITIAL_STATE = {
  employees: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_EMPLOYEES:
      return handleFetchingEmployees(state, action)
    case EMPLOYEES_FETCHED:
      return handleEmployeesFetched(state, action)
    default:
      return state
  }
}

function handleFetchingEmployees (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleEmployeesFetched (state, action) {
  const { employees, loading } = action
  return { ...state, employees, loading }
}
