import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jobService, settingHolidayService } from '../../../../services'
import { fetchingClients } from '../../../../states/actions/client'
import { formatter, log } from '../../../../util'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { apiHostname } from '../../../../config'

// UI
import { Loading, List, Page, Pager, SideModal } from '../../../../components'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const pageSize = 50
export class HolidayImpactedJob extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      funders: [],
      item: {},
      dayTime: '',
      taskDetail: '',
      jobs: { list: [], total: 0 },
      totalReview: 0,
      loading: false,
      clientId: '',
      employeeInfo: '',
      startDay: '',
      endDay: '',
      memoInfo: '',
      payrolls: [],
      languages: [],
      skills: [],
      modal: {
        item: { link: {} },
        show: false
      },
      editLeave: {
        isEdit: false
      },
      modalShow: false,
      lastJobs: ''
    }
  }

  componentDidMount () {
    const { currentPage, filter, loading, sort } = this.state
    this.fetchHolidayJob({ currentPage, filter, loading, sort })
  }

  render () {
    const { form, match } = this.props
    const { getFieldDecorator } = form
    const { jobs, loading, total, currentPage, lastJobs, totalReview } = this.state
    const { params } = match
    const { id } = params
    const info = jobs.list.length > 0 ? {
      firstName: jobs.list[0].first_name,
      lastName: jobs.list[0].last_name,
      gender: jobs.list[0].gender,
      leaveStart: jobs.list[0].leave_start,
      leaveEnd: jobs.list[0].leave_end
    } : {}

    const columns = [
      {
        title: '',
        width: 2,
        render: ({ emergency, cancellation_penalty }) => (<div className='indicator-list'> { emergency ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>E</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} /> }
          { cancellation_penalty > 0 ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} /> }
        </div>)
      },
      {
        title: 'Participant',
        width: 5,
        key: 'client'
      },
      {
        title: 'Day',
        width: 2,
        render: ({ job_start_date: startDate }) => startDate ? moment(startDate).format('dddd') : '-'
      },

      {
        title: 'Start Date',
        width: 3,
        render: ({ job_start_date }) => formatter.toShortDate(job_start_date)
      },
      {
        title: 'End Date',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortDate(job_end_date)
      },
      {
        title: 'Start Time',
        width: 3,
        render: ({ job_start_date }) => formatter.toShortTime(job_start_date)
      },
      {
        title: 'End Time',
        width: 3,
        render: ({ job_end_date }) => formatter.toShortTime(job_end_date)
      },
      {
        title: 'Status',
        width: 2,
        render: ({ job_id, job_leave_status, is_cancel }) => <div style={{ color: '#ccc', fontSize: '12pt' }}>
          { is_cancel ? <div><Icon type='check-circle' /> &nbsp;<Tooltip mouseLeaveDelay={0} title='Cancelled'><span style={{ color: '#ff5b5b' }}><Icon type='close-circle' theme='filled' /></span></Tooltip></div> : <div>
            <Tooltip mouseLeaveDelay={0} title='Keep?'>{ job_leave_status === 'approved' ? <span style={{ color: '#4fbc85' }}><Icon type='check-circle' theme='filled' /></span> : <Icon type='check-circle' onClick={() => this.saveStatus(job_id, 'approved')} /> }</Tooltip> &nbsp;
            <Tooltip mouseLeaveDelay={0} title='To review?'>{ job_leave_status !== 'approved' ? <span style={{ color: '#f2b018' }}><Icon type='exclamation-circle' theme='filled' /></span> : <Icon type='exclamation-circle' onClick={() => this.saveStatus(job_id, 'review')} /> }</Tooltip></div> }
        </div>
      },
      {
        title: '',
        width: 1,
        render: ({ job_id }) => <div className='action-buttons' style={{ textAlign: 'right', width: '40px', marginLeft: '20px' }}>
          <Tooltip mouseLeaveDelay={0} title='View'> <Link to={`/jobs/single/${job_id}`}><Icon type='eye' /></Link></Tooltip>
        </div>
      }
    ]

    return (
      <Page.Body className='impacted'>
        <Page.Left>
          <Page.Menu title='Public Holidays' backLink={`/notification`} panel={<div style={{ textAlign: 'center', padding: '20px 0' }}>
            <div className='employee'>
              {
                lastJobs.holiday_name
              }
            </div>
          </div>} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={`${totalReview} Jobs To Action: Public Holiday (${lastJobs.holiday_name}) On ${formatter.toShortDate(lastJobs.date)}`}>
            <div className='btn' onClick={() => this.handleExport()}>
              Export
            </div>
          </Page.Header>
          <Loading loading={loading} blur>
            <Skeleton loading={loading} active>
              <List cols={columns} rows={jobs.list} />
            </Skeleton>
            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} jobs`}
              current={currentPage}
              onChange={(e) => this.changePage(e)}
              style={{ marginTop: '15px' }}
            />
          </Loading>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchHolidayJob = async ({ startPage = null, currentPage = 1, filter = {}, sort = {} }) => {
    this.setState({ loading: true })
    const { match } = this.props
    const { params } = match
    const { id } = params
    const sorting = { job_start_date: -1, client: 1 }

    const page = startPage || currentPage

    filter.id = { condition: '=', value: id }

    const jobs = await settingHolidayService.listImpactedJobByPage(page, pageSize, filter, sorting)

    const lastJobs = jobs.list[jobs.list.length - 1]
    this.fetchTotalReview()

    this.setState({ jobs, total: jobs.total, loading: false, currentPage: page, filter, sorting, lastJobs })
  }

  fetchTotalReview = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    let filter = {}
    filter.job_leave_status = 'review'
    filter.is_cancel = false
    filter.id = id
    const { total } = await settingHolidayService.listImpactedJobByPage(1, 1, filter)
    this.setState({ totalReview: total })
  }

  changePage = (currentPage) => {
    const { filter, sort } = this.state
    this.fetchHolidayJob({ currentPage, filter, sort })
  }

  handleExport = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    window.location.href = `${apiHostname}/api/export/jobs-to-action/holiday/${id}`
  }

  async saveStatus (id, value) {
    const { jobs } = this.state
    const result = await jobService.save(id, { job_leave_status: value })

    if (result.id) {
      // get new to action number

      this.fetchTotalReview()
      for (let i = 0; i < jobs.list.length; i++) {
        const job = jobs.list[i]
        if (job.job_id === id) {
          job.job_leave_status = value
          break
        }
      }
    }

    this.setState({ jobs })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(HolidayImpactedJob))
