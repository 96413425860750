import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { fetchFutureJobs } from '../../../states/actions/futureJob'
import { JobMenu } from '../../../constants'
import formatter from '../../../util/formatter'
import moment from 'moment'
import AddJobModal from '../AddJobModal'
import { authService } from '../../../services'
import { apiHostname } from '../../../config'

// UI
import { Page, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

const pageSize = 20
const { RangePicker } = DatePicker

export class IndividualFuture extends Component {
  constructor (props) {
    super(props)
    const { jobs: { list, total }, loading } = props
    this.state = {
      jobIds: [],
      currentPage: 1,
      filter: {},
      isGenerating: false,
      list,
      loading,
      searching: false,
      searchText: '',
      showJobModal: false,
      showEndDate: true,
      showExportDate: false,
      sort: {},
      jobStatus: '',
      total
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobs: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const currUrl = this.props.match.url
    const { currentPage, filter, loading, searchText, sort } = this.state
    if (currUrl === '/jobs/all') {
      this.fetchFutureJobs({ currentPage, filter, loading, searchText, sort, jobStatus: 'all' })
      this.setState({ jobStatus: 'all' })
    } else {
      this.fetchFutureJobs({ currentPage, filter, loading, searchText, sort, jobStatus: 'cancel' })
      this.setState({ jobStatus: 'cancel' })
    }

    if (window) {
      window.scrollTo(0, 0)
    }
  }

  /** Search by date currently only search using job_start_date */
  onSearchName (value) {
    const { filter, loading, sort, jobStatus } = this.state
    this.setState({ searching: true })

    if (value && value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_firstname: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_lastname: { condition: 'ilike', value: `%${words[i]}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_start_date: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }

    this.fetchFutureJobs({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort, jobStatus })
    this.setState({ searchText: value, currentPage: 1 })
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  onClickRecurring = () => {
    this.setState({ showEndDate: false })
  }

  onClickSingle = () => {
    this.setState({ showEndDate: true })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  async exportJobList (d1, d2) {
    this.setState({ showExportDate: false, isGenerating: true })

    window.location.href = `${apiHostname}/api/export/jobs/${moment(d2[0]).format('YYYY-MM-DD')}/${moment(d2[1]).format('YYYY-MM-DD')}`
    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 10000)
  }

  render () {
    const { currentPage, isGenerating, list, loading, showExportDate, showJobModal, searching, total, jobStatus } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={JobMenu} backLink='/' countData={this.props.totalPending} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={jobStatus === 'all' ? 'All Jobs' : 'Cancelled Jobs'}>
            {
              this.hasAccess('createJob') ? <div className='btn' onClick={() => this.addJob()}>
              Add Job
              </div>
                : null
            }
              <div>
                <div style={{ display: showExportDate ? 'inline' : 'none' }}>
                  <RangePicker format='YYYY-MM-DD' open={showExportDate} onChange={(d1, d2) => this.exportJobList(d1, d2)} />
                </div>
                <div className='btn' onClick={() => this.setState({ showExportDate: true })}>
                  { isGenerating
                    ? <img src='/icon/button-loading.svg' alt='loading' />
                    : 'Export'}
                </div>
              </div>

          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Participant, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
                <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
            </Row>
          </Page.Filter>

          <div className='jobs'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, base_job_id, job_start_date, job_end_date, job_duration, first_name, last_name, emp_firstname,
                  emp_lastname, suburb, tasks, fund_fullname, client_id, employee_id, funder_id, emergency, cancellation_penalty, sleepover, is_cancel,
                  client_leave_id, client_leave_start, client_leave_end, employee_leave_id, employee_leave_start, employee_leave_end, holiday_date, holiday_name } = item

                  return (
                  <div className={`list-item ${holiday_date ? 'list-item-highlight' : ''}`} key={id}>
                    <Row>
                      <Col lg={1}>
                        {/* { emergency ? <div className='list-indicator emergency'><span>E</span></div> : null }
                        { cancellation_penalty ? <div className='list-indicator late'><span>LC</span></div> : null }
                        { base_job_id > 0 ? <div className='list-indicator recur'><span>R</span></div> : null } */}
                        { is_cancel ? <div className='list-indicator cancelled'><span>C</span></div> : null }
                      </Col>
                      <Col lg={22}>
                        <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                          <Col lg={8}>
                            <Row>
                              <Col lg={8}>
                                <div className='subtitle'>Participant</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a> { client_leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client_leave_start)} - ${formatter.toShortDate(client_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={6}>
                                <div className='subtitle'>Employee</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a> { employee_leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={5}>
                                <div className='subtitle'>Funder</div>
                              </Col>
                              <Col lg={14}>
                                <div className='name'><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a></div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                          <Col md={8}>
                            <div className='detail'>
                              <Icon type='calendar' /> { formatter.toShortDate(job_start_date) } <span className='time-sum'>{moment(job_start_date).format('dddd')}</span>
                            </div>
                          </Col>
                          <Col md={8}>
                            <div className='detail'>
                              <Icon type='clock-circle' /> {(job_duration === null || job_duration === undefined || job_duration === '')
                                ? ''
                                : <span>{Math.floor(job_duration)/60}H ({this.getDurationText(job_duration)})</span>}
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className='detail'><Icon type='environment' /> {suburb}</div>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 5 }}>
                          <Col>
                            <div className='detail-normal'>{ tasks && tasks.length > 300 ? tasks.substr(0, 300) + '...' : tasks }</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={1} className='action-icon'>

                        <Link to={jobStatus === 'all' ? `/jobs/single/${id}` : `/jobs/cancelled/${id}`}>

                          { this.hasAccess('readJob') || this.hasAccess('updateJob') ? <div style={{ color: '#D66E00' }}>
                            <Tooltip mouseLeaveDelay={0}  title='Manage job'>
                              <Icon type='form' />
                            </Tooltip>
                          </div> : null
                          }

                        </Link>
                      </Col>
                    </Row>
                  </div>

                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} jobs`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          <AddJobModal visible={showJobModal} history={this.props.history} onClose={() => this.setState({ showJobModal: false })} />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, jobStatus } = this.state
    this.fetchFutureJobs({ currentPage, filter, searchText, sort, jobStatus })
  }

  fetchFutureJobs = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText, jobStatus }) => {
    try {
      if (Array.isArray(filter.$and) && filter.$and.length) {
        searchText = ''
      }
      const { fetchFutureJobs } = this.props
      this.setState({ currentPage })
      fetchFutureJobs({ loading, currentPage, pageSize, filter, sort, searchText, jobStatus })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load jobs successfully. Please try again later.')
    }
  }

  getDurationText = (duration = 0) => {
    const hour = Math.floor(duration / 60)
    const hourText = 'H'
    const minutes = duration % 60
    const minText = 'M'
    return `${hour}${hourText}${minutes}${minText}`
  }
}

const mapDispatchToProps = {
  fetchFutureJobs
}

const mapStateToProps = (state) => {
  return { ...state.FutureJob }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(IndividualFuture))
