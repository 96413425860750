import React, { Component } from 'react'
import { connect } from 'react-redux'
import { reportService } from '../../../services'
import { formatter, log } from '../../../util'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { apiHostname } from '../../../config'

// UI
import { Loading, List, Page, Pager } from '../../../components'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 30

export class EmployeeActionJob extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      jobs: { data: [], total: 0 },
      loading: false,
    }
  }

  componentDidMount () {
    this.fetchJobs()
  }

  render () {
    const { form, match } = this.props
    const { currentPage, jobs, loading } = this.state
    const { params } = match
    const { client, from, to } = params

    const columns = [
      {
        title: 'Date',
        width: 2,
        render: ({ action_time }) => formatter.toStandardLongDate(action_time)
      },
      {
        title: 'Action',
        width: 2,
        key: 'action',
        render: ({ action }) => <div className='user-action-type' style={action === 'add' ? { background: '#52d9a733', color: '#2ed394' } : action === 'cancel' ? { background: '#e9235a22', color: '#e9235a' } : action === 'uncancel' ? { background: '#ffa50122', color: '#ffa501' } : {}}>{(action || '').toUpperCase()}</div>
      },
      {
        title: 'Job',
        width: 2,
        key: 'type',
        render: ({ type }) => type === 'job' ? 'Individual' : 'Recurring Base'
      },
      {
        title: 'Job Id',
        width: 2,
        key: 'job_id'
      },
      {
        title: 'Job Start',
        width: 2,
        render: ({ type, job_start_date, job_start_time }) => type === 'job' ? moment(job_start_date).format('DD/MM/YYYY hh:mm A') : moment(job_start_time).format('DD/MM/YYYY hh:mm A')
      },
      {
        title: 'Job End',
        width: 2,
        render: ({ action, type, job_end_date, job_end_time, original_end_date }) => type === 'job' ?
          ( action !== 'cancel' ? moment(job_end_date).format('DD/MM/YYYY hh:mm A') : original_end_date ? moment(original_end_date).format('DD/MM/YYYY hh:mm A') : moment(job_end_date).format('DD/MM/YYYY hh:mm A')) :
          moment(job_end_time).format('DD/MM/YYYY hh:mm A')
      },
      {
        title: 'Participant',
        width: 3,
        render: ({ client, client_id }) => <a href={`/clients/${client_id}`} target='_blank'>{client}</a>
      },
      {
        title: 'Employee',
        width: 3,
        render: ({ employee, employee_id }) => <a href={`/employees/${employee_id}`} target='_blank'>{employee}</a>
      },
      {
        title: '',
        width: 1,
        render: ({ type, job_id }) => <div className='action-buttons' style={{ textAlign: 'right', width: '40px', marginLeft: '20px' }}>
          <Tooltip mouseLeaveDelay={0} title='View'> <Link to={`/jobs/${type==='job' ? 'single' : 'recurring'}/${job_id}`}><Icon type='eye' /></Link></Tooltip>
        </div>
      }
    ]

    // backLink={impacted ? '/notifications' : `/employees/${id}`}

    return (
      <Page.Body className='impacted'>
        <Page.Left>
          <Page.Menu title='Report' backLink={`/reports/action`} panel={<div style={{ textAlign: 'center', padding: '20px 0' }}>
            <div className='employee'>
            <img alt='' src={process.env.PUBLIC_URL + '/img/user.png'} className='avatar' />
              <div style={{ fontSize: 14, fontWeight: 'bold', margin: 10 }}>{ client }</div>
              { from } - { to }
            </div>
          </div>} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title={`${jobs.total} User Actions (From ${formatter.toShortDate(from)} to ${formatter.toShortDate(to)})`}>

          </Page.Header>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={jobs.data} />
          </Skeleton>
          <Pager
            size={pageSize}
            total={jobs.total}
            totalText={`Total ${jobs.total} actions`}
            current={currentPage}
            onChange={(e) => this.onChangePage(e)}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  fetchJobs = async (page) => {
    const { match } = this.props
    const { params } = match
    const { client, from, to } = params
    const { currentPage } = this.state
    this.setState({ loading: true })

    const jobs = await reportService.getActionJobs(page || currentPage, pageSize, { user: client, from, to })
    this.setState({ jobs, loading: false })
  }

  onChangePage = (currentPage) => {
    this.fetchJobs(currentPage)
    this.setState({ currentPage })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return { }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeActionJob))
