import React, { Component } from 'react'
import { clientService } from '../../../services'
import { formatter, validator } from '../../../util'
import Moment from 'moment-timezone'

import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)
const dateFormat = 'DD/MM/YYYY'

export class AddJobModal extends Component {
  state = {
    clients: [],
    currentStep: 0,
    funders: [],
    fundingPeriod: [],
    spinLoading: false,
    disableFunder: true
  }

  componentDidMount () {
    const { clientId } = this.props
    if (clientId) {
      this.fetchFunders(clientId)
    }
    this.fetchClients()
  }

  fetchClients = async () => {
    try {
      const clients = await clientService.listByPageActive(1, 0, {}, { first_name: 'asc' })
      this.setState({ clients: clients.list })
    } catch (e) {
      // notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }

  fetchFunders = async (clientId) => {
    try {
      const funders = await clientService.getClientFunders(clientId)
      this.setState({ funders, disableFunder: false })
    } catch (e) {

    }
  }

  onSelectClient = async (value, option) => {
    // const { form } = this.props
    // this.setState({ spinLoading: true })

    // const f = await clientService.getClientFundingPeriod(value)
    // const period = f && validator.isNotEmptyArray(f.item) ? f.item : []
    // this.setState({ fundingPeriod: period, spinLoading: false, disableFunder: false })
    // form.setFieldsValue({ funding_id: null })
  }

  handleNext = () => {
    const { currentStep } = this.state
    this.setState({ currentStep: currentStep === 0 ? 1 : 0 })
  }

  handleSubmit = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { history } = this.props
        const clientId = values.client_id
        // const fundingId = values.funding_id
        // const jobType = values.job_type
        this.setState({ loading: true })

        history.push(`/jobs/single/add?client=${clientId}`)

        this.setState({ loading: false })
      }
    })
  }

  findClients = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findFunders = (input, option) => {
    const funder = `${option.props.children[0]} ${option.props.children[1]} ${option.props.children[1].props.children}`
    return funder.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  render () {
    const { form, visible, onClose, clientId, active } = this.props
    const { clients, currentStep, funders, fundingPeriod, spinLoading, disableFunder } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    return (
      <Modal visible={visible}
        width={700}
        title='New Job'
        onOk={this.handleNext}
        onCancel={onClose}
        footer={[
          <Button key='ok' type='primary' onClick={this.handleSubmit}>Next</Button>
        ]}
      >
        <Steps size='small' current={currentStep}>
          <Step title='Select Participant' />
          <Step title='Click Next to continue' />
        </Steps>
        <div style={{ marginTop: '20px', borderTop: '1px solid #eee', paddingTop: '30px' }}>
          <Form>
            <div style={{ display: currentStep === 0 ? 'inline' : 'none' }}>
              {/* <FormItem {...formItemLayout} label='Job Type'>
                {getFieldDecorator('job_type', {
                  initialValue: 'single'
                })(
                  <RadioGroup buttonStyle='solid' onChange={this.handleTypeChange}>
                    <RadioButton value='single'>One-off</RadioButton>
                    <RadioButton value='recurring'>Recurring</RadioButton>
                  </RadioGroup>
                )}
              </FormItem> */}
              <FormItem {...formItemLayout} label='Participant' hasFeedback>

                {getFieldDecorator('client_id', clientId ? {
                  initialValue: active ? parseInt(clientId) : '',
                  rules: [
                    { required: true, message: 'Please select a client' }
                  ]
                } : {
                  rules: [
                    { required: true, message: 'Please select a client' }
                  ]
                })(
                  <Select showSearch
                    style={{ width: '100%' }}
                    placeholder='Participant'
                    optionFilterProp='children'
                    notFoundContent='Not found'
                    filterOption={(input, option) => this.findClients(input, option)}
                    onChange={(e, c) => this.onSelectClient(e, c)} >
                    {
                      clients.map((client, idx) => {
                        // return <Option key={idx} value={client.id}>
                        //   {client.first_name} {client.last_name} { client.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(client.leave_start)} - ${formatter.toShortDate(client.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null}
                        return <Option key={idx} value={client.id}>
                          {client.first_name} {client.last_name}
                          <div className='clientId'>{client.acc_ref}</div>
                        </Option>

                      })
                    }

                  </Select>
                )}
              </FormItem>

              {/* <Spin spinning={spinLoading}>
                <FormItem {...formItemLayout} label='Funding Period' hasFeedback>
                  {getFieldDecorator('funding_id', {
                    rules: [
                      { required: true, message: 'Please select a funder' }
                    ]
                  })(
                    <Select showSearch
                      style={{ width: '100%' }}
                      placeholder='Funding Period'
                      optionFilterProp='children'
                      notFoundContent='Not found'
                      disabled={disableFunder}
                      filterOption={(input, option) => this.findFunders(input, option)}>
                      {
                        fundingPeriod.map((funder) => (
                          <Option key={funder.id} value={funder.id}>
                            {funder.funder_name} ({funder.start_date ? Moment(funder.start_date).format(dateFormat) : ''} - {funder.end_date ? Moment(funder.end_date).format(dateFormat) : ''})
                          </Option>))
                      }
                    </Select>
                  )}
                </FormItem>
              </Spin> */}

            </div>

          </Form>
        </div>
      </Modal>
    )
  }
}

export default Form.create()(AddJobModal)
