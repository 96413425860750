import React, { Component } from 'react'
import { connect } from 'react-redux'
import { settingCustomIdentifier } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Pager, SideModal, List } from '../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'
import { validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form
const Option = Select.Option

const settingTitle = 'Custom Identifier'
const settingType = 'custom identifier'

const customInputTypes = [
  { name: 'Text', value: 'text' },
  { name: 'Text Area', value: 'text-area' },
  { name: 'Toggle', value: 'toggle' }
]

export class CustomIdentifier extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: {},
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: {},
      sort: {},
      total: 0
    }
  }

  componentWillReceiveProps (nextProps) {
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettings({ currentPage })
  }

  fetchSettings = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      const { module } = this.props
      filter.module = { condition: '=', value: module }
      filter.is_delete = { condition: '=', value: false }
      this.setState({ currentPage, loading: true })

      const settings = await settingCustomIdentifier.listByPage(currentPage, pageSize, filter)

      this.setState({ loading: false })
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form, onComplete } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = {}
    modal.show = false
    this.setState({ modal, selectedItem: {} })
    onComplete()
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  handleSave = () => {
    const { form, module } = this.props
    const { validateFields } = form
    const { selectedItem, currentPage } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal

        let _code = typeof values.label === 'string' ? values.label.toLowerCase().trim() : ''
        _code = _code.replace(/ /g, '_')
        values.label = values.label.trim()
        values.code = _code
        values.module = module

        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await settingCustomIdentifier.save(id, values)
          } else {
            response = await settingCustomIdentifier.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
            this.fetchSettings({ currentPage: currentPage })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, module, code, label, active, input_type, is_include_export, is_include_notes, is_allow_log, is_editable) {
    this.setState({
      selectedItem: { id, module, code, label, active, input_type, is_include_export, is_include_notes, is_allow_log, is_editable }
    })
    this.showModal()
  }

  async handleDelete (id) {
    try {
      const res = await settingCustomIdentifier.remove(id)

      if (res) {
        notify.success('Deleted successfully', `${settingType} deleted successfully.`)
        this.fetchSettings({ currentPage: 1 })
      }
    } catch (e) {
      notify.error('Unable to delete successfully', `Unable to delete ${settingType} successfully. Please try again later`)
    }
  }

  handleType (value) {
    const { form } = this.props
    const { setFieldsValue } = form

    if (value === 'toggle') {
      setFieldsValue({ 'is_include_notes': true })
    } else {
      setFieldsValue({ 'is_include_notes': false })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form, module } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Label',
        width: 6,
        key: 'label'
      },
      {
        title: 'Input Type',
        width: 6,
        render: ({ input_type: inputType }) => {
          const { name } = customInputTypes.find(item => item.value === inputType)
          return (
            <div>{name}</div>
          )
        }
      },
      // {
      //   title: 'Include Export',
      //   width: 2,
      //   render: ({ is_include_export: includeExport }) => {
      //     return (
      //       <div style={{ fontSize: '11pt' }}>{ includeExport ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
      //         : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }</div>
      //     )
      //   }
      // },
      // {
      //   title: 'Include Notes',
      //   width: 2,
      //   render: ({ is_include_notes: includeNotes }) => <div style={{ fontSize: '11pt' }}>{ includeNotes ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
      //     : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }</div>
      // },
      // {
      //   title: 'Allow Log',
      //   width: 2,
      //   render: ({ is_allow_log: allowLog }) => <div style={{ fontSize: '11pt' }}>{ allowLog ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
      //     : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }</div>
      // },
      {
        title: 'Enable',
        width: 2,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>{ active ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
          : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }</div>
      },
      {
        title: '',
        width: 1,
        render: ({ id, module, code, label, active, input_type, is_include_export, is_include_notes, is_allow_log, is_editable }) => {
          return (
            this.hasAccess('updateCustomIdentifier')
              ? <div style={{ cursor: 'pointer' }} onClick={() => this.handleEdit(id, module, code, label, active, input_type, is_include_export, is_include_notes, is_allow_log, is_editable)}>
                <Icon type='form' />
              </div>
              : null
          )
        }
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable }) => this.hasAccess('deleteCustomIdentifier') && is_editable ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        ><Icon type='delete' />
        </Popconfirm> : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} custom identifiers`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>

        <SideModal
          title={`${settingTitle} (${module})`}
          showModal={modal.show || this.props.onAdd}
          onClose={this.hideModal}
          buttons={[
            <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
          ]}
        >
          <Form layout='vertical'>
            <FormItem label='Label'>
              {getFieldDecorator('label', {
                initialValue: selectedItem.label,
                rules: [
                  { min: 2, message: 'Label must be between 2 and 127 characters' },
                  { max: 128, message: 'Label must be between 2 and 127 characters' },
                  { required: true, message: 'Please enter label' },
                  { whitespace: true, message: 'Please enter label' },
                  { validator: this.checkLabel }
                ]
              })(
                <Input disabled={selectedItem.is_editable} />
              )}
            </FormItem>

            {/* <FormItem label='Input Type'>
              {getFieldDecorator('input_type', {
                initialValue: selectedItem.input_type || '',
                rules: [
                  { required: true, message: 'Please select a input type' }
                ]
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder='Please select a input type'
                  onChange={(type) => this.handleType(type)}
                >
                  {
                    customInputTypes.map((types, idx) => {
                      return <Option key={`${types.value}${idx}`} value={types.value}>{types.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem> */}

            {/* <FormItem label='Include Export'>
              {getFieldDecorator('is_include_export', {
                initialValue: selectedItem.is_include_export,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes' unCheckedChildren='No'
                />
              )}
            </FormItem> */}

            {/* <FormItem label='Include Notes?'>
              {getFieldDecorator('is_include_notes', {
                initialValue: selectedItem.is_include_notes,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes' unCheckedChildren='No'
                />
              )}
            </FormItem> */}

            {/* <FormItem label='Allow Log'>
              {getFieldDecorator('is_allow_log', {
                initialValue: selectedItem.is_allow_log,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes' unCheckedChildren='No'
                />
              )}
            </FormItem> */}

            <FormItem label=''>
              {getFieldDecorator('active', {
                initialValue: typeof selectedItem.active === 'boolean' ? selectedItem.active : true,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Enable' unCheckedChildren='Disable' disabled={selectedItem.is_editable}
                />
              )}
            </FormItem>
          </Form>

        </SideModal>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchSettings({ loading: true, currentPage, filter, sort, searchText })
  }

  checkLabel = (rule, value, callback) => {
    if (value && value.length > 0) {
      if (/[^A-Za-z0-9\s]/.test(value)) {
        callback(new Error('Only text and digit are allow'))
      }
    }

    callback()
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return {
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CustomIdentifier))
