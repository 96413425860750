import React, { useEffect, useState } from 'react'
import moment from 'moment'
import BillingRateRow from './row'

import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Tooltip from 'antd/lib/tooltip'
import DatePicker from 'antd/lib/date-picker'
import TimePicker from 'antd/lib/time-picker'
import Popconfirm from 'antd/lib/popconfirm'
import Popover from 'antd/lib/popover'
import message from 'antd/lib/message'

import { Button } from '../../../../../components'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option

function Subset (props) {
  const { category, data: set, form, index, subsets } = props
  const { getFieldDecorator, getFieldsValue } = form
  const [ visible, setVisible ] = useState(index === 0)

  useEffect(() => {
    if (props.validate) {
      validateForm()
    }
  }, [props.validate])

  const formItemLayout = {
    labelCol: { sm: 6, md: 6, lg: 7 },
    wrapperCol: { sm: 14, md: 14, lg: 16 }
  }

  const formItemTimeLayout = {
    labelCol: { sm: 6, md: 6, lg: 12 },
    wrapperCol: { sm: 14, md: 14, lg: 10 }
  }

  function handleDuplicate () {
    props.onDuplicate(set)
  }

  function setBillingRate (category, day, value, isLabel = false) {
    let isExist = false
    const billingCategory = set.categories.filter(c => c.id === category)

    for (let i = 0; i < billingCategory[0].rates.length; i++) {
      const rate = billingCategory[0].rates[i]
      if (rate.day === day) {
        if (isLabel) {
          rate.label = value
        } else {
          rate.value = parseFloat(value)
        }
        isExist = true
        break
      }
    }

    if (!isExist) {
      set.categories.push({ day, value: !isLabel ? value : null, label: isLabel ? value : '' })
    }

    props.onUpdate(set)
  }

  function removeBillingRate (category) {
    const billingCategory = set.categories.filter(c => c.id !== category)
    set.categories = billingCategory

    props.onUpdate(set)
  }

  function addCategory (option) {
    const rates = []
    // for (let j = 0; j < 8; j++) {
    //   // populate normal hours
    //   rates.push({category_id: option.key, day: `n${j + 1}`, value: 0, label: null})
    //   // populate after hours
    //   rates.push({category_id: option.key, day: `a${j + 1}`, value: 0, label: null})
    // }

    rates.push({category_id: option.key, day: `c0`, value: 0, label: null})

    set.categories.push({ id: option.key, name: option.label, rates })
    props.onUpdate(set)
  }

  function updateSetInfo (value) {
    setTimeout(() => {
      const fields = getFieldsValue()
      set.name = fields['rate_set_name']
      set.active = fields['rate_set_active']
      set.start_date = moment(fields['start_date']).startOf('day')
      set.end_date = moment(fields['end_date']).endOf('day')
      // set.normal_hours_start = fields['normal_hours_start'] || null
      // set.normal_hours_end = fields['normal_hours_end'] || null
      // set.after_hours_start = fields['after_hours_start'] || null
      // set.after_hours_end = fields['after_hours_end'] || null
      // set.km_rate = fields['km_rate']
      // set.emergency_rate = fields['emergency_rate']
      // set.so_hours_start = fields['so_hours_start']
      // set.so_hours_end = fields['so_hours_end']
      // set.so_free_getup = fields['so_free_getup']

      props.onUpdate(set)
    }, 500)

    return value
  }

  function handleDuplicate() {
    const newSet = {...set}

    const startDate = moment(set.start_date).startOf('day')
    const endDate = moment(set.end_date).endOf('day')
    const duration = moment.duration(endDate.diff(startDate))
    const durationDays = duration.asDays()

    newSet.id = null
    newSet.name = newSet.name + ' - Duplicate'
    newSet.start_date = moment(set.end_date).add(1, 'day').startOf('day')
    newSet.end_date = moment(set.end_date).add(durationDays + 1, 'day').endOf('day')
    props.onDuplicate(newSet)
  }

  function handleDelete() {
    props.onDelete(set.id)
  }

  function toggle () {
    setVisible(!visible)
  }

  function validateForm () {
    const { form } = props
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (errors) {
        // console.log('has error')
        props.onError(false)
      }
    })
  }

  function handleDateChange (rule, value, callback) {
    const overlapping = isDateOverlapping(value)
    if (overlapping.result) {
      try {
        throw new Error(`Date is overlapping with ${overlapping.period}`)
      } catch (err) {
        callback(err)
      }
    } else {
      // console.log('no overlapp')
      callback()
    }
  }

  function isDateOverlapping (value) {
    for (let i = 0; i < subsets.length; i++) {
      const subset = subsets[i]

      if (subset.id !== set.id) {
        const isBetween = moment(value).isBetween(subset.start_date, subset.end_date)

        if (isBetween) {
          return { result: true, period: `${moment(subset.start_date).format('DD/MM/YYYY')} - ${moment(subset.end_date).format('DD/MM/YYYY')}` }
        }
      }
    }

    return { result: false }
  }

  return (<div className='billing-rate-info'>
    <Form>
      <Row>
        <Col onClick={() => toggle()} lg={20} style={{ cursor: 'pointer', fontWeight: 'bold', paddingLeft: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          { set.name || '' }
          <Tooltip title={ visible ? 'Collapse' : 'Expand'}>
            <div style={{ fontSize: '12pt', marginLeft: '10px' }}><Icon type={ visible ? 'up-circle' : 'down-circle'} /></div>
          </Tooltip>
        </Col>
        <Col lg={4} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          { set.id ? <Button onClick={() => handleDuplicate()}>
            Duplicate
          </Button> : null
          }
          {
            <Popconfirm
              title='Confirm to remove this?'
              onConfirm={() => handleDelete()}
              okText='Yes'
              cancelText='No'
              placement='left'
            >
              <div className='billing-set-remove-row'><Icon type='delete' theme='filled' /></div>
            </Popconfirm>
          }
        </Col>
      </Row>
      <div className='billing-rate-info-separate' />

      <div style={{ display: visible ? 'inline' : 'none' }}>
        <Row style={{ marginTop: '20px' }}>
          <Col lg={10}>
            <FormItem label='Set Name' {...formItemLayout}>
              {getFieldDecorator('rate_set_name', {
                initialValue: set.name || '',
                getValueFromEvent: (e) => updateSetInfo(e.target.value),
                rules: [
                  { min: 2, message: 'Name must be between 2 and 128 characters' },
                  { max: 128, message: 'Name must be between 2 and 128 characters' },
                  { required: true, message: 'Please enter name' },
                  { whitespace: true, message: 'Please enter name' }
                ]
              })(
                <Input />
              )}
            </FormItem>
          </Col>
          <Col lg={2}>
            <FormItem label='' {...formItemLayout}>
              {getFieldDecorator('rate_set_active', {
                initialValue: set.active || false,
                valuePropName: 'checked',
                getValueFromEvent: (e) => updateSetInfo(e),
              })(
                <Switch
                  checkedChildren='Enable' unCheckedChildren='Disable'
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={6}>
            <FormItem label='Start Date' {...formItemTimeLayout}>
              {getFieldDecorator('start_date', {
                initialValue: set.start_date ? moment(set.start_date) : null,
                getValueFromEvent: (e) => updateSetInfo(e),
                rules: [
                  { required: true, message: 'Please select start date' },
                  { validator: handleDateChange }
                ]
              })(
                <DatePicker format={'DD/MM/YYYY'} />
              )}
            </FormItem>
          </Col>
          <Col lg={6}>
            <FormItem label='End Date' {...formItemTimeLayout}>
              {getFieldDecorator('end_date', {
                initialValue: set.end_date ? moment(set.end_date) : null,
                getValueFromEvent: (e) => updateSetInfo(e),
                rules: [
                  { required: true, message: 'Please select end date' },
                  { validator: handleDateChange }
                ]
              })(
                <DatePicker format={'DD/MM/YYYY'} />
              )}
            </FormItem>
          </Col>
        </Row>

        <div className='billing-rate-info-separate' />

        <div className='billing-rate-info-category'>
          <Row style={{ fontSize: 13, color: '#777' }} gutter={10}>
            <Col lg={3}>
              Category
              <Popover
                content={<Select labelInValue style={{ width: 250 }} onChange={(e) => addCategory(e)}>
                  {
                    category.map((cat, idx) => {
                      return <Option key={idx} value={cat.id}>{ cat.name }</Option>
                    })
                  }
                </Select>}
                title='Add Category'
                placement='right'
              >
                <span className='billing-rate-add-category'> <Icon type='plus-circle' theme='filled' /></span>
              </Popover>
            </Col>
          </Row>

          <div className='billing-rate-set-row-list'>
            {
              set.categories.map((cat, index) => (
                <BillingRateRow
                  key={index}
                  setId={set.id}
                  item={cat}
                  canDelete={false}
                  onChange={(category, day, value) => setBillingRate(category, day, value)}
                  onChangeLabel={(category, day, value) => setBillingRate(category, day, value, true)}
                  onDelete={(category) => removeBillingRate(category)} />
              ))
            }
          </div>
        </div>
      </div>
    </Form>
  </div>)
}

export default Form.create()(Subset)
