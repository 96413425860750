import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// UI
import Alert from 'antd/lib/alert'
import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'

import './styles.css'

export default class List extends React.Component {
  constructor (props) {
    super(props)
    const { defaultSort = {} } = props
    this.state = {
      defaultSort,
      pristine: true,
      sorting: {}
    }
  }

  render () {
    const { cols, hideColumns, highlightIf, link, loading = true, onClick, rows, showEmptyMessage = true, subcols, subrows } = this.props
    const { defaultSort, pristine, sorting } = this.state

    const actualLink = (row) => {
      let actualLink = link || ''

      if (actualLink.indexOf('<') >= 0) {
        const key = /<(.*?)>/g.exec(actualLink)
        actualLink = actualLink.replace(/<.*?>/, row[key[1]])
      }

      return actualLink
    }

    const highlight = (row) => {
      if (highlightIf && row[highlightIf.key] === highlightIf.value) {
        return true
      }
      return false
    }

    const listRow = (row, index) => (
      <tr className={'wd-list-row' + (highlight(row) ? ' wd-list-row-highlight' : '')} key={index} style={{ borderCollapse: subrows ? 'collapse' : '2px' }}>
        {cols.map((col, idx) => (
          <td style={toTableWidth(col.width)} className='wd-list-col' key={idx}>
            {col.render ? (
              col.render(row)
            ) : col.key ? (
              row[col.key]
            ) : ''}
          </td>
        ))}
      </tr>
    )

    const toTableWidth = (width) => {
      return { width: `${(100 * width / 24).toFixed(2)}%` }
    }

    return (
      <div className='wd-list-container'>
        <table className='wd-list-body'>
          <tbody>
            {!hideColumns ? (
              <tr className='wd-list-header'>
                {cols.map((col, idx) => {
                  const { key, onSort, title, width } = col
                  const sortKey = pristine ? defaultSort[key] : sorting[idx]

                  return typeof onSort === 'function' ? (
                    <td key={idx} style={toTableWidth(width)}>
                      <div className='wd-list-header-sort' onClick={(e) => this.handleSort(e, idx, key, onSort)}>
                        <span>{title}</span>

                        <span className='wd-list-header-caret'>
                          <Icon type='caret-up' style={{ color: sortKey === 1 ? 'var(--themeColorLite)' : 'var(--themeColorDark)' }} />

                          <Icon type='caret-down' style={{ color: sortKey === -1 ? 'var(--themeColorLite)' : 'var(--themeColorDark)' }} />
                        </span>
                      </div>
                    </td>
                  ) : (
                    <td key={idx} className='wd-list-header' style={toTableWidth(width)}>{title}</td>
                  )
                })}
              </tr>
            ) : null}

            {showEmptyMessage && (!loading && rows.length < 1) ? (
              <Alert message='The list is empty. Try adding something into it.' showIcon type='info' />
            ) : null}

            {rows.map((row, idx) => (
              link || onClick ? (
                <Link to={link ? actualLink(row) : '#'} onClick={(e) => this.handleClick(e, row)} key={idx}>
                  {listRow(row, idx)}
                </Link>
              ) : listRow(row, idx)
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  handleClick (e, row) {
    const { onClick } = this.props

    if (typeof onClick === 'function') {
      e.preventDefault()
      onClick(row)
    }
  }

  handleSort (e, idx, key, onSort) {
    if (typeof onSort === 'function') {
      const { defaultSort, pristine, sorting } = this.state
      let order = pristine ? defaultSort[key] : sorting[idx] || 0
      order = order === 1 ? -1 : order === -1 ? 0 : 1
      this.setState({ pristine: false, sorting: { [idx]: order } })
      onSort(key, order)
    }
  }
}

List.propTypes = {
  cols: PropTypes.array.isRequired,
  defaultSort: PropTypes.object,
  hideColumns: PropTypes.bool,
  highlight: PropTypes.bool,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  rows: PropTypes.array.isRequired,
  showEmptyMessage: PropTypes.bool,
  subcols: PropTypes.array,
}
