export default [
  {
    name: 'Jobs',
    icon: 'thunderbolt',
    link: '/jobs/all',
    permission: 'listJobs'
  },
  {
    name: 'Participants',
    icon: 'usergroup-add',
    link: '/clients',
    permission: 'listClients'
  },
  {
    name: 'Providers',
    icon: 'project',
    link: '/providers',
    permission: 'listEmployees'
  },
  {
    name: 'Employees',
    icon: 'user',
    link: '/employees',
    permission: 'listEmployees'
  },
  {
    name: 'Full Map',
    icon: 'global',
    link: '/map',
    permission: 'listMap'
  },
  {
    name: 'Billings',
    icon: 'dollar',
    link: '/billings',
    permission: 'listPayrolls'
  },
  {
    name: 'Reports',
    icon: 'snippets',
    link: '/reports',
    permission: 'listPayrolls'
  },
  {
    name: 'Settings',
    icon: 'setting',
    link: '/settings',
    permission: 'listSettings'
  }
]
