/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { authService, settingFundManagerService, logService } from '../../../../../services'
import { fetchingFunders, setRefreshActivityLog } from '../../../../../states/actions'
import { AustralianStates } from '../../../../../constants'
import moment from 'moment'
import { accRef, formatter, log, trigger } from '../../../../../util'
import Moment from 'moment-timezone'

// UI
import { Button, Loading, Page, Panel } from '../../../../../components'
import notify from '../../../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'

// import ActivityLog from '../ActivityLog'
import ClientList from '../Client'

// import './styles.css'

const { confirm } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
export class SettingFundManagerPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      clientList: [],
      logList: [],
      loading: false
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchFunder()
    }

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )

    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { match, history } = this.props
    const { item, loading } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Plan Manager' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : `${item.fullname}`}>
            {
              this.isEdit() && this.hasAccess('deleteFunder') ? (
                <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
                </div>
              ) : null
            }
            {
              <div className='btn' onClick={this.handleSave}>
              Save
              </div>
            }
            <Link to={`/settings/funder?t=fundmanager`}>
              <div className='btn'>Back</div>
            </Link>
          </Page.Header>

          <div className='funder-panel'>
            <div className='funder-panel-body'>
              <Tabs defaultActiveKey='1'>
                <TabPane tab='Plan Manager Info' key='1'>
                  { this.infoTab() }
                </TabPane>
                { this.isEdit() ? <TabPane tab='Participants' key='2'>
                  <ClientList fundManagerId={match.params.id} history={this.props.history} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Activity Log' key='3'>
                  {/* <ActivityLog funderId={match.params.id} history={this.props.history} /> */}
                </TabPane> : null }
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { newAccRef, item, loading } = this.state
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 19 }
    }
    const sideBySideFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    return <Form>
      <Panel title='Plan Manager Setting'>
        <Row gutter={12}>
          <Col lg={12}>
            <FormItem {...sideBySideFormItemLayout} label='Active'>
              {getFieldDecorator('active', {
                initialValue: item.active || false,
                valuePropName: 'checked'
              })(
                <Switch
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                />
              )}
            </FormItem>
          </Col>

        </Row>

      </Panel>

      <Panel title='Plan Manager Information'>
        <Loading loading={loading} blur>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='ABN' hasFeedback>
                {getFieldDecorator('abn', {
                  initialValue: item.abn || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Name' hasFeedback>
            {getFieldDecorator('fullname', {
              initialValue: item.fullname || '',
              rules: [
                { min: 2, message: 'Name must be between 2 and 128 characters' },
                { max: 128, message: 'Name must be between 2 and 128 characters' },
                { required: true, message: 'Please enter first name' },
                { whitespace: true, message: 'Please enter first name' }
              ]
            })(
              <Input />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || '',
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states) => (
                        <Option key={states.value} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || ''
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone_number', {
                  initialValue: item.phone_number || '',
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Fax' hasFeedback>
                {getFieldDecorator('fax', {
                  initialValue: item.fax || '',
                  rules: [
                    { min: 2, message: 'Fax Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Fax Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter fax number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Invoice Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email || '',
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    },
                    { min: 2, message: 'Email must be between 2 and 128 characters' },
                    { max: 128, message: 'Email must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter email' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Contact Person'>
            {getFieldDecorator('contact_person', {
              initialValue: item.contact_person || ''
            })(
              <Input />
            )}
          </FormItem>

          <Row gutter={12}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Contact Email' hasFeedback>
                {getFieldDecorator('after_contact_name', {
                  initialValue: item.after_contact_name || ''
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>

              <FormItem {...sideBySideFormItemLayout} label='Contact Phone' hasFeedback>
                {getFieldDecorator('after_contact_number', {
                  initialValue: item.after_contact_number || ''
                })(
                  <Input />
                )}
              </FormItem>

            </Col>

          </Row>

          <FormItem {...formItemLayout} label='Notes' hasFeedback>
            {getFieldDecorator('notes', {
              initialValue: item.notes || ''
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Loading>
      </Panel>
    </Form>
  }

  fetchFunder = async () => {
    try {
      const { match } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const { item } = await settingFundManagerService.get(id)
      this.setState({ item, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load plan manager successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  handleDelete = () => {
    const { history, match } = this.props
    const { params } = match
    const { id } = params

    confirm({
      title: 'Are you sure you want to delete this funder?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await settingFundManagerService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Funder deleted successfully.')
            history.replace('/settings/funder')
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete plan manager successfully. Please try again later.')
        }
      }
    })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingFunders, history } = this.props
        const { item } = this.state
        this.setState({ loading: true })

        values.fullname = values.fullname ? values.fullname.trim() : values.fullname

        try {
          if (this.isEdit()) {
            const response = await settingFundManagerService.save(item.id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            if (response.id) {
              // log.updateFunder(response.id, item, values)
              // trigger.updateFunder(
              //   response.id,
              //   values.fullname,
              //   item,
              //   values,
              //   [
              //     { key: 'abn' },
              //     { key: 'active' },
              //     { key: 'fullname', label: 'Name' },
              //     { key: 'address' },
              //     { key: 'unit_building', label: 'Unit/Building' },
              //     { key: 'phone_number' },
              //     { key: 'email' },
              //     { key: 'fax' },
              //     { key: 'contact_person' },
              //     { key: 'after_contact_name', label: 'After Hours Contact Name' },
              //     { key: 'after_contact_number', label: 'After Hours Contact Number' }
              //   ]
              // )

              notify.success('Saved successfully', 'Plan manager saved successfully.')
              // fetchingFunders(true)
            }
          } else {
            const response = await settingFundManagerService.add(values)
            this.setState({ loading: false })

            if (response.id) {
              const { id, acc_ref } = response
              // log.addFunder(response.id, `New funder ${values.fullname}`)

              // trigger.addFunder(
              //   id,
              //   values.fullname,
              //   values,
              //   [
              //     { key: 'abn' },
              //     { key: 'active' },
              //     { key: 'fullname', label: 'Name' },
              //     { key: 'phone_number' },
              //     { key: 'email' },
              //     { key: 'fax' },
              //     { key: 'contact_person' },
              //     { key: 'after_contact_name', label: 'After Hours Contact Name' },
              //     { key: 'after_contact_number', label: 'After Hours Contact Number' },
              //     { key: 'address' },
              //     { key: 'unit_building', label: 'Unit/Building' }
              //   ]
              // )

              this.setState({ item: { ...item, ...values, id, acc_ref } })
              notify.success('Saved successfully', 'Plan manager saved successfully.')
              // history.replace(`/funders/${id}`)
              window.location.replace(`/settings/funder/fund-manager/${id}`)
              // fetchingFunders(true)
            }
          }

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save plan manager successfully. Please try again later.')
          this.setState({ loading: false })
        }


      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingFunders,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFundManagerPage))
