import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGeneralService } from '../../../services/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../components/Notification'

import FundingType from './FundingType'
import BillingRateSets from './BillingRates/Set'
import BillingRateCategories from './BillingRates/Category'
import TaxCode from './TaxCode'
// import FundManager from './FundManager'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'Funder'
const settingType = 'gender'

export class SettingFunder extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addFundingType: false,
      addFundManager: false,
      addCategory: false,
      addRateSet: false,
      addTaxCode: false,
      currentPage: 1,
      activeTab: '1',
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettings({ currentPage })

    const tab = (new URLSearchParams(window.location.search)).get("t")
    this.setState({ activeTab: tab === 'rateset' ? '2' : (tab === 'fundmanager' ? '5' : '1') })
  }

  fetchSettings = async ({ currentPage = 1 }) => {
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingGeneralService.listByPage(currentPage, pageSize, { type: settingType })
      this.setState({ list: settings.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  addFundingType = () => {
    this.setState({ addFundingType: true })
  }

  addFundManager = () => {
    this.setState({ addFundManager: true })
  }

  addCategory = () => {
    this.setState({ addCategory: true })
  }

  addRateSet = () => {
    this.setState({ addRateSet: true })
  }

  addTaxCode = () => {
    this.setState({ addTaxCode: true })
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal
        values.type = settingType
        values.value = typeof values.name === 'string' ? values.name.toLowerCase() : values.name
        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await settingGeneralService.save(id, values)
          } else {
            values.image = item.image
            response = await settingGeneralService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
            this.fetchSettings({ currentPage: 1 })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, name, value, active) {
    this.setState({ selectedItem: { id, name, value, active } })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await settingGeneralService.remove(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchSettings({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addFundingType, addCategory, addRateSet, addTaxCode, addFundManager, activeTab } = this.state

    const defaultKey = this.hasAccess('listFundingTypes') ? '1' : this.hasAccess('listBillingRates') ? '2' : this.hasAccess('listTaxCodes') ? '4' : '1'

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey={defaultKey} activeKey={activeTab} onChange={this.onTabChange}>
            { this.hasAccess('listFundingTypes')
              ? <TabPane tab='Funding Types' key ='1'>
                <div className='header-action'>
                  { this.hasAccess('createFundingType') ? <div className='btn' onClick={() => this.addFundingType()}>
                    Add Funding Type
                    </div> : null }
                </div>
                <FundingType onAdd={addFundingType} onComplete={() => this.setState({ addFundingType: false })} />
              </TabPane>
              : null }

            { this.hasAccess('listBillingRates')
              ? <TabPane tab='Rate Sets' key='2'>
              <div className='header-action'>
                <Link to={`/settings/funder/billing-rates/add`}>
                  { this.hasAccess('createBillingRate') ? <div className='btn'>
                    Add Rate Set
                  </div> : null }
                </Link>
              </div>
              <BillingRateSets onAdd={addRateSet} onComplete={() => this.setState({ addRateSet: false })} />
            </TabPane>
            : null }

            { this.hasAccess('listBillingRates')
              ? <TabPane tab='Categories' key='3'>
                <div className='header-action'>
                  { this.hasAccess('createBillingRate') ? <div className='btn' onClick={() => this.addCategory()}>
                    Add Category
                  </div> : null }
                </div>
                <BillingRateCategories onAdd={addCategory} onComplete={() => this.setState({ addCategory: false })} />
              </TabPane>
              : null }

            { this.hasAccess('listTaxCodes')
              ? <TabPane tab='Tax Codes' key='4'>
                <div className='header-action'>
                  { this.hasAccess('createTaxCode') ? <div className='btn' onClick={() => this.addTaxCode()}>
                    Add Tax Code
                  </div> : null }
                </div>
                <TaxCode onAdd={addTaxCode} onComplete={() => this.setState({ addTaxCode: false })} />
              </TabPane>
              : null }

            {/* { this.hasAccess('listBillingRates')
              ? <TabPane tab='Plan Manager' key='5'>
                <div className='header-action'>
                  <Link to={`/settings/funder/fund-manager/add`}>
                    { this.hasAccess('createBillingRate') ? <div className='btn'>
                      Add Plan Manager
                    </div> : null }
                  </Link>
                </div>
                <FundManager onAdd={addFundManager} onComplete={() => this.setState({ addFundManager: false })} />
              </TabPane>
              : null } */}
          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFunder))
