import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import { apiHostname, build } from '../../config'
import { MainMenu, MainReport } from '../../constants'
import { routes } from '../../routes'
import { authService } from '../../services'
import { localStorage, sessionStorage } from '../../util'

// UI
import { Button, SideModal } from '../../components'
import Icon from 'antd/lib/icon'

import Form from 'antd/lib/form'

import './styles.css'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false,
      showMenuReport: false,
      showUser: false
    }
  }

  componentDidMount () {
    const el = document.getElementById('root')
    el.addEventListener('click', this.handleAnyClick)
  }

  componentWillUnmount () {
    const el = document.getElementById('root')
    el.removeEventListener('click', this.handleAnyClick)
  }

  handleAnyClick = ({ target }) => {
    const { className } = target

    if (className.indexOf && (className.indexOf('hamburger') < 0)) {
      this.hideAll()
    }
  }

  hideAll = () => {
    this.hideMenu()
    this.hideMenuReport()
  }

  render () {
    const { showMenu, showMenuReport, showUser } = this.state
    const currentUser = authService.getCurrentUser()
    const { avatar, name, role, email } = currentUser
    const profilePicture = avatar !== '' ? `${apiHostname}${avatar}` : '/img/avatar-blank.png'

    const renderMenuGroup = (group) => {
      let hasChildMenu = false
      for (var i = 0; i < group.menu.length; i++) {
        const menu = group.menu[i]

        if (this.canShowMenu(menu.permission)) {
          hasChildMenu = true
          break
        }
      }

      return hasChildMenu ? <div key={group.title}>
        <div className='title'>{ group.icon ? <Icon type={group.icon} /> : null } {group.title}</div>
        <div className='list'>
          {
            group.menu.map((menu, idx) => {
              const showMenu = this.canShowMenu(menu.permission)

              if (showMenu) {
                return menu.link && menu.link !== '' ? (
                  <Link to={menu.link} key={'menu' + idx}><div key={'menu' + idx}>{menu.name}</div></Link>
                ) : (
                  <div key={'menu' + idx}>{menu.name}</div>
                )
              }
            })
          }
        </div>
      </div> : null
    }

    return (
      <Router>
        <div className='app'>
          <div className='app-topbar' style={ build === 'prod' ? { backgroundImage: 'linear-gradient(to right bottom, #ec350d, #FF573399)' } : build === 'staging' || build === 'dev' ? { backgroundImage: 'linear-gradient(to right bottom, #8a065522, #8a0655)' } : null}>
            <div className='content'>
              <Link to='/'>
                <img alt='' src='/img/logo-pumpkin.png' className='app-logo' /> <span style={{ paddingTop: '3px', color: 'white', fontWeight: 'bold' }}>PUMPKIN SC</span> { build === 'prod' ? <span style={{ marginLeft: '2px', color: '#333', fontSize: '8pt', letterSpacing: '1px' }}>legacy</span> : build === 'staging' ? <span style={{ marginLeft: '2px', color: '#333', fontSize: '8pt', letterSpacing: '1px' }}>staging</span> : build === 'dev' ? <span style={{ marginLeft: '2px', color: '#333', fontSize: '8pt', letterSpacing: '1px' }}>dev</span> : null }
              </Link>

              <div className='menu-list'>
                {this.canShowMenu('listJobs') ? (
                  <Link className='menu' to='/jobs/all'>
                    <div onMouseOver={() => this.hideAll()}>
                      <Icon type='thunderbolt' style={{color: '#333'}} /> Jobs
                    </div>
                  </Link>
                ) : null}

                {this.canShowMenu('listClients') ? (
                  <Link className='menu' to='/clients'>
                    <div onMouseOver={() => this.hideAll()}>
                      <Icon type='usergroup-add' style={{color: '#333'}} /> Participants
                    </div>
                  </Link>
                ) : null}

                {/* {this.canShowMenu('listFunders') ? (
                  <Link className='menu' to='/funders'>
                    <div onMouseOver={() => this.hideAll()}>
                      <Icon type='team' style={{color: 'orange'}} /> Funders
                    </div>
                  </Link>
                ) : null} */}

                {this.canShowMenu('listProviders') ? (
                  <Link className='menu' to='/providers'>
                    <div onMouseOver={() => this.hideAll()}>
                      <Icon type='project' style={{color: '#333'}} /> Providers
                    </div>
                  </Link>
                ) : null}

                {/* {this.canShowMenu('listEmployees') ? (
                  <Link className='menu' to='/employees'>
                    <div onMouseOver={() => this.hideAll()}>
                      <Icon type='user' style={{color: '#333'}} /> Employees
                    </div>
                  </Link>
                ) : null} */}

                { this.canShowMenu('listMap') ? (
                  <Link className='menu' to='/map'>
                    <div onMouseOver={() => this.hideAll()}>
                      <Icon type='global' style={{color: '#333'}} /> Map
                    </div>
                  </Link>
                ) : null }

                {this.canShowMenu('listPayrolls') ? (
                  <Link className='menu' to='/billings'>
                    <div onMouseOver={() => this.hideAll()}>
                      <Icon type='dollar' style={{color: '#333'}} /> Billings
                    </div>
                  </Link>
                ) : null}

                {/* <Link className='menu' to='/communication'>
                  <div onMouseOver={() => this.hideAll()}>
                    <Icon type='message' style={{color: 'orange'}} /> Communication
                  </div>
                </Link> */}

                <div className='menu' onClick={() => this.showMenuReport()}>
                  <Icon type='snippets' style={{color: '#333'}} />
                  &nbsp;Reports &nbsp;<Icon type='down' className='App-expand-icon' />
                </div>

                <div className='menu' onClick={() => this.showMenu()}>
                  &nbsp; More &nbsp;<Icon type='down' className='App-expand-icon' />
                </div>

                <Link className='menu' to='/notification'>
                  <div onMouseOver={() => this.hideAll()} style={{ width: '28px' }}>
                    <Icon type='exclamation-circle' style={{color: '#333'}} />
                  </div>
                </Link>

                <div className='menu' style={{width: '200px', textAlign: 'left', fontWeight: 'bold'}} onClick={this.showUserProfile} onMouseOver={() => this.hideMenu()}>
                  <Icon type='user' />&nbsp; {name} &nbsp;<Icon type='down' className='App-expand-icon' />
                </div>
              </div>

              <div className='hamburger' onClick={this.toggleMenu}> ☰ </div>
            </div>
          </div>

          {/* Main Menu (Report) */}
          <div className={'menu-report-popup' + (showMenuReport ? ' menu-report-popup-show' : ' menu-report-popup-hide')} onMouseOver={() => this.showMenuReport()} onMouseOut={() => this.hideMenuReport()}>
            {
              MainReport.map((section, idx) => {
                return (
                  <div className='part' key={'mainReport' + idx}>
                    {
                      section.groups.map((group, idx) => {
                        const showMenu = this.canShowMenu(group.permission, group.name)

                        return showMenu ? (
                          <div className='column' key={'mainReportColumn' + idx}>
                            {
                              group.subgroup ? (
                                this.canShowMenu(group.subgroup.permission, group.subgroup.name) ? (
                                  group.subgroup.map((subgroup) => (
                                    renderMenuGroup(subgroup)
                                  ))
                                ) : null
                              ) : renderMenuGroup(group)
                            }
                          </div>
                        ) : null
                      })
                    }
                  </div>
                )
              })
            }
          </div>

          {/* Main Menu (More) */}
          <div className={'menu-popup' + (showMenu ? ' menu-popup-show' : ' menu-popup-hide')} onMouseOver={() => this.showMenu()} onMouseOut={() => this.hideMenu()}>
            {
              MainMenu.map((section, idx) => {
                return (
                  <div className='part' key={'part' + idx}>
                    {
                      section.groups.map((group, idx) => {
                        const showMenu = this.canShowMenu(group.permission, group.name)

                        return showMenu ? (
                          <div className='column' key={'column' + idx}>
                            {
                              group.subgroup ? (
                                this.canShowMenu(group.subgroup.permission, group.subgroup.name) ? (
                                  group.subgroup.map((subgroup) => (
                                    renderMenuGroup(subgroup)
                                  ))
                                ) : null
                              ) : renderMenuGroup(group)
                            }
                          </div>
                        ) : null
                      })
                    }
                  </div>
                )
              })
            }
          </div>

          <div className='app-content'>
            {routes.map((route, idx) => (
              <Route
                key={idx}
                component={route.component}
                path={route.path}
                exact={route.exact}
              />
            ))}
          </div>

          <SideModal
            title='My Profile'
            showModal={showUser}
            onClose={this.hideUserProfile}
            buttons={[
              <Button key='logout' onClick={this.handleLogout}>Logout</Button>
            ]}
          >
            <img alt='' src='/img/user.png' className='app-user' />
            <div className='app-user-name'>{ name }</div>
          </SideModal>
        </div>
      </Router>
    )
  }

  canShowMenu = (permission, name) => {
    if (permission) {
      return authService.hasAccess(permission)
    }

    return true
  }

  handleLogout = async () => {
    const response = await authService.signOut()

    if (response) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = '/login'
    }
  }

  hideMenu = () => {
    this.setState({ showMenu: false })
  }

  showMenu = () => {
    this.setState({ showMenu: true })
  }

  hideMenuReport = () => {
    this.setState({ showMenuReport: false })
  }

  showMenuReport = () => {
    this.setState({ showMenuReport: true })
  }

  toggleMenu = () => {
    const { showMenu } = this.state
    this.setState({ showMenu: !showMenu })
    window.scrollTo(0, 0)
  }

  hideUserProfile = () => {
    this.setState({ showUser: false })
  }

  showUserProfile = () => {
    this.setState({ showUser: true })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(App))
