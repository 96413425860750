import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/payrolls'
const timesheetUrl = '/private/api/payrolls/timesheet'
const employeeTimesheetUrl = '/private/api/payrolls/employee'
const clientTimesheetUrl = '/private/api/payrolls/client'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${timesheetUrl}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },

  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listTimesheetByPage (page, size, filter, sorting, text, view) {
    return request(`${timesheetUrl}/${view}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getTimesheetSummary (id, view) {
    return request(`${timesheetUrl}/${view}/${id}/summary`)
  },
  listEmployeeTimesheetByPage (page, size, filter, sorting, text) {
    return request(`${employeeTimesheetUrl}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getEmployeeTimesheetSummary (id, eid) {
    return request(`${employeeTimesheetUrl}/${eid}/timesheet/${id}/summary`)
  },
  listClientTimesheetByPage (page, size, filter, sorting, text) {
    return request(`${clientTimesheetUrl}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getClientTimesheetSummary (id, eid) {
    return request(`${clientTimesheetUrl}/${eid}/timesheet/${id}/summary`)
  },
  downloadInvoice (id) {
    return request(`${url}/export/invoice/payroll/${id}`)
  },
  generate (from, to) {
    return request(`${url}/${from}/${to}`)
  }
}
