import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import Icon from 'antd/lib/icon'

import './styles.css'

export default class SearchInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      focus: false
    }
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.highlightComponent = this.highlightComponent.bind(this)
  }

  componentDidMount () {
    window.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.handleClickOutside)
  }

  handleClickOutside (e) {
    const domNode = ReactDOM.findDOMNode(this)

    if ((!domNode || !domNode.contains(e.target))) {
      this.setState({ focus: false })
    }
  }

  highlightComponent () {
    this.setState({ focus: true })
  }

  render () {
    const { className, disabled, placeholder, isSearching = false } = this.props

    return (
      <div className={formatter.toClassName(['wd-search-box', this.state.focus ? ' wd-search-box-focus' : '', className])} onClick={this.highlightComponent}>
        <Icon type='search' className='wd-search-icon' />
        <span className='wd-search-container'>
          <input type='text' className='wd-search-input' placeholder={placeholder || ''} disabled={disabled} onChange={(e) => this.handleChange(e)} onFocus={this.highlightComponent} />
          { isSearching ? <Icon type='loading' className='wd-loading-icon' /> : null }
        </span>

      </div>
    )
  }

  handleChange (e) {
    const { onChange } = this.props

    if (typeof onChange === 'function') {
      onChange(e.target.value)
    }
  }
}

SearchInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}
