import React, { Component } from 'react'
import { connect } from 'react-redux'
import { funderService } from '../../../services'
import { fetchingClients } from '../../../states/actions/client'
import Moment from 'moment-timezone'
import { formatter } from '../../../util'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class FunderClient extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      clientList: [],
      loading: false,
      clientId: ''
    }
    this.filterLogs = this.filterLogs.bind(this)
  }

  componentDidMount () {
    const { currentPage } = this.state
    // this.fetchLogs(currentPage)
    this.fetchClient(currentPage)
  }

  filterLogs (e) {
    this.setState({ currentPage: 1 })
    this.fetchLogs(e.target.value, 1)
  }

  render () {
    const { currentPage, loading, clientList, total } = this.state

    const clientColumns = [
      {
        title: 'Name',
        width: 8,
        render: ({ client_id, first_name, last_name }) => <Link to={`/clients/${client_id}`}><div className='name-label'>{first_name} {last_name}</div></Link>
      },

      {
        title: 'Participant Difficulty',
        width: 6,
        render: ({ payroll_category }) => <div className='name-label' style={{ textTransform: 'capitalize' }}>{payroll_category}</div>
      },

      {
        title: 'Funding ID',
        width: 6,
        key: 'funding_id'
      },

      {
        title: 'Created At',
        width: 4,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      }
    ]

    return (
      <Loading loading={loading} blur>

        <div className='task-list'>
          <Skeleton loading={loading} active>

            <List cols={clientColumns} rows={clientList} />

          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} participants`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchClient = async (startPage = null) => {
    this.setState({ loading: true })
    const { funderId } = this.props
    const { currentPage } = this.state
    const filter = { }

    const page = startPage || currentPage

    filter.funder_id = { condition: '=', value: funderId }

    const { list: clientList, total } = await funderService.listClientByPage(page, pageSize, filter)

    this.setState({ clientList, total, loading: false, currentPage: page, filter })
  }

  // fetchClient = async () => {
  //   try {
  //     const { funderId } = this.props
  //     this.setState({ loading: true })
  //     const { item } = await funderService.getClient(funderId)
  //     const clientList = item
  //     this.setState({ clientList, loading: false })
  //   } catch (e) {
  //     notify.error('Unable to load successfully', 'Unable to load funder successfully. Please try again later.')
  //     this.setState({ loading: false })
  //   }
  // }

  changePage = async (currentPage) => {
    this.fetchClient(currentPage)
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FunderClient))
