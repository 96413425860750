import { FETCHING_PROVIDERS, PROVIDERS_FETCHED } from '../actions/provider'

const INITIAL_STATE = {
  providers: { list: [], total: 0 },
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_PROVIDERS:
      return handleFetchingProviders(state, action)
    case PROVIDERS_FETCHED:
      return handleProvidersFetched(state, action)
    default:
      return state
  }
}

function handleFetchingProviders (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleProvidersFetched (state, action) {
  const { providers, loading } = action
  return { ...state, providers, loading }
}
