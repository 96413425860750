import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_DELETED_JOBS, FETCHING_DELETED_JOBS, DELETED_JOBS_FETCHED } from '../actions/deletedJob'
import jobService from '../../services/job'

function * fetchJobs ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_DELETED_JOBS, loading })
    const jobs = yield jobService.getDeletedJob(currentPage, pageSize, filter, sort, searchText)
    yield put({ type: DELETED_JOBS_FETCHED, jobs, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_DELETED_JOBS, loading: false })
    console.error(e)
  }
}

function * watchDeletedJobs () {
  yield takeEvery(FETCH_DELETED_JOBS, fetchJobs)
}

export default watchDeletedJobs
