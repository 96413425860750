import moment from 'moment-timezone'
import { funderService } from '../services'
import formatter from './formatter'

moment.tz.setDefault('Australia/Melbourne')

export default class DurationBreakdown {
  constructor (funderId, holiday) {
    this.funderId = funderId
    this.holiday = holiday
  }

  async get (startDate, endDate) {
    const rateSet = await funderService.getRateset(this.funderId)

    let normalHourStart = rateSet.normal_hours_start
    let normalHourEnd = rateSet.normal_hours_end
    let afterHourStart = rateSet.after_hours_start
    let afterHourEnd = rateSet.after_hours_end

    const startDateDay = moment(startDate).format('dddd')
    const startDateDayNumber = this.getDayInNumber(startDateDay)
    const endDateDay = moment(endDate).format('dddd')
    const endDateDayNumber = this.getDayInNumber(endDateDay)

    const jobStartTime = moment(moment(startDate).format('hh:mm a'), 'hh:mm a')
    const jobEndTime = moment(moment(endDate).format('hh:mm a'), 'hh:mm a')
    const normalStart = moment(moment(normalHourStart).format('hh:mm a'), 'hh:mm a')
    const normalEnd = moment(moment(normalHourEnd).format('hh:mm a'), 'hh:mm a')
    const afterStart = moment(moment(afterHourStart).format('hh:mm a'), 'hh:mm a')
    const afterEnd = moment(moment(afterHourEnd).format('hh:mm a'), 'hh:mm a')

    const isStartInNormalHour = jobStartTime.isBetween(normalStart, normalEnd)
    const isEndInNormalHour = jobEndTime.isBetween(normalStart, normalEnd)

    const isSameDay = startDate.isSame(endDate, 'day') || endDate.format('hh:mm:ssA') === '12:00:00AM'
    const breakdowns = []

    /*
    console.log('rateSet', rateSet)
    console.log('jobStartTime', formatter.toStandardDate(jobStartTime), 'jobEndTime', formatter.toStandardDate(jobEndTime))
    console.log('normalStart', formatter.toStandardDate(normalStart), 'normalEnd', formatter.toStandardDate(normalEnd))
    console.log('isStartInNormalHour', isStartInNormalHour, 'isEndInNormalHour', isEndInNormalHour)
   */


    if (isSameDay) {
    // 1. All in normal hours
    // 2. Start in normal hours, end in after hours
    // 3. Start in after hours, end in normal hours
    // 4. All in after hours

      if (isStartInNormalHour && isEndInNormalHour) {
        const duration = moment.duration(jobEndTime.diff(jobStartTime)).asHours()

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration,
          day: startDateDayNumber,
          isAfterHours: false
        })
      } else if (isStartInNormalHour && !isEndInNormalHour) {
        const normalDuration = moment.duration(normalEnd.diff(jobStartTime)).asHours()
        const afterDuration = moment.duration(jobEndTime.diff(afterStart)).asHours()

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: normalDuration,
          day: startDateDayNumber,
          isAfterHours: false
        })

        if (afterDuration > 0) {
          breakdowns.push({
            start: jobStartTime,
            end: jobEndTime,
            duration: afterDuration,
            day: startDateDayNumber,
            isAfterHours: true
          })
        }
      } else if (!isStartInNormalHour && isEndInNormalHour) {
        const afterDuration = moment.duration(afterEnd.diff(jobStartTime)).asHours()
        const normalDuration = moment.duration(jobEndTime.diff(normalStart)).asHours()

        if (normalDuration > 0) {
          breakdowns.push({
            start: jobStartTime,
            end: jobEndTime,
            duration: normalDuration,
            day: startDateDayNumber,
            isAfterHours: false
          })
        }

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: afterDuration,
          day: startDateDayNumber,
          isAfterHours: true
        })
      } else {
        let duration = moment.duration(jobEndTime.diff(jobStartTime)).asHours()

        if (moment(endDate).format('hh:mm:ssA') === '12:00:00AM') {
          const midnightEnd = moment(moment(startDate).endOf('day').format('hh:mm:ss a'), 'hh:mm:ss a')
          duration = moment.duration(Math.abs(midnightEnd.diff(jobStartTime))).asHours().toFixed(2)
          duration = Number(duration)
        }

        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration,
          day: startDateDayNumber,
          isAfterHours: true
        })
      }
    } else {
      const midnightEnd = moment(moment().endOf('day').format('hh:mm:ss a'), 'hh:mm:ss a')
      const midnightStart = moment(moment().startOf('day').format('hh:mm a'), 'hh:mm a')

      if (isStartInNormalHour) {
        // First day - normal hours
        const normalDuration = moment.duration(normalEnd.diff(jobStartTime)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: normalDuration,
          day: startDateDayNumber,
          isAfterHours: false
        })

        const afterDuration = moment.duration(midnightEnd.diff(normalEnd)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: Math.round(afterDuration * 100) / 100,
          day: startDateDayNumber,
          isAfterHours: true
        })
      } else {
        // First day - after hours
        const afterDuration = moment.duration(midnightEnd.diff(jobStartTime)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: Math.round(afterDuration * 100) / 100,
          day: startDateDayNumber,
          isAfterHours: true
        })
      }

      // Second day
      const isEndInAfterHour = jobEndTime.isBetween(midnightStart, afterEnd)

      if (isEndInAfterHour) {
        // Second day - after hours
        const nextAfterDuration = moment.duration(jobEndTime.diff(midnightStart)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: nextAfterDuration,
          day: endDateDayNumber,
          isAfterHours: true
        })
      } else {
        // Second day - after hours
        const nextAfterDuration = moment.duration(afterEnd.diff(midnightStart)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: nextAfterDuration,
          day: endDateDayNumber,
          isAfterHours: true
        })
        // Second day - normal hours
        const nextNormalDuration = moment.duration(jobEndTime.diff(normalStart)).asHours()
        breakdowns.push({
          start: jobStartTime,
          end: jobEndTime,
          duration: nextNormalDuration,
          day: endDateDayNumber,
          isAfterHours: false
        })
      }
    }
    return this.output(breakdowns)
  }

  output (breakdowns) {
    let breakdown = ''

    for (let i = 0; i < breakdowns.length; i++) {
      const { start, end, duration, day, isAfterHours } = breakdowns[i]

      if (i === 0) {
        breakdown = `${start.format('h:mmA')} - ${end.format('h:mmA')} (`
      }

      if (duration > 0) {
        // breakdown += `${duration}H ${day < 6 ? 'Weekday' : day === 6 ? 'Sat' : 'Sun'}${isAfterHours ? ' A/H, ' : ', '}`
        breakdown += `${formatter.toDecimal(duration)}H ${this.holiday ? 'P/H' : day < 6 ? 'Weekday' : day === 6 ? 'Sat' : 'Sun'}${isAfterHours ? ' A/H, ' : ', '}`
      }
    }

    breakdown = breakdown.substr(0, breakdown.length - 2) // + `)`

    if (breakdown.indexOf('(') > 0) {
      breakdown += `)`
    }

    // console.log('Time Breakdown: [', breakdown, ']')

    return breakdown
  }

  getDayInNumber (dateDay) {
    return (dateDay === 'Monday') ? 1 : (dateDay === 'Tuesday') ? 2 : (dateDay === 'Wednesday') ? 3 : (dateDay === 'Thursday') ? 4 : (dateDay === 'Friday') ? 5 : (dateDay === 'Saturday') ? 6 : 7
  }
}
