import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { settingOtherService } from '../../../../services/setting'
import moment from 'moment'
import { authService } from '../../../../services'

// UI
import { DateTimePicker, Loading, Page, Panel } from '../../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'
import notify from '../../../../components/Notification'

const { Item: FormItem } = Form

const settingTitle = 'Accounting Ref'
const settingType = 'Public Holiday'

export class SettingAccRef extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      loading: false,
      loadingForm: false,
      settings: {}
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.listByPage(1, 0)
      this.setState({ settings: settings.list[0], loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { settings } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = settings
        this.setState({ loadingForm: true })

        /*  console.log(values.date1, values.date2)

        delete values.date1
        delete values.date2 */

        try {
          let response = await settingOtherService.save(id, values)

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { loading, loadingForm, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }

    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <div>
        <Page.Header title='Accounting Ref'>
          {
            this.hasAccess('updateOther') ? <div className='btn' onClick={() => this.handleSave()}>
            Save
            </div> : null
          }
        </Page.Header>
        <Loading loading={loadingForm} blur>
          <div className='setting-list'>
            <Skeleton loading={loading} active>
              <Form>
                <Panel>
                  <Row>
                    <Col lg={12}>
                      <FormItem label='Participant' {...formItemLayout}>
                        {getFieldDecorator('client_acc_ref', {
                          initialValue: settings.client_acc_ref,
                          rules: [
                            { required: true, message: 'Please enter participant acc ref' }
                          ]
                        })(
                          <Input prefix='SC' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Employee' {...formItemLayout}>
                        {getFieldDecorator('employee_acc_ref', {
                          initialValue: settings.employee_acc_ref,
                          rules: [
                            { required: true, message: 'Please enter employee acc ref' }
                          ]
                        })(
                          <Input prefix='E' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Funder' {...formItemLayout}>
                        {getFieldDecorator('funder_acc_ref', {
                          initialValue: settings.funder_acc_ref,
                          rules: [
                            { required: true, message: 'Please enter funder acc ref' }
                          ]
                        })(
                          <Input prefix='F' />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={12}>
                      <FormItem label='Provider' {...formItemLayout}>
                        {getFieldDecorator('provider_acc_ref', {
                          initialValue: settings.provider_acc_ref,
                          rules: [
                            { required: true, message: 'Please enter provider acc ref' }
                          ]
                        })(
                          <Input prefix='P' />
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                </Panel>
              </Form>

            </Skeleton>
          </div>
        </Loading>
      </div>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingAccRef))
