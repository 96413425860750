export default [
  {
    name: 'Day Counts Since Last Job',
    desc: 'View participant\'s day counts since last job',
    icon: 'calendar',
    link: '/reports/client-last-job',
    permission: 'readClientLastJobCount',
    enable: true
  },
  {
    name: 'Participants\'s Additional Reporting',
    desc: 'Manage participant\'s additional reporting',
    icon: 'snippets',
    link: '/reports/client-additional-reporting',
    permission: 'readClientAdditionalReporting',
    enable: true
  },
  {
    name: 'Participants\'s Plan Details',
    desc: 'Manage participant\'s allocated job hours',
    icon: 'clock-circle',
    link: '/reports/client-plan-details',
    permission: 'readClientJobBalanceHourReport',
    enable: true
  },
  {
    name: 'Participants\' Plan Expiry',
    desc: 'Manage participants\' plan expiry',
    icon: 'dollar',
    link: '/reports/client-plan-expiry',
    permission: 'readClientFundReport',
    enable: true
  },
  {
    name: 'Participants\' Reports Due',
    desc: 'Manage participant\'s implementation and progress due',
    icon: 'issues-close',
    link: '/reports/client-due',
    permission: 'readClientDueReport',
    enable: true
  },
  // {
  //   name: 'Billings List',
  //   desc: 'Manage billings list',
  //   icon: 'team',
  //   link: '/billings',
  //   enable: true,
  //   permission: 'listPayrolls'
  // },
  {
    name: 'User Action',
    desc: 'Manage category and sub-category of products',
    icon: 'fund',
    permission: 'readActionReport',
    link: '/reports/action',
    enable: true
  },
  {
    name: 'Recipient Settings',
    desc: '',
    icon: 'setting',
    permission: 'listRecipients',
    link: '/reports/setting',
    enable: true
  }
]
