import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

export default class ControlLabel extends Component {
  render () {
    const { children } = this.props

    return (
      <div className='wd-control-label'>{children}</div>
    )
  }
}

ControlLabel.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.object
}
