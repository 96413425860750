import { apiHostname } from '../config'
import authService from '../services/auth'
import { localStorage, request } from './index'

export default {
  getProps (apiUrl, options) {
    const defaultOptions = {
      name: 'image',
      accept: '.jpg, .jpeg, .png',
      multiple: false
    }
    options = Object.assign(defaultOptions, options || {})
    const { accept, data, name, multiple, beforeUpload, onError, onProgress, onStart, onSuccess } = options
    return {
      action: `${apiHostname}${apiUrl}`,
      headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
      data,
      name,
      accept,
      multiple,
      beforeUpload (file) {
        if (typeof beforeUpload === 'function') {
          beforeUpload(file)
        }
      },
      onError (err, response, file) {
        if (typeof onError === 'function') {
          onError(err, response, file)
        } else {
          console.error(err, response, file)
        }
      },
      onProgress (step, file) {
        if (typeof onProgress === 'function') {
          onProgress(step, file)
        }
      },
      onStart (file) {
        if (typeof onStart === 'function') {
          onStart(file)
        }
      },
      onSuccess (result, file, req) {
        if (typeof onSuccess === 'function') {
          onSuccess(result, file, req)
        }
      }
    }
  },
  async upload (error, file, _id, token, type = 'image') {
    const { method, url } = error
    const formData = new window.FormData()
    formData.append('_id', _id)
    formData.append(type, file)
    localStorage.setItem('token', token)
    return request(url, {
      method,
      body: formData
    })
  }
}
