import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { jobService, payrollService, employeeService, clientService } from '../../../services'
import { PayrollMenu } from '../../../constants'
import { formatter, log } from '../../../util'
import moment from 'moment'
import debounce from 'lodash.debounce'

// UI
import { ControlLabel, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Row from 'antd/lib/row'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Popover from 'antd/lib/popover'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Tag from 'antd/lib/tag'
import Tooltip from 'antd/lib/tooltip'

const Option = Select.Option
const pageSize = 30

let currentKMs = 0
let currentStatus = ''

export class Payroll extends Component {
  constructor (props) {
    super(props)
    const { payrolls: { list, total }, loading } = props
    this.state = {
      employeeIds: [],
      currentPage: 1,
      filter: { payroll_id: 1, employee_id: 1 },
      list: [],
      loading,
      searchText: '',
      showKM: false,
      sort: {},
      suburbs: [],
      total,
      leaveId: null,
      leaveStart: '',
      leaveEnd: '',
      employee: ''
    }

    this.delayedUpdateKM = debounce(this.delayedUpdateKM, 500)
    this.onSearch = debounce(this.onSearch, 500)
  }

  componentDidMount () {
    // const { match } = this.props
    // const { params } = match
    // const { id, eid } = params

    // const filter = {}
    // filter.payroll_id = id
    // filter.employee_id = eid

    // const { currentPage, loading, searchText, sort } = this.state
    // this.fetchEmployeeTimesheetSummary(id, eid)
    // this.fetchEmployeeTimesheets({ currentPage, filter, loading, searchText, sort })
    // this.fetchEmployeeLeaveInfo(eid)
    // this.setState({ filter })

    const { currentPage, loading, searchText, sort } = this.state
    const { match } = this.props
    const { params } = match
    const { pid, view, id } = params

    const filter = { payroll_id: pid }

    if (view === 'employee') {
      filter.employee_id = id
    } else {
      filter.client_id = id
    }

    this.fetchSummary(pid, id)
    this.fetchTimesheet({ currentPage, filter, loading, searchText, sort })
    this.fetchLeaveInfo(id)
    this.setState({ filter })
  }

  onSearch (value) {
    const { currentPage, filter, loading, sort } = this.state
    this.setState()
    this.fetchTimesheet({ currentPage: 1, filter, loading, searchText: value, sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onFilter (event) {
    const { filter, loading, sort, searchText } = this.state
    const value = event.target.value

    if (value === 'all') {
      delete filter.status
    } else {
      if (value !== 'approved') {
        filter.status = { condition: '!=', value: 'approved' }
      } else {
        filter.status = value
      }
    }

    this.fetchTimesheet({ currentPage: 1, filter, loading, searchText, sort })
    this.setState({ filter })
  }

  updateKMs (e) {
    currentKMs = e.target.value
    return false
  }

  async saveKMs (id) {
    const { list } = this.state
    const result = await jobService.save(id, { kms: currentKMs })

    if (result.id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          list[i].kms = currentKMs
          break
        }
      }
    }

    let totalKM = 0

    for (let i = 0; i < list.length; i++) {
      totalKM += parseFloat(list[i].kms)
    }

    this.setState({ list, kms: totalKM })
  }

  async saveStatus (id, value) {
    const { list } = this.state
    const result = await jobService.save(id, { status: value })

    if (result.id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          list[i].status = value
          break
        }
      }
    }

    this.setState({ list })
  }

  updateKM = async (id, event) => {
    event.persist()

    this.delayedUpdateKM(id, event)
  }

  delayedUpdateKM = async (id, event) => {
    const currentKMs = event.target.value

    const { list } = this.state
    const result = await jobService.save(id, { kms: currentKMs, is_updated: true })

    if (result.id) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].id === id) {
          const prevKMs = list[i].kms
          log.updateJob(id, { kms: prevKMs }, { kms: currentKMs })
          list[i].kms = currentKMs
          break
        }
      }
    }

    let totalKM = 0

    for (let i = 0; i < list.length; i++) {
      totalKM += parseFloat(list[i].kms)
    }

    this.setState({ list, kms: totalKM })
  }

  render () {
    const { currentPage, list, jobs = 0, date_from, date_to, employee, client, loading, total = 0, kms = 0, hours = 0, sleepovers = 0,
      leaveId = null, leaveStart = '', leaveEnd = '' } = this.state
    const { match: { params: { pid } } } = this.props
    //console.log('List', list)
    /*
    const PopoverKM = ({ id, value, children }) => {
      currentKMs = value
      return <Popover content={<div>
        <Input onChange={(e) => this.updateKMs(e)} defaultValue={value} style={{ width: 120 }} />
        <span className='timesheet-action-button' onClick={() => this.saveKMs(id)}> <Icon type='check-circle' theme='filled' /></span></div>} title='Update KMs'>
        { children }
      </Popover>
    }

    const PopoverStatus = ({ id, value, children }) => {
      currentStatus = value
      return <Popover
        content={<Select style={{ width: 150 }} onChange={(e) => this.saveStatus(id, e)} defaultValue={value}>
          <Option value='approved'>Approved</Option>
          <Option value='to_review'>To review</Option>
        </Select>}
        title='Update Status'
      >
        { children }
      </Popover>
    } */

    const columns = [
      {
        title: '',
        width: 1,
        render: null
      },
      {
        title: 'Day',
        width: 3,
        render: ({ job_start_date: startDate }) => startDate ? moment(startDate).format('dddd') : '-'
      },
      {
        title: 'Date',
        width: 5,
        render: ({ job_start_date: startDate, holiday_date, holiday_name }) => holiday_date ? <div>{formatter.toShortDate(startDate)} <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> </div> : formatter.toShortDate(startDate)
      },
      {
        title: 'Hours',
        width: 6,
        key: 'job_hours',
        render: ({ job_hours }) => job_hours
      },
      this.isEmployeeView() ? {
        title: 'Participant',
        width: 6,
        key: 'client',
        render: ({ client, client_leave_id, client_leave_start, client_leave_end }) => client_leave_id ? <div>{client} <Tooltip title={`Leave ${formatter.toShortDate(client_leave_start)} - ${formatter.toShortDate(client_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div> : client
      } : {
        title: 'Employee',
        width: 6,
        key: 'employee',
        render: ({ employee, employee_leave_id, employee_leave_start, employee_leave_end }) => employee_leave_id ? <div>{employee} <Tooltip title={`Leave ${formatter.toShortDate(employee_leave_start)} - ${formatter.toShortDate(employee_leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div> : employee
      },
      {
        title: 'Funder',
        width: 4,
        key: 'funder'
      },
      {
        title: 'Status',
        width: 1,
        render: ({ id, status }) => <div style={{ color: '#ccc', fontSize: '12pt' }}>
          <Tooltip mouseLeaveDelay={0} title='Approve?'>{ status === 'approved' ? <span style={{ color: '#4fbc85' }}><Icon type='check-circle' theme='filled' /></span> : <Icon type='check-circle' onClick={() => this.saveStatus(id, 'approved')} /> }</Tooltip> &nbsp;
          <Tooltip mouseLeaveDelay={0} title='To review?'>{ status !== 'approved' ? <span style={{ color: '#f50' }}><Icon type='exclamation-circle' theme='filled' /></span> : <Icon type='exclamation-circle' onClick={() => this.saveStatus(id, 'review')} /> }</Tooltip>
        </div>
      },
      {
        title: '',
        width: 1,
        render: ({ job_id: jobId }) => <div style={{ textAlign: 'right', width: '40px' }}>
          <a href={`/jobs/single/${jobId}`} target='_blank' className='button' ><Icon type='eye' /></a>
          {/* <Link to={`/jobs/single/${jobId}`} className='button'><Icon type='eye' /></Link> */}
        </div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Timesheet' menu={PayrollMenu} backLink={`/billings/${pid}`} />
        </Page.Left>
        <Page.Content full>
          <Page.Header icon={<Icon type='user' />} title={`${this.isEmployeeView() ? employee : client}'s Timesheet`} leaveFlag={ leaveId ? <Tooltip title={`Leave ${formatter.toShortDate(leaveStart)} - ${formatter.toShortDate(leaveEnd)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null } total={`${formatter.toShortDate(date_from)} - ${formatter.toShortDate(date_to)}`}>
            <Link to={`/billings/${pid}`}>
              <div className='btn'>Back</div>
            </Link>
          </Page.Header>

          <div className='page-summary'>
            <Row>
              <Col lg={12} className='col'>
                <div className='number'>{ jobs }</div>
                Jobs
              </Col>
              <Col lg={12} className='col'>
                <div className='number'>{ formatter.toDecimal(hours) }</div>
                Hours
              </Col>
            </Row>
          </div>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Search</ControlLabel>
                <SearchInput placeholder='Participant, Funder' onChange={(v) => this.onSearch(v)} isSearching={loading} />
              </Col>
              <Col lg={8}>
                <ControlLabel>Status</ControlLabel>
                <Radio.Group defaultValue='all' onChange={(v) => this.onFilter(v)}>
                  <Radio.Button value='all'> All </Radio.Button>
                  <Radio.Button value='approved'>Approved</Radio.Button>
                  <Radio.Button value='review'>To Review</Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <Form>
            <div className='payroll'>
              <Skeleton loading={loading} active>
                <List cols={columns} rows={list} />
              </Skeleton>
            </div>
          </Form>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} jobs`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchTimesheet({ currentPage, filter, searchText, sort })
  }

  fetchSummary = async (payrollId, id) => {
    try {
      this.setState({ loading: true })

      let timesheet

      if (this.isEmployeeView()) {
        timesheet = await payrollService.getEmployeeTimesheetSummary(payrollId, id)
      } else {
        timesheet = await payrollService.getClientTimesheetSummary(payrollId, id)
      }

      const { jobs, kms, hours, sleepovers, date_from, date_to, employee, client } = timesheet
      this.setState({ jobs, kms, hours, sleepovers, loading: false, date_from, date_to, employee, client })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  fetchTimesheet = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    try {
      this.setState({ loading: true })
      let timesheet

      if (this.isEmployeeView()) {
        timesheet = await payrollService.listEmployeeTimesheetByPage(currentPage, pageSize, filter, sort, searchText)
      } else {
        timesheet = await payrollService.listClientTimesheetByPage(currentPage, pageSize, filter, sort, searchText)
      }

      const { list, total } = timesheet
      this.setState({ currentPage, total, list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load timesheet successfully. Please try again later.')
    }
  }

  fetchLeaveInfo = async (id) => {
    let individual

    if (this.isEmployeeView()) {
      individual = await employeeService.get(id)
    } else {
      individual = await clientService.get(id)
    }

    const { item } = individual
    const { leave_id: leaveId, leave_start: leaveStart, leave_end: leaveEnd } = item
    this.setState({ leaveId, leaveStart, leaveEnd })
  }

  getTimesheetView () {
    const { match } = this.props
    const { params } = match
    const { view } = params

    return view || 'employee'
  }

  isEmployeeView () {
    return this.getTimesheetView() === 'employee'
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state.Payroll }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Payroll))
