import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'

import { jobService, authService } from '../../../services'
import { apiHostname } from '../../../config'
import formatter from '../../../util/formatter'

// UI
import { Loading, List, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import AddJobModal from '../../Job/AddJobModal'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const { confirm } = Modal
const { RangePicker } = DatePicker

export class EmployeeJobs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterParam: 'all',
      loading: false,
      jobList: [],
      searchText: '',
      showExportDate: false,
      showJobModal: false,
      showReminderDate: false,
      total: 0
    }
    this.filterJob = this.filterJob.bind(this)
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    const { currentPage, filter, filterParam, loading, searchText, sort } = this.state
    this.fetchEmployeeJob({ currentPage, filter, loading, searchText, sort, filterParam })
  }

  filterJob (e) {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchEmployeeJob({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, loading, sort } = this.state
    if (value && value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_firstname: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_lastname: { condition: 'ilike', value: `%${words[i]}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_start_date: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
        // console.log('filters:', filter)
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchEmployeeJob({ currentPage, filter, loading, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  async exportJobList (d1, d2) {
    const { empId } = this.props

    this.setState({ showExportDate: false })

    window.location.href = `${apiHostname}/api/export/jobs/employee/${empId}/${Moment(d2[0]).format('YYYY-MM-DD')}/${Moment(d2[1]).format('YYYY-MM-DD')}`
  }


  render () {
    const { currentPage, loading, jobList, total, showExportDate, showJobModal } = this.state
    const { active } = this.props

    const columns = [
      {
        title: 'Date',
        width: 2,
        render: ({ job_start_date, holiday_date, holiday_name }) => holiday_date ? <div>{formatter.toShortDate(job_start_date)} <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> </div> : formatter.toShortDate(job_start_date)
      },
      {
        title: 'Participant',
        width: 4,
        render: ({ first_name, last_name, client_id }) => <a href={`/clients/${client_id}`} rel='noopener noreferrer' target='_blank'>{first_name} {last_name}</a>
      },
      {
        title: 'Service',
        width: 4,
        render: ({ service }) => <div style={{ textTransform: 'capitalize' }}>{service}</div>
      },
      {
        title: 'Task',
        width: 6,
        render: ({ tasks }) => <div style={{ fontSize: 10 }}>{formatter.toShortenText(tasks, 120)}</div>
      },
      {
        title: 'Duration',
        width: 3,
        render: ({ job_duration }) => <div>{Math.floor(job_duration)/60}H ({this.getDurationText(job_duration)})</div>
      },
      {
        title: 'Action',
        width: 1,
        render: ({ id }) => <div className='action-buttons'><Link to={`/jobs/single/${id}`}><Tooltip mouseLeaveDelay={0} title='Manage Job'> <Icon type='form' style={{ color: 'orange'}} /></Tooltip></Link></div>
      }
    ]

    return (
      <Loading loading={loading} blur>

        <div className='search-bar'>

          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Participant, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={10}>
              <div className='clearfix' />
            </Col>
            <Col lg={6} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
              {
                this.hasAccess('createJob') ? <div className='btn' onClick={() => this.addJob()}>
              Add Job
                </div>
                  : null
              }
              <div>
                <div style={{ display: showExportDate ? 'inline' : 'none' }}>
                  <RangePicker format='YYYY-MM-DD' open={showExportDate} onChange={(d1, d2) => this.exportJobList(d1, d2)}/>
                </div>
                <div className='btn btn-ghost' onClick={() => this.setState({ showExportDate: true })}>
                  Download Job List
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <Radio.Group onChange={this.filterJob} defaultValue='all' style={{ marginLeft: 20 }}>
          <Radio.Button value='all'>All</Radio.Button>
          <Radio.Button value='cancel'>Cancelled</Radio.Button>
        </Radio.Group>

        <div className='job-list'>
          <Skeleton loading={loading} active>

            <List cols={columns} rows={jobList} />

          </Skeleton>
        </div>
        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} jobs`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

        <AddJobModal active={active} visible={showJobModal} history={this.props.history} onClose={() => this.setState({ showJobModal: false })} />

      </Loading>
    )
  }

  fetchEmployeeJob = async ({ startPage = null, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    const { empId } = this.props
    const sorting = { job_start_date: -1 }
    const nowDate = Moment().startOf('day')

    const page = startPage || currentPage

    if (filterParam === 'all') {
      filter.emp_id = { condition: '=', value: empId }
      filter.is_cancel = false
      sorting.job_start_date = 0
    } else if (filterParam === 'cancel') {
      delete filter.job_start_date
      filter.emp_id = { condition: '=', value: empId }
      filter.is_cancel = true
    } else {
      filter.emp_id = { condition: '=', value: empId }
      filter.job_start_date = { condition: '>=', value: nowDate }
      filter.is_cancel = false
      sorting.job_start_date = 0
    }

    const { list: jobList, total } = await jobService.listByPage(page, pageSize, filter, sorting, searchText)
    this.setState({ jobList, type: filterParam, total, loading: false, currentPage: page, filter, sorting, searchText })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, type } = this.state
    this.fetchEmployeeJob({ currentPage, filter, searchText, sort, filterParam: type })
  }

  getDurationText = (duration = 0) => {
    const hour = Math.floor(duration / 60)
    const hourText = 'H'
    const minutes = duration % 60
    const minText = 'M'
    return `${hour}${hourText}${minutes}${minText}`
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(EmployeeJobs))
