import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../constants/menu/report'

// UI
import { Page, Panel } from '../../components'
import CategoryMenu from '../../components/CategoryMenu'

import './styles.css'

export class Report extends Component {
  componentDidMount () {

  }

  render () {
    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Reports' menu={ReportMenu} />
        </Page.Left>
        <Page.Content>
          <Page.Header title='Reports' />
          <Panel color='orange'>
            Statistic & reports
          </Panel>
          <CategoryMenu menu={ReportMenu} />
        </Page.Content>
        <Page.Right>
          <Page.Panel title='Tips'>
            Generate more sales
          </Page.Panel>
        </Page.Right>
      </Page.Body>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.Report
})

export default connect(mapStateToProps, {

})(Report)
