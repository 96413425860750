import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/api/report'

export default {
  get (type) {
    return request(`${url}/${type}`)
  },
  getByDate (action, from, to) {
    return request(`${url}/${action}/${from}/${to}`)
  },
  getActionJobs (page, size, filter, sorting, text) {
    return request(`${url}/action/jobs/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getClashJobs (page, size) {
    return request(`${url}/job-clash/page/${page}/size/${size}`)
  },
  getBackToBackJobs (page, size) {
    return request(`${url}/job-back-to-back/page/${page}/size/${size}`)
  },
  getPlanDetails (page, size, filter, sorting, text) {
    return request(`${url}/client-funding-hour-balance/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  }
}
