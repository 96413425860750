export default [
  {
    name: 'File',
    icon: 'file',
    link: '/settings/files',
    desc: 'Configure file settings',
    enable: true,
    permission: 'listFiles'
  },
  {
    name: 'Funders',
    icon: 'apartment',
    link: '/settings/funder',
    desc: 'Configure Funders settings',
    enable: true,
    permission: 'listFunderSettings'
  },
  {
    name: 'Public Holidays',
    icon: 'calendar',
    link: '/settings/holidays',
    permission: 'listPublicHolidays',
    enable: true,
    desc: 'Configure public holidays for each year'
  },
  {
    name: 'Profile',
    icon: 'idcard',
    link: '/settings/profile',
    permission: 'listProfileSettings',
    enable: true,
    desc: 'Configure profile settings'
  },
  {
    name: 'Reasons',
    link: '/settings/reasons',
    icon: 'exception',
    desc: 'Configure reasons of inactive',
    enable: true,
    permission: 'listCancellations'
  },
  {
    name: 'Cancellation',
    link: '/settings/cancellations',
    icon: 'exception',
    desc: 'Configure reasons of cancellation',
    enable: true,
    permission: 'listCancellations'
  },
  {
    name: 'Services',
    link: '/settings/services',
    icon: 'customer-service',
    desc: 'Configure services from providers',
    enable: true,
    permission: 'listServices'
  },
  {
    name: 'Suburbs',
    link: '/settings/suburbs',
    icon: 'layout',
    desc: 'Configure suburbs',
    enable: true,
    permission: 'listSuburbs'
  },
  {
    name: 'Others',
    link: '/settings/others',
    icon: 'setting',
    desc: 'Configure other settings',
    enable: true,
    permission: 'listOthers'
  }
]
