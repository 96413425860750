import React, { Component } from 'react'
import Moment from 'moment-timezone'
import { clientService, providerService } from '../../../services'
import { formatter, validator } from '../../../util'

import { Button, Loading, SideModal } from '../../../components'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'
import Blacklist from '../Blacklist'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)
const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

export class AddProviderModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clients: [],
      funders: [],
      loading: false,
      providers: [],
      spinLoading: false,
      serviceList: [],
      item: {},
      isActiveDisabled: true,
      isEndDateMandatory: true
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { providers = [], item = {} } = nextProps

    return { ...prevState, providers, item }
  }

  async componentDidUpdate (prevProps) {
    const prev = prevProps.item
    const curv = this.props.item

    if (prev && curv) {
      if (prev.id !== curv.id) {
        if (curv.id && curv.provider_id) {
          const { clientId } = this.props
          this.setState({spinLoading: true})
          let values = {}
          values.clientId = clientId
          values.providerId = curv.provider_id
          values.providerClientId = curv.id
          values.selectedServices = curv.selected_services
          // const r = await providerService.getServices(curv.provider_id, clientId)
          const r = await providerService.getProviderClientServices(values)

          this.setState({
            spinLoading: false,
            serviceList: r && r.services && validator.isNotEmptyArray(r.services) ? r.services : [],
            isActiveDisabled: !curv.start_date,
            isEndDateMandatory: !curv.active
          })
        } else {
          this.setState({
            serviceList: [],
            isActiveDisabled: true,
            isEndDateMandatory: true
          })
        }
      }
    }
  }

  handleSubmit = () => {
    const { form, isBlacklist, isEdit = false } = this.props
    const { item, providers } = this.state
    const { validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const p = providers.find(e => e.id === values.provider_id)
        const providerName = p ? p.fullname : 'this provider'
        const title = `Are you sure you want to ${isBlacklist ? `blacklist ${providerName} for the participant` : `add ${providerName} to the participant`}?`

        { isEdit && item.id
          ? this.updateProvider(values, this)
          : confirm({
            title,
            content: 'Press Ok to continue, Cancel to return',
            async onOk () {
              that.addProvider(values, that)
            }
          })
        }
      }
    })
  }

  addProvider = async (values, that) => {
    const { isBlacklist, clientId, onUpdate, form } = that.props
    const { providers, serviceList } = that.state
    const { resetFields } = form
    const p = providers.find(e => e.id === values.provider_id)
    const s = serviceList.filter(e => !!values.selected_services.find(f => f === e.id)).map(e => e.name).join(', ')
    const pName = p && s ? (`${p.fullname}, Services ${s}`) : p ? p.fullname : ''

    try {
      const body = {
        provider_id: values.provider_id,
        selected_services: values.selected_services,
        is_blacklist: isBlacklist,
        reason: values.reason || '',
        cmsc_first_name: !isBlacklist ? values.cmsc_first_name : '',
        cmsc_contact: !isBlacklist ? values.cmsc_contact : '',
        cmsc_email: !isBlacklist ? values.cmsc_email : '',
        active: values.active,
        start_date: values.start_date ? Moment(values.start_date).format(dbFormat) : null,
        end_date: values.end_date ? Moment(values.end_date).format(dbFormat) : null,
        notes: values.notes,
        blacklist_action_date: isBlacklist ? Moment().startOf('day').format(dbFormat) : null
      }
      that.setState({loading: true})
      const r = await clientService.addClientProvider(clientId, body)

      resetFields()
      typeof onUpdate === 'function' && onUpdate(r, pName, isBlacklist)
      that.setState({loading: false})
    } catch (e) {
      typeof onUpdate === 'function' && onUpdate(e, pName, isBlacklist)
      that.setState({loading: false})
    }
  }

  updateProvider = async (values) => {
    const { isBlacklist, isEdit, clientId, onUpdate, form } = this.props
    const { item, providers, serviceList } = this.state
    const { resetFields } = form
    const p = providers.find(e => e.id === values.provider_id)
    const s = isBlacklist && isEdit
      ? serviceList.filter(e => !!item.selected_services.find(f => f === e.id)).map(e => e.name).join(', ')
      : serviceList.filter(e => !!values.selected_services.find(f => f === e.id)).map(e => e.name).join(', ')
    const pName = p && s ? (`${p.fullname}, Services ${s}`) : p ? p.fullname : ''

    try {
      let body = {}

      if (!isBlacklist) {
        body = {
          selected_services: values.selected_services,
          is_blacklist: isBlacklist,
          reason: '',
          cmsc_first_name: values.cmsc_first_name || '',
          cmsc_contact: values.cmsc_contact || '',
          cmsc_email: values.cmsc_email || '',
          active: values.active || false,
          start_date: values.start_date ? Moment(values.start_date).format(dbFormat) : null,
          end_date: (values.end_date ? Moment(values.end_date).format(dbFormat) : null),
          notes: values.notes,
          blacklist_action_date: null
        }
      } else {
        body = {
          is_blacklist: isBlacklist,
          reason: values.reason || '',
          end_date: Moment().format(dbFormat),
          blacklist_action_date: Moment().startOf('day').format(dbFormat)
        }
      }

      this.setState({loading: true})
      const r = await clientService.updateClientProvider(clientId, item.id, body)

      resetFields()
      typeof onUpdate === 'function' && onUpdate(r, pName, isBlacklist, true)
      this.setState({loading: false})
    } catch (e) {
      typeof onUpdate === 'function' && onUpdate(e, pName, isBlacklist, true)
      this.setState({loading: false})
    }
  }

  findProviders = (input, option) => {
    const p = `${option.props.children}`
    return p.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  onSelectProvider = async (value) => {
    const { isBlacklist, clientId } = this.props
    this.setState({spinLoading: true})
    const r = await providerService.getServices(value, clientId)
    this.setState({spinLoading: false, serviceList: r && r.services && validator.isNotEmptyArray(r.services) ? r.services : []})
  }

  handleProviderActiveChange = async (value) => {
    const { form } = this.props
    if (value === false) {
      this.setState({ isEndDateMandatory: true })
    } else {
      form.setFieldsValue({ end_date: '' })
      this.setState({ isEndDateMandatory: false })
    }
  }

  handleStartDateSelected = async (value) => {
    const { form, isBlacklist } = this.props
    const date = value === undefined ? form.getFieldValue('start_date') : value
    const active = form.getFieldValue('active')
    let status = true
    let isUpdateEndDate = false

    if (date && !isBlacklist) {
      status = false
      form.setFieldsValue({ active: true })
      isUpdateEndDate = false
    } else {
      form.setFieldsValue({ active: false })
      isUpdateEndDate = true
      status = true
    }

    this.setState({isActiveDisabled: status, isEndDateMandatory: isUpdateEndDate})
  }

  validateEndDate = (rule, value, callback) => {
    const { isEndDateMandatory } = this.state

    if (!isEndDateMandatory) {
      callback()
    } else {
      if (value) {
        callback()
      } else {
        callback(new Error('Please enter end date'))
      }
    }

    callback(new Error('Please enter end date'))
  }

  render () {
    const { form, visible, onClose, isBlacklist, isEdit, clientId } = this.props
    const { clients, currentStep, funders, item, loading, providers = [], spinLoading, serviceList, isActiveDisabled, isEndDateMandatory } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isBlacklist ? 'Add Blacklisted Provider' : isEdit ? 'Update Provider' : 'Add Provider'
    const providersList = !isEdit ? providers.filter(e => e.is_excluded === false) : providers

    return (
      // <Modal visible={visible}
      //   width={700}
      //   title={title}
      //   onOk={this.handleSubmit}
      //   onCancel={onClose}
      //   destroyOnClose={true}
      //   footer={[
      //     <Loading loading={loading} blur>
      //       <Button key='ok' type='primary' onClick={this.handleSubmit}>{isEdit ? 'Update' : 'Add'}</Button>
      //     </Loading>
      //   ]}
      // >
      <SideModal
        key={`sidem_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              { !isBlacklist
                ? <FormItem label='Active'>
                  {getFieldDecorator('active', {
                    initialValue: isEdit ? item.active : false,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      onChange={this.handleProviderActiveChange}
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      disabled={isActiveDisabled}
                    />
                  )}
                </FormItem>
                : null }

              { !isBlacklist
                ? <FormItem label='Start Date'>
                  {getFieldDecorator('start_date', {
                    initialValue: isEdit && item.start_date ? Moment(item.start_date) : ''
                  })(
                    <DatePicker format={dateFormat} onChange={this.handleStartDateSelected} />
                  )}
                </FormItem>
                : null }

              { !isBlacklist && isEndDateMandatory
                ? <FormItem label='End Date'>
                  {getFieldDecorator('end_date', {
                    initialValue: isEdit && item.end_date ? Moment(item.end_date) : '',
                    rules: [
                      { required: isEndDateMandatory, message: 'Please enter end date' }
                      // { validator: this.validateEndDate }
                    ]
                  })(
                    <DatePicker format={dateFormat} />
                  )}
                </FormItem>
                : null }

              <FormItem label='Provider' hasFeedback>
                {getFieldDecorator('provider_id', {
                  initialValue: item.provider_id || '',
                  rules: [
                    { required: true, message: 'Please select a provider' }
                  ]
                })(
                  <Select showSearch
                    style={{ width: '100%' }}
                    placeholder='Select a provider'
                    optionFilterProp='children'
                    notFoundContent='No available providers'
                    filterOption={(input, option) => this.findProviders(input, option)}
                    onChange={(e, c) => this.onSelectProvider(e, c)}
                    disabled={isEdit}
                  >
                    {
                      providersList.map((pvd) => (
                        <Option key={pvd.id} value={pvd.id}>
                          {pvd.fullname}
                        </Option>))
                    }
                  </Select>
                )}
              </FormItem>
              { !isBlacklist || (Blacklist && !isEdit)
                ? <FormItem label={!isBlacklist ? 'Preferred Services' : 'Blacklisted Services'} hasFeedback>
                  {getFieldDecorator('selected_services', {
                    initialValue: Array.isArray(item.selected_services) ? item.selected_services : [],
                    rules: [
                      { required: true, message: 'Please select services' }
                    ]
                  })(
                    <Select
                      mode='multiple'
                      showSearch
                      filterOption={(input, option) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      disabled={!form.getFieldValue('provider_id')}
                    >
                      {
                        serviceList.map((service) => {
                          return <Option key={service.id} value={service.id}>{service.name}</Option>
                        })
                      }
                    </Select>
                  )}
                </FormItem>
                : null }
              { isBlacklist
                ? <FormItem label='Reason' hasFeedback>
                  {getFieldDecorator('reason', {
                    rules: [
                      { min: 2, message: 'Reason must be between 2 and 128 characters' },
                      { max: 128, message: 'Reason must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter reason' },
                      { whitespace: true, message: 'Please enter reason' }
                    ]
                  })(
                    <TextArea rows={3} />
                  )}
                </FormItem>
                : null }
              { !isBlacklist
                ? <FormItem label='Contact'>
                  {getFieldDecorator('cmsc_first_name', {
                    initialValue: isEdit ? item.cmsc_first_name : null,
                    rules: [
                      { min: 1, message: 'Contact name must be between 1 and 128 characters' },
                      { max: 128, message: 'Contact name must be between 1 and 128 characters' },
                      { whitespace: true, message: 'Please enter Contact Name' }
                    ]
                  })(
                    <Input placeholder='Name' />
                  )}
                  {/* {getFieldDecorator('cmsc_last_name', {
                    initialValue: isEdit ? item.cmsc_last_name : null,
                    rules: [
                      { min: 1, message: 'Contact last name must be between 1 and 128 characters' },
                      { max: 128, message: 'Contact last name must be between 1 and 128 characters' },
                      { whitespace: true, message: 'Please enter Contact last name' }
                    ]
                  })(
                    <Input placeholder='Last Name' style={{ marginTop: '8px' }} />
                  )} */}
                  {getFieldDecorator('cmsc_contact', {
                    initialValue: isEdit ? item.cmsc_contact : null,
                    rules: [
                      { min: 1, message: 'Contact contact must be between 1 and 128 characters' },
                      { max: 128, message: 'Contact contact must be between 1 and 128 characters' },
                      { whitespace: true, message: 'Please enter Contact contact ' }
                    ]
                  })(
                    <Input placeholder='Contact' style={{ marginTop: '8px' }} />
                  )}
                  {getFieldDecorator('cmsc_email', {
                    initialValue: isEdit ? item.cmsc_email : null,
                    rules: [
                      { min: 1, message: 'Contact email must be between 1 and 128 characters' },
                      { max: 128, message: 'Contact email must be between 1 and 128 characters' },
                      { whitespace: true, message: 'Please enter Contact email' }
                    ]
                  })(
                    <Input placeholder='Email' style={{ marginTop: '8px' }} />
                  )}
                </FormItem>
                : null }
              { !isBlacklist
                ? <FormItem label='Notes (Optional)'>
                  {getFieldDecorator('notes', {
                    initialValue: isEdit ? item.notes : '',
                  })(
                    <TextArea row={3} />
                  )}
                </FormItem>
                : null }
            </Spin>
          </Form>
        </Loading>
      {/* </Modal> */}
      </SideModal>
    )
  }
}

export default Form.create()(AddProviderModal)
