import React, { Component } from 'react'
import { connect } from 'react-redux'
import { apiHostname } from '../../../config'
import { authService, clientFileService, clientFundingService, settingFileCategoryService, settingFileTypeService } from '../../../services'
import { setRefreshActivityLog, setRefreshFiles, setRefreshFunding } from '../../../states/actions'
import { ClientFileTypeList } from '../../../constants'
import { formatter, log, uploader, validator } from '../../../util'
import Moment from 'moment-timezone'
import { Link } from 'react-router-dom'

// UI
import { Loading, List, Button, SideModal, Pager, FileUpload, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

import './styles.css'

const { Item: FormItem } = Form
const Option = Select.Option
const pageSize = 20
const clientModule = 'client'

const dateFormat = 'DD/MM/YYYY'
const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)
export class ClientFile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      loading: false,
      loadingFile: false,
      loadingForm: false,
      isEmptyPlanPeriod: false,
      isPlanEnabled: false,
      isShowFileRule: false,
      list: [],
      filter: {},
      filterParam: '',
      filteredSubCategories: [],
      mainCategories: [],
      periods: [],
      searchText: '',
      sort: {},
      subCategories: [],
      total: 0,
      modal: {
        edit: false,
        item: {},
        show: false
      },
      currentFileName: '',
      currentSubCategory: {},
      currentMainCategory: {}
    }
  }

  componentWillReceiveProps (nextProps) {
    const { onRefreshFiles } = this.props
    const { currentPage, filter, sort, searchText, filterParam } = this.state

    if (nextProps.shouldRefreshFiles !== this.props.shouldRefreshFiles) {
      if (nextProps.shouldRefreshFiles) {
        this.fetchFiles({ currentPage, filter, sort, searchText, filterParam })
        onRefreshFiles(false)
      }
    }
  }

  componentDidMount () {
    const { currentPage, filter, sort, searchText, filterParam } = this.state

    this.fetchFiles({ currentPage, filter, sort, searchText, filterParam })
    this.fetchMainCategories()
    this.fetchSubCategories()
    this.fetchFunding()
  }

  filterFileActive (e) {
    const { currentPage, filter, sort, searchText } = this.state
    const filterValue = e.target.value

    if (filterValue !== '') {
      filter.active = filterValue
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort, filterParam: filterValue })
  }

  filterFileMandatory (e) {
    const { currentPage, filter, sort, searchText } = this.state
    const filterValue = e.target.value

    if (filterValue !== '') {
      filter.has_mandatory = filterValue
    } else {
      delete filter.has_mandatory
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort, filterParam: filterValue })
  }

  filterPlanPeriod (e) {
    const { currentPage, filter, sort, searchText } = this.state

    if (e) {
      filter.is_associate_plan_period = true
      filter.linked_id = e
    } else {
      delete filter.is_associate_plan_period
      delete filter.linked_id
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, sort } = this.state
    if (value && value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { label: { condition: 'ilike', value: `%${words[i]}%` } },
              { main_category: { condition: 'ilike', value: `%${words[i]}%` } },
              { sub_category: { condition: 'ilike', value: `%${words[i]}%` } },
              // { issuance_date: { condition: 'ilike', value: `%${words[i]}%` } },
              // { expiry_date: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchFiles({ currentPage, filter, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  render () {
    const { form, clientId, history } = this.props
    const { getFieldDecorator, getFieldValue } = form
    const { currentPage, loading, loadingFile, loadingForm, list, total, modal, periods, isEmptyPlanPeriod, isShowFileRule, isPlanEnabled,
      mainCategories, subCategories, filteredSubCategories, currentSubCategory } = this.state
    const { edit, item: modalItem, show: modalShow } = modal

    const columns = [

      {
        title: 'Main Category',
        width: 4,
        key: 'main_category'
      },

      {
        title: 'Sub Category',
        width: 4,
        key: 'sub_category'
      },

      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },

      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },

      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },

      {
        title: 'Enabled',
        width: 2,
        render: ({ active }) => active ? <Icon type='check-circle' theme='twoTone' twoToneColor='#09c675' /> : <div style={{ color: '#ccc' }}><Icon type='check-circle' /></div>
      },

      {
        title: 'Action',
        width: 2,
        render: (item) => <div className='action-buttons'>
          {this.hasAccess('createClientFile') ? (
            <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.showModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          ) : null}
          <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(item.file_name)}`}><div onClick={this.handleDownload(item.file_url)} style={{ cursor: 'pointer' }}><Icon type='file-text' /></div></Tooltip>
          {this.hasAccess('deleteClientFile') ? (
            <div>
              <Tooltip mouseLeaveDelay={0} title='Delete'>
                <Popconfirm
                  title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
                  onConfirm={() => this.handleDelete(item.id, item)}
                  okText='Yes'
                  cancelText='No'
                ><Icon type='delete' />
                </Popconfirm>
              </Tooltip>
            </div>
          ) : null}
        </div>
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='search-bar'>
          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Label, Main Category, Sub Category</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
              <div className='task-header'>
                {this.hasAccess('createClientFile') ? (
                  <div className='btn' onClick={() => this.showModal(false)}>
                    Add File
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row gutter={8} style={{ marginTop: 13 }}>
            <Col lg={8}>
              <ControlLabel>Mandatory</ControlLabel>
              <Radio.Group onChange={(e) => this.filterFileMandatory(e)} defaultValue=''>
                <Radio.Button value=''>All</Radio.Button>
                <Radio.Button value='true'>Mandatory</Radio.Button>
                <Radio.Button value='false'>Non-Mandatory</Radio.Button>
              </Radio.Group>
            </Col>

            <Col lg={6}>
              <ControlLabel>Enabled</ControlLabel>
              <Radio.Group onChange={(e) => this.filterFileActive(e)} defaultValue=''>
                <Radio.Button value=''>All</Radio.Button>
                <Radio.Button value='true'>Active</Radio.Button>
                <Radio.Button value='false'>Inactive</Radio.Button>
              </Radio.Group>
            </Col>

            <Col lg={8}>
              <ControlLabel>Plan Period</ControlLabel>
              <Select style={{ width: 230 }}
                onChange={(e) => this.filterPlanPeriod(e)}
                allowClear>
                {periods.map((period, idx) => {
                  return <Option key={`filter-${period.id}-${idx}`} value={period.id}>{`${formatter.toShortDate(period.start_date)} - ${formatter.toShortDate(period.end_date)}`}</Option>
                }) }
              </Select>
            </Col>
          </Row>
        </div>

        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={list} />

            <SideModal
              title='File'
              showModal={modalShow}
              onClose={this.hideModal}
              buttons={[
                <Button key='0' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
              ]}
            >
              <Form layout='vertical'>
                <FormItem label='Main Category'>
                  {getFieldDecorator('main_category_id', {
                    initialValue: modalItem.main_category_id || '',
                    rules: [
                      { required: true, message: 'Please select main category' }
                    ]
                  })(
                    <Select placeholder='Please select main category'
                      onChange={(mainId) => this.handleMainCategory(mainId)}
                      disabled={edit}>
                      {
                        mainCategories.map((main) => (
                          <Option key={main.name} value={main.id}>{main.name}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Sub Category'>
                  {getFieldDecorator('sub_category_id', {
                    initialValue: modalItem.sub_category_id || '',
                    rules: [
                      { required: true, message: 'Please select sub category' }
                    ]
                  })(
                    <Select placeholder='Please select sub category'
                      onChange={(subId) => this.handleSubCategory(subId)}
                      disabled={edit}>
                      {edit
                        ? subCategories.map((sub) => (
                          <Option key={sub.file_sub_category} value={sub.id}>{sub.file_sub_category}</Option>
                        ))
                        : filteredSubCategories.map((sub) => (
                          <Option key={sub.file_sub_category} value={sub.id}>{sub.file_sub_category}</Option>
                        ))
                      }
                    </Select>
                  )}
                </FormItem>

                <FormItem label='Associate to Plan?'>
                  {getFieldDecorator('is_associate_plan_period', {
                    initialValue: typeof modalItem.is_associate_plan_period === 'boolean' ? modalItem.is_associate_plan_period : false,
                    rules: [
                      { required: isPlanEnabled, message: '' }
                    ],
                    valuePropName: 'checked'
                  })(
                    <Switch
                      // disabled={(modalItem.is_associate_plan_period)}
                      checkedChildren='Yes'
                      unCheckedChildren='No'
                      onChange={(value) => this.handlePlanEnableChange(value)}
                    />
                  )}

                  {getFieldDecorator('linked_id', {
                    initialValue: modalItem.linked_id ? modalItem.linked_id : null,
                    rules: [
                      { validator: this.validateLinkedId }
                    ]
                  })(
                    <Select
                      disabled={!isPlanEnabled}
                      style={{ width: '70%', marginLeft: 20 }}
                      className={isEmptyPlanPeriod ? 'has-error' : '' }
                      showSearch
                      filterOption={(input, option) =>
                        `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {
                        periods.map((period, idx) => {
                          return <Option key={`${period.id}-${idx}`} value={period.id}>{`${formatter.toShortDate(period.start_date)} - ${formatter.toShortDate(period.end_date)}`}</Option>
                        })
                      }
                    </Select>
                  )}

                  {isEmptyPlanPeriod
                    ? <div className='ant-form-explain' style={{ color: '#f5222d' }}>Please select plan period</div>
                    : null}
                </FormItem>

                {!isPlanEnabled ? <FormItem label='Issuance Date'>
                  {getFieldDecorator('issuance_date', {
                    rules: [
                      { required: true, message: 'Please enter issuance date' }
                    ],
                    initialValue: modalItem.issuance_date && Moment(modalItem.issuance_date).isValid() ? Moment(modalItem.issuance_date) : null
                  })(
                    <DatePicker onChange={(e) => this.handleIssuanceDateChange(e)} format={dateFormat} />
                  )}
                </FormItem>
                  : null}

                {!isPlanEnabled ? (
                  (currentSubCategory && currentSubCategory.has_expiry) || modalItem.expiry_date)
                  ? <FormItem label='Expiry Date'>
                    {getFieldDecorator('expiry_date', modalItem.expiry_date ? {
                      rules: [
                        { required: true, message: 'Please enter expiry date' }
                      ],
                      initialValue: modalItem.expiry_date && Moment(modalItem.expiry_date).isValid() ? Moment(modalItem.expiry_date) : null
                    } : {
                      rules: [
                        { required: true, message: 'Please enter expiry date' }
                      ]
                    })(
                      <DatePicker format={dateFormat} />
                    )}
                  </FormItem>
                  : null
                  : null}

                <FormItem label='Label'>
                  {getFieldDecorator('label', {
                    initialValue: modalItem.label,
                    rules: [
                      { min: 2, message: 'Label must be between 2 and 128 characters' },
                      { max: 128, message: 'Label must be between 2 and 128 characters' },
                      // { required: true, message: 'Please enter label' },
                      { whitespace: true, message: 'Please enter label' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: typeof modalItem.active === 'boolean' ? modalItem.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>

                <FormItem>
                  <FileUpload
                    file={modalItem.file_url}
                    fileName={modalItem.file_name}
                    loading={loadingFile}
                    readOnly={false}
                    showError={isShowFileRule}
                    upload={{
                      action: `${apiHostname}/private/api/clients/files/upload/files`,
                      beforeUpload: this.checkFile,
                      data: { id: modalItem.id, moduleId: clientId },
                      disabled: loading,
                      headers: { Authorization: `Bearer ${authService.getCurrentToken()}` },
                      name: 'file',
                      onChange: this.handleUploadFile,
                      showUploadList: false
                    }}
                  />
                </FormItem>

                {isShowFileRule
                  ? <div style={{ marginTop: '5px', color: 'red' }}>Please upload file</div>
                  : null }

                {edit
                  ? (
                    <FormItem label='Uploaded By'>
                      {modalItem.created_by}
                    </FormItem>)
                  : null}

                {edit
                  ? (
                    <FormItem label='Uploaded On'>
                      {formatter.toStandardDate(modalItem.created_at)}
                    </FormItem>
                  )
                  : null}

              </Form>

            </SideModal>
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} files`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  changePage = async (currentPage) => {
    const { filter, sort, searchText } = this.state
    this.fetchFiles({ currentPage, filter, sort, searchText })
  }

  fetchFiles = async ({ currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    const { clientId } = this.props

    filter.module = { condition: '=', value: 'client' }
    filter.module_id = { condition: '=', value: clientId }

    const { list, total } = await clientFileService.listByPage(currentPage, pageSize, filter, sort, searchText)
    this.setState({ list, total, loading: false, currentPage, filter })

    setTimeout(() => {
      this.props.setRefreshFiles(false)
      this.props.setRefreshFunding(false)
    }, 200)
  }

  fetchFunding = async () => {
    const { clientId } = this.props
    try {
      const { list: funding } = await clientFundingService.get(clientId)
      const { period: periods } = validator.isNotEmptyArray(funding) ? funding[0] : []
      this.setState({ periods })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load participant\'s NDIS Plan successfully. Please try again later.')
    }
  }

  fetchMainCategories = async (filter = {}, sort = {}, searchText) => {
    try {
      this.setState({ loading: true })

      filter.active = { condition: '=', value: true }
      filter.classification = { condition: '=', value: 'participant' }

      const mainCategories = await settingFileCategoryService.listByPage(1, 0, filter)

      this.setState({ mainCategories: mainCategories.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load main categories successfully. Please try again later.')
    }
  }

  fetchSubCategories = async (filter = {}, sort = {}, searchText) => {
    try {
      this.setState({ loading: true })
      const subCategories = await settingFileTypeService.listByPage(1, 0, filter)

      this.setState({ subCategories: subCategories.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load sub categories successfully. Please try again later.')
    }
  }

  checkFile = (file) => {
    const maxSize = 10 * 1024 * 1024

    if (file.size > maxSize) {
      notify.error('Exceeded maximum file size', 'Document file size must be 10 MB or less.')
      return false
    }

    return true
  }

  handleIssuanceDateChange = async (value) => {
    this.setState({ loadingForm: true })
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentSubCategory } = this.state

    if (value) {
      if (currentSubCategory.has_expiry && currentSubCategory.expiry_month) {
        const expiryMonth = parseInt(currentSubCategory.expiry_month)
        const expiryDate = Moment(value).add(expiryMonth, 'months')

        setFieldsValue({ expiry_date: expiryDate })
      }
    }

    this.setState({ loadingForm: false })
  }

  handlePlanEnableChange = (value) => {
    const { form } = this.props
    const { setFieldsValue, validateFieldsAndScroll } = form

    if (value === false) {
      setFieldsValue({ linked_id: null })
      setTimeout(() => { validateFieldsAndScroll(['linked_id']) }, 200)
    }

    this.setState({ isPlanEnabled: value })
  }

  handleMainCategory = (mainId) => {
    this.setState({ loadingForm: true })
    const { subCategories, mainCategories } = this.state
    const subs = subCategories.filter(item => mainId === item.file_category_id)
    const selectedMainCat = mainCategories.find(item => mainId === item.id)
    this.setState({
      loadingForm: false,
      filteredSubCategories: subs,
      currentMainCategory: selectedMainCat
    })
  }

  handleSubCategory = (subId) => {
    this.setState({ loadingForm: true })
    const { subCategories } = this.state
    const selectedSubCat = subCategories.find(item => subId === item.id)

    this.setState({ loadingForm: false, currentSubCategory: selectedSubCat })
  }

  handleUploadFile = async (info) => {
    const { status, response } = info.file

    if (status === 'uploading') {
      this.setState({ loadingFile: true, loadingForm: true })
    }

    if (status === 'done') {
      const { modal } = this.state
      const { edit, item } = modal
      const { fileUrl } = response
      const lastIndex = `${fileUrl}`.lastIndexOf('/')
      const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

      if (edit) {
        const currentFileName = formatter.toStandardFileName(item.file_name)
        this.setState({ currentFileName })
      }

      item.file_url = fileUrl
      item.file_name = fileName
      this.setState({ modal, loadingFile: false, loadingForm: false, isShowFileRule: false })
    }

    if (status === 'error') {
      const { token, tokenRefreshed } = response

      if (tokenRefreshed) {
        const { modal } = this.state
        const { edit, item } = modal
        const { UploadType } = uploader
        const response = await uploader.upload(item.id, info, token, UploadType.FILE)
        // console.log('token refreshed & upload file done', response)
        if (response.fileUrl) {
          const { fileUrl } = response
          const lastIndex = `${fileUrl}`.lastIndexOf('/')
          const fileName = decodeURI(fileUrl.substring(lastIndex + 1))

          if (edit) {
            const currentFileName = formatter.toStandardFileName(item.file_name)
            this.setState({ currentFileName })
          }

          item.file_name = fileName
          item.file_url = fileUrl
          this.setState({ modal })
        } else {
          notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
        }
      } else {
        notify.error('Unable to upload successfully', 'Unable to upload file successfully. Please try again later.')
      }

      this.setState({ loadingFile: false, loadingForm: false })
    }
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }

  handleSave = () => {
    const { form, clientId, onUpdateInfo } = this.props
    const { modal, currentSubCategory, currentMainCategory, currentFileName, currentPage, filter, isPlanEnabled, periods, sort, searchText, filterParam } = this.state
    const { edit, item } = modal
    const { file_url: fileUrl, file_name: fileName } = item
    const { validateFields } = form

    validateFields(async (errors, values) => {
      console.log('errors', errors)
      if (!errors && fileUrl) {
        this.setState({ loadingForm: true, isShowFileRule: false })

        values.module = clientModule
        values.module_id = clientId
        values.file_url = fileUrl
        values.file_name = fileName
        values.label = values.label || ''
        values.type = currentSubCategory.file_sub_code
        values.type_id = values.linked_id ? values.linked_id : null
        values.linked_type = values.is_associate_plan_period ? 'plan-period' : null
        values.issuance_date = !isPlanEnabled ? values.issuance_date : null
        values.expiry_date = !isPlanEnabled ? (currentSubCategory && currentSubCategory.has_expiry ? values.expiry_date : null) : null

        let logItemBefore = {
          label: item.label || '',
          file_name: currentFileName || '',
          active: item.active,
          issuance_date: item.issuance_date
        }

        let logItemAfter = {
          label: values.label || '',
          file_name: formatter.toStandardFileName(fileName),
          active: values.active,
          issuance_date: values.issuance_date,
          associate_to_plan: values.linked_type ? true : false
        }

        if (currentSubCategory.has_expiry && values.expiry_date) {
          logItemBefore.expiry_date = item.expiry_date || ''
          logItemAfter.expiry_date = values.expiry_date || ''
        }

        // To generate activity log with associate to plan instead of linked_type(db column name)
        if (item && item.is_associate_plan_period && item.is_associate_plan_period !== values.is_associate_plan_period) {
          console.log('Associate to Plan values changed')
          logItemBefore.associate_to_plan = item.is_associate_plan_period ? true : false
        }

        // To generate activity log with period dates instead of period id
        if (isPlanEnabled || item.is_associate_plan_period) {
          const ppBefore = periods.find(p => p.id === item.linked_id)
          const ppAfter = periods.find(p => p.id === values.linked_id)

          if (ppBefore && ppAfter && item && item.linked_id) {
            logItemBefore.plan_period = `${formatter.toShortDate(ppBefore.start_date)} - ${formatter.toShortDate(ppBefore.end_date)}`
            logItemAfter.plan_period = `${formatter.toShortDate(ppAfter.start_date)} - ${formatter.toShortDate(ppAfter.end_date)}`
          } else if (ppAfter && values.linked_id) {
            if (edit) {
              logItemBefore.plan_period = ''
            }
            logItemAfter.plan_period = `${formatter.toShortDate(ppAfter.start_date)} - ${formatter.toShortDate(ppAfter.end_date)}`
          } else if (ppBefore && item && item.linked_id) {
            logItemBefore.plan_period = `${formatter.toShortDate(ppBefore.start_date)} - ${formatter.toShortDate(ppBefore.end_date)}`
            logItemAfter.plan_period = ''
          }
        }
        delete values.is_associate_plan_period

        try {
          if (edit) {
            const response = await clientFileService.save(item.id, values)

            if (response.id) {
              const extraLog = []

              extraLog.push(`${currentMainCategory.name} - ${currentSubCategory.file_sub_category}${values.label ? ` - ${values.label}` : ''}${fileName ? `(${formatter.toStandardFileName(fileName)})` : ''}`)

              log.updateClientFile(
                clientId,
                logItemBefore,
                logItemAfter,
                [],
                extraLog.join(),
                [
                  { key: 'associate_to_plan', label: 'Associate to Plan' }
                ]
              )

              this.setState({ loadingForm: false, shouldRefreshFunding: true })
              this.fetchFiles({ currentPage, filter, sort, searchText, filterParam })
              this.hideModal()
              onUpdateInfo()
              this.props.setRefreshActivityLog(true)
              this.props.setRefreshFiles(true)
              this.props.setRefreshFunding(true)
              notify.success('Saved successfully', 'File saved successfully')
            }
          } else {
            const response = await clientFileService.add(values)

            if (response.id) {
              const extraLog = []

              extraLog.push(`${currentMainCategory.name} - ${currentSubCategory.file_sub_category}${values.label ? ` - ${values.label}` : ''}${fileName ? `(${formatter.toStandardFileName(fileName)})` : ''}`)

              log.addClientFile(
                clientId,
                logItemAfter,
                [],
                extraLog.join(),
                [
                  { key: 'associate_to_plan', label: 'Associate to Plan' }
                ]
              )

              this.setState({ loadingForm: false, shouldRefreshFunding: true })
              this.fetchFiles({ currentPage, filter, sort, searchText, filterParam })
              this.hideModal()
              onUpdateInfo()
              this.props.setRefreshActivityLog(true)
              this.props.setRefreshFiles(true)
              this.props.setRefreshFunding(true)
              notify.success('Saved successfully', 'File saved successfully')
            }
          }
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save file successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }

      } else if (!fileUrl) {
        this.setState({ isShowFileRule: true })
      }
    })
  }

  async handleDelete (id, info) {
    const { clientId, onUpdateInfo } = this.props
    const { currentPage, filter, periods, sort, searchText } = this.state
    this.setState({ loading: true })

    const response = await clientFileService.remove(id)
    this.setState({ loading: false, shouldRefreshFunding: true })

    if (response.id) {
      let changes = `Delete file${info.main_category ? ` ${info.main_category}` : ''}${info.sub_category ? ` - ${info.sub_category}` : ''}${info.label ? ` - ${info.label}` : ''}${info.file_name ? `(${formatter.toStandardFileName(info.file_name)})` : ''}`
      changes += `, Label "${info.label ? info.label : ''}"`
      changes += `, File Name "${info.file_name ? formatter.toStandardFileName(info.file_name) : ''}"`
      changes += `, Issuance Date "${info.issuance_date ? formatter.toShortDate(info.issuance_date) : ''}"`
      changes += info.expiry_date ? `, Expiry Date "${formatter.toShortDate(info.expiry_date)}"` : ''
      changes += info.is_associate_plan_period ? `, Associate to Plan "${info.is_associate_plan_period}"` : ''
      if (info.linked_id) {
        const pp = periods.find(p => p.id === info.linked_id)
        changes += `, Plan Period from "${pp.start_date ? formatter.toShortDate(pp.start_date) : ''}" to "${pp.end_date ? formatter.toShortDate(pp.end_date) : ''}"`
      }

      log.deleteClientFile(clientId, changes)
      notify.success('Deleted successfully', 'File deleted successfully')
      this.fetchFiles({ currentPage, filter, sort, searchText })
      onUpdateInfo()
      this.props.setRefreshActivityLog(true)
      this.props.setRefreshFiles(true)
      this.props.setRefreshFunding(true)
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.edit = false
    modal.item = {}
    modal.show = false
    this.setState({
      modal,
      isPlanEnabled: false,
      isShowFileRule: false,
      filteredSubCategories: [],
      currentFileName: '',
      currentSubCategory: {},
      currentMainCategory: {}
    })
  }

  showModal = (isEdit, modalItem) => {
    const { modal, subCategories, mainCategories } = this.state
    modal.edit = isEdit || false
    modal.item = modalItem || {}
    modal.show = true
    let editingSub = {}
    let editingMain = {}

    if (isEdit) {
      editingSub = subCategories.find(item => item.id === modalItem.sub_category_id)
      editingMain = mainCategories.find(item => item.id === modalItem.main_category_id)

      this.setState({
        currentFileName: formatter.toStandardFileName(modalItem.file_name),
        currentSubCategory: editingSub,
        currentMainCategory: editingMain,
        isPlanEnabled: modalItem.is_associate_plan_period ? modalItem.is_associate_plan_period : false
      })
    }
    this.setState({ modal })
  }

  validateLinkedId = (rule, value, callback) => {
    const { isPlanEnabled } = this.state
    this.setState({ isEmptyPlanPeriod: false })

    if (!isPlanEnabled) {
      callback()
    } else {
      if (value) {
        callback()
      } else {
        callback(new Error('Please select plan period'))
        this.setState({ isEmptyPlanPeriod: true })
      }
    }

    callback(new Error('Please select plan period'))
  }

}

const mapDispatchToProps = {
  setRefreshActivityLog,
  setRefreshFiles,
  setRefreshFunding
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientFile))
