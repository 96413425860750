import React, { Component } from 'react'
import moment from 'moment'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'
import { apiHostname } from '../../../config'

// UI
import { Button, List, Page } from '../../../components'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

export class AdditionalReporting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      loadingExport: false,
      clients: [],
      sort: {}
    }
  }

  componentDidMount () {
    const { sort } = this.state
    this.fetchClientLastJobCount()
  }

  handleSort = (key, order) => {
    const sort = order === 0 ? {} : { [key]: order }
    this.fetchClientFunding({ sort })
    this.setState({ sort, loadingSpin: true })
  }

  render () {
    const { clients, loading, loadingExport } = this.state

    const columns = [
      {
        title: 'Participant',
        width: 5,
        render: ({ module_id, participant_name }) => <a href={`/clients/${module_id}`}>{participant_name}</a>,
        // onSort: (key, order) => this.handleSort(key, order)
      },

      {
        title: 'Notes',
        width: 14,
        render: ({ value_notes }) => <div>
          {value_notes}
        </div>
      },

      {
        title: 'Created At',
        width: 5,
        render: ({ created_at }) => formatter.toStandardLongDate(created_at)
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Participants' Custom Identifier (Additional Reporting)`}>
            { !loading
              ? <Button key={'export'} type='primary' feedback={loadingExport} onClick={() => this.exportList()}>
                Export
              </Button>
              : null}
          </Page.Header>

          <div className='admin-list'>
            <Skeleton loading={loading} active>
              <div className='report-title'>{`Participants' Additional Reporting `} <span className='report-tag'>{clients.length}</span></div>
              <List cols={columns} rows={clients} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchClientLastJobCount () {
    this.setState({ loading: true })
    const [ ar ] = await Promise.all([
      reportService.get('client-additional-reporting')
    ])

    this.setState({ clients: ar, loading: false })
  }

  async exportList () {
    this.setState({ loadingExport: true })

    window.location.href = `${apiHostname}/api/export/report/client-last-job-count`
    setTimeout(() => {
      this.setState({ loadingExport: false })
    }, 2000)
  }
}

export default AdditionalReporting
