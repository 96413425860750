import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, settingBillingRateService, settingBillingCategoryService } from '../../../../../services'
import moment from 'moment'
import { FieldList } from '../../../../../constants'
import { Link } from 'react-router-dom'
import Subset from './subset'
import uid from 'uid-safe'

// UI
import { Button, FullModal, Page, SectionTitle } from '../../../../../components'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Notification from 'antd/lib/notification'

import './styles.css'
import notify from '../../../../../components/Notification'
import BillingRateRow from './row'
import { truncate } from 'lodash'

const pageSize = 20
const { Item: FormItem } = Form
const Option = Select.Option

const settingTitle = 'Billing Rate Set'
const settingType = 'billing rate set'

export class SettingBillingRates extends Component {
  constructor (props) {
    super(props)
    this.state = {
      billingRates: [],
      categories: [],
      defaultCategory: [],
      currentPage: 1,
      defaultBillingRates: [],
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      rateSet: { sets: [] },
      searchText: '',
      selectedItem: { _id: null },
      showAddCategory: false,
      sort: {},
      loadingForm: false,
      shouldValidate: false
    }
  }

  componentDidMount () {
    const { currentPage } = this.state

    if (this.isEdit()) {
      this.fetchRateSet()
      this.fetchCategories()
    } else {
      this.fetchBillingCategory()
    }

    this.fetchBillingRates({ currentPage })
  }

  initialSubset () {
    const { rateSet, defaultCategory } = this.state

    const subset = {
      id: uid.sync(8),
      name: 'New Set',
      active: true,
      categories: defaultCategory
    }

    rateSet.sets.push(subset)
    this.setState({ rateSet })
  }

  fetchCategories = async () => {
    const categories = await settingBillingCategoryService.listByPage(1, 0, { active: true })
    const nonSleepoverCategory = categories.list.filter(item => item.name !== FieldList.SLEEPOVER)
    this.setState({ categories: nonSleepoverCategory })
  }

  fetchBillingRates = async ({ currentPage = 1 }) => {
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingBillingRateService.listByPage(currentPage, pageSize)
      this.setState({ list: settings.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load Billing Rate Set successfully. Please try again later.')
    }
  }

  fetchBillingCategory = async () => {
    try {
      const categories = await settingBillingCategoryService.listByPage(1, 0, { active: true })
      const nonSleepoverCategory = categories.list.filter(item => item.name !== FieldList.SLEEPOVER)
      const defaultCategory = []

      // Sleepover category
      // const SOCategories = categories.list.filter((item) => item.name === FieldList.SLEEPOVER)
      // const category = SOCategories[0]
      // const rates = []

      // for (let j = 0; j < 8; j++) {
      //   // populate normal hours
      //   rates.push({category_id: category.id, day: `n${j + 1}`, value: null})
      //   // populate after hours
      //   rates.push({category_id: category.id, day: `a${j + 1}`, value: null})
      // }

      // defaultCategory.push({ id: category.id, name: category.name, rates, canDelete: false })

      this.setState({ categories: nonSleepoverCategory, defaultCategory })

      setTimeout(() => {
        this.initialSubset()
      }, 500);

    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load category successfully. Please try again later.')
    }
  }

  fetchRateSet = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    try {
      this.setState({ loading: true })

      const categoryRates = await settingBillingRateService.get(id)
      const billingRates = []

      for (let z = 0; z < categoryRates.sets.length; z++) {
        const set = categoryRates.sets[z]

        for (let i = 0; i < set.categories.length; i++) {
          const rateSet = set.categories[i]

          if (rateSet.name === FieldList.SLEEPOVER) {
            rateSet.canDelete = false
          }

          for (let j = 0; j < rateSet.rates.length; j++) {
            const rate = rateSet.rates[j]
            billingRates.push({category_id: rateSet.id, day: rate.day, value: rate.value})
          }
        }
      }

      this.setState({ rateSet: categoryRates, loading: false })

    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load rate set successfully. Please try again later.')
    }
  }

  handlePreSaveCheck = () => {
    this.setState({ shouldValidate: true, hasError: false })

    setTimeout(() => {
      this.handleSave()
    }, 1100);
  }

  handleSubsetValidation = (res) => {
    this.setState({ hasError: true, shouldValidate: false })
    this.showErrorNotification()
  }

  showErrorNotification = () => {
    Notification['error']({
      message: 'Imcomplete Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { hasError, loadingForm, rateSet } = this.state

    if (loadingForm || hasError) return

    validateFields(['name', 'active'], async (errors, values) => {
      if (!errors) {
        const { match } = this.props
        const { params } = match
        const { id } = params
        const { modal } = this.state
        const { item } = modal

        values.rateset = rateSet.sets
        this.setState({ loadingForm: true })

        try {
          let response

          if (this.isEdit()) {
            response = await settingBillingRateService.save(id, values)
          } else {
            response = await settingBillingRateService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)

            if (!this.isEdit()) {
              window.location.replace(`/settings/funder/billing-rates/${response.id}`)
            }
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }

        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  setBillingRate (set, category, day, value, isLabel = false) {
    const { rateSet } = this.state
    let isExist = false

    const billingRates = rateSet.sets.filter(rs => rs.id === set)
    const billingCategory = billingRates[0].categories.filter(c => c.id === category)

    for (let i = 0; i < billingCategory[0].rates.length; i++) {
      const rate = billingCategory[0].rates[i]

        if (rate.day === day) {
          if (isLabel) {
            rate.label = value
          } else {
            rate.value = parseFloat(value)
          }
          isExist = true
          break
        }
    }

    if (!isExist) {
      billingRates[0].categories.push({ day, value: !isLabel ? value : null, label: isLabel ? value : '' })
    }

    this.setState({ billingRates, rateSet })

    //console.log(rateSet)
  }

  removeBillingRate (category) {
    const { billingRates, categoryRates } = this.state
    const newBillingRates = billingRates.filter(r => r.category_id !== category)
    const newCategoryRates = categoryRates.filter(r => r.id !== category)

    this.setState({ billingRates: newBillingRates, categoryRates: newCategoryRates })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  addCategory = (option) => {
    const { billingRates, categoryRates } = this.state

    const rates = []
    for (let j = 0; j < 8; j++) {
      // populate normal hours
      rates.push({category_id: option.key, day: `n${j + 1}`, value: 0})
      // populate after hours
      rates.push({category_id: option.key, day: `a${j + 1}`, value: 0})

      billingRates.push({category_id: option.key, day: `n${j + 1}`, value: 0})
      billingRates.push({category_id: option.key, day: `a${j + 1}`, value: 0})
    }

    categoryRates.push({ id: option.key, name: option.label, rates })

    this.setState({ billingRates, categoryRates })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  handleAdd () {
    //this.initialSubset()
    this.fetchBillingCategory()
  }

  handleUpdate (set) {
    const { rateSet } = this.state

    for (let i=0; i < rateSet.sets.length; i++) {
      const rateset = rateSet.sets[i]
      if (rateset.id === set.id) {
        rateset = set
        break
      }
    }

    this.setState({ rateSet })
  }

  handleDuplicate (set) {
    const { rateSet } = this.state

    rateSet.sets.unshift(set)

    this.setState({ rateSet })

    notify.success('Duplicate successfully', `'${set.name}' set duplicated successfully.`)
  }

  handleDelete (id) {
    const { rateSet } = this.state

    for (let i=0; i < rateSet.sets.length; i++) {
      const rateset = rateSet.sets[i]
      if (rateset.id === id) {
        rateSet.sets.splice(i, 1)
        break
      }
    }

    this.setState({ rateSet })
  }

  render () {
    const { categories, loading, loadingForm, rateSet, shouldValidate } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 7 },
      wrapperCol: { sm: 14, md: 14, lg: 16 }
    }

    const formItemTimeLayout = {
      labelCol: { sm: 6, md: 6, lg: 12 },
      wrapperCol: { sm: 14, md: 14, lg: 10 }
    }

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={settingTitle}>
            { this.hasAccess('updateBillingRate') ? <Button onClick={() => this.handlePreSaveCheck()}>
              { loadingForm ? <div><Icon type="loading" /> &nbsp;Saving...</div> : 'Save' }
            </Button> : null
            }
            <Link to={`/settings/funder?t=rateset`}>
              <Button>
                Back
              </Button>
            </Link>
          </Page.Header>
          <Form>
          <div className='billing-rate-info'>
            <Row>
              <Col lg={10}>
                <FormItem label='Name' {...formItemLayout}>
                  {getFieldDecorator('name', {
                    initialValue: rateSet.name || '',
                    rules: [
                      { min: 2, message: 'Name must be between 2 and 128 characters' },
                      { max: 128, message: 'Name must be between 2 and 128 characters' },
                      { required: true, message: 'Please enter name' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>
              </Col>
              <Col lg={2}>
                <FormItem label='' {...formItemLayout}>
                  {getFieldDecorator('active', {
                    initialValue: rateSet.active || true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable' unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>
              </Col>
              <Col lg={12} style={{ textAlign: 'right', padding: '6px 15px 0 0' }}>
                <Button onClick={() => this.handleAdd()}>
                  Add Subset
                </Button>
              </Col>
            </Row>
          </div>
          </Form>

          <Skeleton loading={loading} active>
            { rateSet.sets.map((set, index) => {
              return (
                <Subset
                  index={index}
                  key={index}
                  data={set}
                  subsets={rateSet.sets}
                  validate={shouldValidate}
                  category={categories}
                  onError={(pass) => this.handleSubsetValidation(pass)}
                  onUpdate={(set) => this.handleUpdate(set)}
                  onDuplicate={(set) => this.handleDuplicate(set)}
                  onDelete={(id) => this.handleDelete(id)}
                />
            )}
            )}
          </Skeleton>

        </Page.Content>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingBillingRates))
