import { all, fork } from 'redux-saga/effects'

import watchCancelledJobs from './cancelledJob'
import watchCancelledRecurringJobs from './cancelledRecurringJob'
import watchClient from './client'
import watchDeletedJobs from './deletedJob'
import watchEndedBaseJobs from './endedBaseJob'
import watchFunder from './funder'
import watchFutureJobs from './futureJob'
import watchEmployee from './employee'
import watchJob from './job'
import watchPastJobs from './pastJob'
import watchPendingEmployeeJobs from './pendingEmployeeJob'
import watchPendingEmployeeBaseJobs from './pendingEmployeeBaseJob'
import watchProvider from './provider'
import watchRecurringJob from './recurringJob'
import watchRecurringJobList from './recurringJobList'
import watchDashboard from './dashboard'
import watchPayroll from './payroll'
import watchSettingStore from './setting/store'
import watchUser from './user'

export default function * root () {
  yield all([
    fork(watchCancelledJobs),
    fork(watchCancelledRecurringJobs),
    fork(watchClient),
    fork(watchDashboard),
    fork(watchDeletedJobs),
    fork(watchEndedBaseJobs),
    fork(watchFutureJobs),
    fork(watchFunder),
    fork(watchEmployee),
    fork(watchJob),
    fork(watchPastJobs),
    fork(watchRecurringJob),
    fork(watchRecurringJobList),
    fork(watchPayroll),
    fork(watchSettingStore),
    fork(watchUser),
    fork(watchPendingEmployeeJobs),
    fork(watchPendingEmployeeBaseJobs),
    fork(watchProvider)
  ])
}
