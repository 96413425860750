import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class HighlightPanel extends Component {
  render () {
    const { children, className, displayImage = true, img = {}, style, title } = this.props

    return (
      <div className={formatter.toClassName(['wd-highlightpanel', className])} style={style}>
        <div className='wd-panel-body'>
          {displayImage ? (
            img.src ? (
              <img className='wd-panel-image' alt={img.alt} src={img.src} />
            ) : (
              <div className='wd-panel-image' />
            )
          ) : null}

          <div className={formatter.toClassName(['wd-panel-details', displayImage ? '' : 'details-no-image'])}>
            {title ? <div className='wd-panel-title'>{title}</div> : null}

            {children ? <div>{children}</div> : null}
          </div>
        </div>
      </div>
    )
  }
}

HighlightPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  displayImage: PropTypes.bool,
  img: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.node
}
