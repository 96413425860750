import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { formatter, validator } from '../../util'
import { jobService, communicationService } from '../../services'
import moment from 'moment'
import debounce from 'lodash.debounce'

// UI
import { ControlLabel, List, Page, Pager, SearchInput } from '../../components'

import './styles.css'

import Icon from 'antd/lib/icon'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Switch from 'antd/lib/switch'

const TabPane = Tabs.TabPane
const pageSize = 30

class Communication extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPageConfirmation: 1,
      currentPageUnsent: 1,
      loading: false,
      confirmation: { list: [], total: 0 },
      unsent: { list: [], total: 0 },
      searchText: '',
      tabKey: '1',
      filterConfirmationTab: {},
    }
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    this.initialLists()
  }

  render () {
    const { tabKey } = this.state

    return (
      <Page.Body>
        <Page.Content full>
          <Page.Header icon={<Icon type='message' />} title='Communication' />
          <Tabs
            defaultActiveKey='1'
            onTabClick={this.onTabClick}
          >
            <TabPane tab='Job Confirmation' key='1'>
              { this.confirmationTab() }
            </TabPane>
            <TabPane tab='Unsent' key='2'>
              { this.unsentTab() }
            </TabPane>

          </Tabs>

        </Page.Content>
        <Page.Right>
          <div className='wd-noti-panel-box'>
            <ControlLabel>Search: </ControlLabel>
            <SearchInput placeholder='' onChange={(v) => this.onSearchName(v)} />
            { /** Search Filter Toggle Switchs */ }
            {
              tabKey === '1'
              ? (
                <div style={{ marginTop: '16px' }}>
                  <ControlLabel>Confirmation</ControlLabel>
                  <Radio.Group
                    onChange={this.handleConfirmationFilter}
                    defaultValue='all' size='default'>
                    <Radio.Button value='all'>All</Radio.Button>
                    <Radio.Button value='no'>No</Radio.Button>
                    <Radio.Button value='yes'>Yes</Radio.Button>
                  </Radio.Group>
                </div>)
              : null
            }

          </div>
        </Page.Right>
      </Page.Body>
    )
  }

  confirmationTab = () => {
    const { currentPageConfirmation, confirmation, loading } = this.state

    const confirmationColumns = [
      {
        title: 'Employee',
        width: 4,
        render: ({ employee_id: empId, employee_name: empName }) => <Link to={`/employees/${empId}`}>{empName}</Link>
      },
      {
        title: 'Subject',
        width: 8,
        key: 'subject'
      },
      {
        title: 'Date Sent',
        width: 4,
        render: ({ sent_at: sentAt }) => formatter.toStandardDate(sentAt)
      },
      {
        title: 'Confirmed?',
        width: 2,
        render: ({ is_confirmed: isConfirmed }) => isConfirmed ? <div style={{ color: '#4fbc85', fontSize: '11.5pt' }}><Icon type='check-circle' theme='filled' /></div> : <div style={{ color: '#ccc', fontSize: '11.5pt' }}><Icon type='check-circle' /></div>
      },
      {
        title: 'Action',
        width: 2,
        render: ({ module, job_id }) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='View job'><Link to={module === 'job' ? `/jobs/single/${job_id}` : `/jobs/recurring/${job_id}`}><Icon type='eye' /></Link></Tooltip>
        </div>
      }
    ]

    return (
      <div>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={confirmationColumns} rows={confirmation.list} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={confirmation.total}
          totalText={`Total ${confirmation.total} records`}
          current={currentPageConfirmation}
          onChange={(page) => this.refreshList('confirmation', page)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }


  unsentTab = () => {
    const { currentPageUnsent, unsent, loading, confirmation } = this.state

    const unsentColumns = [
      {
        title: 'Comm. Date',
        width: 4,
        render: ({ comm_date: commDate }) => commDate
      },
      {
        title: 'User',
        width: 2,
        key: 'user'
      },
      {
        title: 'Event',
        width: 2,
        key: 'event'
      },
      {
        title: 'Job Start',
        width: 4,
        key: 'job_start_date'
      },
      {
        title: 'Job End',
        width: 4,
        key: 'job_end_date'
      },
      {
        title: 'Participant',
        width: 3,
        key: 'client'
      },
      {
        title: 'Employee',
        width: 3,
        key: 'employee'
      },
      {
        title: 'Action',
        width: 2,
        render: ({ type, id }) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='View job'><Link to={type === 'single' ? `/jobs/single/${id}` : `/jobs/recurring/${id}`}><Icon type='eye' /></Link></Tooltip>
        </div>
      }
    ]

    return (
      <div>

        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <List cols={unsentColumns} rows={unsent.list} />
          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={unsent.total}
          totalText={`Total ${unsent.total} records`}
          current={currentPageUnsent}
          onChange={(page) => this.refreshList('unsent', page)}
          style={{ marginTop: '15px' }}
        />
      </div>
    )
  }

  initialLists = async () => {
    const { currentPageConfirmation, currentPageUnsent } = this.state
    this.setState({ loading: true })

    const unsent = await communicationService.listUnsentByPage(currentPageUnsent, pageSize, {})

    const filter = {}
    filter.should_confirm = { condition: '=', value: 'true' }
    const confirmation = await jobService.getEmails(currentPageConfirmation, pageSize, filter)

    this.setState({ loading: false, confirmation: confirmation, unsent: unsent })
  }

  fetchConfirmation = async (searchText, currentPage = 1, filter = this.state.filterConfirmationTab, sort = {}) => {
    this.setState({ loading: true })

    filter.should_confirm = { condition: '=', value: 'true' }
    const confirmation = await jobService.getEmails(currentPage, pageSize, filter, sort, searchText)

    this.setState({ confirmation, loading: false, currentPageConfirmation: currentPage, searchText })
  }

  fetchUnsent = async (searchText, currentPage = 1, filter = {}, sort = {}) => {
    this.setState({ loading: true })

    const unsent = await communicationService.listUnsentByPage(currentPage, pageSize, filter, sort, searchText)

    this.setState({ unsent, loading: false, currentPageUnsent: currentPage, searchText })
  }

  handleConfirmationFilter = (e) => {
    const value = e.target.value
    const { searchText } = this.state
    const filter = {}

    if (value === 'no') {
      filter.is_confirmed = { condition: '=', value: 'false' }
    } else if (value === 'yes') {
      filter.is_confirmed = { condition: '=', value: 'true' }
    }

    this.setState({ filterConfirmationTab: filter })
    this.fetchConfirmation(searchText, 1, filter)
  }

  refreshList = async (filterParam, currentPage) => {
    this.setState({ loading: true })

    // Confirmation search filters
    if (filterParam === 'confirmation') {
      const { filterConfirmationTab, searchText } = this.state
      const filter = {}

      if (!Array.isArray(filter.$and)) {
        filter.$and = []
      }

      filter.$and.push(filterConfirmationTab)
      filter.should_confirm = { condition: '=', value: 'true' }

      const confirmation = await jobService.getEmails(currentPage, pageSize, filter, {}, searchText)
      return this.setState({ confirmation, loading: false, currentPageConfirmation: currentPage })
    }

    // Unsent
    if (filterParam === 'unsent') {
      const { searchText } = this.state
      this.fetchUnsent(searchText, currentPage)
    }
  }

  onSearchName (value) {
    const { tabKey } = this.state

    if (tabKey === '1') {
      this.fetchConfirmation(value)
    } else if (tabKey === '2') {
      this.fetchUnsent(value)
    } else {
      console.log('Search Failed.')
    }

    this.setState({searchText: value})
  }

  onTabClick = (key) => {
    this.setState({ tabKey: key })
  }
}

export default Form.create()(Communication)
