import React, { Component } from 'react'
import { connect } from 'react-redux'
import { funderService, settingFundManagerService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import Moment from 'moment-timezone'
import { formatter, log } from '../../../util'

// UI
import { Loading, List, Pager } from '../../../components'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const { confirm, error } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class FunderFundManager extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      list: [],
      fundManager: [],
      loading: false,
      loadingForm: false,
      selectedManagers: [],
      showFundManager: false,
    }
    this.filterLogs = this.filterLogs.bind(this)
  }

  componentDidMount () {
    const { currentPage } = this.state
    // this.fetchLogs(currentPage)
    this.fetchFundManager(currentPage)
    this.fetchFundManagerList()
  }

  filterLogs (e) {
    this.setState({ currentPage: 1 })
    this.fetchLogs(e.target.value, 1)
  }

  render () {
    const { form } = this.props
    const { currentPage, loading, loadingForm, list, total, showFundManager, fundManager, selectedManagers } = this.state
    const { getFieldDecorator } = form

    const listColumns = [
      {
        title: 'Name',
        width: 18,
        render: ({ fullname }) => <div className='name-label'>{fullname}</div>
      },
      {
        title: 'Created At',
        width: 4,
        render: ({ created_at }) => formatter.toStandardDate(created_at)
      }
    ]

    return (
      <div>
        <Loading loading={loading} blur>
          <div className='task-header'>
            <div className='btn' onClick={() => this.showModal()}>
              Add Manager
            </div>
          </div>

          <div className='task-list'>
            <Skeleton loading={loading} active>

              <List cols={listColumns} rows={list} />

            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} plan managers`}
            current={currentPage}
            onChange={(e) => this.changePage(e)}
            style={{ marginTop: '15px' }}
          />
        </Loading>

        <Modal
          title="Add Plan Manager"
          style={{ top: 20 }}
          visible={showFundManager}
          maskClosable={false}
          confirmLoading={loadingForm}
          onOk={() => this.handleSaveFundManager()}
          onCancel={() => this.showModal()}
          >
          <Form layout='vertical'>
            <FormItem label='Plan Manager'>
              {getFieldDecorator('fund_manager', {
                initialValue: selectedManagers,
                rules: [
                  { required: true, message: 'Please select a plan manager' }
                ]
              })(
                <Select placeholder='Please select one/multiple plan manager(s)'
                  mode='multiple'
                  showSearch
                  filterOption={(input, option) =>
                    `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {
                    fundManager.map((manager, idx) => {
                      return <Option key={`fm${idx}`} value={manager.id}>{manager.fullname}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
          </Form>
        </Modal>
      </div>
    )
  }

  fetchFundManager = async (startPage = null) => {
    this.setState({ loading: true })
    const { funderId, form } = this.props
    const { currentPage } = this.state
    const filter = { }

    const page = startPage || currentPage

    filter.funder_id = { condition: '=', value: funderId }

    const { list, total } = await funderService.listFundManagerByPage(page, pageSize, filter)
    const selectedManagers = list.reduce((a, value) => (a.push(parseInt(value.fund_manager_id)), a), [])

    this.setState({ list, total, loading: false, currentPage: page, filter, selectedManagers })
  }

  fetchFundManagerList = async () => {
    const fm = await settingFundManagerService.listByPage(1, 0)
    this.setState({ fundManager: fm.list })
  }

  changePage = async (currentPage) => {
    this.fetchFundManager(currentPage)
  }

  handleSaveFundManager = async () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['fund_manager'], async (errors, values) => {
      if (!errors) {
        try {
          const { funderId } = this.props
          const { fundManager, selectedManagers } = this.state
          this.setState({ loadingForm: true })
          const response = await funderService.addFunderFundManager(funderId, { selectedIds: values.fund_manager})

          if (response) {
            let currentFMLabels = ''
            let fmLabels = ''

            if (selectedManagers !== values.fund_manager) {
              for (var j = 0; j < selectedManagers.length; j++) {
                const funderInfo = fundManager.find((f) => f.id === selectedManagers[j])
                currentFMLabels += funderInfo.fullname + ', '
              }

              for (var i = 0; i < values.fund_manager.length; i++) {
                const funderInfo = fundManager.find((f) => f.id === values.fund_manager[i])
                fmLabels += funderInfo.fullname + ', '
              }

              log.updateFunderFundManager(funderId, `Plan Manager from "${(currentFMLabels || '').slice(0, -2)}" to "${(fmLabels || '').slice(0, -2)}"`)
            }

            this.setState({ loadingForm: false, showFundManager: false })
            this.fetchFundManager()
            notify.success('Saved successfully', 'Plan Manager saved successfully.')
            this.props.setRefreshActivityLog(true)
            this.props.onUpdateFM()
          }
        } catch (e) {
          console.log('errors', e)
          notify.error('Unable to save successfully', 'Unable to save plan manager successfully. Please try again later.')
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  showModal = () => {
    const { showFundManager } = this.state
    this.setState({ showFundManager: !showFundManager })
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Funder }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(FunderFundManager))
