import React, { Component } from 'react'
import ReportMenu from '../../../constants/menu/report'
import { reportService } from '../../../services'
import { formatter } from '../../../util'
import moment from 'moment'
// UI
import { List, Page } from '../../../components'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

export class ReportEmployeeCert extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      certExpired: { list: [], total: 0 },
      certExpiring: { list: [], total: 0 }
    }
  }

  componentDidMount () {
    this.fetchEmployeeCert()
  }

  render () {
    const { certExpired, certExpiring, loading} = this.state

    const columns = [
      {
        title: 'Employee',
        width: 10,
        render: ({ id, first_name, last_name }) => <a href={`/employees/${id}`}>{first_name} {last_name}</a>
      },
      {
        title: 'Expired On',
        width: 14,
        render: (emp) => <table width='100%'>
        {
           emp.police_expiry_days !== null ? <tr><td width='65%'>● Police Check expired on {moment(emp.police_expire_date).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.duration(emp.police_expire_date)} days</td></tr> : null
        }
        {
           emp.first_aid_expiry_days !== null ? <tr><td width='65%'>● First Aid Cert. expired on {moment(emp.first_aid_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.duration(emp.first_aid_expiry)} days</td></tr> : null
        }
        {
           emp.dwes_expiry_days !== null ? <tr><td width='65%'>● DWES expired on {moment(emp.dwes_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.duration(emp.dwes_expiry)} days</td></tr> : null
        }
        {
           emp.work_with_child_expiry_days !== null ? <tr><td width='65%'>● Working With Children Check expired on {moment(emp.work_with_child_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.duration(emp.work_with_child_expiry)} days</td></tr> : null
        }
        {
           emp.driver_license_expiry_days !== null ? <tr><td width='65%'>● Driver License expired on {moment(emp.driver_license_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.duration(emp.driver_license_expiry)} days</td></tr> : null
        }
        {
           emp.vehicle_registration_expiry_days !== null ? <tr><td width='65%'>● Vehicle Registration expired on {moment(emp.vehicle_registration_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.duration(emp.vehicle_registration_expiry)} days</td></tr> : null
        }
        {
           emp.vehicle_insurance_expiry_days !== null ? <tr><td width='65%'>● Vehicle Insurance expired on {moment(emp.vehicle_insurance_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.duration(emp.vehicle_insurance_expiry)} days</td></tr> : null
        }
       </table>
      }
    ]

    const endingColumns = [
      {
        title: 'Employee',
        width: 10,
        render: ({ id, first_name, last_name }) => <a href={`/employees/${id}`}>{first_name} {last_name}</a>
      },
      {
        title: 'Expiring On',
        width: 14,
        render: (emp) => <table width='100%'>
        {
           this.showExpiring(emp.police_expiry_days) ? <tr><td width='60%'>● Police Check expiring on {moment(emp.police_expire_date).format('DD/MM/YYYY')}</td><td td width='40%' style={{ textAlign: 'right' }}>{formatter.expiringDuration(emp.police_expire_date)} days to go</td></tr> : null
        }
        {
           this.showExpiring(emp.first_aid_expiry_days) ? <tr><td width='60%'>● First Aid Cert. expiring on {moment(emp.first_aid_expiry).format('DD/MM/YYYY')}</td><td td width='40%' style={{ textAlign: 'right' }}>{formatter.expiringDuration(emp.first_aid_expiry)} days to go</td></tr> : null
        }
        {
           this.showExpiring(emp.dwes_expiry_days) ? <tr><td width='60%'>● DWES expiring on {moment(emp.dwes_expiry).format('DD/MM/YYYY')}</td><td td width='40%' style={{ textAlign: 'right' }}>{formatter.expiringDuration(emp.dwes_expiry)} days to go</td></tr> : null
        }
        {
           this.showExpiring(emp.work_with_child_expiry_days) ? <tr><td width='65%'>● Working With Children Check expiring on {moment(emp.work_with_child_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.expiringDuration(emp.work_with_child_expiry)} days to go</td></tr> : null
        }
        {
           this.showExpiring(emp.driver_license_expiry_days) ? <tr><td width='65%'>● Driver License expiring on {moment(emp.driver_license_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.expiringDuration(emp.driver_license_expiry)} days to go</td></tr> : null
        }
        {
           this.showExpiring(emp.vehicle_registration_expiry_days) ? <tr><td width='65%'>● Vehicle Registration expiring on {moment(emp.vehicle_registration_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.expiringDuration(emp.vehicle_registration_expiry)} days to go</td></tr> : null
        }
        {
           this.showExpiring(emp.vehicle_insurance_expiry_days) ? <tr><td width='65%'>● Vehicle Insurance expiring on {moment(emp.vehicle_insurance_expiry).format('DD/MM/YYYY')}</td><td td width='35%' style={{ textAlign: 'right' }}>{formatter.expiringDuration(emp.vehicle_insurance_expiry)} days to go</td></tr> : null
        }
         </table>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Employee's Certificate`} />

          <div className='admin-list'>
            <Skeleton loading={loading} active>
            <div className='report-title'>{`Employees with Expired Certificates `} <span className='report-tag'>{certExpired.total}</span></div>
            <List cols={columns} rows={certExpired.list} />

            <div className='report-title'>{`Employees with Expiring Certificates `} <span className='report-tag'>{certExpiring.total}</span></div>
            <List cols={endingColumns} rows={certExpiring.list} />
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  async fetchEmployeeCert () {
    this.setState({ loading: true })

    const [ expired, expiring ] = await Promise.all([
      reportService.get('cert-expired'),
      reportService.get('cert-expiring')
    ])

    this.setState({ certExpired: expired, certExpiring: expiring, loading: false })
  }

  showExpiring (days) {
    return days !== null && parseInt(days) <= 90 && parseInt(days) > 0
  }
}

export default ReportEmployeeCert
