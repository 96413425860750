import React, { Component } from 'react'
import { connect } from 'react-redux'
import Moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import Communication from './Communication'

import { authService, jobService, employeeService, clientService,
  funderService, logService, settingGeneralService, settingBillingRateService,
  settingHolidayService, employeeLeaveService, settingOtherService,
  settingCancellationService, clientLeaveService, clientFundingService,
  communicationService, settingFileCategoryService, settingFileTypeService } from '../../../services'
import { FieldList } from '../../../constants'

import { fetchingJobs } from '../../../states/actions/job'
import { setRefreshCommLog } from '../../../states/actions'
import { JobMenu } from '../../../constants'
import { common, formatter, log, validator } from '../../../util'
import DurationBreakdown from '../../../util/duration'

// S1.5
import GetUpTask from './getup'
import File from './File'
import AddFileModal from './AddFileModal'

// UI
import { DateTimePicker, DurationPicker, Loading, List, Page, Panel, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Alert from 'antd/lib/alert'
import Badge from 'antd/lib/badge'
import Button from 'antd/lib/button'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Select from 'antd/lib/select'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Col from 'antd/lib/col'
// import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import TimePicker from 'antd/lib/time-picker'
import Notification from 'antd/lib/notification'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const { TextArea } = Input
const TabPane = Tabs.TabPane
const Option = Select.Option

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const DefaultDuration = 12

const IntervalBlock3Min = 3
const IntervalBlock15Min = 15
const IntervalChangeDate = '2020-09-28 12:00:00'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

export class Job extends Component {
  constructor (props) {
    super(props)
    this.state = {
      clientId: '',
      clientInfo: '',
      funderId: '',
      fundingId: '',
      funders: [],
      employees: [],
      providers: [],
      settings: [],
      payrolls: [],
      languages: [],
      skills: [],
      services: [],
      genders: [],
      funding: [],
      // files
      fileInfo: {},
      fileList: [],
      mainFileCategoryList: [],
      subFileCategoryList: [],
      showAddFileModal: false,
      currentEmployee: { id: '', name: '' },
      currentFunder: { id: '', name: '' },
      currentBillingCategory: { name: '' },
      isRecurring: false,
      billingRate: [],
      billingRateId: null,
      isEmergencyTime: false,
      isHoliday: false,
      isTimeConflict: false,
      isOnLeave: false,
      isMatchLanguage: true,
      isMatchSkill: true,
      billingCategory: [],
      emergencyField: false,
      selectedEmpInfo: '',
      item: { client: {}, employee: {} },
      modalShow: false,
      loading: false,
      showGetupHours: false,
      dayNumber: '',
      showCancelAlert: false,
      clientLanguage: [],
      clientSkill: [],
      clientServs: [],
      prevEmployee: [],
      logList: [],
      showSleepoverAlert: false,
      employeePending: false,
      cancelType: [],
      cancelReason: [],
      showOtherField: false,
      funderInfo: {},
      funderDetail: {},
      showFunderAlert: false,
      clientLeaveInfo: '',
      clientIsOnLeave: false,
      clientConflictInfo: '',
      showClientConflictAlert: false,
      showFunderModal: false,
      //isOvernightSleepover: false,
      notAssignedTime: false,
      currentTotalHours: 0,
      employeeId: '',
      providerId: '',
      durationBreakdown: '',
      conflictJobInfo: '',
      showCancelModal: false,
      showCancelConfirmModal: false,
      showEmergencyModal: false,
      settingOthers: {},
      showEdit: true,
      showSave: false,
      clientFunderId: 0,
      unsentCommTotal: 0,
      tabActiveKey: '1',
      showEmployeeReasonModal: false,
      changeEmployeeReason: '',
      changeEmployeeReasonType: '',
      changeEmployeeOtherReason: '',
      changeEmployeeNote: '',
      isHandlingLateCancel: false,
      isHandlingCancel: false,
      shouldRefreshComm: false,
      showClientPublicAlert: false,
      showClientPrivateAlert: false,
      showEmployeePublicAlert: false,
      showEmployeePrivateAlert: false,
      showPlanPeriodEndedAlert: false,
      jobBalanceHours: { message: '', remaining_hours: null },
      jobDurationBeforeEdit: 0,
      jobDurationInterval: IntervalBlock3Min,
      isShowFundingPeriod: false
    }
  }

  async componentDidMount () {
    let clientId = ''
    let funderId = ''
    let fundingId = ''
    let employeeId
    let providerId
    let serviceId

    if (window) {
      window.scrollTo(0,0)
    }

    this.fetchSettings()

    if (this.isEdit()) {
      const item = await this.fetchJob()
      const jobId = this.getJobId()
      if (item) {
        clientId = item.client_id
        fundingId = item.funding_period_id
        employeeId = item.employee_id
        providerId = item.provider_id
        serviceId = item.service_id

        this.showBreakdown(Moment(item.job_start_date), Moment(item.job_end_date))
      }

      this.fetchLogs()
      this.fetchFunders(clientId, fundingId, item.job_start_date)
    } else {
      const { location } = this.props
      const { client, funding } = common.getQueryString(location.search)

      clientId = client
      fundingId = funding

      this.fetchBalanceHour(clientId)
      this.fetchFunders(clientId, fundingId, 'now')
    }

    this.fetchClient(clientId, employeeId)
    this.fetchAllProviders(clientId)
    this.fetchAllEmployees()
    this.fetchFileCategories()

    this.setState({ clientId, funderId, employeeId, providerId, serviceId })
  }

  // if the date selected is before/after the IntervalChangeDate, assign the interval block time respectively
  checkIntervalChange = (date) => {
    let newInterval = this.state.jobDurationInterval

    if (date && Moment(date).isValid()) {
      const cdate = Moment(date).clone().utc()
      const jdate = Moment(IntervalChangeDate).utc()

      if (cdate.isAfter(jdate)) {
        newInterval = IntervalBlock3Min
      } else {
        newInterval = IntervalBlock15Min
      }
    }

    return newInterval
  }

  handleDateChange = async (date) => {
    const { form, history } = this.props
    const { clientId, item: job, jobDurationInterval, funderDetail } = this.state

    const jobDuration = job.job_duration || DefaultDuration
    const jobStartDateTime = date
    let newInterval = this.checkIntervalChange(date)
    const availablePlans = await clientService.getClientFundingAll(clientId)

    if (availablePlans && availablePlans.length > 0) {
      const isBetween = availablePlans.find(item => Moment(date).isBetween(item.start_date, item.end_date))

      if (!isBetween) {
        confirm({
          title: `The service date is outside of available plan period. Are you sure to add job?`,
          content: (
            <div />
          ),
          okText: 'Yes',
          cancelText: 'No',
          onOk () { },
          onCancel () {
            history.replace(`/jobs/all`)
          }
        })
      }
    }

    if (funderDetail && funderDetail.id && Moment(date).isAfter(funderDetail.end_date)) {
      this.setState({ showPlanPeriodEndedAlert: true })
    } else {
      this.setState({ showPlanPeriodEndedAlert: false })
    }

    this.setState({ jobStartDateTime, jobDurationInterval: newInterval, billingCategory: [] })

    const jobStartDt = date ? Moment(date).format(dbFormat) : ''
    this.fetchBalanceHour(clientId, jobStartDt)
    this.updateBillingRate(clientId, jobStartDt)
  }

  handleDurationChange = async (jobDuration) => {
    // const { form } = this.props
    // const jobStartDateTime = form.getFieldValue('job_start_date')
    // if (jobStartDateTime) {
    //   const newJobEndDateTime = Moment(jobStartDateTime).add(jobDuration, 'minutes')
    //   const durationsHours = newJobEndDateTime.diff(jobStartDateTime, 'hours')

    //   this.showBreakdown(jobStartDateTime, newJobEndDateTime)
    // }

    this.setState({ item: { ...this.state.item, job_duration: jobDuration }})
  }

  handleBadgeCount = (value) => {
    this.setState({ unsentCommTotal: value })
    this.fetchLogs()
  }

  replacer = (value) => {
    let str = value

    if (typeof str === 'string') {
      // Replace special characters
      str = str.replace(/[•·]/g, '-')

      // Remove extra blank space or tab
      str = str.replace(/([ ]{2,})|(\t)/g, ' ')

      return str
    }

    return str || ''
  }

  render () {
    const { history, match, form } = this.props
    const { getFieldDecorator } = form
    const { clientInfo, item, isRecurring, showEdit, showSave, unsentCommTotal, tabActiveKey,
      showCancelConfirmModal, isHandlingLateCancel, isHandlingCancel, settingOthers, showEmergencyModal, showEmployeeReasonModal,
      showFunderModal, showCancelModal, cancelType, showOtherField, funders, cancelReason, shouldRefreshComm,
      showClientPrivateAlert, showClientPublicAlert, mainFileCategoryList, subFileCategoryList, fileInfo, showAddFileModal } = this.state
    let clientPrivateAlertMsg = ''

      const formItemLayout = {
        labelCol: { sm: 6, md: 6, lg: 4 },
        wrapperCol: { sm: 14, md: 14, lg: 17 }
      }

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = (pAlert || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Jobs' menu={JobMenu} countData={this.props.totalPending} backLink='/jobs/all' />
        </Page.Left>
        <Page.Content full>

          <Page.Header title={
            !this.isEdit() ? `New Job ${clientInfo ? `For ${clientInfo.first_name} ${clientInfo.last_name}` : ''}`
              : item.is_cancel && !item.is_delete
                ? 'Single Job (Cancelled)'
                : !item.is_cancel && !item.is_delete
                  ? 'Single Job'
                  : ''
          }>
            {
              showSave && this.isEdit() && !item.is_cancel && this.hasAccess('updateJob') ? (
                <div className='btn' onClick={this.showCancelModal} style={{ marginRight: 15 }}>Cancel Job</div>
              ) : null
            }
            {
              showSave && this.isEdit() && item.is_cancel && this.hasAccess('updateJob') ? (
                <div className='btn' onClick={this.handleUncancel} style={{ marginRight: 15 }}>Uncancel Job</div>
              ) : null
            }

            {
              showEdit && this.isEdit() && this.hasAccess('updateJob') ? (
                <div className='btn' onClick={this.handleEditButton}>Edit</div>
              ) : null
            }
            {
              (showSave && (this.hasAccess('updateJob')) && !item.is_cancel) || (!this.isEdit() && this.hasAccess('createJob')) ? (
                <div className='btn' onClick={this.checkBeforeSave}>Save</div>
              ) : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>


          { showClientPrivateAlert && clientInfo.private_alert
            ? <div>
                  <Alert message={
                    <div dangerouslySetInnerHTML={{
                      __html: `<span style="font-weight: bold;">Participant: ${clientInfo.first_name} ${clientInfo.last_name}</span><br />${clientPrivateAlertMsg}`
                    }} />
                  } type='warning' showIcon /><br />
                </div>
                : null }

          <Tabs activeKey={tabActiveKey} onChange={(e) => this.handleChangeTab(e)} >
            <TabPane tab={<div><Icon type='info-circle' /> Information</div>} key='1'>
              { this.infoTab() }
            </TabPane>

            { this.isEdit()
              ? <TabPane tab={<div><Icon type='safety' /> Files</div>} key='2'>
                <File jobId={item.id} history={history} onRefreshPage={() => this.refreshPage()} />
              </TabPane>
              : null }

            { this.isEdit() ? <TabPane tab={<div><Icon type='bars' /> Activity Log</div>} key='3'>
              { this.logTab() }
            </TabPane> : null }
          </Tabs>

          {/* --------------------------------------CANCEL CONFIRM MODAL START---------------------------------------- */}
          {/* <Modal
            width={550}
            title='Are you sure you want to cancel this job?'
            visible={showCancelConfirmModal}
            onOk={this.handleLateCancel}
            onCancel={() => this.setState({ showCancelConfirmModal: false })}
            footer={[
              <Button key='1' type='primary' loading={isHandlingLateCancel} onClick={() => this.handleLateCancel(true)}>Late Cancel</Button>,
              <Button key='2' loading={isHandlingCancel} onClick={() => this.handleLateCancel()}>Normal Cancel</Button>
            ]}
          >
            <div style={{ fontSize: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <div style={{ fontSize: 50, color: 'orange', marginRight: '20px' }}>
                <Icon type='question-circle' />
              </div>

              <div>You are cancelling this job within {settingOthers.cancellation_notice} hours of start time making it a Late Cancellation.<br /><br />
            Please confirm if Late Cancellation charges should be applied.<br /><br />
                <div>Late Charge: <b>{this.getChargeHours()} hours</b></div>
              </div>

            </div>
          </Modal> */}
          {/* --------------------------------------CANCEL CONFIRM MODAL END---------------------------------------- */}

          {/* --------------------------------------CHANGE FUNDER MODAL START---------------------------------------- */}
          {/* <Modal
            width={600}
            title='Change Funder'
            visible={showFunderModal}
            onOk={this.handleFunderSubmit}
            onCancel={this.handleFunderCancel}
            footer={[
              <Button key='ok' type='primary' onClick={this.handleFunderSubmit}>Confirm</Button>,
              <Button key='cancel' onClick={this.handleFunderCancel}>Cancel</Button>
            ]}
          >
            <FormItem {...formItemLayout} label='Funder' hasFeedback>
              {getFieldDecorator('funder_id', {
                initialValue: item.funder_id
              })(
                <Select
                  style={{ width: '100%' }}
                  placeholder='Funders'>
                  {
                    funders.map((funder, idx) => (<Option key={idx} value={funder.funder_id}>
                      {funder.funder_name}
                    </Option>))
                  }
                </Select>
              )}
            </FormItem>
          </Modal> */}
          {/* --------------------------------------CHANGE FUNDER MODAL END---------------------------------------- */}

          {/* --------------------------------------CANCEL JOB MODAL START---------------------------------------- */}
          <SideModal
            title='Cancel Job'
            showModal={showCancelModal}
            onClose={this.hideCancelModal}
            buttons={[
              <Button key='0' type='primary' onClick={this.handleCancelJob}>Cancel Job</Button>
            ]}
          >

            <FormItem label='Cancellation Type'>
              {getFieldDecorator('cancellation_type', {
                rules: [
                  { required: true, message: 'Please select cancellation type' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleCancelTypeChange}>
                  {
                    cancelType.map((items, idx) => {
                      return <Option key={idx} value={items.id}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Cancellation Reason'>
              {getFieldDecorator('cancellation_reason', {
                rules: [
                  { required: true, message: 'Please select reason' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleCancelReasonChange}>
                  {
                    cancelReason.map((items, idx) => {
                      return <Option key={idx} value={items.name}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            {
              showOtherField

                ? (
                  <FormItem label='Other Reason For Cancellation'>
                    {getFieldDecorator('cancellation_other_reason', {
                    })(
                      <TextArea row={2} />
                    )}
                  </FormItem>
                )

                : null
            }
            <FormItem label='Notes (Optional)'>
              {getFieldDecorator('cancellation_note', {
              })(
                <TextArea rows={2} />
              )}
            </FormItem>

          </SideModal>
          {/* --------------------------------------CANCEL JOB MODAL END---------------------------------------- */}

          {/* --------------------------------------CHANGE EMPLOYEE MODAL START---------------------------------------- */}
          <SideModal
            title='You Have Changed Employee'
            showModal={showEmployeeReasonModal}
            onClose={() => this.setState({showEmployeeReasonModal: false})}
            buttons={[
              <Button key='0' type='primary' onClick={() => this.handleEmployeeChangeReason()}>Submit</Button>
            ]}
          >
            <FormItem label='Reason Type'>
              {getFieldDecorator('change_employee_reason_type', {
                rules: [
                  { required: true, message: 'Please select reason type' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleEmployeeReasonTypeChange}>
                  {
                    cancelType.map((items, idx) => {
                      return <Option key={idx} value={items.id}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            <FormItem label='Reason To Change'>
              {getFieldDecorator('change_employee_reason', {
                rules: [
                  { required: true, message: 'Please select reason' }
                ]
              })(
                <Select style={{ width: '100%' }} onChange={this.handleCancelReasonChange}>
                  {
                    cancelReason.map((items, idx) => {
                      return <Option key={idx} value={items.name}>{items.name}</Option>
                    })
                  }
                </Select>
              )}
            </FormItem>
            {
              showOtherField

                ? (
                  <FormItem label='Other Reason To Change'>
                    {getFieldDecorator('change_employee_other_reason', {
                    })(
                      <TextArea row={2} />
                    )}
                  </FormItem>
                )

                : null
            }
            <FormItem label='Notes (Optional)'>
              {getFieldDecorator('change_employee_note', {
              })(
                <TextArea rows={2} />
              )}
            </FormItem>

          </SideModal>
          {/* --------------------------------------CHANGE EMPLOYEE MODAL END---------------------------------------- */}

          {/* --------------------------------------ADD FILE MODAL START---------------------------------------- */}
          <AddFileModal
            jobId={'add'}
            key={`addfilemodaljob_new`}
            item={fileInfo}
            categoriesList={mainFileCategoryList}
            subCategoriesList={subFileCategoryList}
            onClose={() => this.handleAddFileModal(false)}
            onSetFile={(values) => this.updateFileAdded(values)}
            visible={showAddFileModal}
          />

          {/* --------------------------------------ADD FILE MODAL END---------------------------------------- */}
        </Page.Content>
      </Page.Body>

    )
  }

  infoTab = () => {
    const { form } = this.props
    const { billRateInfo, billingRateId, employees, funders, item, loading, clientInfo, jobBalanceHours, payrolls, isHoliday, holidayInfo = [], billingCategory, services,
      isRecurring, emergencyField, knownLanguage = [], missingSkill = [], isMatchLanguage, isMatchSkill, isTimeConflict,
      isOnLeave, selectedEmpInfo, clientLanguage, prevEmployee, clientSkill, clientServs, showSleepoverAlert, employeePending,
      cancelType, cancelReason, showOtherField, clientLeaveInfo, clientIsOnLeave, clientConflictInfo, showClientConflictAlert,
      showCancelModal, showCancelConfirmModal, showEmergencyModal, settingOthers, funderInfo, funderDetail, showFunderAlert, showFunderModal,
      durationBreakdown, conflictJobInfo, isLicenseExpired, isDwesExpired, isPoliceExpired, isFirstAidExpired, isVehicleInsuranceExpired,
      isVehicleRegistrationExpired, isWWCCExpired, showEmployeeReasonModal, isHandlingLateCancel, isHandlingCancel, showEmployeePrivateAlert,
      showEmployeePublicAlert, showPlanPeriodEndedAlert,
      jobStartDateTime, jobEndDateTime, jobDurationInterval, isShowFundingPeriod, fileList, mainFileCategoryList, subFileCategoryList } = this.state
    let employeePrivateAlertMsg = ''
    let employeePublicAlertMsg = ''
    let itemTasks = item.tasks || ''
    let itemNotes = item.notes || ''

    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 17 }
    }
    const shortFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }
    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }
    const threeeFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 14 },
      wrapperCol: { sm: 14, md: 14, lg: 8 }
    }

    if (selectedEmpInfo && selectedEmpInfo.private_alert) {
      const pAlert = selectedEmpInfo.private_alert
      employeePrivateAlertMsg = (pAlert || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const balanceHourWords = jobBalanceHours.message
      ? jobBalanceHours.message
      : jobBalanceHours.remaining_hours === null
      ? ''
      : `Current plan has ${formatter.toDecimalOptional(jobBalanceHours.remaining_hours)} hour${jobBalanceHours.remaining_hours === 1 ? '' : 's'} before ${this.isEdit() ? 'editing' : 'adding'} this job.`

    const fileColumns = [
      {
        title: 'Main Category',
        width: 4,
        render: ({ main_category_id }) => {
          const mainCat = mainFileCategoryList.find(e => e.id === main_category_id)

          return <div>{mainCat ? mainCat.name : ''}</div>
        }
      },
      {
        title: 'Sub Category',
        width: 4,
        render: ({ sub_category_id }) => {
          const subCat = subFileCategoryList.find(e => e.id === sub_category_id)

          return <div>{subCat ? subCat.file_sub_category : ''}</div>
        }
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, file_name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{file_name ? `[${formatter.toStandardFileName(file_name)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          <Tooltip mouseLeaveDelay={0} title='Edit'><div onClick={() => this.handleAddFileModal(true, item)} style={{ cursor: 'pointer' }}><Icon type='form' /></div></Tooltip>
          <Tooltip mouseLeaveDelay={0} title='Delete'>
            <Popconfirm
              title={`Confirm to delete ${item.label ? item.label : 'this'}?`}
              onConfirm={() => this.handleFileDelete(item)}
              okText='Yes'
              cancelText='No'
            ><Icon type='delete' />
            </Popconfirm>
          </Tooltip>
        </div>
      }
    ]

    return <Loading loading={loading} blur>

      <Row gutter={16}>
        <Col lg={12}>
          <Panel>
            { !clientInfo
              ? <Skeleton paragraph={{ rows: 1 }} />
              : <Row>
                <Col style={{ height: 105 }}>
                  <span className='client-name'><a href={`/clients/${clientInfo.id}`} target='_blank'>{ clientInfo.first_name } { clientInfo.last_name }</a></span>
                  <span className='client-accref'>{ clientInfo.acc_ref }</span>
                  <div className='client-details'>
                    { clientInfo.phone ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { clientInfo.phone }</div> : null }
                    <div style={{ fontSize: 12, marginRight: 10 }}>{ clientInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { clientInfo.unit_building ? `${clientInfo.unit_building},` : '' } {clientInfo.address }</div>
                  </div>
                  <div className='client-details' style={{ marginTop: 15 }}>
                    <b>Preference</b>
                    {/* <div style={{ fontSize: 12, marginRight: 10, marginLeft: 10, textTransform: 'capitalize' }}>{ clientInfo.preferred_gender ? <span style={{ color: '#ed6d1e' }}><Icon type='user-add' /></span> : null } { clientInfo.preferred_gender }</div> */}

                    <div style={{ fontSize: 12, marginLeft: 10 }}>{ clientLanguage ? <span style={{ color: '#ed6d1e' }}><Icon type='font-size' /></span> : null } {
                      clientLanguage.map((language, idx) => (
                        <span key={idx} className='preferrence-item'>{language}{idx === clientLanguage.length - 1 ? '' : ','}</span>
                      ))
                    }</div>
                  </div>
                  <div className='client-sub-details'>
                    <b>Services</b>
                    <div style={{ fontSize: 12, marginLeft: 10 }}>{ clientServs ? <Icon type='check-circle' theme='twoTone' twoToneColor='#ed6d1e' /> : null } {
                      clientServs.map((serv, idx) => (
                        <span key={idx} className='preferrence-item'>{serv}{idx === clientServs.length - 1 ? '' : ','}</span>
                      ))
                    }</div>
                  </div>
                </Col>
              </Row>
            }
          </Panel>
        </Col>
        <Col lg={12}>
          <Panel>
            { !isShowFundingPeriod
              ? <Skeleton paragraph={{ rows: 1 }} />
              : <Row>
                <Col lg={24} style={{ height: 105 }}>
                  { funderDetail && funderDetail.id
                    ? <div>
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                          { funderInfo.fullname ? <span className='client-name'> <a href={`/funders/${funderInfo.id}`} target='_blank'> { funderInfo.fullname }</a> </span> : null }
                          { funderInfo.acc_ref ? <span className='client-accref'>{ funderInfo.acc_ref }</span> : null }
                        </div>
                        {/* <span className='changeFunder'
                          style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', cursor: 'pointer' }}
                          onClick={this.handleChangeFunder}><Icon type='edit' /></span> */}
                      </div>

                      <div className='client-details'>
                        { funderInfo.phone_number ? <div style={{ fontSize: 12, marginRight: 10 }}><Icon type='phone' theme='twoTone' twoToneColor='#ed6d1e' /> { funderInfo.phone_number }</div> : null }
                        <div style={{ fontSize: 12 }}>{ funderInfo.address ? <Icon type='home' theme='twoTone' twoToneColor='#ed6d1e' /> : null } { funderInfo.address }</div>
                      </div>

                      { funderDetail && funderDetail.id ? <div><div className='client-sub-details' style={{ marginTop: 15 }}>
                        <b>Plan Period</b>
                        <div style={{ fontSize: 12, marginLeft: 10 }}>
                          <span style={{ color: '#ed6d1e' }}><Icon type='calendar' /></span> { funderDetail && funderDetail.id ? <span>{Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' - ' + Moment(funderDetail.end_date).format('DD/MM/YYYY')} {showPlanPeriodEndedAlert ? <span style={{ color: 'red' }}> (ENDED)</span> : ''}</span> : '' }
                        </div>
                      </div>
                      <div className='client-sub-details' style={{ marginTop: 5 }}>
                        <b>SC Fund</b>
                        <div style={{ fontSize: 12, marginLeft: 10 }}>
                          <span style={{ color: '#ed6d1e' }}></span> { funderDetail && funderDetail.sc_fund !== '' ? ( funderDetail.sc_fund === 'ndia-managed' ? 'NDIA-managed' : formatter.capitalize(funderDetail.sc_fund) ) : '-' }
                        </div>
                      </div></div> : null
                      }
                    </div>
                    : <div>
                      <div className='client-details'>
                      <div style={{ fontSize: 12 }}>No active funding period avaliable.</div>
                      </div>
                    </div>}
                </Col>
              </Row>
            }
          </Panel>
        </Col>
      </Row>

      <Panel
        title={`Job Date`}
        subtitle={<Row>
          <Col lg={24}>
            <div style={{ fontSize: 12, color: 'red' }}>
              { balanceHourWords }
            </div>
          </Col>
        </Row>}>
        <Row>
          {/* Start Alert */}
          <Col lg={24}>
            {
              clientIsOnLeave ? <div className='alert-info'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Participant Not Available</span> {clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</div>}
                  type='error'
                  banner
                  showIcon
                />
              </div> : null
            }
            {
              showClientConflictAlert ? <div className='alert-info'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Participant Has Another Shift</span> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}</div>}
                  type='error'
                  banner
                  showIcon
                />
              </div> : null
            }
            {/* {
              form.getFieldValue('job_end_date') < form.getFieldValue('job_start_date') ? <div className='alert-container'>
                <Alert
                  message={<div><span style={{ fontWeight: 'bold' }}>Job Time Error</span> End Time should not be earlier than Start Time </div>}
                  type='error'
                  banner
                  showIcon
                /></div> : null
            } */}
            {
              showFunderAlert ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Funder Not Available</span> { funderDetail.funder_name } not available for selected date</div>}
                  type='error'
                  showIcon
                />
              </div> : null
            }
            {/* {
              isHoliday ? <div className='alert-info'>
                <Alert
                  banner
                  message={<div><span style={{ fontWeight: 'bold' }}>Public Holiday</span> {`${holidayInfo.name} (${Moment(holidayInfo.date).format('DD/MM/YYYY')})`}</div>}
                  type='info'
                  showIcon
                />
              </div> : null
            } */}
          </Col>
          {/* End Alert */}
        </Row>
        <Row>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Date'>
              {getFieldDecorator('job_start_date', {
                initialValue: this.isEdit() && item.job_start_date ? Moment(item.job_start_date) : Moment(),
                rules: [
                  { required: true, message: 'Please enter job start time' }
                ]
              })(
                <DateTimePicker onChange={this.handleDateChange} showTime={false} />
              )}
            </FormItem>
          </Col>
          <Col lg={12}>
            <FormItem {...shortFormItemLayout} label='Duration'>
              {getFieldDecorator('job_duration', {
                initialValue: this.isEdit() && item.job_duration ? item.job_duration : this.isEdit() ? 0 : DefaultDuration,
                rules: [
                  { required: true, message: 'Please enter job duration' }
                ]
              })(
                <DurationPicker
                  onChange={this.handleDurationChange}
                  value={item.job_duration}
                  dateValue={jobStartDateTime}
                  interval={jobDurationInterval}
                />
              )}
            </FormItem>
          </Col>
        </Row>

      </Panel>

      <Panel title='Shift Work' type={item.employee_id === null ? 'warn' : ''}>
        <Row gutter={16}>
          {/* // Start Alert */}

          <Col lg={24}>
            <div style={{ marginBottom: 10 }}>
              {
                !isMatchLanguage ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Language Mismatch</span> {`${clientInfo.first_name} ${clientInfo.last_name} speaks these languages: ${knownLanguage},
                      but ${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name} does not. `} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
              {
                !isMatchSkill ? <div className='alert-info'>
                  <Alert
                    message={<div><span style={{ fontWeight: 'bold' }}>Skills Mismatch</span> {`${selectedEmpInfo.first_name} ${selectedEmpInfo.last_name}  does not possess following skill(s): ${missingSkill}`} </div>}
                    type='error'
                    banner
                    showIcon
                  />
                </div> : null
              }
            </div>
          </Col>
          {/* // End Alert */}
        </Row>

        <Form layout='vertical'>
          <Row gutter={16}>
            <Col lg={8}>
              <FormItem
                label={<span>Support Item
                </span>}>
                {getFieldDecorator('billing_category_id', this.isEdit() ? {
                    initialValue: item.billing_category_id,
                    rules: [
                      { required: true, message: 'Please Select Billing Rate' }
                    ]
                  }
                  : {
                    initialValue: Array.isArray(billingCategory) && billingCategory.length > 0 ? billingCategory[0].category_id : '',
                    rules: [
                      { required: true, message: 'Please Select Billing Rate' }
                    ]
                  })(
                  <Select onSelect={(v, o) => this.onSelectBillingRate(o)}>
                    {
                      billingCategory && billingCategory.map((bill, idx) => {
                        return <Option key={idx} value={bill.category_id}>{bill.category_name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label='Service'>
                {getFieldDecorator('service_id', this.isEdit() ? {
                    initialValue: item.service_id,
                    rules: [
                      { required: true, message: 'Please Select Service' }
                    ]
                  }
                  : {
                    initialValue: '',
                    rules: [
                      { required: true, message: 'Please Select Service' }
                    ]
                  })(
                    <Select
                      optionFilterProp='children'
                      filterOption={(input, option) => this.findServices(input, option)}
                    >
                    {
                      services.map((pay) => {
                        return <Option key={pay.id} value={pay.id}>{pay.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label={<span>Employee</span>} hasFeedback>
                {getFieldDecorator('employee_id', {
                  initialValue: item.employee_id,
                  rules: [
                    !employeePending ? { required: true, message: 'Please select an employee OR turn on Pending' } : {}
                  ]
                })(
                  <Select showSearch
                    style={{ width: '100%' }}
                    placeholder='Employee'
                    optionFilterProp='children'
                    notFoundContent='Not found'
                    filterOption={(input, option) => this.findEmployees(input, option)}
                    disabled={employeePending}>
                    {
                      employees.map((items, idx) => {
                        return <Option key={idx} value={items.id}>{items.first_name} {items.last_name} { items.leave_id ? <Tooltip title={`Leave ${formatter.toShortDate(items.leave_start)} - ${formatter.toShortDate(items.leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> : null }</Option>
                      })
                    }

                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={16}>
            <Col lg={8}>
              <FormItem label='Tasks' hasFeedback>
                {getFieldDecorator('tasks', {
                  initialValue: this.replacer(itemTasks),
                  rules: [
                    { min: 2, message: 'Tasks must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter tasks' }
                  ]
                })(
                  <TextArea row={10} style={{ minHeight: '100px' }} />
                )}
              </FormItem>
            </Col>
            <Col lg={8}>
              <FormItem label='Notes'>
                {getFieldDecorator('notes', {
                  initialValue: this.replacer(itemNotes),
                })(
                  <TextArea row={10} style={{ minHeight: '100px' }} />
                )}
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Panel>

    { !this.isEdit()
      ? <Panel
        title='Files'
        subtitle={(<div className='btn' onClick={() => this.handleAddFileModal(true)}> {'Add File'}</div>)}
      >
        <List cols={fileColumns} rows={fileList} />
      </Panel>
      : null }
    </Loading>
  }

  logTab = () => {
    const { loading, logList } = this.state
    const columns = [
      {
        title: 'Created At',
        width: 5,
        render: ({ created_at: createdAt }) => Moment(createdAt).format('DD/MM/YYYY hh:mm A')
      },
      {
        title: 'Updated By',
        width: 4,
        render: ({ updated_by: updatedBy }) => <div style={{ textTransform: 'capitalize' }}>{updatedBy}</div>
      },
      {
        title: 'Action',
        width: 3,
        render: ({ action }) => <div style={{ textTransform: 'uppercase' }}>{action}</div>
      },
      {
        title: 'Changes',
        width: 12,
        key: 'changes'
      }
    ]

    return (
      <Loading loading={loading} blur>
        <div className='task-list'>
          <Skeleton loading={loading} active>
            <List cols={columns} rows={logList} />
          </Skeleton>
        </div>
      </Loading>
    )
  }

  /*********************************************************************************
    Keep all refactored/clean codes below here
    Please arrange by alphabet A-Z
  *********************************************************************************/

  // 1. Fetching data ////////////////////////////////////////////////////////////////////////////////////

  fetchAllProviders = async (clientId, providerId) => {
    try {
      const providersAvailable = await clientService.getClientAvailableProviderList(clientId)
      this.setState({ providers: providersAvailable })

      // setTimeout(() => {
      //   this.cacheEmployeeName(employeeId)
      // }, 150)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load providers successfully. Please try again later.')
    }
  }

  fetchAllEmployees = async () => {
    try {
      const { list } = await employeeService.listByPage(1, 0, { active: { condition: '=', value: true } })
      this.setState({ employees: list })

      // setTimeout(() => {
      //   this.cacheEmployeeName(employeeId)
      // }, 150)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchBillingRate = async (clientId, funderId, jobStartDate) => {
    const funderInfo = await funderService.get(funderId)
    const funderInfoRes = funderInfo.item
    const funderRateSet = funderInfoRes ? funderInfoRes.rate_set : null
    const billRateInfo = await settingBillingRateService.get(funderRateSet)

    // const billingCategory = await clientFundingService.getBillingCategory(clientId, funderId)
    const billingCategory = await clientFundingService.getBillingCategoryByPeriod(clientId, jobStartDate)

    // CR0003 - SO Getup
    const nonSleepoverCategory = billingCategory.filter(item => item.category_name !== FieldList.SLEEPOVER)
    const sleepoverCategory = billingCategory.filter((item) => item.category_name === FieldList.SLEEPOVER)

    this.setState({
      billingCategory: nonSleepoverCategory,
      completeBillingCategory: billingCategory,
      billingRateId: funderRateSet,
      billRateInfo
    }, () => {
      this.cacheBillingCategory()
    })
  }

  updateBillingRate = async (clientId, jobStartDate) => {
    const billingCategory = await clientFundingService.getBillingCategoryByPeriod(clientId, jobStartDate)
    const nonSleepoverCategory = billingCategory.filter(item => item.category_name !== FieldList.SLEEPOVER)

    this.setState({
      billingCategory: nonSleepoverCategory
    }, () => {
      this.cacheBillingCategory()
    })
  }

  fetchCancel = async () => {
    const cancelType = await settingCancellationService.getAll()
    this.setState({ cancelType: cancelType.list })
  }

  getCancelTypeName = (id) => {
    const { cancelType } = this.state
    const type = cancelType.filter((item) => item.id === id)
    return type ? type[0].name : ''
  }

  // Get client remaning job hours
  fetchBalanceHour = async (clientId, date = 'now') => {
    let balanceHours = await clientService.getClientBalanceHours(clientId, date)

    if (balanceHours && balanceHours.client_id) {
      this.setState({ jobBalanceHours: balanceHours })
    } else {
      this.setState({ jobBalanceHours: { message: balanceHours.message, remaining_hours: null } })
    }
  }

  fetchClient = async (clientId, empId) => {
    try {
      const clientInfoRes = await clientService.get(clientId)
      const clientInfo = clientInfoRes.item
      const clientLanguages = clientInfo.languages
      const clientServices = clientInfo.services
      const clientHasPrivateAlert = clientInfo.private_alert !== null && !validator.isEmptyString(clientInfo.private_alert)


      // Get services info
      if (clientServices && clientServices.length > 0 && clientServices[0] !== null) {
        let preferredService = []
        const services = await Promise.all(clientServices.map(item => settingGeneralService.get(item)))

        for (var j = 0; j < services.length; j++) {
          const item = services[j].item.name
          preferredService.push(item)
        }

        this.setState({ clientServs: preferredService })
      }

      // Get languages info
      if (clientLanguages && clientLanguages.length > 0 && clientLanguages[0] !== null) {
        let preferredLanguage = []
        const languages = await Promise.all(clientLanguages.map(item => settingGeneralService.get(item)))

        for (var i = 0; i < languages.length; i++) {
          const item = languages[i].item.name
          preferredLanguage.push(item)
        }

        this.setState({ clientLanguage: preferredLanguage })
      }

      this.setState({ clientInfo, showClientPrivateAlert: clientHasPrivateAlert })
    } catch (e) {
      // console.log(e)
      notify.error('Unable to load successfully, Unable to load participants successfully. Please try again later')
    }
  }

  fetchEmployees = async (empId) => {
    try {
      const employeeInfoRes = await employeeService.get(empId)
      const employees = await employeeService.listByPage(1, 0)
      this.setState({ employeeInfo: employeeInfoRes.item, employeeId: employeeInfoRes.item.id }) //employees: employees.list,

      setTimeout(() => {
        this.checkEmployee(empId)
      }, 1500)
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }
  }

  fetchFileCategories = async () => {
    try {
      this.setState({ loading: true })
      const mainCats = await settingFileCategoryService.listByPage(1, 0, { active: true, classification: 'job' })
      const subCats = await settingFileTypeService.listByPage(1, 0, { active: true, classification: 'job' })

      this.setState({
        mainFileCategoryList: mainCats && validator.isNotEmptyArray(mainCats.list) ? mainCats.list : [],
        subFileCategoryList: subCats && validator.isNotEmptyArray(subCats.list) ? subCats.list : [],
        loading: false
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load file categories successfully. Please try again later.')
    }
  }

  fetchFunders = async (clientId, fundingId, jobStartDate) => {
    // due to addJobModal desdoes not select funding period, for new created job, must select one current active funding id and attach
    // TODO: need to update funding id if the funding period is not in selected period
    if (!fundingId) {
      const fp = await clientService.getClientFundingPeriod(clientId)
      const period = fp && validator.isNotEmptyArray(fp.item) ? fp.item : []
      if (period.length > 0) {
        fundingId = period[0].id
      }
    }

    const funders = await clientService.getClientFunders(clientId)
    const funderDetail = this.isEdit()
      ? await clientService.getClientFundingByStartD(fundingId, jobStartDate)
      : await clientService.getClientFunding(fundingId) || {}
    let funderId = ''

    if (funderDetail && funderDetail.id) {
      funderId = funderDetail.funder_id

      if (this.isEdit()) {
        if (Moment(jobStartDate).isAfter(funderDetail.end_date)) {
          this.setState({ showPlanPeriodEndedAlert: true })
        }
      } else {
        if (Moment().isAfter(funderDetail.end_date)) {
          this.setState({ showPlanPeriodEndedAlert: true })
        }
      }
    }

    if (funderId) {
      const funderInfo = await funderService.get(funderId)
      this.setState({
        funderInfo: validator.isNotEmptyObject(funderInfo) ? funderInfo.item : {},
        currentFunder: validator.isNotEmptyObject(funderInfo) ? funderInfo.item : {},
      })
    }

    this.setState({ funderId, funders, funderDetail, fundingId, isShowFundingPeriod: true })

    const fundClient = await clientService.getClientFundersInfo(clientId, funderId)

    if (fundClient.item && fundClient.item.end_date === null) {
      this.setState({ notAssignedTime: true })
      this.alertUnassignedTime()
    } else {
      this.setState({ notAssignedTime: false })
    }

    this.fetchBillingRate(clientId, funderId, jobStartDate)
  }

  fetchJob = async () => {
    try {
      this.setState({ loading: true })

      const jobId = this.getJobId()
      const { item } = await jobService.get(jobId)

      if (!item.job_start_date) {
        item.job_start_date = Moment().startOf('hour')
      }

      // if (!item.job_end_date) {
      //   item.job_end_date = Moment(item.job_start_date).startOf('hour').add(1, 'hour')
      // }

      let newInterval = IntervalBlock3Min
      if (item.job_duration && item.job_start_date) {
        newInterval = this.checkIntervalChange(item.job_start_date)
      }

      const isEmergency = item.emergency

      if (item.client_id) {
        // Get client remaning job hours
        const startDate = item.job_start_date ? Moment(item.job_start_date).format(dbFormat) : null // avoid RFC2822 not compatible issue
        await this.fetchBalanceHour(item.client_id, startDate)
      }

      this.setState({
        item,
        loading: false,
        billingRateId: item.billing_rate_id,
        emergencyField: isEmergency,
        currentTotalHours: item.job_hours,
        clientId: item.client_id,
        jobDurationBeforeEdit: item.job_duration || 0,
        jobDurationInterval: newInterval
      })

      if (item.pending_employee) {
        this.setState({ employeePending: true })
      }

      this.setState({ loading: false })

      return item
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load job successfully. Please try again later.')
    }

    return null
  }

  fetchLogs = async () => {
    const jobId = this.getJobId()

    const filter = {
      type: 'job',
      type_id: jobId
    }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'service-sc' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    const settingOthers = await settingOtherService.get(1)

    this.setState({
      settings: settings.list,
      payrolls: settings.list.filter(item => item.type === 'payroll'),
      languages: settings.list.filter(item => item.type === 'language'),
      services: settings.list.filter(item => item.type === 'service-sc'),
      genders: settings.list.filter(item => item.type === 'gender'),
      funding: settings.list.filter(item => item.type === 'funding'),
      settingOthers: settingOthers.item
    })
  }

  findEmployees = (input, option) => {
    const client = `${option.props.children[0]} ${option.props.children[2]}`
    return client.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  findServices = (input, option) => {
    const service = `${option.props.children[0]}`
    return service.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  // 2. Logic ///////////////////////////////////////////////////////////////////////////////////////////

  checkBeforeSave = () => {
    const {
      showFunderAlert, showClientConflictAlert, clientInfo, selectedEmpInfo, clientConflictInfo, conflictJobInfo, funderDetail, showClientPrivateAlert, jobBalanceHours, jobDurationBeforeEdit, showPlanPeriodEndedAlert
    } = this.state
    let clientPrivateAlertMsg = ''
    let jobDurationOverAlert = 0


    const { form } = this.props
    const { handleSave } = this

    if (clientInfo && clientInfo.private_alert) {
      const pAlert = clientInfo.private_alert
      clientPrivateAlertMsg = (pAlert || '').replace(/(?:\r\n|\r|\n)/g, '<br />')
    }

    const currentDuration = form.getFieldValue('job_duration')
    let exceedHours = 0
    let newJobHour = 0

    if (jobBalanceHours.remaining_hours === null) {
      jobDurationOverAlert = 2
    } else {
      if (jobBalanceHours.plan_allocated_hrs !== null) {
        if (this.isEdit()) {
          const withoutJobHour = jobBalanceHours.total_hours - parseFloat((jobDurationBeforeEdit / 60).toFixed(2))
          newJobHour = withoutJobHour + parseFloat((currentDuration / 60).toFixed(2))
        } else {
          newJobHour = jobBalanceHours.total_hours + parseFloat((currentDuration / 60).toFixed(2))
        }

        exceedHours = newJobHour - jobBalanceHours.plan_allocated_hrs

        if (newJobHour > jobBalanceHours.plan_allocated_hrs) {
          jobDurationOverAlert = 1
        } else {
          jobDurationOverAlert = 0
        }
      }
    }

    // console.log('job duration', exceedHours, jobDurationOverAlert)

    // if (form.getFieldValue('job_end_date') < form.getFieldValue('job_start_date')) {
    //   this.showDateNotification()
    //   return
    // }

    if (showClientPrivateAlert || showFunderAlert || jobDurationOverAlert > 0 || showPlanPeriodEndedAlert) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div>
            <p>The job will be { this.isEdit() ? 'updated' : 'created' } with following issue(s):</p>

            {/* Private Alert */}

            {
              showClientPrivateAlert
                    ? <div>
                      <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
                        <span dangerouslySetInnerHTML={{
                          __html: ` <strong>Participant: ${clientInfo.first_name} ${clientInfo.last_name}</strong><br />${clientPrivateAlertMsg}`
                        }} />
                      </p>
                    </div>
                    : null
            }

            {/* Funder */}

            {
              showFunderAlert ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Funder Not Available On Selected Date</strong></p>
                <p>{ `Plan Period is from ` + Moment(funderDetail.start_date).format('DD/MM/YYYY') + ' to ' + Moment(funderDetail.end_date).format('DD/MM/YYYY') }</p>
                <br /></div> : null
            }
            {
              showPlanPeriodEndedAlert
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Funder's plan period has ended</strong></p>
                  <br />
                </div> : null
            }

            {/* Client On Leave */}

            {/* {
              clientIsOnLeave ? <div>
                <div><p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Participant On Leave</strong></p></div>
                <p>{clientInfo.first_name} {clientInfo.last_name} is on leave from {Moment(clientLeaveInfo.leave_start).format('DD/MM/YYYY')} to {Moment(clientLeaveInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            } */}

            {/* Client Shift Conflict */}

            {/* {
              showClientConflictAlert ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Participant Has Another Shift</strong></p>
                <p> {clientInfo.first_name} {clientInfo.last_name} has shift on {Moment(clientConflictInfo.job_start_date).format('DD/MM/YYYY')} {formatter.toShortTime(Moment(clientConflictInfo.job_start_date))} to {formatter.toShortTime(Moment(clientConflictInfo.job_end_date))}</p>
                <br />
              </div> : null
            } */}

            {/* Employee On Leave */}

            {/* {
              isOnLeave ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Employee On Leave</strong></p>
                <p>{selectedEmpInfo.first_name} {selectedEmpInfo.last_name} is on leave from {Moment(selectedEmpInfo.leave_start).format('DD/MM/YYYY')} to {Moment(selectedEmpInfo.leave_end).format('DD/MM/YYYY')}</p>
                <br />
              </div> : null
            } */}

            { /** Job duration has exceed funding plan allocated hours or no funding plan available */}

            { jobDurationOverAlert === 2
              ? <div>
                <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>No valid plan or usable hours available</strong></p>
                <p> {clientInfo.first_name} {clientInfo.last_name} may not have plan or usable hours available. Unable to retrieve info for plan allocated hours. </p>
                <br />
              </div>
              : jobDurationOverAlert === 1
                ? <div>
                  <p style={{ fontSize: '14px' }}><Icon type='exclamation-circle' theme='filled' style={{ color: 'red', fontSize: '12pt' }} /> <strong>Total Job Hours exceeds Plan Allocated Hours</strong></p>
                  <p> After {this.isEdit() ? 'updated' : 'added'} this job, the total job hours under current plan will exceed Plan Allocated Hours ({formatter.toDecimalOptional(jobBalanceHours.plan_allocated_hrs)} hour{jobBalanceHours.plan_allocated_hrs === 1 ? '' : 's'}) by {exceedHours} hour{exceedHours === 1 ? '' : 's'}. </p>
                  <br />
                </div>
                : null }


            <mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark>

          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () {
          // eslint-disable-next-line no-lone-blocks
          handleSave()
        },
        onCancel () {
        }
      })
    } else {
      handleSave()
    }
  }

  checkEmail = () => {
    const that = this
    const { unsentCommTotal } = this.state

    if (unsentCommTotal > 0) {
      that.setState({ shouldRefreshComm: true })
      confirm({
        title: `Send Communication?`,
        content: `A communication has been generated. Click Yes to review and send.`,
        okText: 'Yes',
        cancelText: 'No',
        onOk () {
          that.setState({ tabActiveKey: '2' })
        },
        onCancel () {

        }
      })
    } else {}
  }

  checkEmployee = async (empId, changeEmployee = false) => {
    const { clientInfo } = this.state
    const { form } = this.props
    const formStartDate = Moment(form.getFieldValue('job_start_date'))
    const formEndDate = Moment(form.getFieldValue('job_end_date'))

    const employeeInfoRes = await employeeService.get(empId)
    const employeeInfo = employeeInfoRes.item
    const clientLanguages = clientInfo.languages
    const clientSkills = clientInfo.skills

    this.setState({
      selectedEmpInfo: employeeInfo
    })

    if (clientSkills && clientSkills.length > 0 && clientSkills[0] !== null) {
      const finalSkills = []
      const employeeSkills = `${employeeInfo.skills}`

      const diffSkills = clientSkills.filter(x => !employeeSkills.includes(x))
      const isMatchSkill = !(diffSkills.length > 0)

      const skills = await Promise.all(diffSkills.map(item => settingGeneralService.get(item)))

      skills.map(skill => (
        finalSkills.push(skill.item.name)
      ))

      this.setState({ missingSkill: finalSkills, isMatchSkill })
      // this.showEmployeeSkillConfirm()
    }

    if (clientLanguages && clientLanguages.length > 0 && clientLanguages[0] !== null) {
      const languages = []
      const finalLang = []
      const employeeLanguage = employeeInfo.languages

      clientLanguages.forEach((e1) => employeeLanguage.forEach((e2) => {
        if (e1 === e2) {
          languages.push(e2)
        }
      }))

      const languageNames = await Promise.all(clientLanguages.map(item => settingGeneralService.get(item)))
      languageNames.map(lang => (
        finalLang.push(lang.item.name)
      ))

      this.setState({ knownLanguage: finalLang, isMatchLanguage: languages.length > 0 })
    }
  }

  checkHoliday = async (date) => {
    const holiday = await settingHolidayService.getTodayHoliday(date)

    if (holiday.list && holiday.list.length > 0) {
      this.setState({ isHoliday: true, holidayInfo: holiday.list[0] })
      return true
    } else {
      this.setState({ isHoliday: false })
      return false
    }
  }

  cacheEmployeeName = (employeeId) => {
    if (employeeId) {
      const employeeName = this.getEmployeeName(employeeId)
      this.setState({
        currentEmployee: {
          name: employeeName,
          id: employeeId
        }
      })
    }
  }

  cacheBillingCategory = () => {
    const { item, currentBillingCategory } = this.state
    if (item.billing_category_id && currentBillingCategory.name === '') {
      const name = this.getBillingCategoryName(item.billing_category_id)
      this.setState({
        currentBillingCategory: {
          name
        }
      })
    }
  }

  getEmployeeName = (employeeId) => {
    const { employees } = this.state
    const employee = employees.filter(item => item.id === employeeId)
    return employee.length > 0 ? `${employee[0].first_name} ${employee[0].last_name}` : ''
  }

  getBillingCategoryName = async (billingId) => {
    const { billingCategory } = this.state
    let category = billingCategory.filter(item => item.category_id === billingId)
    return category.length > 0 ? category[0].category_name : ''
  }

  getPayrollCategoryName = (payrollValue) => {
    const { payrolls } = this.state
    const payroll = payrolls.filter(item => item.value === payrollValue)
    return payroll.length > 0 ? payroll[0].name : ''
  }

  getServiceName = async (servId) => {
    const { services } = this.state
    const serv = services.filter(item => item.id === servId)
    return serv.length > 0 ? serv[0].name : ''
  }

  getChargeHours = () => {
    const { settingOthers = {} } = this.state
    const { form } = this.props
    const startDateTime = Moment(form.getFieldValue('job_start_date'))
    const endDateTime = Moment(form.getFieldValue('job_end_date'))

    const startDate = startDateTime.seconds(0).milliseconds(0)
    const endDate = endDateTime.seconds(0).milliseconds(0)
    const durations = Moment.duration(Math.abs(endDate.diff(startDate)))
    const diffHours = durations.asHours()

    var job_hours = Math.round(diffHours * 100) / 100

    return job_hours > settingOthers.cancellation_charge_hour ? settingOthers.cancellation_charge_hour : job_hours
  }

  getOriginalHours = () => {
    const { item } = this.state

    const start = Moment(item.job_start_date)
    const end = item.original_end_date ? Moment(item.original_end_date) : Moment(item.job_end_date)
    const durations = Moment.duration(Math.abs(start.diff(end)))
    const diffHours = durations.asHours()

    return diffHours
  }

  getScheduleDay = () => {
    let jobStartDate
    if (this.isEdit()) {
      const { item } = this.state
      jobStartDate = item.job_start_date
    } else {
      const { form } = this.props
      jobStartDate = form.getFieldValue('job_start_date')
    }
    return jobStartDate && Moment(jobStartDate).isValid() ? `on ${Moment(jobStartDate).format('DD/MM/YYYY')}, ${Moment(jobStartDate).format('dddd')}` : ''
  }

  showBreakdown = async (start, end) => {
    const { item, funderId } = this.state

    const isHoliday = await this.checkHoliday(start)
    const breakdown = new DurationBreakdown(item.funder_id || funderId, isHoliday)
    const durationBreakdown = await breakdown.get(start, end)

    this.setState({ durationBreakdown })
  }

  // 3. UI Control ///////////////////////////////////////////////////////////////////////////////////////////

  alertUnassignedTime = () => {
    const { history } = this.props
    const { clientId } = this.state
    confirm({
      title: 'Plan Period Not Defined!',
      content: 'Please assign a plan period for this funder before proceeding. Click Ok to be redirected to the participant page or Cancel to choose another Funder',
      onOk () {
        history.replace(`/clients/${clientId}`)
      },
      onCancel () {
        // this.setState({ showFunderModal: true })
      }
    })
  }

  disabledDate = (value) => {
    const form = this.props.form
    return value < form.getFieldValue('job_start_date')
  }

  disabledTime = (value) => {
    const form = this.props.form
    return value < form.getFieldValue('job_start_date')
  }

  handleAddFileModal = (showAddFileModal, info = {}) => {
    this.setState({ showAddFileModal, fileInfo: info })
  }

  handleCancelJob = async () => {
    const { form } = this.props
    const { employeeId, funderInfo, settingOthers, clientFunderId, item } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const that = this

    validateFieldsAndScroll(['job_start_date', 'cancellation_reason', 'cancellation_type', 'cancellation_note', 'cancellation_other_reason'], async (errors, values) => {
      const valuesStart = Moment(values.job_start_date)
      const nowTime = Moment()

      const durations = Moment.duration(Math.abs(valuesStart.diff(nowTime)))
      const diffHours = durations.asHours()
      const cancelParam = settingOthers.cancellation_notice
      const isPastDate = Moment().isAfter(valuesStart)
      const { name: adminName } = authService.getCurrentUser()

      if (!errors) {
        // Normal cancellation
        values.is_cancel = true
        values.cancellation_time = Moment()
        values.cancellation_penalty = false
        values.funder_id = funderInfo.id
        values.employee_id = employeeId || null
        values.cancellation_staff = adminName
        // values.original_end_date = item.job_end_date

        // item.original_end_date = item.job_end_date

        this.setState({ showCancelAlert: false, item })

        confirm({
          title: 'Are you sure you want to cancel this job?',
          content: 'Press Ok to continue, No to return',
          okText: 'Yes',
          cancelText: 'No',
          async onOk () {
            const response = await jobService.save(jobId, values)
            if (response.id) {
              log.cancelJob(response.id, `Reason: ${that.getCancelTypeName(values.cancellation_type)} - ${values.cancellation_reason} ${values.cancellation_other_reason ? ` - ${values.cancellation_other_reason}` : ''} ${values.cancellation_note ? ` (${values.cancellation_note})` : ''}`)
              notify.success('Success', 'Job Cancelled.')

              that.resetCancelForm()
              that.refreshPage()
            }
          }
        })
      }
    })
  }

  handleEmployeeChange = async () => {
    const { history } = this.props
    const { jobBalanceHours, clientId } = this.state

    if (jobBalanceHours && jobBalanceHours.message) {
      confirm({
        title: `No valid plan or usable hours available.`,
        content: (
          <div>Click Ok to be redirected to participant page or Cancel</div>
        ),
        okText: 'OK',
        onOk () {
          history.replace(`/clients/${clientId}`)
        },
        onCancel () {

        }
      })
    }
  }

  handleEmployeeChangeReason = async () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll([ 'change_employee_reason', 'change_employee_other_reason', 'change_employee_note' ],
      async (errors, values) => {
        if (!errors) {
          this.setState({
            showEmployeeReasonModal: false,
            changeEmployeeReason: values.change_employee_reason,
            changeEmployeeOtherReason: values.change_employee_other_reason,
            changeEmployeeNote: values.change_employee_note
          })
          this.setState({ loading: true })

          that.handleChangeEmployee()
          //Proceed to save
          setTimeout(() => {
            this.handleSave()
          }, 500)
        }
      })
  }

  handleFileDelete = (item) => {
    const { fileList } = this.state

    if (item && item.seq) {
      const idx = fileList.findIndex(e => e.seq === item.seq)
      if (idx > -1) {
        fileList.splice(idx, 1)

        for (let i = 0; i < fileList.length; i++) {
          fileList[i].seq = String(i)
        }
      }

      this.setState({ fileList })
    }
  }

  handleUncancel = async () => {
    const { form } = this.props
    const { funderInfo, item } = this.state
    const { validateFieldsAndScroll } = form
    const jobId = this.getJobId()
    const { name: adminName } = authService.getCurrentUser()
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      values.is_cancel = false
      values.cancellation_reason = null
      values.cancellation_time = null
      values.cancellation_other_reason = null
      values.cancellation_type = null
      values.cancellation_penalty = false
      values.funder_id = funderInfo.id
      values.cancellation_staff = adminName
      // values.job_end_date = item.original_end_date

      delete values.change_employee_reason
      delete values.change_employee_reason_type
      delete values.change_employee_note

      confirm({
        title: 'Are you sure to uncancel this job?',
        content: 'Click Yes to uncancel, No to leave the job cancelled.',
        okText: 'Yes',
        cancelText: 'No',
        async onOk () {
          values.notes = ''

          const response = await jobService.save(jobId, values)
          if (response.id) {
            notify.success('Success', 'Job Uncancelled.')
            log.uncancelJob(response.id, 'Job is uncancelled')
            that.refreshPage()
          }
        }
      })
    })
  }

  handleEmployeeReasonTypeChange = async (id, value) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReason: cancelReason.list, changeEmployeeReasonType: value.props.children })
  }

  handleCancelTypeChange = async (id) => {
    const cancelReason = await settingCancellationService.get(id)
    this.setState({ cancelReason: cancelReason.list })
  }

  handleCancelReasonChange = async (value) => {
    if (value === 'Other') {
      this.setState({ showOtherField: true })
    } else {
      this.setState({ showOtherField: false })
    }
  }

  handleChangeTab (tabNumber) {
    this.setState({ tabActiveKey: tabNumber })
  }

  handlePendingEmployee = (value) => {
    const { form } = this.props
    if (value) {
      this.setState({ employeePending: true, isOnLeave: false, isTimeConflict: false, isMatchLanguage: true, isMatchSkill: true })
      form.setFieldsValue({ employee_id: null })
    } else {
      this.setState({ employeePending: false })
      form.setFieldsValue({ employee_id: null })
    }
  }

  handleEmergencyPayClick = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Pay?',
        content: 'Not Paying Employee Emergency Rate?',
        onOk () {
          form.setFieldsValue({ emergency_pay: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_pay: true })
        }
      })
    }
  }

  handleEmployeeEmergency = async (isEmergency = false) => {
    const { form } = this.props
    const { employeeId, newEmployeeId } = this.state

    if (isEmergency) {
      const isEmergency = form.getFieldValue('emergency')
      const isInvoiceEmergency = form.getFieldValue('emergency_invoice')
      form.setFieldsValue({ emergency: true, emergency_pay: true, emergency_invoice: !isEmergency ? false : (isInvoiceEmergency || false), employee_id: newEmployeeId })

      this.setState({ employeeId: newEmployeeId })
      this.checkEmployee(newEmployeeId)
    } else {
      form.setFieldsValue({ newEmployeeId: employeeId, emergency_pay: false })
      // this.setState({ emergencyField: false })
    }

    this.setState({ showEmergencyModal: false })
  }

  handleEmployeeCancel = async () => {
    const { form } = this.props
    const { employeeId } = this.state
    form.setFieldsValue({ employee_id: employeeId })
    this.checkEmployee(employeeId)
    this.setState({ showEmergencyModal: false })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
    this.fetchCancel()
  }

  handleEmergencyYes = () => {
    const { form } = this.props
    form.setFieldsValue({ emergency: true })
    this.setState({ emergencyField: true, isEmergencyTime: false })

    setTimeout(() => {
      form.setFieldsValue({ emergency_pay: true, emergency_invoice: true })
    }, 200)
  }

  handleEmergencyNo = () => {
    const { form } = this.props
    form.setFieldsValue({ emergency: false, emergency_pay: false, emergency_invoice: false })
    this.setState({ emergencyField: false, isEmergencyTime: false })
  }

  handleEmergencyClick = (value) => {
    this.setState({ emergencyField: !!value })

    const { form } = this.props
    setTimeout(() => {
      form.setFieldsValue({ emergency_pay: value, emergency_invoice: value })
    }, 200)
  }

  handleChangeFunder = () => {
    this.setState({ showFunderModal: true })
  }

  handleChangePayroll = (value) => {
    const { form } = this.props

    confirm({
      title: 'Change Carer Skill Level Required',
      content: 'Do you really want to change the required skill level?',
      onOk () {
        form.setFieldsValue({ payroll: value })
      },
      onCancel () {
        if (form.getFieldValue('payroll') === 'complex') {
          form.setFieldsValue({ payroll: 'standard' })
        } else {
          form.setFieldsValue({ payroll: 'complex' })
        }
      }
    })
  }

  handleEmergencyInvoiceClick = (value) => {
    const { form } = this.props

    if (!value) {
      confirm({
        title: 'Not Emergency Invoice?',
        content: 'Not Charging Participant Emergency Loading?',
        onOk () {
          form.setFieldsValue({ emergency_invoice: value })
        },
        onCancel () {
          form.setFieldsValue({ emergency_invoice: true })
        }
      })
    }
  }

  resetCancelForm = () => {
    const { form } = this.props
    this.setState({ showCancelModal: false, showOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({ cancellation_reason: '', cancellation_type: '', cancellation_other_reason: '', cancellation_note: '' })
    }, 1000)
  }

  handleChangeEmployee= async () => {
    const { form } = this.props
    this.setState({ showEmployeeReasonModal: false, showOtherField: false })

    setTimeout(() => {
      form.setFieldsValue({ change_employee_reason: '', change_employee_reason_type: '', change_employee_other_reason: '', change_employee_note: '' })
    }, 1000)
  }

  handleSave = () => {
    const { form, history } = this.props
    const { clientInfo, funderDetail, jobDurationBeforeEdit } = this.state
    const { validateFieldsAndScroll, setFieldsValue } = form

    const { name: adminName } = authService.getCurrentUser()

    // Default fields
    const fields = [
      'job_start_date', 'job_duration', 'billing_category_id', 'service_id',
      'employee_id', 'tasks', 'notes', 'emergency', 'emergency_pay',
      'emergency_invoice', 'cancellation_penalty'
    ]

    validateFieldsAndScroll(fields,
    async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })

        const { billingRateId, item, dayNumber, clientFunderId, funderInfo, clientId, employeePending, currentBillingCategory, currentEmployee, currentFunder, fileList } = this.state


        // calculate Job Hours
        const startDate = values.job_start_date.seconds(0).milliseconds(0)

        values.billing_rate_id = billingRateId
        values.job_start_day = Moment(startDate).format('dddd')
        values.client_id = clientId
        values.funder_id = funderInfo.id
        values.funding_period_id = funderDetail.id
        values.payroll = await this.getBillingCategoryName(values.billing_category_id)
        values.service = await this.getServiceName(values.service_id)

        // Replace and remove if tasks has special character, tab or extra blank spaces
        if (values.tasks.length > 0) {
          values.tasks = this.replacer(values.tasks).trim()
          setFieldsValue({ tasks: values.tasks })
        }

        // Replace and remove if notes has special character, tab or extra blank spaces
        if (values.notes.length > 0) {
          values.notes = this.replacer(values.notes).trim()
          setFieldsValue({ notes: values.notes })
        }

        try {
          if (this.isEdit()) {
        //     values.original_end_date = values.job_end_date
            const response = await jobService.save(item.id, values)

            if (response.id) {
              let extraLogs = []

              if (currentEmployee.id !== '' && currentEmployee.id !== values.employee_id) {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(`Employee changed from "${currentEmployee.name}" to "${newEmployeeName}"`)

                this.setState({
                  currentEmployee: {
                    name: newEmployeeName,
                    id: values.employee_id
                  }
                })

        //         log.updateJobExtra(response.id, 'employee change reason', `Employee changed to "${newEmployeeName}" with reason: ${changeEmployeeReasonType} - ${changeEmployeeReason} ${changeEmployeeOtherReason ? ` - ${changeEmployeeOtherReason}` : ''} ${changeEmployeeNote ? ` (${changeEmployeeNote})` : ''}`)

              }

              if (values.funder_id !== item.funder_id) {
                extraLogs.push(`Funder changed from "${currentFunder.fullname}" to "${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== item.billing_category_id) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(`Support Item changed from "${currentBillingCategory.name}" to "${name}"`)
              }

              if (values.service_id !== item.service_id) {
                const oldService = await this.getServiceName(item.service_id)
                const newService = await this.getServiceName(values.service_id)
                extraLogs.push(` Service changed from "${oldService}" to "${newService}"`)
              }

              item.job_duration = jobDurationBeforeEdit

              log.updateJob(response.id, item, values, ['is_updated', 'funder_id', 'client_id', 'service_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'cancellation_penalty', 'job_type'], extraLogs.join())
              notify.success('Saved successfully', 'Job saved successfully.')

              this.refreshPage()
            }

            this.setState({ item: { ...item, ...values }, loading: false, changeEmployeeReason: '', changeEmployeeReasonType: '', changeEmployeeOtherReason: '', changeEmployeeNote: '' })
          } else {
        //     values.original_end_date = values.job_end_date
            values.job_type = 'single'
            const response = await jobService.add(values)
            this.setState({ loading: false })

            if (response.id) {
              const { id } = response
              this.setState({ item: { ...item, ...values, id } })

              let extraLogs = []

              extraLogs.push(`Participant to "${clientInfo.first_name} ${clientInfo.last_name}"`)

              if (values.employee_id) {
                const newEmployeeName = this.getEmployeeName(values.employee_id)
                extraLogs.push(` Employee to "${newEmployeeName}"`)
              }

              if (values.funder_id !== undefined) {
                extraLogs.push(` Funder to "${funderInfo.fullname}"`)
              }

              if (values.billing_category_id !== undefined) {
                const name = await this.getBillingCategoryName(values.billing_category_id)
                extraLogs.push(` Support Item to "${name}"`)
              }

              if (values.service_id !== undefined) {
                const newService = await this.getServiceName(values.service_id)
                extraLogs.push(` Service to "${newService}"`)
              }

              // ------ append file start -------
              if (validator.isNotEmptyArray(fileList)) {
                await this.onSaveUploadFiles(response.id)
              }
              // ------ append file end -------

              log.addJob(id, values, ['is_updated', 'funder_id', 'client_id', 'service_id', 'billing_rate_id', 'billing_category_id', 'payroll_category', 'payroll', 'staff', 'cancellation_penalty', 'job_type'], extraLogs.join())
              notify.success('Saved successfully', 'Job saved successfully.')
              history.replace(`/jobs/single/${id}`)

              this.cacheEmployeeName(values.employee_id)

              this.refreshPage()
            }
          }

          this.props.setRefreshCommLog(true)
        } catch (e) {
          // console.log('error', e)
          notify.error('Unable to save successfully', 'Unable to save job successfully. Please try again later.')
          this.setState({ loading: false })
        }
      } else {
        this.showErrorNotification('error')
      }
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  hideCancelModal = () => {
    this.setState({ showCancelModal: false })
  }

  onSaveUploadFiles = async (jobId) => {
    const { fileList, mainFileCategoryList, subFileCategoryList } = this.state
    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i]
      delete file.seq
      file.module_id = jobId

      const cat = mainFileCategoryList.find(e => e.id === file.main_category_id)
      const subcat = subFileCategoryList.find(e => e.id === file.sub_category_id)
      // console.log(`upload file ${i}`, file)

      const response = await jobService.addFile(file)

      if (response && response.id) {
        let logItemAfter = {
          label: file.label || '',
          file_name: formatter.toStandardFileName(file.file_name),
          active: file.active,
          issuance_date: file.issuance_date
        }

        if (subcat && (subcat.has_expiry && file.expiry_date)) {
          logItemAfter.expiry_date = file.expiry_date || ''
        }

        const extraLog = []
        extraLog.push(`${cat.name} - ${subcat.file_sub_category}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

        log.addJobFile(jobId, logItemAfter, [], extraLog.join())
      }
    }
  }

  onSelectBillingRate (option) {
    this.setState({ showGetupHours: option.props.children.indexOf('Sleepover') > -1 })
  }

  async refreshPage () {
    this.fetchJob()
    const jobId = this.getJobId()
    // await this.fetchUnsentEmail(jobId)

    // setTimeout(() => {
    //   this.checkEmail()
    // }, 600)

    setTimeout(() => {
      this.fetchLogs()
    }, 1000)
  }

  async sendEmail (jobId) {
    // console.log('Will Send Email for:', jobId)
    // fetch email to be sent

    const filter = {}
    filter.job_id = { condition: '=', value: jobId }
    const communication = await jobService.getEmails(1, 5, filter)
    const emailToSend = communication.list[0]
    const emailId = emailToSend.id

    // send email

    const result = communicationService.send(emailId)

    if (result) {
      notify.success('Sent successfully', 'Email sent successfully')
      window.location.reload()
    } else {
      notify.error('Unable to send successfully', 'Please try again later.')
      window.location.reload()
    }
  }

  showCancelModal = () => {
    this.setState({ showCancelModal: true })
  }

  showErrorNotification = (type) => {
    Notification[type]({
      message: 'Imcomplete Job Information',
      description: 'Please fill up all required the fields to proceed.',
      top: 130
    })
  }

  showDateNotification = () => {
    Notification['error']({
      message: 'Job Time Error',
      description: 'End Time should not be earlier than Start Time',
      top: 130
    })
  }

  updateFileAdded = (values) => {
    const { fileList } = this.state
    if (values.seq) {
      const idx = fileList.findIndex(e => e.seq === values.seq)
      if (idx > -1) {
        fileList.splice(idx, 1, values)
      }
    } else {
      values.seq = String(fileList.length) // need to change to string to make sure the checking item.seq wont return false for first item, because first item of seq is 0
      fileList.push(values)
    }

    this.setState({ fileList }, () => {
      this.handleAddFileModal(false)
    })
  }
  // 4. General --------------

  getJobId = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id
  }

  isEdit = () => {
    return this.getJobId() !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingJobs,
  setRefreshCommLog
}

const mapStateToProps = (state) => {
  return { ...state.Job }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Job))
