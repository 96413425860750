import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { settingGeneralService } from '../../../services/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../components/Notification'

import BillingRateCategories from './Category'
import BillingRateSets from './Set'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'Billing Rates'
const settingType = 'gender'

export class SettingBillingRate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addCategory: false,
      addRateSet: false,
      currentPage: 1,
      filter: {},
      list: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchSettings({ currentPage })
  }

  fetchSettings = async ({ currentPage = 1 }) => {
    try {
      this.setState({ currentPage, loading: true })
      const settings = await settingGeneralService.listByPage(currentPage, pageSize, { type: settingType })
      this.setState({ list: settings.list, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  addCategory = () => {
    this.setState({ addCategory: true })
  }

  addRateSet = () => {
    this.setState({ addRateSet: true })
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { selectedItem } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        const { id } = selectedItem
        const { modal } = this.state
        const { item } = modal
        values.type = settingType
        values.value = typeof values.name === 'string' ? values.name.toLowerCase() : values.name
        this.setState({ loadingForm: true })

        try {
          let response

          if (id) {
            response = await settingGeneralService.save(id, values)
          } else {
            values.image = item.image
            response = await settingGeneralService.add(values)
          }

          this.setState({ loadingForm: false })

          if (response.id) {
            const { id } = response
            modal.item = { ...item, ...values, id }

            this.setState({ modal })
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
            this.fetchSettings({ currentPage: 1 })
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          }
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingType} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  handleEdit (id, name, value, active) {
    this.setState({ selectedItem: { id, name, value, active } })
    this.showModal()
  }

  async handleDelete (id) {
    const res = await settingGeneralService.remove(id)

    if (res) {
      message.success('Deleted successfully', `${settingType} deleted successfully`)
      this.fetchSettings({ currentPage: 1 })
    }
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addCategory, addRateSet, currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey='1'>
            <TabPane tab='Rate Sets' key='1'>
              <div className='header-action'>
                <Link to={`/settings/billing-rates/add`}>
                  { this.hasAccess('createBillingRate') ? <div className='btn'>
                    Add Rate Set
                  </div> : null }
                </Link>
              </div>
              <BillingRateSets onAdd={addRateSet} onComplete={() => this.setState({ addRateSet: false })} />
            </TabPane>
            <TabPane tab='Categories' key='2'>
              <div className='header-action'>
                { this.hasAccess('createBillingRate') ? <div className='btn' onClick={() => this.addCategory()}>
                  Add Category
                </div> : null }
              </div>
              <BillingRateCategories onAdd={addCategory} onComplete={() => this.setState({ addCategory: false })} />
            </TabPane>
          </Tabs>

        </Page.Content>

        <Page.Right>
          <Page.Panel title='Tips'>
            Configure billing rate sets for funders
          </Page.Panel>
        </Page.Right>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingBillingRate))
