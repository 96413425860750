import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { jobService, clientService, authService } from '../../../services'
import formatter from '../../../util/formatter'
import { fetchingClients } from '../../../states/actions/client'
import Moment from 'moment-timezone'
import { apiHostname } from '../../../config'

// UI
import { Loading, List, Pager, ControlLabel, SearchInput } from '../../../components'
import notify from '../../../components/Notification'
import AddJobModal from '../../Job/AddJobModal'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const { confirm } = Modal
const { RangePicker } = DatePicker

export class ClientJobs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      filterParam: 'all',
      searchText: '',
      total: 0,
      jobList: [],
      loading: false,
      clientId: '',
      showExportDate: false,
      showJobModal: false,
      showReminderDate: false
    }
    this.filterJob = this.filterJob.bind(this)
    this.onSearchName = debounce(this.onSearchName, 500)
  }

  componentDidMount () {
    const { currentPage, filter, filterParam, loading, searchText, sort } = this.state
    this.fetchClientJob({ currentPage, filter, loading, searchText, sort, filterParam: filterParam })
  }

  filterJob (e) {
    const { filter, loading, searchText, sort } = this.state
    const filterValue = e.target.value

    this.setState({ filterParam: filterValue })
    this.fetchClientJob({ currentPage: 1, filter, loading, searchText, sort, filterParam: filterValue })
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, loading, sort } = this.state
    if (value && value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_firstname: { condition: 'ilike', value: `%${words[i]}%` } },
              { emp_lastname: { condition: 'ilike', value: `%${words[i]}%` } },
              { fund_fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } },
              { job_start_day: { condition: 'ilike', value: `%${words[i]}%` } },
              { tasks: { condition: 'ilike', value: `%${words[i]}%` } },
              { string_start_date: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
        // console.log('filters:', filter)
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchClientJob({ currentPage, filter, loading, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  addJob = () => {
    this.setState({ showJobModal: true })
  }

  sendJobList(d1, d2) {
    const { clientId, clientName } = this.props

    this.setState({ showReminderDate: false })

    confirm({
      title: `Send ${clientName} a job list from ${formatter.toShortDate(d2[0])} to ${formatter.toShortDate(d2[1])}?`,
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await clientService.sendJobList(clientId, Moment(d2[0]).format('YYYY-MM-DD'), Moment(d2[1]).format('YYYY-MM-DD'))

          if (response.jobs) {
            notify.success('Send successfully', 'Jobs sent successfully.')
          }
        } catch (e) {
          console.log(e)
          notify.error('Unable to send successfully', 'Unable to send jobs successfully. Please try again later.')
        }
      }
    })
  }

  async exportJobList (d1, d2) {
    const { clientId } = this.props

    this.setState({ showExportDate: false })

    window.location.href = `${apiHostname}/api/export/jobs/client/${clientId}/${Moment(d2[0]).format('YYYY-MM-DD')}/${Moment(d2[1]).format('YYYY-MM-DD')}`
  }

  render () {
    const { currentPage, loading, jobList, total, showExportDate, showJobModal, showReminderDate } = this.state
    const { clientId, active } = this.props

    const columns = [
      // {
      //   title: '',
      //   width: 2,
      //   render: ({ emergency, base_job_id, cancellation_penalty }) => <div className='indicator-list'>
      //     { emergency ? <div className='job-indicator' style={{ backgroundColor: '#ff5b5bbb' }}>E</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >E</div> }
      //     { cancellation_penalty ? <div className='job-indicator' style={{ backgroundColor: '#b17bcd' }}>LC</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >LC</div> }
      //     { base_job_id > 0 ? <div className='job-indicator' style={{ backgroundColor: '#1890ffbb' }}>R</div> : <div className='job-indicator' style={{ backgroundColor: '#f4f4f4' }} >R</div> }
      //   </div>
      // },
      {
        title: 'Date',
        width: 2,
        render: ({ job_start_date, holiday_date, holiday_name }) => holiday_date ? <div>{formatter.toShortDate(job_start_date)} <Tooltip title={holiday_name} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='bell' theme='twoTone' twoToneColor='#ff0000' /></Tooltip> </div> : formatter.toShortDate(job_start_date)
      },
      // {
      //   title: 'Day',
      //   width: 2,
      //   render: ({ job_start_date }) => formatter.toDay(job_start_date)
      // },
      {
        title: 'Employee',
        width: 4,
        render: ({ emp_firstname, emp_lastname, employee_id, leave_id, leave_start, leave_end }) => employee_id && leave_id ? <div><a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a> <Tooltip title={`Leave ${formatter.toShortDate(leave_start)} - ${formatter.toShortDate(leave_end)}`} mouseEnterDelay={0} mouseLeaveDelay={0}><Icon type='exclamation-circle' theme='twoTone' twoToneColor='#ff0000' /></Tooltip></div>
          : employee_id && !leave_id ? <a href={`/employees/${employee_id}`} rel='noopener noreferrer' target='_blank'>{emp_firstname} {emp_lastname}</a> : null
      },
      // {
      //   title: 'Participant Work',
      //   width: 4,
      //   render: ({ payroll }) => <div style={{ textTransform: 'capitalize' }}>{payroll}</div>
      // },
      {
        title: 'Service',
        width: 4,
        render: ({ service }) => <div style={{ textTransform: 'capitalize' }}>{service}</div>
      },
      {
        title: 'Task',
        width: 6,
        render: ({ tasks }) => <div style={{ fontSize: 10 }}>{formatter.toShortenText(tasks, 120)}</div>
      },
      {
        title: 'Duration',
        width: 3,
        render: ({job_duration}) => <div>{Math.floor(job_duration)/60}H ({this.getDurationText(job_duration)})</div>
      },
      // {
      //   title: 'Location',
      //   width: 2,
      //   key: 'suburb'
      // },
      // {
      //   title: 'Funder',
      //   width: 2,
      //   render: ({ fund_fullname, funder_id }) => <div style={{ textTransform: 'capitalize' }}><a href={`/funders/${funder_id}`} rel='noopener noreferrer' target='_blank'>{fund_fullname}</a> </div>

      // },
      {
        title: 'Action',
        width: 1,
        render: ({ id }) => <div className='action-buttons'><Link to={`/jobs/single/${id}`}><Tooltip mouseLeaveDelay={0} title='Manage Job'> <Icon type='form' style={{ color: 'orange' }} /></Tooltip></Link>

        </div>

      }
    ]

    return (
      <Loading loading={loading} blur>

        <div className='search-bar'>

          <Row gutter={8}>
            <Col lg={8}>
              <ControlLabel>Participant, Employee, Funder, Date, Day, Suburb, Tasks</ControlLabel>
              <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
            </Col>
            <Col lg={10}>
              <div className='clearfix' />
            </Col>
            <Col lg={6} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
            {/* <div>
              <div style={{ display: showReminderDate ? 'inline' : 'none' }}>
                <RangePicker format="YYYY-MM-DD" open={showReminderDate} onChange={(d1, d2) => this.sendJobList(d1, d2)}/>
              </div>
              <div className='btn btn-ghost' onClick={() => this.setState({ showReminderDate: true })}>
                Send Job List
              </div>
            </div> */}
              {
                this.hasAccess('createJob') ? <div className='btn' onClick={() => this.addJob()}>
              Add Job
                </div>
                  : null
              }
              <div>
                <div style={{ display: showExportDate ? 'inline' : 'none' }}>
                  <RangePicker format='YYYY-MM-DD' open={showExportDate} onChange={(d1, d2) => this.exportJobList(d1, d2)}/>
                </div>
                <div className='btn btn-ghost' onClick={() => this.setState({ showExportDate: true })}>
                  Download Job List
                </div>
              </div>
            </Col>
          </Row>

        </div>

        <Radio.Group onChange={this.filterJob} defaultValue='all' style={{ marginLeft: 20 }}>
          {/* <Radio.Button value='all'>All</Radio.Button> */}
          <Radio.Button value='all'>All</Radio.Button>
          <Radio.Button value='cancel'>Cancelled</Radio.Button>
        </Radio.Group>

        <div className='job-list'>
          <Skeleton loading={loading} active>

            <List cols={columns} rows={jobList} />

          </Skeleton>
        </div>
        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} jobs`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

        <AddJobModal clientId={clientId} active={active} visible={showJobModal} history={this.props.history} onClose={() => this.setState({ showJobModal: false })} />

      </Loading>
    )
  }

  fetchClientJob = async ({ startPage = null, currentPage = 1, filter = {}, sort = {}, searchText, filterParam }) => {
    this.setState({ loading: true })
    const { clientId } = this.props
    const sorting = { job_start_date: -1 }
    const nowDate = Moment().startOf('day')

    const page = startPage || currentPage

    if (filterParam === 'all') {
      filter.client_id = { condition: '=', value: clientId }
      filter.is_cancel = false
      sorting.job_start_date = 0
    } else if (filterParam === 'cancel') {
      delete filter.job_start_date
      filter.client_id = { condition: '=', value: clientId }
      filter.is_cancel = true
    } else {
      filter.client_id = { condition: '=', value: clientId }
      filter.job_start_date = { condition: '>=', value: nowDate }
      filter.is_cancel = false
      sorting.job_start_date = 0
    }

    const { list: jobList, total } = await jobService.listByPage(page, pageSize, filter, sorting, searchText)
    this.setState({ jobList, type: filterParam, total, loading: false, currentPage: page, filter, sorting, searchText })
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort, type } = this.state
    this.fetchClientJob({ currentPage, filter, searchText, sort, filterParam: type })
  }

  getDurationText = (duration = 0) => {
    const hour = Math.floor(duration / 60)
    const hourText = 'H'
    const minutes = duration % 60
    const minText = 'M'
    return `${hour}${hourText}${minutes}${minText}`
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  fetchingClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientJobs))
