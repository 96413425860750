import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

export default class Text extends Component {
  render () {
    const { label, placeholder, value, ...others } = this.props
    const _value = value || ''

    return label ? (
      <div className='witty-text-container'>
        <div className='witty-label'>{label}</div>

        <div className='witty-text'>
          <input type='text' className='control' value={_value} {...others} />
        </div>
      </div>
    ) : (
      <div className='witty-text'>
        <div className={'label' + (value ? ' label-visible' : '')}>{placeholder}</div>

        <input type='text' className='control' placeholder={placeholder} value={_value} {...others} />
      </div>
    )
  }
}

Text.propTypes = {
  label: PropTypes.node,
  placeholder: PropTypes.string.isRequired
}
