export const REFRESH_ACTIVITY_LOG = 'REFRESH_ACTIVITY_LOG'
export const REFRESH_COMM_LOG = 'REFRESH_COMM_LOG'
export const REFRESH_FILE = 'REFRESH_FILE'
export const REFRESH_FUNDING = 'REFRESH_FUNDING'

export const setRefreshActivityLog = (done) => {
  return {
    type: REFRESH_ACTIVITY_LOG,
    done
  }
}

export const setRefreshCommLog = (done) => {
  return {
    type: REFRESH_COMM_LOG,
    done
  }
}

export const setRefreshFiles = (done) => {
  return {
    type: REFRESH_FILE,
    done
  }
}

export const setRefreshFunding = (done) => {
  return {
    type: REFRESH_FUNDING,
    done
  }
}
