/* global google */

import React, { Component } from 'react'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'
import { authService, clientService, clientFileService, funderService, settingGeneralService, settingReasonService, taskService, logService,
  settingCustomIdentifier } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates, CountryList, ClientFileTypes } from '../../../constants'
import ActivityLog from '../ActivityLog'
import CarePlan from '../CarePlan'
import CarePlanV2 from '../CarePlanV2'
import File from '../File'
import Jobs from '../Job'
import ClientFunding from '../Funding'
import Blacklist from '../Blacklist'
import Moment from 'moment-timezone'
import { formatter, log, trigger, validator } from '../../../util'

// UI
import { Loading, Page, Panel, SideModal, MandatoryList, CustomIdentifierList } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const { RangePicker } = DatePicker

const dateFormat = 'DD/MM/YYYY'
const clientModule = 'client'
const defaultFunderId = 1 // for SC, funder id is fixed to 1 (NDIS)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySidePrivateLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

export class Client extends Component {
  constructor (props) {
    super(props)
    this.state = {
      funders: [],
      // tasks: [],
      genders: [],
      funding: [],
      item: {},
      loading: false,
      clientId: '',
      currentAge: 0,
      payrolls: [],
      languages: [],
      services: [],
      logList: [],
      heights: [],
      reasons: [],
      relationships: [],
      funderBillingCategories: [],
      identifierSetting: [],
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      showAuthorizeRep: false,
      showEdit: true,
      showSave: false,
      showReminderDate: false,
      showExitedReason: false,
      showExitedToggle: false,
      shouldRefreshFiles: false,
      shouldRefreshFunding: false,
      shouldActive: false,
      isDuplicateClient: false,
      isFileHasAction: false,
      duplicateClientInfo: {},
      isExitedToggleChange: false,
      settingsMandatory: [],
      uploadedMandatory: [],
      hasMissingMandatoryFiles: false
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
    this.handleActiveChange = this.handleActiveChange.bind(this)
    this.handleExitedReason = this.handleExitedReason.bind(this)
    this.handleDuplicateCheck = debounce(this.handleDuplicateCheck, 500)
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchClient()
      this.fetchLogs()
      this.fetchFiles()
      this.fetchCustomIdentifierSetting()
    }

    this.fetchFunders()
    this.fetchReasons()
    this.fetchSettings()
    this.fetchSettingFilesMandatory()
    this.handleFunderCategoryLoaded(defaultFunderId)

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )
    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    // console.log(place.geometry.location.lat(), place.geometry.location.lng())

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ address: place.formatted_address, postcode, state, suburb })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  getSupportItem () {
    const { item, funderBillingCategories } = this.state
    let supportItem = '-'

    if (item && validator.isNotEmptyArray(item.support_item)) {
      const cat = funderBillingCategories.filter(cat => cat.category_id === item.support_item[0])
      supportItem = validator.isNotEmptyArray(cat) ? cat[0].category_name : ''
    }

    return supportItem
  }

  render () {
    const { history, match } = this.props
    const { currentAge, item, loading, showEdit, showSave, showReminderDate, shouldRefreshFiles, shouldRefreshFunding, funderBillingCategories, genders } = this.state
    const supportItem = this.getSupportItem()

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit() ? 'Participant (Add)' : loading ? <div className='client-panel-header-skeleton' style={{ width: 200 }} /> : showEdit ? `${item.first_name} ${item.last_name} (View Only)` : showSave ? `${item.first_name} ${item.last_name} (Edit Mode)` : 'Participant'}>

            {this.isEdit() && this.hasAccess('deleteClient') ? showSave ? (
              <div className='btn btn-ghost' onClick={this.handleDelete} style={{ marginRight: 20 }}>
                Delete
              </div>
            ) : null : null}

            {
              showEdit && this.isEdit() && this.hasAccess('updateClient')

                ? (
                  <div className='btn' onClick={this.handleEditButton}>
                  Edit
                  </div>)

                : null
            }

            {
              showSave || !this.isEdit()

                ? (
                  <div className='btn' onClick={() => this.checkBeforeSave()}>
                  Save
                  </div>)

                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='client-panel'>
            { this.isEdit()
              ? <div className='client-panel-header'>
                { loading ? <Row>
                  <Col lg={3} style={{ textAlign: 'center' }}>
                    <div className='client-panel-header-skeleton-avatar' />
                  </Col>
                  <Col lg={5}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4}>
                    <div className='client-panel-header-skeleton' />
                  </Col>
                  <Col lg={4} />
                </Row>
                  : <div>
                    <Row>
                      <Col lg={3} style={{ textAlign: 'center' }}>
                        { item.gender ? <img alt='' src={process.env.PUBLIC_URL + '/img/' + (item.gender && item.gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' /> : null }
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-label'>Name</div>
                        <div className='client-panel-header-value'>{item.first_name} {item.last_name}<div className='client-panel-header-subvalue'>{ item.acc_ref }</div></div>
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-label'>NDIS Number</div>
                        <div className='client-panel-header-value'>{item.ndis_number || '-'}</div>
                      </Col>
                      <Col lg={4}>
                        <div className='client-panel-header-label'>Balance Hour</div>
                        <div className='client-panel-header-value'>{item.remaining_hours}</div>
                      </Col>
                      <Col lg={6}>
                        <div className='client-panel-header-label'>Support Item</div>
                        <div className='client-panel-header-value'>{supportItem || '-'}</div>
                      </Col>
                      <Col lg={3}>
                        <div className='client-panel-header-label'>DOB</div>
                        <div className='client-panel-header-value'>{formatter.toShortDate(item.dob) || '-'}</div>
                      </Col>
                    </Row>
                    </div> }
              </div>
              : null }
            <div className='client-panel-body'>
              <Tabs
                defaultActiveKey='1'
              >
                <TabPane tab='Participant Info' key='1'>
                  { this.infoTab() }
                </TabPane>

                { this.isEdit() ? <TabPane tab='Custom Identifier' key='11'>
                  { this.customIdenfierTab() }
                </TabPane> : null}

                { this.isEdit() ? <TabPane tab='Detailed Info' key='2'>
                  { this.detailedInfoTab() }
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Jobs' key='3'>
                  <Jobs clientId={match.params.id} active={item.active} clientName={item.first_name} history={this.props.history} />
                </TabPane> : null }
                {/* {
                  this.isEdit() ? <TabPane tab='Tasks' key='4'>
                    <Tasks clientId={match.params.id} history={this.props.history} />
                  </TabPane> : null
                } */}
                {/* { this.isEdit() ? <TabPane tab='Care Plan' key='5'>
                    <CarePlan clientId={match.params.id} history={this.props.history} />
                  </TabPane> : null } */}
                { this.isEdit() ? <TabPane tab='Care Plan' key='55'>
                  <CarePlanV2 clientId={match.params.id} history={this.props.history} genders={genders} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='NDIS Plan' key='6' forceRender>
                  <ClientFunding clientId={match.params.id} clientName={`${item.first_name} ${item.last_name}`} history={this.props.history} onUpdateInfo={() => this.updateInfo()} onRefreshFunding={() => this.setState({ shouldRefreshFunding: false })} shouldRefreshFunding={shouldRefreshFunding} />
                </TabPane> : null }
                {/* { this.isEdit() ? <TabPane tab='Leave' key='7'>
                  <Leave clientId={match.params.id} history={this.props.history} />
                </TabPane> : null } */}
                { this.isEdit() ? <TabPane tab='Providers' key='8'>
                  <Blacklist clientId={match.params.id} history={this.props.history} />
                </TabPane> : null }
                { this.isEdit() && this.hasAccess('readClientFile') ? <TabPane tab='Files' key='9'>
                  <File clientId={match.params.id} history={this.props.history} onUpdateInfo={() => this.updateInfo()} onRefreshFiles={() => this.setState({ shouldRefreshFiles: false })} shouldRefreshFiles={shouldRefreshFiles} />
                </TabPane> : null }
                { this.isEdit() ? <TabPane tab='Activity Log' key='10'>
                  <ActivityLog clientId={match.params.id} history={this.props.history} />
                </TabPane> : null }
              </Tabs>
            </div>
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const {
      currentAge, newAccRef, genders, heights, item, isExitedToggleChange, languages, loading, payrolls, reasons,
      services, showAuthorizeRep, showExitedToggle, showExitedReason, shouldActive,
      relationships, settingsMandatory, uploadedMandatory
    } = this.state
    const { getFieldDecorator } = form

    return <Form>
      <Loading loading={loading} blur>
        <Panel title='Account'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                {getFieldDecorator('acc_ref', {
                  initialValue: item.acc_ref
                })(
                  <Input readOnly />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={this.handleActiveChange}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    disabled={item.is_exited}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          {/* <Row>
            <Col lg={12}>
              {this.isEdit() && showExitedToggle && this.hasAccess('updateClientExited') ? <FormItem {...sideBySideFormItemLayout} label='Exited'>
                {getFieldDecorator('is_exited', {
                  initialValue: item.is_exited || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={() => !item.is_exited ? this.setState({ showExitedReason: true, isExitedToggleChange: true }) : null}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem> : null}
            </Col>
          </Row>

          <Row /> */}
        </Panel>
        <Panel title='Information'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='First Name' hasFeedback>
                {getFieldDecorator('first_name', {
                  initialValue: item.first_name,
                  rules: [
                    { min: 2, message: 'First Name must be between 2 and 128 characters' },
                    { max: 128, message: 'First Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input onChange={this.isEdit() ? null : this.handleDuplicateCheck} />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Last Name' hasFeedback>
                {getFieldDecorator('last_name', {
                  initialValue: item.last_name,
                  rules: [
                    { min: 2, message: 'Last Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Last Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter last name' },
                    { whitespace: true, message: 'Please enter last name' }
                  ]
                })(
                  <Input onChange={this.isEdit() ? null : this.handleDuplicateCheck} />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='NDIS Number' hasFeedback>
                {getFieldDecorator('ndis_number', {
                  initialValue: item.ndis_number,
                  rules: [
                    { required: true, message: 'Please enter ndis number' },
                    { whitespace: true, message: 'Please enter ndis number' },
                    { validator: this.handleNDISNumCheck() }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={19}>
                  <FormItem {...sideBySideExtraFormItemLayout} label='Date Of Birth' hasFeedback>
                    {getFieldDecorator('dob', this.isEdit() && item.dob ? {
                      initialValue: Moment(item.dob),
                      rules: [
                        { required: true, message: 'Please enter dob' }
                      ]
                    } : {
                      rules: [
                        { required: true, message: 'Please enter dob' }
                      ]
                    })(
                      <DatePicker format={dateFormat} onChange={this.handleDOBChange} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={5}>
                  <div className='client_age'>
                    {currentAge} years old
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number' hasFeedback>
                {getFieldDecorator('mobile_phone', {
                  initialValue: item.mobile_phone,
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number' hasFeedback>
                {getFieldDecorator('phone', {
                  initialValue: item.phone,
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Gender' hasFeedback>
                {getFieldDecorator('gender', {
                  initialValue: item.gender,
                  rules: [
                    { required: true, message: 'Please select gender.' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      genders.map((gender) => {
                        return <Option key={gender.value}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
              {/* <FormItem {...sideBySideFormItemLayout} label='Sexual Identity' hasFeedback>
                {getFieldDecorator('client_sexual', {
                  initialValue: item.client_sexual,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      genders.map((gender) => {
                        return <Option value={gender.value} key={`sic${gender.value}`}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem> */}
            </Col>
          </Row>

          {/* <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Height' hasFeedback>
                {getFieldDecorator('height', {
                  initialValue: item.height,
                  rules: [
                    // { required: true, message: 'Please select height' }
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      heights.map((height, idx) => {
                        return <Option key={height.value}>{height.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row> */}
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Have Authorized Rep. ?'>
                {getFieldDecorator('authorize_rep', {
                  initialValue: item.authorize_rep || false,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                    onChange={this.handleAuthorizeRepChange}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        {
          showAuthorizeRep

            ? <div className='show-authorize-field'>
              <Panel title='Authorized Representative'>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Name'>
                      {getFieldDecorator('authorize_rep_name', {
                        initialValue: item.authorize_rep_name || ''
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Contact'>
                      {getFieldDecorator('authorize_rep_phone', {
                        initialValue: item.authorize_rep_phone,
                        rules: [
                          { min: 2, message: 'Contact must be between 2 and 128 characters' },
                          { max: 128, message: 'Contact must be between 2 and 128 characters' },
                          { whitespace: true, message: 'Please enter contact' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='E-Mail'>
                      {getFieldDecorator('authorize_rep_email', {
                        initialValue: item.authorize_rep_email || ''
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                      {getFieldDecorator('authorize_rep_relationship', {
                        initialValue: item.authorize_rep_relationship
                      })(
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                          {
                            relationships.map((relation, idx) => {
                              return <Option key={relation.value}>{relation.name}</Option>
                            })
                          }
                        </Select>
                      )}
                      {/* {getFieldDecorator('authorize_rep_relationship', {
                        initialValue: item.authorize_rep_relationship
                      })(
                        <Input />
                      )} */}
                    </FormItem>
                  </Col>

                </Row>

              </Panel>
            </div>
            : ''
        }

        <Panel title='Alert'>
          {/* <Row>
            <Col lg={12}>
              <div style={{ paddingLeft: 16, marginBottom: 25, fontSize: '11pt' }}><strong>Public</strong></div>
            </Col>
            <Col lg={12}>
              <div style={{ paddingLeft: 16, marginBottom: 25, fontSize: '11pt' }}><strong>Private</strong></div>
            </Col>
          </Row> */}
          <Row>
            {/* <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Public Alert'>
                {getFieldDecorator('public_alert', {
                  initialValue: item.public_alert
                })(
                  <TextArea autosize={{ minRows: 3, maxRows: 8 }} />
                )}
              </FormItem>
            </Col> */}
            <Col lg={24}>
              <FormItem {...formItemLayout} label='Private Alert'>
                {getFieldDecorator('private_alert', {
                  initialValue: item.private_alert
                })(
                  <TextArea autosize={{ minRows: 3, maxRows: 8 }} />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Do not leave the client alone'>
                {getFieldDecorator('not_leave_alone', {
                  initialValue: item.not_leave_alone,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

        </Panel>

        <Panel title='Preferences'>
          <FormItem {...formItemLayout} label='Preferred Languages' hasFeedback>
            {getFieldDecorator('preferred_languages', {
              initialValue: Array.isArray(item.languages) && item.languages[0] !== null ? item.languages : []
            })(
              <Select mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {
                  languages.map((language) => {
                    return <Option key={language.id} value={language.id}>{language.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          {/* <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Preferred Gender' hasFeedback>
                {getFieldDecorator('preferred_gender', {
                  initialValue: item.preferred_gender
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      genders.map((gender) => {
                        return <Option key={gender.value}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row> */}
          <FormItem {...formItemLayout} label='Preferred Services' hasFeedback>
            {getFieldDecorator('preferred_services', {
              initialValue: Array.isArray(item.services) && item.services[0] ? item.services : []
            })(
              <Select mode='multiple'
                showSearch
                filterOption={(input, option) =>
                  `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {
                  services.map((svc) => {
                    return <Option key={svc.id} value={svc.id}>{svc.name}</Option>
                  })
                }
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label='Private Notes' hasFeedback>
            {getFieldDecorator('private_notes', {
              initialValue: item.private_notes
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
        </Panel>
        <Panel title='Address'>
          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address,
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { required: true, message: 'Please enter address' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
            {getFieldDecorator('unit_building', {
              initialValue: item.unit_building,
              rules: [
                { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter unit/building info' }
              ]
            })(
              <Input placeholder='Please Enter Unit No/Building Name' />
            )}
          </FormItem>

          <Row style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>

            </Col>
          </Row>

          <Row style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || ''
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states, idx) => (
                        <Option key={idx} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode' hasFeedback>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        <Panel title='Second Contact'>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Name'>
                {getFieldDecorator('second_contact_name_1', {
                  initialValue: item.second_contact_name_1,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                {getFieldDecorator('second_contact_phone_1', {
                  initialValue: item.second_contact_phone_1,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>

          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('second_contact_relationship_1', {
                  initialValue: item.second_contact_relationship_1,
                  rules: [
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      relationships.map((relation, idx) => {
                        return <Option key={relation.value}>{relation.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='E-Mail'>
                {getFieldDecorator('second_contact_email_1', {
                  initialValue: item.second_contact_email_1
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <hr className='contact-separator' />
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Name'>
                {getFieldDecorator('second_contact_name_2', {
                  initialValue: item.second_contact_name_2,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                {getFieldDecorator('second_contact_phone_2', {
                  initialValue: item.second_contact_phone_2,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Relationship'>
                {getFieldDecorator('second_contact_relationship_2', {
                  initialValue: item.second_contact_relationship_2,
                  rules: [
                  ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {
                      relationships.map((relation, idx) => {
                        return <Option key={relation.value}>{relation.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='E-Mail'>
                {getFieldDecorator('second_contact_email_2', {
                  initialValue: item.second_contact_email_2
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>

        <Panel title='Mandatory Files'>
          <div className='mandatory-files-panel'>
            <MandatoryList
              form={form}
              onChange={this.handleUpdateMandatory}
              moduleType={'client'}
              moduleId={item.id}
              settingsMandatory={settingsMandatory}
              items={uploadedMandatory}
            />
          </div>
        </Panel>

      </Loading>

      <SideModal
        title='Inactive Reason'
        showModal={showExitedReason}
        onClose={() => this.handleCancelInactiveReason()}
        buttons={[
          <Button key='0' type='primary' onClick={() => this.handleExitedReason(false)}>Submit</Button>
        ]}
      >
        <FormItem label='Reason'>
          {getFieldDecorator('exited_reason', {
            rules: [
              isExitedToggleChange ? { required: true, message: 'Please select reason' } : {}
            ]
          })(
            <Select
              showSearch
              filterOption={(input, option) =>
                `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {
                reasons.map((reason) => {
                  return <Option key={reason.name}>{reason.name}</Option>
                })
              }
            </Select>
          )}
        </FormItem>
        <FormItem label='Note'>
          {getFieldDecorator('exited_reason_note', {
            rules: [
              // isExitedToggleChange ? { required: true, message: 'Please enter note' } : {}
            ]
          })(
            <TextArea rows={4} />
          )}
        </FormItem>
      </SideModal>
    </Form>
  }

  customIdenfierTab = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const { identifierSetting } = this.state

    return <CustomIdentifierList
      moduleType={'participant'}
      moduleId={id}
      identifierSetting={identifierSetting}
    />
  }

  detailedInfoTab = () => {
    const { form } = this.props
    const { item, loading, languages } = this.state
    const { getFieldDecorator } = form

    return <Form>
      <Loading loading={loading} blur>
        <Panel title='Detailed Information'>
          <FormItem {...formItemLayout} label='Living arrangement of others' hasFeedback>
            {getFieldDecorator('detail_living_arrangement', {
              initialValue: item.detail_living_arrangement
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country of Birth'>
                {getFieldDecorator('detail_cob', {
                  initialValue: item.detail_cob,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Select showSearch
                    placeholder='Please select a country'>
                    {
                      CountryList.map((states, idx) => (
                        <Option key={idx} value={states.name}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Religion'>
                {getFieldDecorator('detail_religion', {
                  initialValue: item.detail_religion,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <FormItem {...formItemLayout} label='Cultural Needs' hasFeedback>
            {getFieldDecorator('detail_cultural', {
              initialValue: item.detail_cultural
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Interpreter required'>
                {getFieldDecorator('detail_interpreter', {
                  valuePropName: 'checked',
                  initialValue: item.detail_interpreter || false
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Aboriginal or Torres Strait Islander'>
                {getFieldDecorator('detail_aboriginal', {
                  valuePropName: 'checked',
                  initialValue: item.detail_aboriginal || false
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>
        <Panel title='Other Info'>
          <FormItem {...formItemLayout} label='Health care professionals' hasFeedback>
            {getFieldDecorator('other_healthcare', {
              initialValue: item.other_healthcare
            })(
              <TextArea rows={4} />
            )}
          </FormItem>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Medicare'>
                {getFieldDecorator('other_medicare', {
                  initialValue: item.other_medicare,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Private Health Fund'>
                {getFieldDecorator('other_private_fund', {
                  initialValue: item.other_private_fund,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Pension Card'>
                {getFieldDecorator('other_pension_card', {
                  initialValue: item.other_pension_card,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Health Care Card'>
                {getFieldDecorator('other_healthcare_card', {
                  initialValue: item.other_healthcare_card,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Companion Card'>
                {getFieldDecorator('other_companion_card', {
                  initialValue: item.other_companion_card,
                  rules: [
                    { min: 2, message: 'Name must be between 2 and 128 characters' },
                    { max: 128, message: 'Name must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Preferred Hospital'>
                {getFieldDecorator('other_preferred_hospital', {
                  initialValue: item.other_preferred_hospital,
                  rules: [
                    { min: 2, message: 'Contact must be between 2 and 128 characters' },
                    { max: 128, message: 'Contact must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter contact' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Ambulance'>
                {getFieldDecorator('other_ambulance', {
                  initialValue: item.other_ambulance,
                  rules: [
                    { min: 2, message: 'Ambulance must be between 2 and 128 characters' },
                    { max: 128, message: 'Ambulance must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Disabled Parking'>
                {getFieldDecorator('other_disabled_parking', {
                  initialValue: item.other_disabled_parking
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Loading>
    </Form>
  }

  handleAuthorizeRepChange = (e) => {
    if (e === false) {
      this.setState({ showAuthorizeRep: false })
    } else {
      this.setState({ showAuthorizeRep: true })
    }
  }

  handleDOBChange = (e) => {
    const currYear = Moment()
    const ageNum = currYear.diff(e, 'years')

    this.setState({ currentAge: ageNum })
  }

  // handleSADateChange = (e) => {
  //   this.checkActiveDisable(e)
  // }

  handleFunderCategoryLoaded = async (funderId) => {
    const categories = await funderService.getBillingCategory(funderId)
    this.setState({ funderBillingCategories: categories })
  }

  // checkActiveDisable = (value = undefined) => {
  //   const { form } = this.props
  //   const date = value === undefined ? form.getFieldValue('service_agreement_date') : value
  //   let status = false

  //   if (date) {
  //     status = false
  //   } else {
  //     form.setFieldsValue({ active: false })
  //     status = true
  //   }

  //   this.setState({shouldActive: status})
  // }

  fetchClient = async () => {
    try {
      const { match, form } = this.props
      const { params } = match
      const { id } = params
      this.setState({ loading: true })
      const { item } = await clientService.get(id)

      // get current age
      const empDate = Moment(item.dob)
      const currYear = Moment()
      const ageNum = currYear.diff(empDate, 'years')
      const defaultAuthorize = item.authorize_rep

      // get balance hour
      let balanceHours = { message: '', remaining_hours: null }
      if (item.id) {
        balanceHours = await clientService.getClientBalanceHours(item.id, 'now')
        item.remaining_hours = balanceHours.plan_allocated_hrs && balanceHours.remaining_hours !== null ? `${formatter.toDecimalOptional(balanceHours.remaining_hours)} Hour${balanceHours.remaining_hours === 1 ? '' : 's'}` : 'No plan allocated hours'
      }

      this.setState({ item, loading: false, clientId: id, currentAge: ageNum, showAuthorizeRep: defaultAuthorize, shouldActive: !item.service_agreement_date || false, showExitedToggle: !item.active })
      // if no service agreement date, form is forced to set active to false
      // service agreement date already obsolete, moved to plan period instead
      // if (!item.service_agreement_date) {
      //   form.setFieldsValue({ active: false })
      // }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load participant successfully. Please try again later.')
      this.setState({ loading: false })
    }
  }

  fetchCustomIdentifierSetting = async () => {
    const filter = {}
    filter.module = { condition: '=', value: 'participant' }
    filter.active = { condition: '=', value: true }
    const customFields = await settingCustomIdentifier.listByPage(1, 0, filter)
    this.setState({ identifierSetting: customFields.list })
  }

  fetchFunders = async () => {
    const funders = await funderService.listByPage(1, 0)
    this.setState({ funders: funders.list })
  }

  fetchFiles = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    const clientId = id

    const filter = {}
    // filter.type = {
    //   $or: [
    //     { condition: '=', value: ClientFileTypes.CARE_PLAN },
    //     { condition: '=', value: ClientFileTypes.OTHER }
    //   ]
    // }
    filter.module_id = { condition: '=', value: clientId }
    filter.module = { condition: '=', value: clientModule }
    filter.has_mandatory = { condition: '=', value: true }
    filter.active = { condition: '=', value: true }
    filter.classification = { condition: '=', value: 'participant' }

    const fileTypes = await clientFileService.listByPage(1, 0, filter)
    this.setState({ uploadedMandatory: fileTypes.list })

    // const care_plan = fileTypes.list.filter(item => item.type === ClientFileTypes.CARE_PLAN)
    // const other = fileTypes.list.filter(item => item.type === ClientFileTypes.OTHER)
    // this.setState({
    //   driverLicense: driver_license[0],
    //   dwes: dwes[0],
    //   firstAid: first_aid[0],
    //   policeCheck: police_check[0],
    //   vehicleInsurance: vehicle_insurance[0],
    //   vehicleRegistration: vehicle_registration[0],
    //   wwcc: wwcc[0]
    // })
  }

  fetchLogs = async () => {
    const { match } = this.props
    const { params } = match
    const { id } = params

    const filter = {}
    filter.type = { condition: '=', value: 'participant' }
    filter.type_id = { condition: '=', value: id }

    const logs = await logService.listByPage(1, 20, filter)
    this.setState({
      logList: logs.list
    })
  }

  fetchReasons = async () => {
    const filter = {}
    filter.code = { condition: '=', value: 'inactive-client' }

    const reasons = await settingReasonService.listItemsByPage(1, 0, filter)
    this.setState({ reasons: reasons.list })
  }

  fetchSettingFilesMandatory = async (currentPage = 1) => {
    const filter = {}
    this.setState({ loading: true })

    filter.active = { condition: '=', value: true }
    filter.has_mandatory = { condition: '=', value: true }
    filter.classification = { condition: '=', value: 'participant' }

    const { list: documents } = await clientFileService.listDocumentByPage(currentPage, 0, filter)
    this.setState({ loading: false, settingsMandatory: documents })
  }

  fetchSettings = async () => {
    const filter = {}
    filter.type = {
      $or: [
        { condition: '=', value: 'payroll' },
        { condition: '=', value: 'language' },
        { condition: '=', value: 'service-provider' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'funding' },
        { condition: '=', value: 'height' },
        { condition: '=', value: 'relationship' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      payrolls: settings.list.filter(item => item.type === 'payroll'),
      languages: settings.list.filter(item => item.type === 'language'),
      services: settings.list.filter(item => item.type === 'service-provider'),
      genders: settings.list.filter(item => item.type === 'gender'),
      funding: settings.list.filter(item => item.type === 'funding'),
      heights: settings.list.filter(item => item.type === 'height'),
      relationships: settings.list.filter(item => item.type === 'relationship')
    })
  }

  handleDelete = () => {
    const { history, match } = this.props
    const { params } = match
    const { id } = params

    confirm({
      title: 'Are you sure you want to delete this participant?',
      content: 'Press Ok to continue, Cancel to return',
      async onOk () {
        try {
          const response = await clientService.remove(id)

          if (response.id) {
            notify.success('Deleted successfully', 'Participant deleted successfully.')
            history.replace('/clients')
          }
        } catch (e) {
          notify.error('Unable to delete successfully', 'Unable to delete participant successfully. Please try again later.')
        }
      }
    })
  }

  handleDuplicateCheck = () => {
    const { form } = this.props
    const { validateFields } = form

    validateFields(['first_name', 'last_name'],
      async (errors, values) => {
        if (!errors) {
          const res = await clientService.checkDuplicateClient(values)

          if (res.total > 0) {
            warning({
              title: 'Participant already exists',
              content: (
                <div className='duplicate-warnings'>
                  {res.list.map((item, index) => {
                    const url = `/clients/${item.id}`
                    return (
                      <div key={index}><a href={url} rel='noopener noreferrer' target='_blank'>
                        {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                      </a>
                      </div>
                    )
                  })}
                </div>
              ),
              okText: 'OK',
              onOk () { }
            })

            this.setState({ isDuplicateClient: true, duplicateClientInfo: res })
          } else {
            this.setState({ isDuplicateClient: false, duplicateClientInfo: {} })
          }
        }
      })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleActiveChange (value) {
    this.setState({ showExitedReason: !value, isExitedToggleChange: !value })

    // if (!value) {
    //   const { form } = this.props
    //   const { setFieldsValue } = form

    //   setTimeout(() => { setFieldsValue({ is_exited: false }) }, 200)
    // }
  }

  handleCancelInactiveReason () {
    const { form } = this.props
    const { setFieldsValue, getFieldValue } = form

    setFieldsValue({ active: !getFieldValue('active') })

    this.setState({ showExitedReason: false, isExitedToggleChange: false })
  }

  handleExitedReason (value) {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(['exited_reason'], async (errors, values) => {
      if (!errors) {
        this.setState({ showExitedReason: false })
      }
    })
  }

  handleNDISNumCheck = () => async (rule, value, callback) => {
    const { item } = this.state
    const res = await clientService.checkDuplicateNDISNum({ ndis_number: value, id: item.id })

    if (res && res.total > 0) {
      try {
        throw new Error(`This ndis number already exists`)
      } catch (err) {
        callback(err)
      }
    } else {
      callback()
    }
  }

  checkBeforeSave = () => {
    const { form } = this.props
    const { getFieldValue } = form
    const { isDuplicateClient, duplicateClientInfo } = this.state
    const { handleSave } = this

    const mandatory = getFieldValue('mandatory')
    this.setState({ hasMissingMandatoryFiles: false })

    // Check whether all mandatory categories have file uploaded; missing file uploaded disactive employee profile but still allow to save
    let hasFileList = []
    if (validator.isArray(mandatory)) {
      hasFileList = mandatory.filter(item => item.file_name && item.file_url)
    }

    if (isDuplicateClient && duplicateClientInfo.total > 0) {
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div className='duplicate-warnings'>
            <div style={{ fontSize: '14px' }}>
              <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              <strong> Participant already exists</strong>
            </div>
            {duplicateClientInfo.list.map((item, index) => {
              const url = `/clients/${item.id}`
              return (
                <div key={index}><a href={url} rel='noopener noreferrer' target='_blank'>
                  {`${item.first_name} ${item.last_name} - Ref: ${item.acc_ref}`}
                </a>
                </div>
              )
            })}

            <div><br /><br /><mark><strong>Please click OK to proceed or Cancel to go back.</strong></mark></div>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () { handleSave() },
        onCancel () { }
      })
    } else if (validator.isArray(mandatory) && mandatory.length !== 0 && mandatory.length !== hasFileList.length) {
      this.setState({ hasMissingMandatoryFiles: true })
      confirm({
        title: 'Proceed To Save?',
        content: (
          <div className='duplicate-warnings'>
            <div style={{ fontSize: '14px' }}>
              <Icon type='exclamation-circle' theme='filled' style={{ color: '#f6ad32', fontSize: '12pt' }} />
              <strong> Missing files in "Mandatory Files". Participant profile will be deactivate when proceed to save</strong>
            </div>

            <div><br /><br /><mark><strong>Please click OK to proceed or Cancel to return.</strong></mark></div>
          </div>
        ),
        okText: 'OK',
        cancelText: 'Cancel',
        onOk () { handleSave() },
        onCancel () {}
      })
    } else {
      handleSave()
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    let extraLog = ''

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { fetchingClients, history } = this.props
        const { item, longitude, latitude, hasMissingMandatoryFiles, uploadedMandatory } = this.state
        this.setState({ loading: true })

        if (longitude && latitude) {
          values.longitude = longitude
          values.latitude = latitude
        }

        const clientActive = form.getFieldValue('active')

        if (clientActive) {
          values.active = true
          values.is_exited = false
        } else {
          values.active = false
        }
        if (hasMissingMandatoryFiles) {
          values.active = false
        }

        if (values.postcode === '') {
          values.postcode = null
        }

        if (values.exited_reason && values.exited_reason !== '') {
          extraLog += `Inactive Reason "${values.exited_reason}" ${values.exited_reason_note ? `, Inactive Note "${values.exited_reason_note}"` : ''}`
        }

        delete values.exited_reason
        delete values.exited_reason_note

        try {
          if (this.isEdit()) {
            if (item.ndis_number !== values.ndis_number) {
              values.hasNDISChange = true
            }

            const response = await clientService.save(item.id, values)
            this.setState({ item: { ...item, ...values }, loading: false })

            const fileList = values.mandatory || []
            delete values.mandatory

            if (response.id) {
              notify.success('Saved successfully', 'Participant saved successfully.')
              log.updateClient(response.id, item, values, [], extraLog, [{ key: 'ndis_number', label: 'NDIS Number' }])

              trigger.updateClient(
                response.id,
                `${values.first_name} ${values.last_name}`,
                item,
                values,
                [
                  { key: 'first_name' },
                  { key: 'last_name' },
                  { key: 'address' },
                  { key: 'mobile_phone', label: 'Mobile Number' },
                  { key: 'email' },
                  { key: 'gender' },
                  { key: 'dob', label: 'Date of Birth' },
                  { key: 'phone_number' },
                  { key: 'payroll_category', label: 'Participant Difficulty' },
                  { key: 'ndis_number', label: 'NDIS Number' },
                ]
              )

              // prepare and log each mandatory files uploaded
              for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i]

                if (file.file_name && file.file_url) {
                  let extraFileLog = []
                  let logItemBefore = {}

                  // get previously uploaded file, if have value return prepare log item
                  const item = uploadedMandatory.find(e => e.id === file.id)
                  if (item) {
                    logItemBefore.label = item.label || ''
                    logItemBefore.file_name = formatter.toStandardFileName(item.file_name) || ''
                    logItemBefore.active = item.active
                    logItemBefore.issuance_date = item.issuance_date
                    logItemBefore.expiry_date = item.expiry_date
                  }

                  let logItemAfter = {
                    label: file.label || '',
                    file_name: formatter.toStandardFileName(file.file_name) || '',
                    active: true,
                    issuance_date: file.issuance_date
                  }
                  if (file.expiry_date) {
                    logItemAfter.expiry_date = file.expiry_date
                  }
                  extraFileLog.push(`${file.main_category} - ${file.sub_category}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

                  if (item) {
                    log.updateClientFile(response.id, logItemBefore, logItemAfter, [], extraFileLog.join())
                  } else {
                    log.addClientFile(response.id, logItemAfter, [], extraFileLog.join())
                  }
                }
              }


              fetchingClients(true)
              window.location.replace(`/clients/${response.id}`)
            }
          } else {
            const response = await clientService.add(values)
            this.setState({ loading: false })

            const fileList = values.mandatory || []
            delete values.mandatory

            if (response.id) {
              notify.success('Saved successfully', 'Participant saved successfully.')
              const { id, acc_ref } = response
              log.addClient(id, `New participant ${values.first_name} ${values.last_name}`)

              // prepare and log each mandatory files uploaded
              for (let i = 0; i < fileList.length; i++) {
                const file = fileList[i]

                if (file.file_name && file.file_url) {
                  let extraFileLog = []
                  let logItemAfter = {
                    label: file.label || '',
                    file_name: formatter.toStandardFileName(file.file_name) || '',
                    active: true,
                    issuance_date: file.issuance_date
                  }
                  if (file.expiry_date) {
                    logItemAfter.expiry_date = file.expiry_date
                  }
                  extraFileLog.push(`${file.main_category} - ${file.sub_category}${file.label ? ` - ${file.label}` : ''}${file.file_name ? `(${formatter.toStandardFileName(file.file_name)})` : ''}`)

                  log.addClientFile(id, logItemAfter, [], extraFileLog.join())
                }
              }

              this.setState({ item: { ...item, ...values, id, acc_ref } })
              window.location.replace(`/clients/${id}`)
              fetchingClients(true)
            }
          }

          const empDate = Moment(values.dob)
          const currYear = Moment()
          const ageNum = currYear.diff(empDate, 'years')
          this.setState({ currentAge: ageNum })

          this.props.setRefreshActivityLog(true)
        } catch (e) {
          notify.error('Unable to save successfully', 'Unable to save participant successfully. Please try again later.')
          this.setState({ loading: false })
        }

        this.fetchLogs()
      }
    })
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  updateInfo = () => {
    this.fetchClient()
    this.fetchFiles()
  }
}

const mapDispatchToProps = {
  fetchingClients,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Client))
