import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'

import './styles.css'

export default class WDDatePicker extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
  }

  render () {
    const { datePicker, fieldDecorator, form, formItem = {}, readOnly } = this.props
    const { getFieldDecorator } = form
    const { id, options = {} } = fieldDecorator
    const { format: dateFormat } = datePicker
    formItem.className = formatter.toClassName(['wd-datepicker', formItem.className])

    return (
      <Form.Item hasFeedback={!readOnly} {...formItem}>
        {readOnly ? (
          <span className='ant-form-text'>{dateFormat ? formatter.toDate(options.initialValue, dateFormat) : formatter.toStandardDate(options.initialValue)}</span>
        ) : (
          getFieldDecorator(id, options)(<DatePicker {...datePicker} />)
        )}
      </Form.Item>
    )
  }
}

WDDatePicker.propTypes = {
  datePicker: PropTypes.object,
  fieldDecorator: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  formItem: PropTypes.object,
  readOnly: PropTypes.bool.isRequired
}
