import moment from 'moment-timezone'
import numeral from 'numeral'

moment.tz.setDefault('Australia/Melbourne')

export default {
  capitalize (str, toLowerCase = true) {
    if (typeof str === 'string') {
      const words = str.split(' ')
      return words.map((word) => word.charAt(0).toUpperCase() + (toLowerCase ? word.substring(1).toLowerCase() : word.substring(1))).join(' ')
    }

    return ''
  },
  round (number) {
    if (typeof number === 'number') {
      return Math.round(number * 100) / 100
    }

    return 0
  },
  toClassName (arr) {
    if (arr && arr.length > 0) {
      return arr.filter((str) => typeof str === 'string' && str).join(' ')
    }

    return ''
  },
  toMoment (str) {
    if (str) {
      return moment(str)
    }

    return moment(new Date())
  },
  toDate (str, format) {
    if ((typeof str === 'string' || str) && typeof format === 'string') {
      return moment(str).format(format)
    }

    return ''
  },
  toNumber (number) {
    const num = numeral(number)
    return num.value()
  },
  toDecimal (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0,0.00')}`
    }

    return 0
  },
  toDecimalOptional (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0,0.[00]')}`
    }

    return 0
  },
  toDecimalWithoutComma (number) {
    if (typeof number === 'number') {
      return `${numeral(number).format('0.00')}`
    }

    return 0
  },
  toPercentage (value, decimal = 1) {
    return value ? Number(Math.round(value + `e${decimal}`) + `e-${decimal}`) + '%' : '0%'
  },
  toPrice (number, currency = 'RM') {
    if (typeof number === 'number') {
      return `${currency} ${numeral(number).format('0,0.00')}`
    }

    return 0
  },
  toShortDate (str) {
    return this.toDate(str, 'DD/MM/YYYY')
  },
  toDay (str) {
    return this.toDate(str, 'dddd')
  },
  toHourText (hr) {
    const val = numeral(hr / 60).format('0.0')
    return hr === 60 ? `(${val} Hr) ` : `(${val} Hrs)`
  },
  toStandardDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm A')
  },
  toStandardLongDate (str) {
    return this.toDate(str, 'DD/MM/YYYY hh:mm:ss A')
  },
  toShortTime (str) {
    return this.toDate(str, 'hh:mm A')
  },
  toIcon (mediaType, defaultIcon) {
    if (typeof mediaType === 'string') {
      if (mediaType.startsWith('audio')) {
        return '/icon/audio.svg'
      } else if (mediaType.startsWith('video')) {
        return '/icon/video.svg'
      } else {
        switch (mediaType) {
          case 'application/pdf':
            return '/icon/pdf.svg'
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
          case 'application/vnd.ms-word.document.macroEnabled.12':
          case 'application/vnd.ms-word.template.macroEnabled.12':
            return '/icon/doc.svg'
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
          case 'application/vnd.ms-excel.sheet.macroEnabled.12':
          case 'application/vnd.ms-excel.template.macroEnabled.12':
          case 'application/vnd.ms-excel.addin.macroEnabled.12':
          case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
            return '/icon/xls.svg'
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          case 'application/vnd.openxmlformats-officedocument.presentationml.template':
          case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
          case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
          case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
            return '/icon/ppt.svg'
          case 'image/jpeg':
            return '/icon/jpg.svg'
          case 'image/png':
            return '/icon/png.svg'
          default:
            return '/icon/file.svg'
        }
      }
    }

    return defaultIcon || '/icon/resource.svg'
  },
  duration (date) {
    const duration = moment.duration(moment().endOf('days').diff(moment(date).endOf('days')))
    return duration.asDays().toFixed(0)
  },
  expiringDuration (date) {
    const duration = moment.duration(moment(date).endOf('days').diff(moment().endOf('days')))
    return duration.asDays().toFixed(0)
  },
  toStandardFileName (str) {
    const fileNameIndex = `${str}`.lastIndexOf('_')
    const fileExtIndex = `${str}`.lastIndexOf('.')
    const name = decodeURI(str.substring(0, fileNameIndex))
    const extName = str.substring(fileExtIndex)
    return name + extName
  },
  toShortenText (str, range = 30) {
    let length = str.length
    let index = str.indexOf(' ', range)
    index = index === -1 ? length : index
    return length <= range ? str : str.substring(0, index) + (index === length ? '' : '...')
  }
}
