import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchProviders } from '../../../states/actions/provider'
import { ProviderMenu } from '../../../constants'
import { apiHostname } from '../../../config'
import { queryString } from '../../../util'

// UI
import { ControlLabel, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'

const pageSize = 40
const Option = Select.Option

export class Provider extends Component {
  constructor (props) {
    super(props)
    const { providers: { list, total }, loading } = props
    this.state = {
      currentPage: 1,
      filter: { active: true },
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      phones: [],
      total
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    this.onSelectActive = this.onSelectActive.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { providers: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    const line = 55
    this.fetchProviders({ currentPage, filter, loading, searchText, sort, line })

    if (window) {
      window.scrollTo(0, 0)
    }
  }

  onSearchName (value) {
    const { currentPage, filter, loading, sort } = this.state
    this.setState({ searching: true })

    if (value && value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { fullname: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    const line = 83
    this.fetchProviders({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort, line })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onSelectActive (value) {
    const { currentPage, filter, loading, searchText, sort } = this.state
    filter.active = value
    const line = 91
    this.fetchProviders({ currentPage, filter, loading, searchText, sort, line })
    this.setState({ filter })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  async export () {
    const { filter } = this.state

    this.setState({ isGenerating: true })
    window.location.href = `${apiHostname}/api/export/list/provider${queryString.stringify({ filter: JSON.stringify(filter) })}`
    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 4000)
  }

  render () {
    const { currentPage, list, loading, searching, total } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ProviderMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Providers'>
            <Link to='/providers/add'>
              {
                this.hasAccess('createProvider') ? <div className='btn'>
                Add
                </div>
                  : null
              }
            </Link>
            {
              this.hasAccess('listFunders') ? <div className='btn' onClick={() => this.export()}>
              Export
              </div> : null
            }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={8}>
                <ControlLabel>Name, Acc Ref ID, Suburb</ControlLabel>
                <SearchInput placeholder='Search providers' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
              </Col>
              <Col lg={8}>
                <ControlLabel>Active</ControlLabel>
                <Select defaultValue='true' style={{ width: 120 }} onChange={this.onSelectActive}>
                  <Option value='true'>Active</Option>
                  <Option value='false'>Inactive</Option>
                </Select>
              </Col>
            </Row>
          </Page.Filter>

          <div className='providers'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const { id, acc_ref, fullname, phone_number, suburb, active } = item

                return (
                  <Link to={`/providers/${id}`} key={id}>
                    <div className={`list-item ${active ? '' : 'list-item-dim'}`}>
                      <Row className='list-content'>
                        <Col md={6}><img alt='' src={process.env.PUBLIC_URL + '/img/love.png'} className='avatar' /></Col>

                        <Col md={18}>
                          <div className='name'>{fullname}</div>
                          <div style={{ fontSize: '8pt' }}>{acc_ref}</div>
                          <div style={{ fontSize: '8pt' }}>{suburb}</div>
                        </Col>

                      </Row>
                    </div>
                  </Link>
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} providers`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    const line = 191
    this.fetchProviders({ currentPage, filter, searchText, sort, line })
  }

  fetchProviders = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText, line }) => {
    try {
      // const { fetchProviders } = this.props
      this.setState({ currentPage })

      this.props.fetchProviders({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load providers successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchProviders
}

const mapStateToProps = (state) => {
  return { ...state.Provider }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Provider)
