import { FETCHING_PAYROLLS, PAYROLLS_FETCHED, PAYROLL_TIMESHEETS_FETCHED, PAYROLL_TIMESHEET_SUM_FETCHED } from '../actions/payroll'

const INITIAL_STATE = {
  loading: true,
  payrolls: { list: [], total: 0 },
  timesheets: { list: [], total: 0 },
  timesheetSummary: { jobs: 0, kms: 0, sleepovers: 0, hours: 0 }
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_PAYROLLS:
      return handleFetchingPayrolls(state, action)
    case PAYROLLS_FETCHED:
      return handlePayrollsFetched(state, action)
    case PAYROLL_TIMESHEETS_FETCHED:
      return handleTimesheetsFetched(state, action)
    case PAYROLL_TIMESHEET_SUM_FETCHED:
      return handleTimesheetSummaryFetched(state, action)
    default:
      return state
  }
}

function handleFetchingPayrolls (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handlePayrollsFetched (state, action) {
  const { payrolls, loading } = action
  return { ...state, payrolls, loading }
}

function handleTimesheetsFetched (state, action) {
  const { timesheets, loading } = action
  return { ...state, timesheets, loading }
}

function handleTimesheetSummaryFetched (state, action) {
  const { timesheetSummary, loading } = action
  return { ...state, timesheetSummary, loading }
}
