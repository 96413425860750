export default [
  {
    name: 'Billings List',
    desc: 'Manage all customers from different channels',
    icon: 'team',
    link: '/billings',
    enable: true,
    permission: 'listPayrolls'
  }
]
